var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-snackbar', {
    staticClass: "snackbar",
    attrs: {
      "color": "primary",
      "elevation": "16",
      "timeout": 500
    },
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('v-row', {
    staticClass: "mb-n6"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "50"
    }
  }, [_c('v-icon', {
    staticClass: "icon",
    attrs: {
      "large": "",
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-account-star ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1 ml-n2"
  }, [_vm._v("Top Members")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.items,
      "label": "Date Range",
      "solo": "",
      "dense": "",
      "flat": ""
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('base-card', [_c('div', {
    staticClass: "pa-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column text-start align-start justify-start"
  }, [_c('p', {
    staticClass: "mb-0 text-body-2 font-weight-bold secondary--text"
  }, [_c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "blue lighten-5"
    }
  }, [_c('span', {
    staticClass: "blue--text"
  }, [_vm._v("%")])]), _vm._v(" Top Members' Contribution ")], 1), _c('h3', {
    staticClass: "font-600 secondary--text ml-12"
  }, [_vm._v(" 0 ")])])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column text-start align-start justify-start mb-n6"
  }, [_c('p', {
    staticClass: "mb-0 text-body-2 font-weight-bold secondary--text"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "label": "",
      "small": "",
      "color": "green lighten-5"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "green",
      "small": ""
    }
  }, [_vm._v(" mdi-shape ")])], 1), _vm._v(" Groups "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "color": "grey darken-1"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip")])])], 1), _c('div', {
    staticClass: "ml-n4 mt-2"
  }, [_c('v-select', {
    attrs: {
      "dense": "",
      "items": _vm.items,
      "multiple": "",
      "readonly": "",
      "solo": "",
      "flat": "",
      "color": "white"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return [index < 2 ? _c('v-chip', {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "green lighten-5"
          }
        }, [_c('span', {
          staticClass: "green--text"
        }, [_vm._v(_vm._s(item))])]) : _vm._e(), index === 1 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v("(+" + _vm._s(_vm.value.length - 1) + " others)")])];
            }
          }], null, true)
        }, [_c('span', [_c('ul', {
          staticStyle: {
            "list-style-type": "none"
          }
        }, _vm._l(_vm.items, function (item, index) {
          return _c('li', {
            key: index
          }, [_vm._v(" " + _vm._s(item) + " ")]);
        }), 0)])])], 1) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column text-start align-start justify-start mb-n6"
  }, [_c('p', {
    staticClass: "mb-0 text-body-2 font-weight-bold secondary--text"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "label": "",
      "small": "",
      "color": "grey lighten-2"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey",
      "small": ""
    }
  }, [_vm._v(" mdi-alpha-t-circle ")])], 1), _vm._v(" Topics "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "color": "grey darken-1"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip")])])], 1), _c('div', {
    staticClass: "ml-n4 mt-2"
  }, [_c('v-select', {
    attrs: {
      "dense": "",
      "items": _vm.items,
      "multiple": "",
      "readonly": "",
      "solo": "",
      "flat": "",
      "color": "white"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref5) {
        var item = _ref5.item,
          index = _ref5.index;
        return [index < 2 ? _c('v-chip', {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "grey lighten-2"
          }
        }, [_c('span', {
          staticClass: "grey--text"
        }, [_vm._v(_vm._s(item))])]) : _vm._e(), index === 1 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var on = _ref6.on,
                attrs = _ref6.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v("(+" + _vm._s(_vm.value.length - 1) + " others)")])];
            }
          }], null, true)
        }, [_c('span', [_c('ul', {
          staticStyle: {
            "list-style-type": "none"
          }
        }, _vm._l(_vm.items, function (item, index) {
          return _c('li', {
            key: index
          }, [_vm._v(" " + _vm._s(item) + " ")]);
        }), 0)])])], 1) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)])])], 1)], 1)]), [_c('v-data-table', {
    staticClass: "elevation-1 rounded-table",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.members,
      "sort-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "item.avatar",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-img', {
          attrs: {
            "src": item.avatar,
            "max-height": "50px",
            "max-width": "50px"
          }
        })];
      }
    }, {
      key: "item.authProvider",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-avatar', {
          attrs: {
            "size": "25"
          }
        }, [_c('img', {
          attrs: {
            "src": require(("@/assets/images/logos/" + (item.authProvider) + ".svg"))
          }
        })])];
      }
    }, {
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": "",
            "color": "landing_grey"
          }
        }, [_c('div', {
          staticClass: "mb-2 mt-2"
        }, [_c('v-row', [_c('h4', {
          staticClass: "text-xl secondary--text ml-2 mt-3"
        }, [_vm._v(" Top Performing Members ")])]), _c('v-row', [_c('p', {
          staticClass: "grey--text text--darken-4 ml-2 mb-1 text-caption text--weight-bold"
        }, [_vm._v(" Top Performing Members based on Engagement Score achived during selected time period. ")])])], 1)])];
      },
      proxy: true
    }, {
      key: "item.resumeDate",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('v-chip', [_c('v-icon', {
          staticClass: "icon"
        }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
          staticClass: "ma-1 secondary--text text-sm"
        }, [_vm._v(_vm._s(_vm.formatDateTime(item.resumeDate)))])], 1)];
      }
    }, {
      key: "item.isDeletedFromDirectory",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('v-switch', {
          attrs: {
            "color": "primary"
          },
          model: {
            value: item.isDeletedFromDirectory,
            callback: function ($$v) {
              _vm.$set(item, "isDeletedFromDirectory", $$v);
            },
            expression: "item.isDeletedFromDirectory"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [item.isDeletedFromDirectory ? _c('div', [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref12) {
              var on = _ref12.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-cancel")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" Archived ")])])], 1) : _c('div', [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "medium": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editItem(item);
            }
          }
        }, [_vm._v(" mdi-pencil ")]), _c('v-icon', {
          attrs: {
            "medium": ""
          },
          on: {
            "click": function ($event) {
              _vm.deleteItem(_vm.members.indexOf(item));
            }
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.initialize
          }
        }, [_vm._v(" Reset ")])];
      },
      proxy: true
    }])
  })]], 2)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
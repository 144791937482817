var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: [{
      'sale-page-one': _vm.$route.path == '/sale-page-one'
    }, {
      'sale-page-two': _vm.$route.path == '/sale-page-two'
    }]
  }, [_c('v-app-bar', {
    staticClass: "navbar white",
    class: {
      'd-none': _vm.$route.path == '/sale-page-two'
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "navbar-toggle-dropdown p-absolute",
    class: {
      open: _vm.isToggleNavbar
    },
    attrs: {
      "id": "navbar-toggle-dropdown"
    }
  }, [_c('navbar')], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-avatar', {
    staticClass: "ml-n6 mt-3",
    attrs: {
      "width": "auto",
      "height": "35",
      "tile": ""
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/CheqqMateforCreators.Logo.png"),
      "alt": ""
    }
  })])], 1)], 1), _c('v-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('ul', {
    staticClass: "navbar-nav navigation-navbar d-flex flex-row"
  }, [_c('li', {
    staticClass: "nav-item me-3 mt-5"
  }, [_c('a', {
    staticClass: "nav-link secondary--text",
    attrs: {
      "href": "/"
    }
  }, [_vm._v("Home")]), _c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/dashboard"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Dashboard ")])])], 1)])]), _c('li', {
    staticClass: "nav-item me-3 mt-5"
  }, [_c('a', {
    staticClass: "nav-link secondary--text"
  }, [_vm._v("User Account")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "d-flex justify-space-between align-center",
    attrs: {
      "href": "#"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Profile")]), _c('v-icon', [_vm._v("mdi-menu-right")])], 1), _c('div', {
    staticClass: "navbar-submenu"
  }, [_c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/view-profile"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" View Profile ")])])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/edit-profile"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Edit Profile ")])])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/manage-subscription"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Manage Subscription ")])])], 1)])])])])])])]), _c('v-col', {
    attrs: {
      "md": "4"
    }
  }), this.$store.state.auth.authUser || _vm.loggedIn ? _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "md": "2"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/view-profile"
    }
  }, [_c('v-avatar', {
    staticClass: "me-3 mt-3 left-0",
    attrs: {
      "size": "40"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm._userProfile.avatar
    }
  })], 1)], 1), _c('p', {
    staticClass: "secondary--text mt-5"
  }, [_vm._v(" " + _vm._s(_vm.authUser.email ? _vm._userProfile.username : _vm.authUser.email) + " ")])], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "md": "1"
    }
  }, [!this.$store.state.auth.authUser || !_vm.loggedIn ? _c('div', [_c('v-btn', {
    staticClass: "font-600 text-capitalize mt-3",
    attrs: {
      "href": "/sign-in",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v(" Login ")])], 1) : _c('div', [_c('v-btn', {
    staticClass: "font-600 text-capitalize mt-3",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.logOut
    }
  }, [_vm._v(" Sign Out ")])], 1)])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
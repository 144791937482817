var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-overlay', {
    attrs: {
      "value": _vm.isLoading
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
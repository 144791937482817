var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-12"
  }, [_c('v-snackbar', {
    staticClass: "snackbar",
    attrs: {
      "color": "primary",
      "elevation": "16",
      "timeout": 1000
    },
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-sitemap ")])], 1), _c('h2', {
    staticClass: "mb-0 grey--text text--darken-1"
  }, [_vm._v(" Workflows ")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), _c('v-divider', {
    staticClass: "mb-4"
  }), _vm._v(" " + _vm._s(_vm.workflowDetails.workflowTree) + " "), _c('br'), _vm._v(" ACTIVE WORKFLOW ITEMS: " + _vm._s(_vm.initialisedWorkflowTree) + " "), _c('div', {
    ref: "canvas",
    staticClass: "workflow-canvas rounded-card pa-4",
    staticStyle: {
      "overflow": "auto"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('v-col', {
    staticClass: "pt-n1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-card', {
    staticClass: "ma-2",
    staticStyle: {
      "height": "60px"
    }
  }, [_c('v-row', {
    staticClass: "ma-2 pr-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Workflow Name",
      "outlined": "",
      "dense": "",
      "color": "secondary"
    },
    model: {
      value: _vm.workflowDetails.workflowName,
      callback: function ($$v) {
        _vm.$set(_vm.workflowDetails, "workflowName", $$v);
      },
      expression: "workflowDetails.workflowName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    staticClass: "ml-n2",
    attrs: {
      "fab": "",
      "color": "primary",
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.zoomIn
    }
  }, [_c('v-icon', {
    staticClass: "mt-1"
  }, [_vm._v("mdi-magnify-plus-outline")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    staticClass: "ml-n1",
    attrs: {
      "fab": "",
      "text": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.zoomOut
    }
  }, [_c('v-icon', {
    staticClass: "mt-1"
  }, [_vm._v("mdi-magnify-minus-outline")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.toggleFullscreen
    }
  }, [_c('v-icon', [_vm._v("mdi-fullscreen")])], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    staticClass: "mt-2 ml-n4 grey--text text--darken-1 font-weight-bold",
    attrs: {
      "large": "",
      "fab": "",
      "text": ""
    },
    on: {
      "click": _vm.updateWorkflow
    }
  }, [_vm._v(" Save ")])], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    staticClass: "mt-2 ml-n6",
    attrs: {
      "fab": "",
      "color": "grey darken-1",
      "text": "",
      "large": ""
    },
    on: {
      "click": _vm.resetWorkflow
    }
  }, [_c('v-icon', [_vm._v("mdi-restore")])], 1)], 1)], 1), _vm._l(_vm.workflowItems, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-12",
      style: {
        transform: ("scale(" + (_vm.zoomScale) + ")")
      }
    }, [_c('v-row', {
      attrs: {
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-card', {
      staticClass: "ma-1 pa-2",
      staticStyle: {
        "min-height": "80px"
      },
      style: {
        'border-left': _vm.hoverId === item.id ? '4px solid #AEB4BE' : ''
      },
      attrs: {
        "outlined": ""
      },
      on: {
        "mouseover": function ($event) {
          _vm.hoverId = item.id;
        },
        "mouseleave": function ($event) {
          _vm.hoverId = null;
        }
      }
    }, [_c('div', {
      staticClass: "pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline",
      staticStyle: {
        "min-height": "36px"
      }
    }, [_c('span', {
      staticStyle: {
        "min-height": "36px"
      }
    }, [_c('v-chip', {
      staticClass: "mr-1",
      attrs: {
        "label": "",
        "small": "",
        "color": _vm.getBadge(item.type).color
      }
    }, [_c('v-icon', {
      attrs: {
        "color": _vm.getBadge(item.type).textColor,
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.getBadge(item.type).icon) + " ")])], 1), _vm._v(" " + _vm._s(item.type) + " ")], 1), _c('span', {
      staticStyle: {
        "min-height": "36px"
      }
    }, [_c('v-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hoverId === item.id,
        expression: "hoverId === item.id"
      }],
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "teal lighten-2"
      },
      on: {
        "click": function ($event) {
          return _vm.editModal(item);
        }
      }
    }, [_vm._v(" mdi-pencil ")])], 1), _c('v-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hoverId === item.id,
        expression: "hoverId === item.id"
      }],
      attrs: {
        "icon": "",
        "disabled": _vm.hasChildren(item) || index === 0
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "red lighten-2"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteModal(item);
        }
      }
    }, [_vm._v(" mdi-delete ")])], 1)], 1), _c('v-card-text', [_c('p', {
      staticClass: "ml-8 mt-n6 text-none text-body-2 grey--text text--darken-1 font-weight-bold"
    }, [_vm._v(_vm._s(item.trigger))])]), _c('v-card-text', [_c('p', {
      staticClass: "mt-n8 text-none text-body-2 grey--text text--darken-1"
    }, [_vm._v(_vm._s(item.description))])])], 1)]), !_vm.hasChildren(item) ? _c('div', {
      staticClass: "d-flex justify-center"
    }, [_c('v-menu', {
      attrs: {
        "top": "",
        "offset-x": _vm.offset,
        "rounded": "lg",
        "nudge-width": 200
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "mt-n6 ml-2",
            attrs: {
              "fab": "",
              "small": "",
              "color": "primary"
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', {
            attrs: {
              "dark": ""
            }
          }, [_vm._v(" mdi-plus ")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item-content', {
      staticClass: "d-flex justify-center"
    }, [_c('v-list-item-title', {
      staticClass: "ml-4 grey--text text-overline text--darken-2"
    }, [_vm._v("Choose Workflow Item")])], 1), _c('v-divider'), _vm._l(_vm.items, function (item2, i) {
      return _c('v-list-item', {
        key: i,
        attrs: {
          "link": ""
        }
      }, [_c('v-btn', {
        staticClass: "ml-8 d-flex justify-start",
        attrs: {
          "icon": "",
          "plain": "",
          "small": ""
        },
        on: {
          "click": function ($event) {
            return _vm.addModal({
              item: item,
              itemType: item2
            });
          }
        }
      }, [_c('v-icon', {
        attrs: {
          "color": _vm.getBadge(item2.type).textColor,
          "small": ""
        }
      }, [_vm._v(" " + _vm._s(_vm.getBadge(item2.type).icon) + " ")]), _vm._v(" " + _vm._s(item2.type) + " ")], 1)], 1);
    })], 2)], 1)], 1) : _vm._e()], 1)], 1), _vm.hasChildren(item) ? _c('svg', {
      staticClass: "mb-6 mt-n2",
      staticStyle: {
        "width": "100%",
        "height": "60px"
      }
    }, [_c('line', {
      staticStyle: {
        "stroke": "rgb(174,180,190)",
        "stroke-width": "1"
      },
      attrs: {
        "x1": "50%",
        "y1": "10",
        "x2": "50%",
        "y2": "50"
      }
    })]) : _vm._e(), _vm._l(item.children, function (child, childIndex) {
      return _c('div', {
        key: ("child-" + childIndex)
      }, [child.id !== -1 && !(!_vm.hasChildren(child) && child.type === 'condition') ? _c('v-row', {
        attrs: {
          "justify": "center"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "6"
        }
      }, [_c('v-card', {
        staticClass: "mt-n4 ma-1 pa-2",
        class: _vm.hasChildren(child) ? 'mt-n11' : 'mt-n8',
        staticStyle: {
          "min-height": "80px"
        },
        style: {
          'border-left': _vm.hoverId === child.id ? '4px solid #AEB4BE' : ''
        },
        attrs: {
          "outlined": ""
        },
        on: {
          "mouseover": function ($event) {
            _vm.hoverId = child.id;
          },
          "mouseleave": function ($event) {
            _vm.hoverId = null;
          }
        }
      }, [_c('div', {
        staticClass: "pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline",
        staticStyle: {
          "min-height": "36px"
        }
      }, [_c('span', {
        staticStyle: {
          "min-height": "36px"
        }
      }, [_c('v-chip', {
        staticClass: "mr-1",
        attrs: {
          "label": "",
          "small": "",
          "color": _vm.getBadge(child.type).color
        }
      }, [_c('v-icon', {
        attrs: {
          "color": _vm.getBadge(child.type).textColor,
          "small": ""
        }
      }, [_vm._v(" " + _vm._s(_vm.getBadge(child.type).icon) + " ")])], 1), _vm._v(" " + _vm._s(child.type) + " ")], 1), _c('span', {
        staticStyle: {
          "min-height": "36px"
        }
      }, [_c('v-btn', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.hoverId === child.id,
          expression: "hoverId === child.id"
        }],
        attrs: {
          "icon": ""
        }
      }, [_c('v-icon', {
        attrs: {
          "color": "teal lighten-2"
        },
        on: {
          "click": function ($event) {
            return _vm.editModal(child);
          }
        }
      }, [_vm._v(" mdi-pencil ")])], 1), _c('v-btn', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.hoverId === child.id,
          expression: "hoverId === child.id"
        }],
        attrs: {
          "icon": "",
          "disabled": _vm.hasChildren(child)
        }
      }, [_c('v-icon', {
        attrs: {
          "color": "red lighten-2"
        },
        on: {
          "click": function ($event) {
            return _vm.deleteModal(child);
          }
        }
      }, [_vm._v(" mdi-delete ")])], 1)], 1), child.condition ? _c('v-card-text', {
        staticClass: "mb-n8"
      }, [_c('p', {
        staticClass: "ml-8 mt-n6 text-none text-body-2 grey--text text--darken-1 font-weight-bold"
      }, [_vm._v(_vm._s(child.condition.replace('greater than', '> ').replace('equals', '= ')) + " " + _vm._s(child.threshold))])]) : _vm._e(), _c('v-card-text', [_c('p', {
        staticClass: "text-none text-body-2 grey--text text--darken-1"
      }, [_vm._v(_vm._s(child.description))])])], 1)]), !_vm.hasSibling(child) || child.id === item.children[item.children.length - 1].id && !_vm.hasChildren(child) || item.children[item.children.length - 1].id === -1 ? _c('div', {
        staticClass: "d-flex justify-center"
      }, [_c('v-menu', {
        attrs: {
          "top": "",
          "offset-x": _vm.offset,
          "rounded": "lg",
          "nudge-width": 200
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref2) {
            var on = _ref2.on,
              attrs = _ref2.attrs;
            return [_c('v-btn', _vm._g(_vm._b({
              staticClass: "mt-n6",
              attrs: {
                "fab": "",
                "small": "",
                "color": "primary"
              }
            }, 'v-btn', attrs, false), on), [_c('v-icon', {
              attrs: {
                "dark": ""
              }
            }, [_vm._v(" mdi-plus ")])], 1)];
          }
        }], null, true)
      }, [_c('v-list', [_c('v-list-item-content', {
        staticClass: "d-flex justify-center"
      }, [_c('v-list-item-title', {
        staticClass: "ml-4 grey--text text-overline text--darken-2"
      }, [_vm._v("Choose Workflow Item")])], 1), _c('v-divider'), _vm._l(_vm.items, function (item, i) {
        return _c('v-list-item', {
          key: i,
          attrs: {
            "link": ""
          }
        }, [_c('v-btn', {
          staticClass: "ml-8 d-flex justify-start",
          attrs: {
            "icon": "",
            "plain": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.addModal({
                item: child,
                itemType: item
              });
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": _vm.getBadge(item.type).textColor,
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.getBadge(item.type).icon) + " ")]), _vm._v(" " + _vm._s(item.type) + " ")], 1)], 1);
      })], 2)], 1)], 1) : child.id === item.children[item.children.length - 1].id && _vm.hasSibling(child) ? _c('div', {
        staticClass: "d-flex justify-center",
        staticStyle: {
          "width": "100%",
          "height": "60px"
        }
      }, [_c('span', [_c('v-btn', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: !_vm.hasChildren(child),
          expression: "!hasChildren(child)"
        }],
        staticClass: "mt-n6 ml-6",
        attrs: {
          "fab": "",
          "small": "",
          "color": "primary"
        },
        on: {
          "click": function ($event) {
            return _vm.addModal(child);
          }
        }
      }, [_c('v-icon', {
        attrs: {
          "dark": ""
        }
      }, [_vm._v(" mdi-plus ")])], 1)], 1)]) : _vm._e(), _vm.hasChildren(child) && child.type === 'condition' && _vm.hasSibling(child) ? _c('svg', {
        staticClass: "mt-n16 mb-n12",
        staticStyle: {
          "width": "100%",
          "height": "120px"
        }
      }, [_c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "50%",
          "y1": "0",
          "x2": "50%",
          "y2": "50"
        }
      }), _c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "50%",
          "y1": "50",
          "x2": "10%",
          "y2": "50"
        }
      }), _c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "50%",
          "y1": "50",
          "x2": "89%",
          "y2": "50"
        }
      })]) : !_vm.hasSibling(child) ? _c('svg', {
        staticStyle: {
          "width": "100%",
          "height": "60px"
        }
      }) : _vm.hasSibling(child) && child.id !== item.children[item.children.length - 1].id ? _c('svg', {
        class: _vm.hasSibling(child) && child.id !== item.children[item.children.length - 1].id ? 'mt-n2 mb-3' : 'mt-n2 mb-6',
        staticStyle: {
          "width": "100%",
          "height": "60px"
        }
      }, [_c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "50%",
          "y1": "10",
          "x2": "50%",
          "y2": "50"
        }
      })]) : _vm._e()], 1)], 1) : _vm._e(), _vm.hasChildren(child) ? _c('v-row', {
        staticClass: "mt-n4",
        attrs: {
          "justify": "center"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [child.children && child.children.length > 0 ? _c('workflow-children', {
        staticClass: "mt-n16",
        attrs: {
          "children": child.children,
          "childV1": child,
          "childV2": item.children[item.children.length - 1].id,
          "parent": _vm.hasChildren(child) ? child : {},
          "parentV2": _vm.findParentV3(child)[0]
        },
        on: {
          "workflow-add": function ($event) {
            return _vm.addModalV2($event);
          },
          "workflow-edit": function ($event) {
            return _vm.editModal($event);
          },
          "workflow-delete": function ($event) {
            return _vm.deleteModal($event);
          }
        }
      }) : _vm._e()], 1)], 1) : _vm._e()], 1);
    })], 2);
  })], 2)], 1)])])])], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.editItemModal,
      callback: function ($$v) {
        _vm.editItemModal = $$v;
      },
      expression: "editItemModal"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "mb-4 grey--text text-overline text--darken-2"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "label": "",
      "small": "",
      "color": _vm.getBadge(_vm.editItemPayload.type).color
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.getBadge(_vm.editItemPayload.type).textColor,
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.getBadge(_vm.editItemPayload.type).icon) + " ")])], 1), _vm._v(" " + _vm._s(_vm.editItemPayload.type) + " ")], 1), _c('v-spacer'), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('v-menu', {
    attrs: {
      "offset-x": _vm.offset,
      "rounded": "lg",
      "nudge-width": 200
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mt-n3 ml-2",
          attrs: {
            "plain": "",
            "color": "primary",
            "disabled": _vm.editItemPayload.type === 'condition' || _vm.editItemPayload.type === 'trigger'
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "dark": ""
          }
        }, [_vm._v(" mdi-swap-horizontal-bold ")])], 1)];
      }
    }])
  }, [_c('v-list', [_c('v-list-item-content', {
    staticClass: "d-flex justify-center"
  }, [_c('v-list-item-title', {
    staticClass: "ml-4 grey--text text-overline text--darken-2"
  }, [_vm._v("Choose Workflow Item")])], 1), _c('v-divider'), _vm._l(_vm.items, function (item2, i) {
    return _c('v-list-item', {
      key: i,
      attrs: {
        "link": ""
      }
    }, [_c('v-btn', {
      staticClass: "ml-8 d-flex justify-start",
      attrs: {
        "icon": "",
        "plain": "",
        "small": ""
      },
      on: {
        "click": function ($event) {
          _vm.editItemPayload.type = item2.type, _vm.editItemPayload.description = item2.description, _vm.editItemPayload.sender = '', _vm.editItemPayload.trigger = '', _vm.editItemPayload.delays = '', _vm.editItemPayload.tags = '', _vm.editItemPayload.condition = '', _vm.editItemPayload.threshold = '';
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": _vm.getBadge(item2.type).textColor,
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.getBadge(item2.type).icon) + " ")]), _vm._v(" " + _vm._s(item2.type) + " ")], 1)], 1);
  })], 2)], 1)], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('at-ta', {
    attrs: {
      "members": _vm.members
    }
  }, [_c('v-textarea', {
    attrs: {
      "rows": _vm.editItemPayload && _vm.editItemPayload.type === 'message' ? '5' : '3',
      "color": "secondary",
      "hint": "Type '@' to mention a member",
      "outlined": "",
      "dense": "",
      "label": _vm.editItemPayload && _vm.editItemPayload.type === 'message' ? 'Message' : 'Description'
    },
    model: {
      value: _vm.editItemPayload.description,
      callback: function ($$v) {
        _vm.$set(_vm.editItemPayload, "description", $$v);
      },
      expression: "editItemPayload.description"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.editItemPayload && _vm.editItemPayload.type === 'message' ? _c('v-select', {
    staticClass: "mt-n4 mb-n16",
    attrs: {
      "items": _vm.senders,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Send message from"
    },
    model: {
      value: _vm.editItemPayload.sender,
      callback: function ($$v) {
        _vm.$set(_vm.editItemPayload, "sender", $$v);
      },
      expression: "editItemPayload.sender"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.editItemPayload && _vm.editItemPayload.type === 'trigger' ? _c('v-select', {
    staticClass: "mt-n8 mb-n8",
    attrs: {
      "items": _vm.triggers,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Criteria"
    },
    model: {
      value: _vm.editItemPayload.trigger,
      callback: function ($$v) {
        _vm.$set(_vm.editItemPayload, "trigger", $$v);
      },
      expression: "editItemPayload.trigger"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.editItemPayload && _vm.editItemPayload.type === 'delay' ? _c('v-select', {
    staticClass: "mt-n16 mb-n8",
    attrs: {
      "items": _vm.delays,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Delay (days)"
    },
    model: {
      value: _vm.editItemPayload.delays,
      callback: function ($$v) {
        _vm.$set(_vm.editItemPayload, "delays", $$v);
      },
      expression: "editItemPayload.delays"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_vm.editItemPayload && _vm.editItemPayload.type === 'remove-tag' ? _c('v-select', {
    staticClass: "mt-n16 mb-8",
    attrs: {
      "items": _vm.tags,
      "color": "secondary",
      "outlined": "",
      "label": "Remove Selected Tags",
      "chips": "",
      "multiple": ""
    },
    model: {
      value: _vm.editItemPayload.tags,
      callback: function ($$v) {
        _vm.$set(_vm.editItemPayload, "tags", $$v);
      },
      expression: "editItemPayload.tags"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_vm.editItemPayload && _vm.editItemPayload.type === 'tag' ? _c('v-select', {
    staticClass: "mt-n16 mb-8",
    attrs: {
      "items": _vm.tags,
      "color": "secondary",
      "outlined": "",
      "label": "Select Tags",
      "chips": "",
      "multiple": ""
    },
    model: {
      value: _vm.editItemPayload.tags,
      callback: function ($$v) {
        _vm.$set(_vm.editItemPayload, "tags", $$v);
      },
      expression: "editItemPayload.tags"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.editItemPayload && _vm.editItemPayload.type === 'condition' ? _c('v-card', {
    staticClass: "mt-n16 mb-12 pa-4",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.conditions,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Criteria"
    },
    model: {
      value: _vm.editItemPayload.condition,
      callback: function ($$v) {
        _vm.$set(_vm.editItemPayload, "condition", $$v);
      },
      expression: "editItemPayload.condition"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.thresholds[_vm.conditions.indexOf(_vm.editItemPayload.condition)].threshold,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Threshold"
    },
    model: {
      value: _vm.editItemPayload.threshold,
      callback: function ($$v) {
        _vm.$set(_vm.editItemPayload, "threshold", $$v);
      },
      expression: "editItemPayload.threshold"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none mr-2 mb-2 mt-n16",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.editItemModal = false;
      }
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.addItemModal,
      callback: function ($$v) {
        _vm.addItemModal = $$v;
      },
      expression: "addItemModal"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "grey--text text-overline text--darken-2"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "label": "",
      "small": "",
      "color": _vm.addItemPayload.itemType && _vm.addItemPayload.itemType.color
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.addItemPayload.itemType && _vm.addItemPayload.itemType.textColor,
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.addItemPayload.itemType && _vm.addItemPayload.itemType.icon) + " ")])], 1), _vm._v(" " + _vm._s(_vm.addItemPayload.itemType && _vm.addItemPayload.itemType.type === 'remove-tag' ? 'Remove Tag' : ("Add " + (_vm.addItemPayload.itemType && _vm.addItemPayload.itemType.type))) + " ")], 1)]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rows": _vm.addItemPayload.itemType && _vm.addItemPayload.itemType.type === 'message' ? '5' : '3',
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": _vm.addItemPayload.itemType && _vm.addItemPayload.itemType.type === 'message' ? 'Message' : 'Description'
    },
    model: {
      value: _vm.addItemPayload.description,
      callback: function ($$v) {
        _vm.$set(_vm.addItemPayload, "description", $$v);
      },
      expression: "addItemPayload.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.addItemPayload.itemType && _vm.addItemPayload.itemType.type === 'trigger' ? _c('v-select', {
    staticClass: "mt-n8 mb-n8",
    attrs: {
      "items": _vm.triggers,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Criteria"
    },
    model: {
      value: _vm.addItemPayload.trigger,
      callback: function ($$v) {
        _vm.$set(_vm.addItemPayload, "trigger", $$v);
      },
      expression: "addItemPayload.trigger"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.addItemPayload.itemType && _vm.addItemPayload.itemType.type === 'delay' ? _c('v-select', {
    staticClass: "mt-n8 mb-n8",
    attrs: {
      "items": _vm.delays,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Delay (days)"
    },
    model: {
      value: _vm.addItemPayload.delays,
      callback: function ($$v) {
        _vm.$set(_vm.addItemPayload, "delays", $$v);
      },
      expression: "addItemPayload.delays"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mt-n16 mb-8 pa-4",
    attrs: {
      "outlined": ""
    }
  }, [_vm.addItemPayload.itemType && _vm.addItemPayload.itemType.type === 'condition' ? _c('v-select', {
    attrs: {
      "items": _vm.conditions,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Criteria"
    },
    model: {
      value: _vm.addItemPayload.condition,
      callback: function ($$v) {
        _vm.$set(_vm.addItemPayload, "condition", $$v);
      },
      expression: "addItemPayload.condition"
    }
  }) : _vm._e(), _vm.addItemPayload.itemType && _vm.addItemPayload.itemType.type === 'condition' ? _c('v-select', {
    attrs: {
      "items": _vm.thresholds[_vm.conditions.indexOf(_vm.addItemPayload.condition)] ? _vm.thresholds[_vm.conditions.indexOf(_vm.addItemPayload.condition)].threshold : [],
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Threshold"
    },
    model: {
      value: _vm.addItemPayload.threshold,
      callback: function ($$v) {
        _vm.$set(_vm.addItemPayload, "threshold", $$v);
      },
      expression: "addItemPayload.threshold"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none mr-2 mb-2 mt-n8",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.addChild(_vm.addItemPayload), _vm.addItemModal = false;
      }
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.deleteItemModal,
      callback: function ($$v) {
        _vm.deleteItemModal = $$v;
      },
      expression: "deleteItemModal"
    }
  }, [_c('base-card', [_c('v-card-title', [_c('h3', {
    staticClass: "mt-4 mb-n4 secondary--text text-wrap"
  }, [_vm._v("Are you sure you want to delete this step?")])]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-card-title', [_c('span', {
    staticClass: "grey--text text-overline text--darken-2"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "label": "",
      "small": "",
      "color": _vm.getBadge(_vm.deleteItemPayload.type).color
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.getBadge(_vm.deleteItemPayload.type).textColor,
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.getBadge(_vm.deleteItemPayload.type).icon) + " ")])], 1), _vm._v(" " + _vm._s(_vm.deleteItemPayload && _vm.deleteItemPayload.type === 'remove-tag' ? 'Remove Tag' : ("Add " + (_vm.deleteItemPayload.type))) + " ")], 1)]), _c('v-card-text', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "ml-n3",
    attrs: {
      "auto-grow": "",
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "readonly": ""
    },
    model: {
      value: _vm.deleteItemPayload.description,
      callback: function ($$v) {
        _vm.$set(_vm.deleteItemPayload, "description", $$v);
      },
      expression: "deleteItemPayload.description"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end mt-n12"
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.deleteItemModal = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.removeItem(_vm.deleteItemPayload), _vm.deleteItemModal = false;
      }
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
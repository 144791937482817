<template>
  <v-container class="py-6">
    <!--edit lesson dialog-->
    <workflow-editor
      :showEditor="lessonFullScreen"
      :lessonData="lessonPayload"
      @reset-full-screen-mode="lessonFullScreen = false"
    />
      <v-row>
        <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                   <DashbordSidebar />
                </div>
                <div class="box-content">
                    <div class="d-flex justify-end pa-2 d-block d-md-none">
                        <v-btn
                            icon   
                            @click="isSidebar = !isSidebar"
                        >
                            <v-icon dark>
                                mdi-format-list-bulleted-square
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="box-container">                        
                        <div class="d-flex justify-space-between flex-wrap mb-5">
                           <div class="d-flex align-center mt-n2 ">
                            <v-avatar
                                  tile
                                  size="25"
                                  class="me-3"
                              >
                                  <v-icon
                                  color="grey darken-1"
                                  >
                                  mdi-certificate
                                  </v-icon>
                              </v-avatar>
                                <h2 class="mb-0 grey--text text--darken-1 mb-1">Manage Workflow</h2>
                            </div>
                            <v-btn 
                                outlined 
                                color="primary" 
                                class="text-capitalize font-600"
                                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                            >
                                BACK
                            </v-btn>                             
                       </div>
                      <!---toolbar-->
                      <v-card class="mb-8">
                          <v-toolbar
                            flat
                            color="primary"
                            dark
                          >
                            <v-toolbar-title class="text-h5 font-weight-bold">Workflow Details</v-toolbar-title>
                          </v-toolbar>
                          <v-tabs vertical>
                            <v-tab>
                              <v-icon left class="mr-3">
                                mdi-information-outline
                              </v-icon>
                              <span class="text-none text-body-1 grey--text text--darken-2">Information</span>
                            </v-tab>
                            <v-tab>
                              <v-icon left class="mr-3">
                                mdi-palette
                              </v-icon>
                              <span class="text-none text-body-1 grey--text text--darken-2">Appearance</span>
                            </v-tab>
                            <v-tab>
                              <v-icon left>
                                mdi-security
                              </v-icon>
                              <span class="text-none text-body-2 grey--text text--darken-2">Access Control</span>
                            </v-tab>
                            <v-tab-item>
                              <v-card flat>
                                <v-card-text>
                                    <v-expansion-panels class="mt-n4" flat bordered>
                                      <v-expansion-panel
                                      >
                                        <v-expansion-panel-header class="text-h6 font-weight-bold grey--text text--darken-2">
                                          Workflow Information
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                          <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                  v-model="workflowDetails.workflowName"
                                                  color="secondary"
                                                  outlined
                                                  :counter="30"
                                                  dense
                                                  label="Workflow Name"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                              <v-textarea
                                                v-model="workflowDetails.workflowDescription"
                                                rows="2"
                                                class="mt-n4"
                                                color="secondary"
                                                label="Workflow Description"
                                                outlined
                                                dense
                                            ></v-textarea>
                                            </v-col>
                                            <v-col cols="12">
                                              <v-textarea
                                                v-model="workflowDetails.learningObjectives"
                                                rows="2"
                                                class="mt-n4"
                                                color="secondary"
                                                label="Learning Objectives"
                                                outlined
                                                dense
                                            ></v-textarea>
                                            </v-col>
                                            <v-col cols="12">
                                              <v-textarea
                                                v-model="workflowDetails.prerequisites"
                                                rows="2"
                                                class="mt-n4"
                                                color="secondary"
                                                label="Prerequisites"
                                                outlined
                                                dense
                                            ></v-textarea>
                                            </v-col>                                      
                                            <v-col cols="12">
                                              <v-select
                                                v-model="workflowDetails.tags"
                                                :items="tags"
                                                :item-text="'label'"
                                                :item-value="'label'"
                                                chips
                                                label="Tags"
                                                class="pb-2 mt-n4"
                                                multiple
                                                dense
                                                outlined
                                              ></v-select>
                                            </v-col>
                                            <v-col cols="12">
                                              <v-select
                                                v-model="workflowDetails.difficulty"
                                                class="mt-n6"
                                                :items="['Beginner', 'Intermediate', 'Advanced']"
                                                :item-text="'name'"
                                                color="grey--text text--darken-1"
                                                label="Difficulty"
                                                outlined
                                                dense
                                              ></v-select>
                                            </v-col> 
                                          </v-row>
                                        </v-expansion-panel-content>
                                      </v-expansion-panel>
                                    </v-expansion-panels>
                                    <v-card class="ml-6 mr-6 pt-2" outlined>
                                      <p class="ml-6 grey--text text--darken-2">Duration:  <strong>{{ sumOfWorkflowDuration }} mins</strong></p>
                                    <p class="ml-6 mt-n1 grey--text text--darken-2">Sections:  <strong>{{ countOfSections }}</strong></p> 
                                    <p class="ml-6 mt-n1 grey--text text--darken-2">Lessons:  <strong>{{ countOfLessons }}</strong></p> 
                                    </v-card>

                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                            <v-tab-item>
                              <v-card flat>
                                <v-card-text>
                                  <!-- <p>
                                    Thumbnail Image
                                  </p> -->
                                  <v-row>
                                    <v-col cols="12">
                                      <v-card outlined>
                                        <v-card-text>
                                          <p class="text-h6 font-weight-bold grey--text text--darken-2">Thumbnail Image</p>
                                          <v-card v-if="workflowDetails.thumbnailImageUrl" outlined style="max-width: 125px;" class="text-center mb-2 mt-n2" >
                                            <v-avatar
                                            size="100"
                                            class=""
                                            tile
                                          >
                                            <v-img contain :src="workflowDetails.thumbnailImageUrl"></v-img>
                                          </v-avatar>
                                          </v-card>

                                          <upload-file
                                            icon="mdi-image"
                                            label="Thumbnail"
                                            ref="uploadFile"
                                            type="Thumbnail"
                                            @upload-details="uploadDetails = $event"
                                            @media-url="workflowDetails.thumbnailImageUrl = $event"
                                            @media-name="workflowDetails.thumbnailImage = $event"
                                          />
                                          <v-btn color="primary" class="text-capitalize font-600" @click="uploadThumbnail" elevation="0"
                                            :disabled="(!uploadDetails || uploadDetails.type !== 'Thumbnail')">
                                            Upload
                                          </v-btn>
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-card outlined>
                                        <v-card-text>
                                          <p class="text-h6 font-weight-bold grey--text text--darken-2">Cover Image</p>
                                          <v-card v-if="workflowDetails.coverImageUrl" outlined style="max-width: 125px;" class="text-center mb-2 mt-n2" >
                                            <v-avatar
                                            size="100"
                                            class="align-self-stretch"
                                            tile
                                          >
                                            <v-img contain :src="workflowDetails.coverImageUrl"></v-img>
                                          </v-avatar>
                                          </v-card>
                                          <upload-file
                                            icon="mdi-image"
                                            label="Cover"
                                            ref="uploadFile2"
                                            type="Cover"
                                            @upload-details="uploadDetails = $event"
                                            @media-url="workflowDetails.coverImageUrl = $event"
                                            @media-name="workflowDetails.coverImage = $event"
                                          />
                                          <v-btn color="primary" class="text-capitalize font-600" @click="uploadCoverImage" elevation="0"
                                          :disabled="(!uploadDetails || uploadDetails.type !== 'Cover')">
                                            Upload
                                          </v-btn>
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>                            
                            <v-tab-item>
                              <v-card flat>
                                <v-card-text>
                                  <p>
                                    Control which group has access to this workflow
                                  </p>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-select
                                        v-model="workflowDetails.accessControl"
                                        :items="groups"
                                        :item-text="'name'"
                                        color="grey--text text--darken-1"
                                        label="Group Access"
                                        outlined
                                        dense
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs>
                        </v-card>
                      <!--list of sections and lessons-->
                      <v-card color="transparent" outlined class="mt-8">
                        <vue-nestable v-model="workflowDetails.tableOfContents">
                          <vue-nestable-handle slot-scope="{ item }" 
                            :item="item"
                            :hasParent="hasParent(item)" 
                            @edit-item="editModal($event)"
                            @edit-item-full-screen="editLessonFullScreen($event)"
                            @delete-item="deleteModal($event)"
                            >

                            <sub class="ml-n12 mr-2" style="min-width: 50px;">
                              {{ item.duration }}mins
                            </sub>

                            <v-icon color="grey" small class="mt-n1">
                              mdi-dots-vertical
                            </v-icon>
                            <v-icon color="grey" small class="ml-n5 mt-n1">
                              mdi-dots-vertical
                            </v-icon>
                            <span class="text-body-1 grey--text text--darken-2 font-weight-medium ml-4 text-none">{{ item.level }}</span>
                          </vue-nestable-handle>
                          <template slot="footer" slot-scope="{ item, index }">
                            <div class="add-item">
                              <v-btn
                                class="ml-10"
                                icon
                                plain
                                @click="addRow(item, index)"
                              >
                                <v-icon
                                class="ml-2"
                                small
                                >
                                  mdi-plus
                                </v-icon>
                                Add Lesson
                              </v-btn>
                            </div>
                          </template>
                        </vue-nestable>
                        <!---add section-->
                        <div class="add-item ma-2 pa-4">
                          <v-btn
                            class="ml-12"
                            icon
                            plain
                            @click="addSection()"
                          >
                            <v-icon
                            class="ml-2"
                            small
                            >
                              mdi-plus
                            </v-icon>
                            Add Section
                          </v-btn>
                        </div>
                      </v-card>
                      <div style="height: 150px;"></div>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
    <!--floating action button at bottom right corner-->
    <!---stack cancel button on top of save changes button-->
    <v-btn
      v-if="saveButton"
      class="text-none text-bold mb-12"
      fixed
      dark
      color = "darkGrey"
      bottom
      right
      plain
      elevation = "10"
      @click = "saveButton = false"
    >
    <v-icon small class="mt-1">
      mdi-close
    </v-icon>
      <span class="ml-1">Cancel</span>
    </v-btn>

    <v-btn
      v-if="saveButton"
      class="text-none text-bold"
      fixed
      dark
      color = "primary"
      bottom
      right
      elevation = "10"
      @click = "updateWorkflow(), saveButton = false"
    >
      <span class="ml-1">Save Changes</span>
    </v-btn>
      <!--delete dialog-->
      <v-dialog
        max-width="500"
        v-model="deleteItemModal"
        >
        <v-card>
          <v-card-title>
            <h3 class="secondary--text text-wrap">Please confirm action</h3>
          </v-card-title>
          <v-card-text>
            <p class="grey--text text--darken-2 mb-3">Are you sure you want to delete this?</p>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="deleteItemModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              elevation="0"
              color="primary"
              @click="removeItem(deleteItemPayload), deleteItemModal = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--edit dialog-->
      <v-dialog
        max-width="500"
        v-model="editItemModal"
        >
        <v-card>
          <v-card-title>
            <h3 class="secondary--text text-wrap">{{`Edit ${editItemPayload ? editItemPayload.level: ''} Name`}}</h3>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="foundItemDescription"
                  color="secondary"
                  outlined
                  :counter="30"
                  dense
                  label="Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6" >
                <v-select
                  v-if="editItemPayload && editItemPayload.level === 'Lesson'"
                  v-model="editItemPayload.duration"
                  :items="durations"
                  color="secondary"
                  outlined
                  dense
                  label="Duration (mins)"
                  class="mt-n4 mb-n8"
              ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              v-if="(editItemPayload && editItemPayload.level === 'Lesson')"
              class="ml-12 mt-n1 text-none"
              color="primary"
              icon
              text
              plain
              @click="editLesson(editItemPayload)"
            >
              <v-icon
              >
                mdi-fullscreen
              </v-icon>
              Edit Lesson
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none mr-2 mb-2 mt-n2"
              elevation="0"
              @click="editItemModal = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import DashbordSidebar from "@/components/DashboardSidebar.vue";
import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import VueNestable from '@/components/nestable/VueNestable.vue';
import VueNestableHandle from '@/components/nestable/VueNestableHandle.vue'
import WorkflowEditor from './WorkflowEditor.vue';
import interestList from "../../data/interestList";
import UploadFile from "@/components/storageAccount/UploadFile.vue";
import { nanoid } from 'nanoid';

require('@/components/nestable/assets/vue-nestable.css')

// const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
const cheqqMateServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
  }
};

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit('app/START_LOADING');
    return config;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    store.commit('app/FINISH_LOADING');
    return response;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

const defaultWorkflowDetails = {
  id: "98bbc81e-d9c6-4b72-b57c-d4bd40da104b",
  type: "Workflows",
  userId: "tzishi@live.co.uk",
  groupId: "c6d32027-4577-4d86-9fca-146d4a11debd",
  workflowName: "Ruzivo's Awesome Squad Workflow",
  recurrencePattern: null,
  dayOfWeek: 0,
  dayOfMonth: 0,
  timeOfDay: 0,
  workflowDateTime: "2023-10-28T12:42:41.7290347Z",
  workflowHistory: [],
};

  export default {
    components: {
        DashbordSidebar,
        VueNestable,
        VueNestableHandle,
        WorkflowEditor,
        UploadFile
    },    
    data: () => ({
      uploadDetails: {},
      mediaUrl: '',
      lessonFullScreen: false,
      lessonPayload: null,
      saveButton: false,
      deleteItemModal: false,
      deleteItemPayload: null,
      editItemModal: false,
      editItemPayload: null,
      selectedMembers: null,
      workflowId: '',
      selectedDayOfWeek: '',
      selectedDayOfMonth: '',
      selectedRecurrencePattern: '',
      dialog: false,
      file: {},
      fileData: "",
      singleSelect: false,
      dialog2: false,
      isSidebar: false,
      dialogDelete: false,
      uploadedFile: '',
      workflowName: 'My Workflow',
      headers: [
        { text: 'Sent Date', align: 'start', sortable: true, value: 'sentDate'},
        // { text: 'Id', sortable: true, value: 'id'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headers2: [
        {
          text: 'Full Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Phone Number', value: 'phoneNumber', sortable: false },
        { text: 'Email Address', value: 'emailAddress', sortable: false },
        // { text: 'Resume Date', value: 'resumeDate', sortable: true },
        // { text: 'Is Archived', value: 'isDeletedFromDirectory', sortable: false },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],
      groupIds: ['3b9ca61d-3484-479c-8153-91a6ef2500c3','3b9ca61d-3484-479c-8153-91a6ef2500c5', '3b9ca61d-3484-479c-8153-91a6ef2500c8'],
      timeOfDay:[
        { label: 'Early Morning (3am - 6am)' ,value: 3 },
        { label: 'Morning (6am - 9am)' ,value: 6 },
        { label: 'Late Morning (9am-  12pm)' ,value: 9 },
        { label: 'Afternoon (12pm - 3pm)' ,value: 12 },
        { label: 'Late Afternoon (3pm - 6pm)' ,value: 15 },
        { label: 'Evening (6pm - 9pm)' ,value: 18 },
        { label: 'Late Evening (9pm - 12am)' ,value: 21 },
        { label: 'Night (12am - 3am)' ,value: 0 },
      ],
      recurrencePatterns: [
        'Daily',
        'Weekly',
        'Monthly'
      ],
      dayOfWeek: [
        {
          code: 0,
          name: 'Sunday',
        },
        {
          code: 1,
          name: 'Monday',
        },
        {
          code: 2,
          name: 'Tuesday',
        },
        {
          code: 3,
          name: 'Wednesday',
        },
        {
          code: 4,
          name: 'Thursday',
        },
        {
          code: 5,
          name: 'Friday',
        },
        {
          code: 6,
          name: 'Saturday',
        }
      ],
      date: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
      menu: false,
      modal: false,
      menu2: false,
      menu2: false,
      modal2: false,
      time: ['09:00'],
      dayOfMonth:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28],
      members: [],
      members2: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        phoneNumber: '',
        emailAddress: '',
        resumeDate: new Date().toJSON().slice(0,10),
        isDeletedFromDirectory: false,
      },
      defaultItem: {
        name: '',
        phoneNumber: '',
        emailAddress: '',
        resumeDate: new Date().toJSON().slice(0,10),
        isDeletedFromDirectory: false,
      },
      durations: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90, 100],
      nestableItems: [
        {
          id: 0,
          level: "Section",
          description: "",
          children: [
            {
              id: 0,
              level: "Lesson",
              description: "",
              duration: 0,
              children: []
            }
          ]
        },
        {
          id: 1,
          level: "Section",
          description: "",
          children: [
            {
              id: 1,
              level: "Lesson",
              description: "",
              duration: 0,
              children: []
            },
            {
              id: 2,
              level: "Lesson",
              description: "",
              duration: 0,
              children: []
            }
          ]
        },
        {
          id: 2,
          level: "Section",
          description: "",
          children: [
            {
              id: 22,
              level: "Lesson",
              description: "",
              duration: 0,
              children: []
            }
          ]
        }
      ],
      initialWorkflowDetails: {},
    }),

    computed: {
      ...mapState('auth', ['authUser']),
      ...mapState('user', [ 'memberDirectory','workflow']),
      authUser () {
        return this.$store.state.auth.authUser
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Add Members' : 'Add Member'
      },
      workflowDetails () {
        return this.workflow
      },
      sumOfWorkflowDuration() {
        if(this.workflowDetails.tableOfContents) {
          const arrayOfChildren = this.workflowDetails.tableOfContents.map(item => item.children);
          const children = arrayOfChildren.flat();
          return children.reduce((acc, item) => acc + item.duration, 0);
        }
      },
      countOfSections() {
        if(this.workflowDetails.tableOfContents) {
          return this.workflowDetails.tableOfContents.length;
        }
      },
      countOfLessons() {
        if(this.workflowDetails.tableOfContents) {
          const arrayOfChildren = this.workflowDetails.tableOfContents.map(item => item.children);
          const children = arrayOfChildren.flat();
          return children.length;
        }
      },
      foundItemDescription: {
        get() {
          if(this.workflowDetails.tableOfContents && this.editItemPayload && this.editItemPayload.level === 'Section') {
            const foundItem = this.workflowDetails.tableOfContents.find(item => item.id === this.editItemPayload.id && item.level === this.editItemPayload.level);
            return foundItem ? foundItem.description : '';
          } else if (this.workflowDetails.tableOfContents && this.editItemPayload && this.editItemPayload.level === 'Lesson') {
            // find children of nestableItems and return description that matches the id
            const arrayOfChildren = this.workflowDetails.tableOfContents.map(item => item.children);
            const children = arrayOfChildren.flat();
            const foundItem = children.find(item => item.id === this.editItemPayload.id && item.level === this.editItemPayload.level);
            return foundItem ? foundItem.description : '';
          }
        },
        set(value) {
          if(this.workflowDetails.tableOfContents && this.editItemPayload && this.editItemPayload.level === 'Section') {
            const foundItem = this.workflowDetails.tableOfContents.find(item => item.id === this.editItemPayload.id && item.level === this.editItemPayload.level);
            if(foundItem) {
              foundItem.description = value;
            }
          } else if (this.workflowDetails.tableOfContents && this.editItemPayload && this.editItemPayload.level === 'Lesson') {
            const arrayOfChildren = this.workflowDetails.tableOfContents.map(item => item.children);
            const children = arrayOfChildren.flat();
            const foundItem = children.find(item => item.id === this.editItemPayload.id && item.level === this.editItemPayload.level);
            if(foundItem) {
              foundItem.description = value;
            }
          }
        },
      },
      // workflowDetails: {
      //   get() {
      //     return [this.workflow]
      //       ? [this.workflow].map(workflow => {
      //           return {
      //             ...workflow,
      //             workflowHistory: workflow.workflowHistory? workflow.workflowHistory.map(history => {
      //               return {
      //                 ...history,
      //                 sentDate: this.formatDateTime(history.sentDate)
      //               }
      //             })
      //             :[]
      //           }
      //         })[0]
      //       : defaultWorkflowDetails  
      //   },
      //   set(value) {
      //     //update workflow array
      //     // this.$store.commit('user/SCHEDULE_DETAILS_UPDATED', value)
      //   },
      // },
      groups() {
        let groups = this.$store.state.user.groupList
        groups.unshift({id: '', name: 'All'})

        return this.$store.state.user.groupList
          ? groups
          : defaultUserProfile.groups
      },
      memberDirectory() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.memberDirectory
          : defaultMemberDirectory
      },
      userProfile() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.userProfile
          : defaultUserProfile
      },
      memberDirectoryPerson() {
        // return members where isDeletedFromDirectory is false
        return this.memberDirectory.person.filter(member => {
          return member.isDeletedFromDirectory == false;
        });
      },
      tags(){
          return interestList
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialog2 (val) {
        val || this.close()
      },      
      dialogDelete (val) {
        val || this.closeDelete()
      },
      file(f) {
        if (f) {
            f.text().then((data) => {
            this.fileData = data;
            });
        }
      },
      "$route.query.workflowId"(){
        this.workflowId = this.$route.query.workflowId
      },
      workflowDetails: {
        handler() {
          this.saveButton = JSON.stringify(this.workflowDetails) !== JSON.stringify(this.initialWorkflowDetails);
        },
          deep: true,
        },    
    },
    mounted () {
      if(this.$route.query.workflowId) {
        this.workflowId = this.$route.query.workflowId
        this.readWorkflowById()
      }
      this.initialWorkflowDetails = { ...this.workflowDetails };
    },
    created () {
      this.initialize()
    },

    methods: {
      ...mapActions('user', ['getMemberDirectory','updateGroupName','updateWorkflowNoRedirect', 'updateLesson']),
      ...mapGetters('user', ['getShortList', 'getWishList', 'getUserProfile', 'getCurrentUser','getMemberDirectoryGetter']),    
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        formatDateTime (date) {
          return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
          // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        },
        convertDate (date) {
          return new Date(date).toISOString().split('T')[0]
        },
        convertTime (date) {
          return new Date(date).toLocaleTimeString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
        },
        createDateTime(dateString, time, idx) {
          const date = new Date(dateString[idx]);
          var timeParts = time[idx]?time[idx].split(":"):'00:00';
    
          date.setHours(timeParts[0]);
          date.setMinutes(timeParts[1]);
          return date;
        },                        
        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        season (val) {
            return this.range[val]
        },
        hasHistory () { 
            return window.history.length > 2 
        },        
        updateWorkflow(){
          this.updateWorkflowNoRedirect();
          this.uploadDetails.type = '';
        },
        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        close2 () {
          this.dialog2 = false
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        readWorkflowById() {
          axios
          .get(
            `${cheqqMateServiceBaseUrl}/getWorkflowById`+
              '?UserEmail=' + this.authUser.email + '&WorkflowId=' + this.workflowId,
            cheqqMateServiceConfig,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
              }
            }
          )
          .then(res => {
            if(res.data) {
              //save to vuex, so that it persists, instead of consistently calling api
              this.$store.commit('user/COURSE_DETAILS_UPDATED', res.data.workflow);
            } 
            else {
            }
          })
          .catch(error => {
            if (error.response) {
            }
        });
        },
      initialize () {
          // this.members = this.memberDirectory.person
          // loop through memberdirectory and get the id of each person
          // loop through groupDetails and get the memberId
          // compare the two arrays and return the person object that matches the memberId

          // var _groupMembers = [];
          // for (let i = 0; i < this.groupDetails.memberId.length; i++) {
          //   if(this.groupDetails.memberId.includes(this.memberDirectory.person[i].id)){
          //     _groupMembers.push(this.memberDirectory.person[i])
          //   }
          // }
          // this.members = _groupMembers
        },
        beforeMove ({ dragItem, pathFrom, pathTo }) {
          // Item 4 can not be nested more than one level
          if (dragItem.key === 4) {
            return pathTo.length === 1
          }

          // All other items can be
          return true
        },
        addRow(row, index) {
          const rowId = this.generateId();
          row.children.push({
            id: rowId,
            level: "Lesson",
            description: "",
            children: []
          });
          // create a new lesson
          this.addLesson(rowId);
        },
        removeRow(row, index) {
          this.workflowDetails.tableOfContents = this.workflowDetails.tableOfContents.map(item => {
            if(item.children) {
              item.children = item.children.filter(child => child.id !== row[index].id);
            }
            return item;
          });
        },
        removeItem(payload) {
          //delete section or lesson moved to the parent 'section' component
          if(this.workflowDetails.tableOfContents && payload && (payload.level === 'Section' || !this.hasParent(payload))) {
            const foundItemIndex = this.workflowDetails.tableOfContents.findIndex(item => item.id === payload.id && item.level === payload.level);
            this.removeSection(foundItemIndex);
          } 
          else if (this.workflowDetails.tableOfContents && payload && payload.level === 'Lesson') {
            const arrayOfChildren = this.workflowDetails.tableOfContents.map(item => item.children);
            const children = arrayOfChildren.flat();
            const foundItemIndex = children.findIndex(item => item.id === payload.id && item.level === payload.level);
            this.removeRow(children, foundItemIndex);
          }
        },
        addSection() {
          const rowId = this.generateId();
          this.workflowDetails.tableOfContents.push({
            id: this.workflowDetails.tableOfContents.length,
            level: "Section",
            description: "",
            children: [
            {
              id: rowId,
              level: "Lesson",
              description: "",
              children: []
            }
          ]
          });
          this.updateWorkflowNoRedirect().then(() => {
            // create a new lesson
            this.addLesson(rowId);
          })
        },
        removeSection(index) {
          this.workflowDetails.tableOfContents.splice(index, 1);
        },
        editModal(item) {
          this.editItemPayload = item
          this.editItemModal = true
          // this.saveButton = true
        },
        deleteModal(item) {
          this.deleteItemPayload = item
          this.deleteItemModal = true
          // this.saveButton = true
        },
        editLesson(item) {
          this.editItemModal = false
          this.lessonPayload = item
          this.lessonFullScreen = true
          this.editLessonFullScreen(item)
        },
        editLessonFullScreen(item) {
          console.log(item)
          this.lessonFullScreen = true
          this.lessonPayload = item
        },
        hasParent(item) {
          // does the item have a parent
          return this.workflowDetails.tableOfContents.some(nestableItem => {
            if(nestableItem.children) {
              return nestableItem.children.some(child => child.id === item.id);
            }
          });
        },
        uploadThumbnail() {
          this.$refs.uploadFile.blobStorageUpload();
        },
        uploadCoverImage() {
          this.$refs.uploadFile2.blobStorageUpload();
        },
        generateId: () => nanoid(5),
        addLesson(lessonId) {     
        var payload = {
          UserEmail: this.memberDirectory.userId,
          Lesson: 
            {
              id: lessonId,
              workflowId: this.workflowDetails.id,
              duration: 0,
              htmlContent: "",
		        }
        }
        this.updateLesson(payload);
      },
    }
  }
</script>
<style lang="scss" scoped>
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #2196F3 !important;
            border-left: 4px solid #2196F3;
        }
    }
    // .add-item::before {
    //   content: " ";
    //   border: 5px solid #959eb1;
    //   border-radius: 100%;
    //   position: absolute;
    //   top: -6px;
    //   left: -6px;
    // }
    .add-item {
      // position: relative;
      margin: 0;
      margin-left: 0px;
      padding: 0rem;
      border: 1px solid #959eb1;
      // border-top: 1px solid #959eb1;

      border-radius: 5px;
      background: #f9fafb;
    }
</style>
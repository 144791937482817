<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="text-center mb-10">
            <h3 class="mb-3">
              Forgot Password
            </h3>
            <h5 class="text-sm font-600 grey--text text--darken-4">
              Please provide email to retrieve password
            </h5>
          </div>
          <div class="mb-4">
            <p class="text-14 mb-1">
              Email
            </p>
            <v-text-field
              v-model="user.email"
              placeholder="example@gmail.com"
              outlined
              dense
              hide-details=""
              class="mb-4"
            />
          </div>
          <div class="mb-4">
            <v-btn
              block
              color="primary"
              class="text-capitalize font-600"
              @click="forgetPassword"
            >
              Reset Password
            </v-btn>
          </div>
          <!-- <div class="d-flex align-center justify-center w-200 mx-auto mb-4">
            <v-divider></v-divider>
            <p class="mx-3 mb-1">on</p>
            <v-divider></v-divider>
          </div>             -->
        </div>
        <div class="py-4 grey lighten-2">
          <div class="text-center">
            <span class="grey--text text--darken-1"> <a
              href="/"
              class="ms-2 grey--text text--darken-4 font-600"
            >Return to homepage</a> </span>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
  import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
  import { mapGetters } from 'vuex'

  const auth = getAuth()
  export default {
    data () {
      return {
        checkbox: false,
        user: {
          email: '',
        },
      }
    },
    computed: {
      ...mapGetters('app', ['isLoading']),
    },
    methods: {
      forgetPassword () {
        this.$store.commit('app/START_LOADING')
        sendPasswordResetEmail(auth, this.user.email)
          .then(() => {
            // Password reset email sent!
            // ..
            this.$store.commit('app/FINISH_LOADING')
            // redirect
            this.$router.push('/sign-in')
          })
          .catch((error) => {
            const errorCode = error.code
            const errorMessage = error.message
            // ..
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sign-up-card {
    width: 500px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    margin: 2rem auto;
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
    @media(max-width: 500px){
      width: 100%;
    }
    .sign-up-card-container {
      padding: 3rem 3.75rem 0px;
      @media(max-width: 500px){
        padding: 3rem 1rem 0px;
      }
    }
  }

</style>

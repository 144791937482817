<template>
  <div>
    <p class="text-sm secondary--text px-5 pt-6">
      DASHBOARD
    </p>
    <!---Analytics-->
    <v-list-group
      no-action
      class="ml-1 mt-n3 mb-2"
    >
      <template v-slot:activator>
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-view-dashboard
        </v-icon>
        <v-list-item-content>
          <v-list-item-subtitle v-text="'Analytics'" class="primary--text"> </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <div class="ml-4 mt-1">
      <!---Summary-->
      <v-list-item>
        <router-link
          to="/analytics/summary"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-chart-pie
            </v-icon>
            <span class="primary--text">Summary</span>
          </div>  
        </router-link> 
      </v-list-item>
      <!---Reports-->
      <p class="text-sm secondary--text px-1 pt-n4">
        REPORTS
      </p>
      <!---Members-->
      <v-list-group
        no-action
        class="ml-1 mt-n3"
      >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-subtitle v-text="'Members'" class="primary--text"> </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <div class="ml-4 mt-1">

      <v-list-item>
        <router-link
        to="/analytics/total-members"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <span class="mr-n1 font-weight-bold primary--text text-sm">#</span>
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-account
            </v-icon>
            <span class="primary--text ml-n1">Total Members</span>
          </div>     
        </router-link> 
      </v-list-item>
      <v-list-item>
        <router-link
          to="/analytics/new-members"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-account-plus
            </v-icon>
            <span class="primary--text">New Members</span>
          </div>
        </router-link>    
      </v-list-item>
      <v-list-item>
        <router-link
          to="/analytics/top-members"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-account-star
            </v-icon>
            <span class="primary--text">Top Members</span>
          </div>
        </router-link>    
      </v-list-item>
      <v-list-item>
        <router-link
          to="/analytics/potential-champions"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-medal
            </v-icon>
            <span class="primary--text">Potential Champions</span>
          </div>
        </router-link>    
      </v-list-item>
      </div>
      </v-list-group>

      <!---Engagement-->
      <v-list-group
        no-action
        class="ml-1 mt-n2"
      >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-subtitle v-text="'Engagement'" class="primary--text"> </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <div class="ml-4">
      <v-list-item>
        <router-link
          to="/analytics/posts"
          class="dashboard-nav-link px-2 mt-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-forum
            </v-icon>
            <span class="primary--text">Posts</span>
          </div>     
        </router-link> 
      </v-list-item>
      <v-list-item>
        <router-link
          to="/analytics/events"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-ticket
            </v-icon>
            <span class="primary--text">Events</span>
          </div>
        </router-link>    
      </v-list-item>
      <v-list-item>
        <router-link
          to="/analytics/courses"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-certificate
            </v-icon>
            <span class="primary--text">Courses</span>
          </div>
        </router-link>    
      </v-list-item>
      <v-list-item>
        <router-link
        to="/analytics/meetups"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-account-multiple-check
            </v-icon>
            <span class="primary--text">Meetups</span>
          </div>
        </router-link>    
      </v-list-item>
      </div>
      </v-list-group>

      <!---Retention-->
      <v-list-group
        no-action
        class="ml-1 mt-n2"
      >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-subtitle v-text="'Retention'" class="primary--text"> </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <div class="ml-4">
      <v-list-item>
        <router-link
          to="/analytics/retention"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-account-clock
            </v-icon>
            <span class="primary--text">Retention</span>
          </div>     
        </router-link> 
      </v-list-item>
      <v-list-item>
        <router-link
          to="/analytics/flight-risk"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-account-alert-outline
            </v-icon>
            <span class="primary--text">Flight Risk</span>
          </div>
        </router-link>    
      </v-list-item>
      </div>
      </v-list-group>

      <!---Responsiveness-->
      <v-list-group
        no-action
        class="ml-1 mt-n2"
      >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-subtitle v-text="'Responsiveness'" class="primary--text"> </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <div class="ml-4">
      <v-list-item>
        <router-link
          to="/analytics/responsiveness"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-timer-sand
            </v-icon>
            <span class="primary--text">Responsiveness</span>
          </div>     
        </router-link> 
      </v-list-item>
      </div>
      </v-list-group>
      </div>
    </v-list-group>
    <router-link
      to="/member-directory"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-account-group
        </v-icon>
        <span class="primary--text">Members</span>
      </div>
      <span class="secondary--text mb-1">
        <v-badge
          color="primary"
          inline
          :content="memberDirectory.person ? (memberDirectory.person.length > 0 ? memberDirectory.person.length : '0') : '0'"
        >
        </v-badge>
      </span>
    </router-link>
    <router-link
      to="/group-directory"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-shape
        </v-icon>
        <span class="primary--text">Groups</span>
      </div>
      <span class="secondary--text mb-1">
        <v-badge
          color="primary"
          inline
          :content="groupList?groupList.length:0"
        >
        </v-badge>
      </span>
    </router-link>
    <router-link
      to="/matches"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-history
        </v-icon>
        <span class="primary--text">Matches</span>
      </div>
      <v-badge
          color="primary"
          inline
          :content="matchList ? (matchList.length > 0 ? matchList.length : '0') : '0'"
        >
        </v-badge>
    </router-link>
    <router-link
      to="/events"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-ticket
        </v-icon>
        <span class="primary--text">Events</span>
      </div>
      <v-badge
          color="primary"
          inline
          :content="matchList ? (matchList.length > 0 ? matchList.length : '0') : '0'"
        >
        </v-badge>
    </router-link>
    <router-link
      to="/courses"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-certificate
        </v-icon>
        <span class="primary--text">Courses</span>
      </div>
      <v-badge
          color="primary"
          inline
          :content="matchList ? (matchList.length > 0 ? matchList.length : '0') : '0'"
        >
        </v-badge>
    </router-link>

    <!---Automation-->
    <v-list-group
      no-action
      class="ml-1 mt-n3"
    >
      <template v-slot:activator>
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-lightning-bolt
        </v-icon>
        <v-list-item-content>
          <v-list-item-subtitle v-text="'Automations'" class="primary--text"> </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <div class="ml-8 mt-1">
      <!---Schedules-->
      <v-list-item>
        <router-link
          to="/schedules"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-clock-time-four
            </v-icon>
            <span class="primary--text">Schedules</span>
          </div>
          <div class="ml-9"></div>
          <span class="secondary--text mb-1 ml-10">
            <v-badge
              color="primary"
              inline
              :content="scheduleList?scheduleList.length:0"
            >
            </v-badge>
          </span>      
        </router-link> 
      </v-list-item>
      <!---Workflows-->
      <v-list-item>
        <router-link
          to="/workflows"
          class="dashboard-nav-link px-2"
        >
          <div class="d-flex align-center">
            <v-icon
              class="icon mr-2"
              color="primary"
            >
              mdi-sitemap
            </v-icon>
            <span class="primary--text">Workflows</span>
          </div>
          <div class="ml-9"></div>
          <span class="secondary--text mb-1 ml-9">
            <v-badge
                color="primary"
                inline
                :content="memberDirectory ? (memberDirectory.workflows.length > 0 ? memberDirectory.workflows.length : '0') : '0'"
              >
            </v-badge>
          </span>
        </router-link>    
      </v-list-item>
      </div>
    </v-list-group>

    <p class="text-sm secondary--text px-5 pt-6">
      ACCOUNT SETTINGS
    </p>
    <router-link
      to="/view-profile"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-account
        </v-icon>
        <span class="primary--text">Profile</span>
      </div>
    </router-link>
    <router-link
      to="/manage-billing"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-credit-card
        </v-icon>
        <span class="primary--text">Billing</span>
      </div>
    </router-link>
    <!---Merchant-->
    <v-list-group
      no-action
      class="ml-1 mt-n3"
    >
      <template v-slot:activator>
        <v-icon
          class="icon mr-2"
          color="primary"
        >
          mdi-storefront
        </v-icon>
        <v-list-item-content>
          <v-list-item-subtitle v-text="'Merchant'" class="primary--text"> </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <div class="ml-8 mt-1">
      <!---Plan-->

      <!-- <v-list-item>
            <router-link
              to="/manage-subscription"
              class="dashboard-nav-link"
            >
              <div class="d-flex align-center">
                <v-icon
                  class="icon mr-2"
                  color="primary"
                >
                  mdi-shape
                </v-icon>
                <span class="primary--text">Plans</span>
              </div>
            </router-link>
      </v-list-item> -->

      <!---Paywall-->
      <v-list-item>
  
            <router-link
              to="/manage-paywall"
              class="dashboard-nav-link"
            >
              <div class="d-flex align-center">
                <v-icon
                  class="icon mr-2"
                  color="primary"
                >
                  mdi-wallet
                </v-icon>
                <span class="primary--text">Paywall</span>
              </div>
            </router-link>       
      </v-list-item>

      <!---Transaction-->
      <v-list-item>
            <router-link
              to="/manage-transactions"
              class="dashboard-nav-link"
            >
              <div class="d-flex align-center">
                <v-icon
                  class="icon mr-2"
                  color="primary"
                >
                  mdi-invoice-text
                </v-icon>
                <span class="primary--text">Transactions</span>
              </div>
            </router-link>       
      </v-list-item>
      </div>


    </v-list-group>
  </div>
</template>

<script>
 import { mapGetters, mapActions, mapState } from 'vuex'

  const defaultUserProfile = {
    id: 'Jane.Done@example.com',
    username: 'Jane.Done',
    type: 'userProfile',
    userId: 'Jane.Done@example.com',
    personalDetails: {
        id: "tzishi@live.co.uk",
        name: "Tino Zishiri",
        phoneNumber: "07943062109",
        emailAddres: "tzishi@live.co.uk",
        resumeDate: "14/08/2023 10:46",
        isDeletedFromDirectory: false
    },
    subscription: {
        name: "Free",
        isActive: true,
        createdDate: "14/08/2023 10:46"
    },
    memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
    groups: [
        {
            groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
            groupName: "Tino Zishiri's Awesome Squad"
        }
    ],
    schedule: [
        {
            "id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
            "scheduleName": "Tino Zishiri's Awesome Squad Schedule"
        }
    ],
    matches: 1,
    matchesLastModifiedDate: "14/08/2023 10:46",
    meetups: 1,
    meetupsLastModifiedDate: "14/08/2023 10:46",
    pauses: 1,
    pausesLastModifiedDate: "14/08/2023 10:46",
    dashboard: {
        topUserGroups: [
            {
                userGroup: null,
                matches: 0,
                meetups: 0,
                pauses: 0
            }
        ]
    },
  };
  const defaultMemberDirectory = {
    id: "4fe4dd40-cb2f-4164-bea2-16b05305c603",
    type: "Members",
    userId: "tino.zishiri@gmail.com",
    person: [
        {
            id: "ca7337e5-4a8d-4241-ab5d-62a5bcc419ba",
            name: "Tino Zishiri",
            phoneNumber: "447943062109",
            emailAddress: "tino.zishiri@gmail.com",
            resumeDate: "02/10/2023 22:16",
            isDeletedFromDirectory: false
        },
        {
            id: "734b201f-16cf-43cd-9115-39e991473550",
            name: "Tino Zishiri 1",
            phoneNumber: "447943062109",
            emailAddress: "member1@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        },
        {
            id: "1d519c8f-768f-4392-8d24-aed21f71324c",
            name: "Tino Zishiri 2",
            phoneNumber: "07943062109",
            emailAddress: "member2@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        },
        {
            id: "ab55e046-3110-4a74-aa85-1cca7b0260f8",
            name: "Tino Zishiri 3",
            phoneNumber: "07943062109",
            emailAddress: "member3@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        }
      ],
    }
  export default {
    components: {},
    data () {
      return {
        items: [
        {
          id: 1,
          name: 'Applications :',
          children: [
            { id: 2, name: 'Calendar : app' },
            { id: 3, name: 'Chrome : app' },
            { id: 4, name: 'Webstorm : app' },
          ],
        }]
      }
    },
    watch: {
    },
    computed: {
      ...mapState('auth', ['authUser','userProfile', 'memberDirectory']),
      ...mapState('user', ['matchList']),
      authUser () {
        return this.$store.state.auth.authUser
      },
      userProfile() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.userProfile
          : defaultUserProfile
      },
      memberDirectory() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.memberDirectory
          : defaultMemberDirectory
      }, 
      groupList() {
        return this.$store.state.user.groupList
          ? this.$store.state.user.groupList
          : defaultUserProfile.groups
      },
      scheduleList() {
        return this.$store.state.user.scheduleList
          ? this.$store.state.user.scheduleList
          : defaultUserProfile.schedule
      },
    },
    mounted () {
    },
    methods: {
    },
  }
</script>

<style lang="scss">
    .dashboard-nav-link {
        color: rgb(125, 135, 156) !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &.router-link-exact-active {
            color: #616161 !important;
            border-left: 4px solid #616161;
        }
        &:hover {
            color: #616161 !important;
            border-left: 4px solid #616161;
        }
    }
</style>

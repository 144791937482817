<template>
    <v-file-input
        v-model="file"
        :rules="rules"
        accept="image/png, image/jpg, image/jpeg, image/bmp"
        small-chips
        outlined
        dense
        :prepend-icon="icon ? icon : 'mdi-camera'"
        :label="label ? label : 'Upload File'"
        type="file"
        @change = "handleFileSelection"
    ></v-file-input>
  </template>
  <script>
    import axios from 'axios';
    import { BlobServiceClient } from '@azure/storage-blob';
    import { mapState } from 'vuex';

  export default {
    name      : 'UploadFile',
    props: {
      icon: {
        type: String,
        required: false,
        default: "mdi-camera"
      },
      label: {
        type: String,
        required: false,
        default: "Upload File"
      },
      type: {
        type: String,
        required: false,
        default: "Thumbnail"
      }
    },
    data: () => ({
        rules: [
            value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],
        file: null,
        imageUrl: '',
        mediaDetails: {},
        previewDialog: false,
        mediaUrl: '',
        mediaName: '',
        mediaType: '',
        snackbar: false,
        snackbarMessage: ''
    }),
    computed: {
      ...mapState('user', [ 'memberDirectory']),
      memberDirectory() {
        return this.$store.state.user.memberDirectory
      },
    },
    methods: {
        async blobStorageUpload() {
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_CHAT_API_KEY || '';
            try {
                const response = await axios.get('https://chat-func-cheqqmate-dev-weu-01.azurewebsites.net/api/credentialsV2');
                const { url, sasKey } = response.data;
                var blobName = this.buildBlobName(this.file || this.files[0]);
                this.mediaName = blobName;
                try {
                    await this.blobUpload(this.file || this.files[0], url, 'assets', sasKey, blobName);
                    this.mediaUrl = url + '/assets/' + this.memberDirectory.id + '/' + blobName;
                    this.handleMediaUrl();
                } catch (error) {
                    this.snackbarMessage = 'Error uploading file:' + error;
                    this.snackbar = true;
                }
            } catch (error) {
                this.snackbarMessage = 'Error uploading file:' + error;
                this.snackbar = true;
            }
        },
        handleMediaUrl() {
            this.$emit('media-url', this.mediaUrl);
            this.$emit('media-name', this.mediaName);
        },
        handleFileSelection(file) {
        if (!file) {
            return;
        }
            this.file = file;
            this.mediaType = file.type;
            this.imageUrl = URL.createObjectURL(file);
            this.mediaDetails = {
                name: file.name,
                size: file.size,
                type: file.type.replace('application/', '').replace('image/', ''),
            }
            // this.previewDialog = true;
            this.$emit('upload-details', {
                type: this.type,
                file: this.file
            })
        },
        buildBlobName(file) {
            var filename = file.name.substring(0, file.name.lastIndexOf('.'));
            var ext = file.name.substring(file.name.lastIndexOf('.'));
            return filename + '_' + Math.random().toString(16).slice(2) + ext;
        },
        async blobUpload(file, url, containerName, sasKey, blobName) {
            const blobServiceClient = new BlobServiceClient(
            `${url}?${sasKey}`
        );
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blockBlobClient = containerClient.getBlockBlobClient(this.memberDirectory.id + '/' + blobName);
        try {
            const response = await blockBlobClient.uploadData(file);
            return response._response.status;
        } catch (error) {
            console.log(error);
            this.snackbarMessage = 'Error uploading file:' + error;
            this.snackbar = true;
            return error;
        }
        },
        clearFile() {
            this.file = null;
            this.imageUrl = '';
        },
        formatFileSize(bytes) {
        if (bytes === 0) return '0 B';
            const k = 1024;
            const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },
    },
  }
</script>
<template>
  <v-container class="py-12">
    <v-snackbar
        v-model = "snackbar"
        color = "primary"
        elevation = "16"
        class = "snackbar"
        :timeout="1000"
    >
      {{ snackbarMessage }}
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
          <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
              <DashbordSidebar />
          </div>
          <div class="box-content">
              <div class="d-flex justify-end pa-2 d-block d-md-none">
                  <v-btn
                      icon   
                      @click="isSidebar = !isSidebar"
                  >
                      <v-icon dark>
                          mdi-format-list-bulleted-square
                      </v-icon>
                  </v-btn>
              </div>
              <div class="box-container">
                <div class="d-flex justify-space-between flex-wrap mb-5">
                  <div class="d-flex align-center ">
                    <v-avatar
                      tile
                      size="25"
                      class="me-3"
                    >
                      <v-icon
                        color="grey darken-1"
                      >
                        mdi-sitemap
                      </v-icon>
                    </v-avatar>
                    <h2 class="mb-0 grey--text text--darken-1">
                      Workflows
                    </h2>
                  </div>
                  <v-btn 
                      outlined 
                      color="primary" 
                      class="text-capitalize font-600"
                      @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                  >
                      BACK
                  </v-btn>
                </div>
                <v-divider class="mb-4"></v-divider>
                {{ workflowDetails.workflowTree }} <br/>
                ACTIVE WORKFLOW ITEMS: {{ initialisedWorkflowTree }}
                  <!--Canvas-->
                  <!--:cols="item.children && item.children.length > 1 ? '' : ''"-->
                <div class="workflow-canvas rounded-card pa-4"
                  style="overflow: auto;" 
                  ref="canvas"
                >
                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col cols="7" class="pt-n1">
                    <v-card class=" ma-2" style="height:60px;">
                      <v-row class="ma-2 pr-4">
                        <!-- Workflow Name -->
                        <v-col cols="9">
                          <v-text-field
                            v-model="workflowDetails.workflowName"
                            label="Workflow Name"
                            outlined
                            dense
                            color="secondary"
                          ></v-text-field>
                        </v-col>
                        <!-- Zoom In Button -->
                        <v-col cols="1">
                          <v-btn @click="zoomIn"
                            class="ml-n2"
                            fab
                            color="primary"
                            text
                            small
                          >
                            <v-icon class="mt-1">mdi-magnify-plus-outline</v-icon>
                          </v-btn>
                        </v-col>

                        <!-- Zoom Out Button -->
                        <v-col cols="1">
                          <v-btn @click="zoomOut"
                            class="ml-n1"
                            fab
                            text
                            small
                            color="primary"
                          >
                            <v-icon class="mt-1">mdi-magnify-minus-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <!-- Toggle Fullscreen Button -->
                        <v-col cols="1">
                          <v-btn @click="toggleFullscreen"
                            class=""
                            fab
                            text
                            small
                            color="primary"
                          >
                            <v-icon>mdi-fullscreen</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <!---save name-->
                  <v-col cols="1">
                    <v-btn
                      large
                      fab
                      class="mt-2 ml-n4 grey--text text--darken-1 font-weight-bold"
                      text
                      @click="updateWorkflow"
                    >
                      Save
                    </v-btn>
                  </v-col>
                  <!---reset-->
                  <v-col cols="1">
                    <v-btn @click="resetWorkflow"
                      class="mt-2 ml-n6"
                      fab
                      color="grey darken-1"
                      text
                      large
                    >
                    <v-icon>mdi-restore</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                  <div v-for="(item, index) in workflowItems"
                    :key="index"
                    class="mb-12"
                    :style="{ transform: `scale(${zoomScale})` }"
                  >
                    <!--Parent-->
                    <v-row justify="center">
                      <v-col
                        cols="6"
                      >
                        <v-card
                          class="ma-1 pa-2"
                          style="min-height: 80px;"
                          :style="{'border-left': hoverId === item.id ? '4px solid #AEB4BE' : ''}"
                          outlined
                          @mouseover="hoverId = item.id"
                          @mouseleave="hoverId = null"
                        >
                          <div style="min-height: 36px;" class="pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline">
                            <span style="min-height: 36px;">
                              <v-chip
                                class="mr-1"
                                label
                                small
                                :color="getBadge(item.type).color"
                              >
                              <v-icon :color="getBadge(item.type).textColor" small>
                                {{ getBadge(item.type).icon }}
                              </v-icon>
                              </v-chip>
                              {{ item.type }}
                            </span>
                            <span style="min-height: 36px;">
                              <v-btn
                                v-show="hoverId === item.id"
                                icon
                              >
                                <v-icon
                                  color="teal lighten-2"
                                  @click="editModal(item)"
                                >
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                              <v-btn
                                v-show="hoverId === item.id"
                                icon
                                :disabled="hasChildren(item) || index === 0"
                              >
                                <v-icon
                                  color="red lighten-2"
                                  @click="deleteModal(item)"
                                >
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </span>
                            <v-card-text>
                              <p class="ml-8 mt-n6 text-none text-body-2 grey--text text--darken-1 font-weight-bold">{{ item.trigger }}</p>
                            </v-card-text>                            
                            <v-card-text>
                              <p class="mt-n8 text-none text-body-2 grey--text text--darken-1">{{ item.description }}</p>
                            </v-card-text>
                          </div>
                        </v-card>
                        <!--actions-->
                        <div class="d-flex justify-center" v-if="!hasChildren(item)">
                          <v-menu
                            top
                            :offset-x="offset"
                            rounded="lg"
                            :nudge-width="200"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mt-n6 ml-2"
                                fab
                                small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon dark>
                                  mdi-plus
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item-content class="d-flex justify-center">
                                  <v-list-item-title class="ml-4 grey--text text-overline text--darken-2">Choose Workflow Item</v-list-item-title>
                                </v-list-item-content>
                              <v-divider></v-divider>
                              <v-list-item
                                v-for="(item2, i) in items"
                                :key="i"
                                link
                              >
                              <v-btn
                                class="ml-8 d-flex justify-start"
                                icon
                                plain
                                small
                                @click="addModal({item, itemType: item2})"
                              >
                              <v-icon :color="getBadge(item2.type).textColor" small>
                                    {{ getBadge(item2.type).icon }}
                                  </v-icon>              
                                  {{ item2.type }}
                              </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                    <!--Child-->
                      <!--trigger-->
                      <svg v-if="hasChildren(item)" style="width:100%; height:60px;" class="mb-6 mt-n2">
                        <line x1="50%" y1="10" x2="50%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
                      </svg>
                      <div v-for="(child, childIndex) in item.children"
                        :key="`child-${childIndex}`"
                      >
                        <!--don't display condition if children are deleted-->
                        <v-row justify="center" v-if="child.id !==-1 && !(!hasChildren(child) && child.type==='condition')">
                          <v-col
                            cols="6"
                          >
                            <v-card
                              class="mt-n4 ma-1 pa-2"
                              :class="hasChildren(child) ? 'mt-n11' : 'mt-n8'"
                              style="min-height: 80px;"
                              :style="{'border-left': hoverId === child.id ? '4px solid #AEB4BE' : ''}"
                              outlined
                              @mouseover="hoverId = child.id"
                              @mouseleave="hoverId = null"
                            >
                              <div style="min-height: 36px;" class="pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline">
                                <span style="min-height: 36px;">
                                  <v-chip
                                    class="mr-1"
                                    label
                                    small
                                    :color="getBadge(child.type).color"
                                  >
                                  <v-icon :color="getBadge(child.type).textColor" small>
                                    {{ getBadge(child.type).icon }}
                                  </v-icon>
                                  </v-chip>
                                  {{ child.type }}
                                  <!-- <p class="text-none text-body-2 grey--text text--darken-1">{{ child.description }}</p> -->
                                  <!-- <p class="text-none text-body-2 grey--text text--darken-1">
                                    <br/> hasChildren: {{  hasChildren(child) }}
                                    <br/> hasSibling:  {{  hasSibling(child) }}
                                    <br/> child: {{ child.id}}
                                    <br/> child2: {{ item.children[item.children.length-1].id }}
                                    <br/> parent: {{ findParentV3(child)[0].id }}
                                  </p> -->
                            <!-- isLastActiveChild: {{  isLastActiveChild(item) }}
                            <br/> isLast: {{  isLast(child) }}
                            <br/> hasChildren: {{  hasChildren(child) }}
                            <br/> hasSibling:  {{  hasSibling(child) }} -->
                                </span>
                                <span style="min-height: 36px;">
                                  <v-btn
                                    v-show="hoverId === child.id"
                                    icon
                                  >
                                    <v-icon
                                      color="teal lighten-2"
                                      @click="editModal(child)"
                                    >
                                      mdi-pencil
                                    </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-show="hoverId === child.id"
                                    icon
                                    :disabled="hasChildren(child)"
                                  >
                                    <v-icon
                                      color="red lighten-2"
                                      @click="deleteModal(child)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </span>
                                <v-card-text v-if="child.condition" class=" mb-n8">
                                  <p class="ml-8 mt-n6 text-none text-body-2 grey--text text--darken-1 font-weight-bold">{{ child.condition.replace('greater than', '> ').replace('equals', '= ') }} {{ child.threshold }}</p>
                                </v-card-text>  
                                <v-card-text>
                                  <p class="text-none text-body-2 grey--text text--darken-1">{{ child.description }}</p>
                                </v-card-text>
                              </div>
                            </v-card>
                          
                            <!--actions-->
                            <!-- <div class="d-flex justify-center" v-if="!hasSibling(child)"> -->
                            <div class="d-flex justify-center" v-if="!hasSibling(child) || (child.id === item.children[item.children.length-1].id && !hasChildren(child)) || item.children[item.children.length-1].id ===-1">
                              <v-menu
                                top
                                :offset-x="offset"
                                rounded="lg"
                                :nudge-width="200"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mt-n6"
                                    fab
                                    small
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon dark>
                                      mdi-plus
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item-content class="d-flex justify-center">
                                      <v-list-item-title class="ml-4 grey--text text-overline text--darken-2">Choose Workflow Item</v-list-item-title>
                                  </v-list-item-content>
                                  <v-divider></v-divider>
                                  <v-list-item
                                    v-for="(item, i) in items"
                                    :key="i"
                                    link
                                  >
                                  <v-btn
                                    class="ml-8 d-flex justify-start"
                                    icon
                                    plain
                                    small
                                    @click="addModal({item : child, itemType: item})"
                                  >
                                    <v-icon :color="getBadge(item.type).textColor" small>
                                      {{ getBadge(item.type).icon }}
                                    </v-icon>              
                                    {{ item.type }}
                                  </v-btn>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </div>
                            <div v-else-if="child.id === item.children[item.children.length-1].id && hasSibling(child)" class="d-flex justify-center" style="width:100%; height:60px;">
                              <span>
                                <v-btn
                                  v-show="!hasChildren(child)"
                                  class="mt-n6 ml-6"
                                  fab
                                  small
                                  color="primary"
                                  @click="addModal(child)"
                                >
                                  <v-icon dark>
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </span>
                            </div>
                            <svg v-if="hasChildren(child) && child.type === 'condition' && (hasSibling(child))" style="width:100%; height:120px;" class="mt-n16 mb-n12">
                              <line x1="50%" y1="0" x2="50%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
                              <line x1="50%" y1="50" x2="10%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
                              <line x1="50%" y1="50" x2="89%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
                            </svg>
                            <!-- <svg v-else-if="!hasChildren(child) && child.type === 'condition'">
                            </svg> -->
                            <!-- <svg v-else-if="(!hasChildren(child) && child.type==='condition')" style="width:100%; height:60px;">
                            </svg> 
                            <svg v-else-if="child.type !== 'condition' && !hasChildren(child) && hasSibling(child)" style="width:100%; height:60px;" 
                            -->
                            <svg v-else-if="!hasSibling(child)" style="width:100%; height:60px;">
                            </svg>
                            <!--&& ( hasSibling(child) && child.id === item.children[item.children.length-1].id)-->
                            <svg v-else-if="hasSibling(child) && child.id !== item.children[item.children.length-1].id"style="width:100%; height:60px;" 
                              :class="hasSibling(child) && child.id !== item.children[item.children.length-1].id?'mt-n2 mb-3':'mt-n2 mb-6'"
                              >
                              <line x1="50%" y1="10" x2="50%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
                            </svg>

                          </v-col>

                        </v-row>
                        <!---grand-children-->
                        <v-row class="mt-n4" v-if="hasChildren(child)" justify="center">
                          <v-col cols="12">
                            <workflow-children 
                              class="mt-n16" 
                              v-if="child.children && child.children.length > 0" 
                              :children="child.children"
                              :childV1="child"
                              :childV2="item.children[item.children.length-1].id"
                              :parent="hasChildren(child)?child:{}"
                              :parentV2="findParentV3(child)[0]"
                              @workflow-add="addModalV2($event)"
                              @workflow-edit="editModal($event)"
                              @workflow-delete="deleteModal($event)"
                              />
                          </v-col>
                        </v-row>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </v-col>
    </v-row>
      <!--edit dialog-->
      <v-dialog
        max-width="500"
        v-model="editItemModal"
        >
        <v-card>
          <v-card-title>
            <span class="mb-4 grey--text text-overline text--darken-2">
              <v-chip
                class="mr-1"
                label
                small
                :color="getBadge(editItemPayload.type).color"
              >
              <v-icon :color="getBadge(editItemPayload.type).textColor" small>
                {{ getBadge(editItemPayload.type).icon }}
              </v-icon>
              </v-chip>
              {{ editItemPayload.type }}
            </span>
            <v-spacer></v-spacer>
            <!--actions-->
            <div class="d-flex justify-end">
              <v-menu
                :offset-x="offset"
                rounded="lg"
                :nudge-width="200"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-n3 ml-2"
                    plain
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="editItemPayload.type === 'condition' || editItemPayload.type === 'trigger'"
                  >
                    <v-icon dark>
                      mdi-swap-horizontal-bold
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-content class="d-flex justify-center">
                      <v-list-item-title class="ml-4 grey--text text-overline text--darken-2">Choose Workflow Item</v-list-item-title>
                    </v-list-item-content>
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="(item2, i) in items"
                    :key="i"
                    link
                  >
                  <v-btn
                    class="ml-8 d-flex justify-start"
                    icon
                    plain
                    small
                    @click="editItemPayload.type = item2.type, editItemPayload.description = item2.description, editItemPayload.sender = '', editItemPayload.trigger = '', editItemPayload.delays = '', editItemPayload.tags = '', editItemPayload.condition = '', editItemPayload.threshold = ''"
                  >
                  <v-icon :color="getBadge(item2.type).textColor" small>
                        {{ getBadge(item2.type).icon }}
                      </v-icon>              
                      {{ item2.type }}
                  </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <!--messsage class="mt-n4 mb-n8"-->
              <v-col cols="12">
                <at-ta :members="members">
                <v-textarea
                  v-model="editItemPayload.description"
                  :rows="editItemPayload && editItemPayload.type === 'message'  ? '5': '3'"
                  color="secondary"
                  hint="Type '@' to mention a member"
                  outlined
                  dense
                  
                  :label="editItemPayload && editItemPayload.type === 'message'  ? 'Message': 'Description'"
                ></v-textarea>
              </at-ta>
              </v-col>
              <!--message-->
              <v-col cols="6">
                <v-select
                  v-if="editItemPayload && editItemPayload.type === 'message'"
                  v-model="editItemPayload.sender"
                  :items="senders"
                  color="secondary"
                  outlined
                  dense
                  label="Send message from"
                  class="mt-n4 mb-n16"
              ></v-select>
              </v-col>
              <!--trigger-->
              <v-col cols="12">
                <v-select
                  v-if="editItemPayload && editItemPayload.type === 'trigger'"
                  v-model="editItemPayload.trigger"
                  :items="triggers"
                  color="secondary"
                  outlined
                  dense
                  label="Criteria"
                  class="mt-n8 mb-n8"
              ></v-select>
              </v-col>
              <!--delay-->
              <v-col cols="6">
                <v-select
                  v-if="editItemPayload && editItemPayload.type === 'delay'"
                  v-model="editItemPayload.delays"
                  :items="delays"
                  color="secondary"
                  outlined
                  dense
                  label="Delay (days)"
                  class="mt-n16 mb-n8"
              ></v-select>
              </v-col>
              <!--remove-tags-->
              <v-col cols="9">
                <v-select
                  v-if="editItemPayload && editItemPayload.type === 'remove-tag'"
                  v-model="editItemPayload.tags"
                  :items="tags"
                  color="secondary"
                  outlined
                  class="mt-n16 mb-8"
                  label="Remove Selected Tags"
                  chips
                  multiple
              ></v-select>
              </v-col>
              <!--add tags-->
              <v-col cols="9">
                <v-select
                  v-if="editItemPayload && editItemPayload.type === 'tag'"
                  v-model="editItemPayload.tags"
                  :items="tags"
                  color="secondary"
                  outlined
                  class="mt-n16 mb-8"
                  label="Select Tags"
                  chips
                  multiple
              ></v-select>
              </v-col>
              <!--condition-->
              <v-col cols="12">
                <v-card
                  class="mt-n16 mb-12 pa-4"
                  outlined
                  v-if="editItemPayload && editItemPayload.type === 'condition'"
                >   
                  <v-select
                    v-model="editItemPayload.condition"
                    :items="conditions"
                    color="secondary"
                    outlined
                    dense
                    label="Criteria"
                  ></v-select>
                  <v-select
                    v-model="editItemPayload.threshold"
                    :items="thresholds[conditions.indexOf(editItemPayload.condition)].threshold"
                    color="secondary"
                    outlined
                    dense
                    label="Threshold"
                  ></v-select>
              </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none mr-2 mb-2 mt-n16"
              elevation="0"
              @click="editItemModal = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--add dialog-->
      <v-dialog
        max-width="500"
        v-model="addItemModal"
        >
        <v-card>
          <v-card-title>
            <!-- <h3 class="secondary--text text-wrap"> Add Workflow Step</h3> -->
            <span class="grey--text text-overline text--darken-2">
              <v-chip
                class="mr-1"
                label
                small
                :color="addItemPayload.itemType && addItemPayload.itemType.color"
              >
              <v-icon :color="addItemPayload.itemType && addItemPayload.itemType.textColor" small>
                {{ addItemPayload.itemType && addItemPayload.itemType.icon }}
              </v-icon>
              </v-chip>
              {{ addItemPayload.itemType && addItemPayload.itemType.type === 'remove-tag'  ? 'Remove Tag': `Add ${addItemPayload.itemType && addItemPayload.itemType.type}`}}
            </span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <!--messsage - need to update specific item within nestableItems-->
              <v-col cols="12">
                <v-textarea
                  v-model="addItemPayload.description"
                  :rows="addItemPayload.itemType && addItemPayload.itemType.type === 'message'  ? '5': '3'"
                  color="secondary"
                  outlined
                  dense
                  :label="addItemPayload.itemType && addItemPayload.itemType.type === 'message'  ? 'Message': 'Description'"
                ></v-textarea>
              </v-col>
              <!--trigger-->
              <v-col cols="12">
                <v-select
                  v-if="addItemPayload.itemType && addItemPayload.itemType.type === 'trigger'"
                  v-model="addItemPayload.trigger"
                  :items="triggers"
                  color="secondary"
                  outlined
                  dense
                  label="Criteria"
                  class="mt-n8 mb-n8"
              ></v-select>
              </v-col>
              <!--delay-->
              <v-col cols="6">
                <v-select
                  v-if="addItemPayload.itemType && addItemPayload.itemType.type === 'delay'"
                  v-model="addItemPayload.delays"
                  :items="delays"
                  color="secondary"
                  outlined
                  dense
                  label="Delay (days)"
                  class="mt-n8 mb-n8"
              ></v-select>
              </v-col>
              <!--condition-->
              <v-col cols="12">
                <v-card
                  class="mt-n16 mb-8 pa-4"
                  outlined
                >                
                  <v-select
                    v-if="addItemPayload.itemType && addItemPayload.itemType.type === 'condition'"
                    v-model="addItemPayload.condition"
                    :items="conditions"
                    color="secondary"
                    outlined
                    dense
                    label="Criteria"
                  ></v-select>
                  <v-select
                    v-if="addItemPayload.itemType && addItemPayload.itemType.type === 'condition'"
                    v-model="addItemPayload.threshold"
                    :items="thresholds[conditions.indexOf(addItemPayload.condition)]?thresholds[conditions.indexOf(addItemPayload.condition)].threshold:[]"
                    color="secondary"
                    outlined
                    dense
                    label="Threshold"
                  ></v-select>
              </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none mr-2 mb-2 mt-n8"
              elevation="0"
              @click="addChild(addItemPayload), addItemModal = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--delete dialog-->
      <v-dialog v-model="deleteItemModal" max-width="500px">
        <base-card>
            <v-card-title>
                <h3 class="mt-4 mb-n4 secondary--text text-wrap">Are you sure you want to delete this step?</h3>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                      <v-card-title>
                        <span class="grey--text text-overline text--darken-2">
                          <v-chip
                            class="mr-1"
                            label
                            small
                            :color="getBadge(deleteItemPayload.type).color"
                          >
                          <v-icon :color="getBadge(deleteItemPayload.type).textColor" small>
                            {{ getBadge(deleteItemPayload.type).icon }}
                          </v-icon>
                          </v-chip>
                          {{ deleteItemPayload && deleteItemPayload.type === 'remove-tag'  ? 'Remove Tag': `Add ${deleteItemPayload.type}`}}
                        </span>
                      </v-card-title>
                      <v-card-text>
                        <v-col cols="12">
                          
                            <v-textarea
                              v-model="deleteItemPayload.description"
                              auto-grow
                              color="secondary"
                              outlined
                              dense
                              readonly
                              class="ml-n3"
                            ></v-textarea>
                        </v-col>
                      </v-card-text>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end mt-n12">
            <v-btn
              text
              @click="deleteItemModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              elevation="0"
              color="primary"
              @click="removeItem(deleteItemPayload), deleteItemModal = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </base-card>
      </v-dialog>
  </v-container>
</template>

<script>
import DashbordSidebar from "@/components/DashboardSidebar";
import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import WorkflowChildren from "./WorkflowChildren.vue";
import { add } from "lodash";
import { nanoid } from 'nanoid';
import AtTa from 'vue-at/dist/vue-at-textarea' // for textarea
// const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
const cheqqMateServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
  }
};

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit('app/START_LOADING');
    return config;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    store.commit('app/FINISH_LOADING');
    return response;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

  export default {
    components: {
        DashbordSidebar,
        WorkflowChildren,
        AtTa
    },    
    data: () => ({
      moving: false,
      initialisedWorkflowTree: {},
      startMovePosition: null,
      zoomScale: 1,  // Add this line
      snackbar: false,
      offset: true,
      snackbarMessage: '',
      dialog: false,
      isSidebar: false,
      isHovered: false,
      hoverId: null,
      editItemModal: false,
      editItemPayload: {},
      addItemModal: false,
      addItemPayload: {},
      conditionalItem: false,
      deleteItemModal: false,
      deleteItemPayload: {},
      senders: ['Self', 'Kingbot'],
      // senders: [this.authUser()?this.authUser().email:'Self', 'Kingbot'],
      justify: [
        'start',
        'center',
        'end',
        'space-around',
        'space-between',
      ],
      items: [
      // { type: 'trigger', icon: 'mdi-lightning-bolt', color: 'red lighten-4', textColor: 'red' },
      { type: 'condition', icon: 'mdi-filter', color: 'blue lighten-4', textColor: 'blue' },
      { type: 'delay', icon: 'mdi-timer', color: 'green lighten-4', textColor: 'green' },
      { type: 'message', icon: 'mdi-message', color: 'blue-grey lighten-4', textColor: 'blue-grey' },
      { type: 'tag', icon: 'mdi-tag', color: 'orange lighten-4', textColor: 'orange' },
      { type: 'remove-tag', icon: 'mdi-tag-remove', color: 'purple lighten-4', textColor: 'purple' },
      ],
      tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5'],
      delayDays: 0,
      delays: [1, 2, 3, 4, 5, 7, 14, 28],
      triggers: ['New Member Joins', 'Meetup Invitation Response', 'Course Enrollment', 'Course Completion', 'Event Registration'],
      conditions: [
        'Tag equals',
        'Number of days since last post greater than',
        'User Profile complete',
        'Tenure greater than',
        'Number of days on snooze greater than',
        'Number of days inactive greater than',
        'Number of messages posted greater than',
        'Number skipped meetups greater than',
        'Number accepted meetups greater than',
        'Number cancelled meetups greater than'
      ],
      thresholds: [
        {criteria:'Tag equals', threshold: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5']},
        {criteria: 'Number of days since last post greater than', threshold: [1, 2, 3, 4, 5, 7, 15, 30, 60, 90, 180, 365]},
        {criteria: 'User Profile complete', threshold: ['']},
        {criteria: 'Tenure greater than', threshold: [1, 2, 3, 4, 5, 7, 15, 30, 60, 90, 180, 365]},
        {criteria: 'Number of days on snooze greater than', threshold: [1, 2, 3, 4, 5, 7, 15, 30, 60, 90, 180, 365]},
        {criteria: 'Number of days inactive greater than', threshold: [1, 2, 3, 4, 5, 7, 15, 30, 60, 90, 180, 365]},
        {criteria: 'Number of messages posted greater than', threshold: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]},
        {criteria: 'Number skipped meetups greater than', threshold: [1, 2, 3, 4, 5, 7, 8, 9, 10]},
        {criteria: 'Number accepted meetups greater than', threshold: [1, 2, 3, 4, 5, 7, 8, 9, 10]},
        {criteria: 'Number cancelled meetups greater than', threshold: [1, 2, 3, 4, 5, 7, 8, 9, 10]}
      ], 
      workflowId: '',
      nestableItem: [
        {
          id: 0,
          type: "trigger",
          condition: null,
          description: "New Trigger",
          children: []
        }
      ],
      members: ['User', 'Date'],
    }),
    mounted () {
      if(this.$route.query.id) {
        this.workflowId = this.$route.query.id
        this.readWorkflowById()
      }
    },
    computed: {
        ...mapState('auth', ['authUser', 'memberDirectory','group']),
        ...mapState('user', [ 'memberDirectory','workflow']),
        authUser () {
          return this.$store.state.auth.authUser
        },
        workflowItems: {
          get() {
            return this.workflow?this.workflow.workflowTree:this.nestableItem
          },
          set(value) {
            this.workflow.workflowTree = value
          }
        },
        workflowItemsV2: {
          get() {
            return this.removeItemsWithIdMinusOne(this.workflowItems)
          },
          set(value) {
            this.workflowItems = value
          }
        },
        activeWorkItems() {
          return this.removeItemsWithIdMinusOne(this.workflowItems)
        },
        workflowDetails () {
          return this.workflow
        },
    },

    methods: {
      ...mapActions('user', ['getMemberDirectory','updateWorkflowName','updateWorkflowNoRedirect']),
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        formatDateTime (date) {
            return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
            // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        },
        editItem (item){
          this.$emit('edit-item', item);
        },
        editItemFullScreen (item){
          this.$emit('edit-item-full-screen', item);
        },
        deleteItem (item){
          this.$emit('delete-item', item);
        },
        getBadge(type) {
          /* https://help.threado.com/en/articles/6313321-what-are-the-different-blocks-you-can-use-to-perform-actions */
          switch (type) {
            case 'trigger':
              return { icon: 'mdi-lightning-bolt', color: 'red lighten-4', textColor: 'red' };
            case 'condition':
              return { icon: 'mdi-filter', color: 'blue lighten-4', textColor: 'blue' };
            case 'delay':
              return { icon: 'mdi-timer', color: 'green lighten-4', textColor: 'green' };
            case 'message':
              return { icon: 'mdi-message', color: 'blue-grey lighten-4', textColor: 'blue-grey' };
            case 'tag':
              return { icon: 'mdi-tag', color: 'orange lighten-4', textColor: 'orange' };
            case 'remove-tag':
              return { icon: 'mdi-tag-remove', color: 'purple lighten-4', textColor: 'purple' };
            default:
              return {};
          }
        },
        deleteModal(item) {
          this.deleteItemPayload = item
          this.deleteItemModal = true
        },
        editModal(item) {
          this.editItemPayload = item
          this.editItemModal = true
        },
        addModal(item) {
          this.addItemPayload = item
          this.addItemModal = true
        },
        addModalV2(item) {
          this.addItemPayload = item
          this.addItemModal = true
          this.conditionalItem = true
        },
        isLast(item) {
          var filteredWorkflowItems = this.workflowItems.filter(item => item.id !== -1)
          return item.id === filteredWorkflowItems[filteredWorkflowItems.length-1].id
        },
        hasHistory () { 
              return window.history.length > 2 
          },
        isLastActiveChild(item) {
          //where id !== -1
          // return item.children.filter(child => child.id !== -1)[item.children.length-1].id === item.id
          //filter out all items with id = -1
          
          return item.id === item.children.filter(child => child.id !== -1)[item.children.filter(child => child.id !== -1).length-1].id

        },
        hasChildren(item) {
          return item.children.filter(child => child.id !== -1).length > 0;
          // return item.children && item.children.length > 0
        },
        //use id to find parent and check if the parent has multiple children
        hasSibling(item) {
          var parent = this.workflowItems.find((element) => element.children.some((child) => child.id === item.id))
          return parent && parent.children.length > 1
        },
        removeElement(arr, item) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === item.id) {
              arr.splice(i, 1)
              return arr
            }
            if (arr[i].children) {
              this.removeElement(arr[i].children, item)
            }
          }
          return arr
        },
        softRemoveElement(arr, item) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === item.id) {
              
              arr[i].id = -1
              //find parent aswell and set the id to -1 only if there is no other active child
              var foundParent = this.findParentV2(item)
              if(!this.hasChildren(foundParent)){
                foundParent.id = -1
              }
              return arr
            }
            if (arr[i].children) {
              this.softRemoveElement(arr[i].children, item)
            }
          }
          return arr
        },
        removeItem(item) {
          // traverse the nestableItem and all its child elements to find the item and remove the item
          // soft remove the item by setting the id to -1 instead of removing the item altogether.

          // var foundParent = this.findParentV2(item)
          // console.log('foundParent', foundParent)
          // if(foundParent.type === 'condition'){
          //   this.workflowItems = this.softRemoveElement(this.workflowItems, item)
          //   }
          //   else{
          //     this.workflowItems = this.removeElement(this.workflowItems, item)
          //   }

            this.workflowItems = this.softRemoveElement(this.workflowItems, item)
          
          this.workflowItemsV2 = this.workflowItems;
          // REMEMBER: when saving the workflow, remove all items with id = -1
          this.snackbar = true
          this.snackbarMessage = 'Step deleted successfully'
        },
        findParent(arr, item) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === item.id) {
              return arr[i]
            }
            if (arr[i].children) {
              this.findParent(arr[i].children, item)
            }
          }
          return arr
        },
        findParentV2(item){
          // given an item, traverse the workflowItems and all its child elements to find the parent item
          // return the parent item
          var parent= {}
          for (let i = 0; i < this.workflowItems.length; i++) {
            for (let j = 0; j < this.workflowItems[i].children.length; j++) {
              parent = this.workflowItems[i].children[j]
            }
          }
          return parent
        },
        findParentV3(item) {
          for (let i = 0; i < this.workflowItems.length; i++) {
            if (this.workflowItems[i].id === item.id) {
              return this.workflowItems[i]
            }
            if (this.workflowItems[i].children) {
              this.findParent(this.workflowItems[i].children, item)
            }
          }
          return this.workflowItems
        },
        addChildToParentV2(arr, parent, child) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === parent.id) {
              if (Array.isArray(parent)) {
                  parent = parent[0]
                } 
              parent.children.push(child)
              // if child is an array, add each child to the parent
              if (Array.isArray(child)) {
                arr[i].children = arr[i].children.concat(parent)
              } else {
              arr[i].children.push(parent)
              }
              return arr
            }
            if (arr[i].children) {
              this.addChildToParentV2(arr[i].children, parent, child)
            }
          }
          return arr
        },
        // after finding the parent item, add the child item to the parent item
        addChildToParent(arr, parent, child) {
          console.log('before parent', parent)
          if (Array.isArray(parent)) {
              parent = parent[0]
            } 
          parent.children.push(child)
          return arr
        },
        // get the parent item and add the child item to the parent item
        addChild(payload) {
          var _addItemPayload = {};
          var foundParent = {};
          if(this.conditionalItem){
            console.log('conditionalItem', this.conditionalItem)
            foundParent = payload.item
            _addItemPayload = {
                  id: this.generateId(),
                  type: payload.itemType.type,
                  condition: '',
                  description: '',
                  delayDays: 0,
                  children: []
                }            
          } 
          // if not adding a conditional item
          else {
            if (payload.itemType.type === 'condition') {
              //initilize the new item
              _addItemPayload = payload.itemType
              _addItemPayload.id = this.generateId()
              _addItemPayload.condition = payload.condition
              _addItemPayload.threshold = payload.threshold
              _addItemPayload.description = payload.description
              _addItemPayload.children = [
                {
                  id: this.generateId(),
                  type: 'message',
                  condition: '',
                  description: 'falsey',
                  delayDays: 0,
                  children: []
                },
                {
                  id: this.generateId(),
                  type: 'message',
                  condition: '',
                  description: 'truthy',
                  delayDays: 0,
                  children: []
                }]

              }  else {
                //initilize the new item
                _addItemPayload = {
                  id: this.generateId(),
                  type: payload.itemType.type,
                  condition: '',
                  description: '',
                  delayDays: 0,
                  children: []
                }
              }
            foundParent = this.findParent(this.workflowItems, payload.item)
          }
          
          this.workflowItems = this.addChildToParent(this.workflowItems, foundParent, _addItemPayload)
          this.snackbar = true
          this.snackbarMessage = 'Step added successfully'
        },
        generateId: () => nanoid(10),
        removeItemsWithIdMinusOne(items) {
          return items?items:[]
            .filter(item => item.id !== -1)
            .map(item => {
              if (item.children && item.type !== 'condition') {
                item.children = this.removeItemsWithIdMinusOne(item.children);
              }
              return item;
            });
        },
        removeItemsWithIdMinusOneV2(items) {
          // Ensure items is an array; if not, default to an empty array
          items = Array.isArray(items) ? items : [];

          return items
            .filter(item => item.id !== -1)
            .map(item => {
              if (item.children) {
                // Recursively filter children
                item.children = this.removeItemsWithIdMinusOneV2(item.children);
              }
              return item;
            });
        },
        readWorkflowById() {
          axios
          .get(
            `${cheqqMateServiceBaseUrl}/getWorkflowById`+
              '?UserEmail=' + this.authUser.email + '&WorkflowId=' + this.workflowId,
            cheqqMateServiceConfig,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
              }
            }
          )
          .then(res => {
            if(res.data) {
              console.log('res.data.workflow', res.data.workflow)
              this.initialisedWorkflowTree = res.data.workflow.workflowTree
              //save to vuex, so that it persists, instead of consistently calling api
              this.$store.commit('user/WORKFLOW_DETAILS_UPDATED', res.data.workflow);
            } 
            else {
            }
          })
          .catch(error => {
              if (error.response) {
              }
          });
        },
        updateWorkflow(){
          //1. remove all items with id = -1
          this.workflowDetails.workflowTree = this.removeItemsWithIdMinusOneV2(this.workflowDetails.workflowTree)
          //2. update the workflow
          this.updateWorkflowNoRedirect();
        },
        resetWorkflow(){
          // grab the initial workflow tree and set it as the current workflow tree
          this.readWorkflowById()
          this.workflowItems = this.initialisedWorkflowTree
        },
        //zoom
        zoomIn() {
          this.zoomScale += 0.1;  // Increase zoomScale by 0.1
        },
        zoomOut() {
          if (this.zoomScale > 0.1) {  // Prevent zoomScale from becoming 0 or negative
            this.zoomScale -= 0.1;  // Decrease zoomScale by 0.1
          }
        },
        toggleFullscreen() {
          const canvas = this.$refs.canvas;  // Get a reference to the canvas

          if (!document.fullscreenElement) {
            if (canvas.requestFullscreen) {
              canvas.requestFullscreen();
            } else if (canvas.webkitRequestFullscreen) {  // Chrome, Safari and Opera
              canvas.webkitRequestFullscreen();
            } else if (canvas.msRequestFullscreen) {  // IE/Edge
              canvas.msRequestFullscreen();
            }
          } else {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {  // Chrome, Safari and Opera
              document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {  // IE/Edge
              document.msExitFullscreen();
            }
          }
        },
      },
    }
</script>
<style lang="scss" scoped>
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #2196F3 !important;
            border-left: 4px solid #2196F3;
        }
    }
  .rounded-card {
      border-radius: 10px !important;
      overflow: hidden;
  }
  .add-item {
      // position: relative;
      margin: 0;
      margin-left: 0px;
      padding: 0rem;
      border: 1px solid #959eb1;
      // border-top: 1px solid #959eb1;

      border-radius: 5px;
      background: #f9fafb;
    }
    .workflow-canvas {
      // display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh; /* Full height of the viewport */
      width: 100%; /* Full width */
      background-color: #f5f5f5; /* Light grey background */
      border: 1px solid #ddd; /* Grey border */
      overflow: auto; /* Add scroll if content is larger than the container */
    }
</style>
<template>
  <div>
    <!-- features  -->
    <section id="features" class="mb-15">
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center mb-15">
            <h1 class="secondary--text text-40 font-weight-bold">
              Powerful Features
            </h1>
          </v-col>
        </v-row>
        <v-row class="landing-features p-relative">
          <v-col
            cols="12"
            md="4"
            lg="4"
            class="text-center"
            v-for="(n, index) in powerfulFeatures"
            :key="index"
          >
            <base-card class="shadow">
              <div class="py-14">
                <v-icon x-large color="primary">{{ n.icon }}</v-icon>
                <h4 class="mt-6 secondary--text">{{ n.name }}</h4>
                <div v-if="n.tag">
                  <v-chip
                    class="text-sm white--text mt-2"
                    small
                    color="secondary"
                    label
                    dark
                  >
                    {{ n.tag }}
                  </v-chip>
                </div>
              </div>
            </base-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="faqs" class="py-15">
      <v-container>
        <v-row class="mb-5">
          <v-col cols="12" class="text-center">
            <h2 class="text-40 secondary--text font-weight-black">
              Frequently Asked Questions
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <template>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item,i) in faqs"
                :key="i"
              >
                <v-expansion-panel-header>
                  <div class="secondary--text">
                    <strong>
                      {{item.title}}
                    </strong>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="secondary--text">
                    {{ item.content }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-row>
      </v-container>
    </section>
    <!-- pricing  -->
    <section id="pricing" class="py-15 section-pricing">
      <v-container>
        <v-row>
          <v-col cols="12" class="mb-5">
            <h2 class="text-30 text-center secondary--text">
              Choose the plan that fits your needs.
            </h2>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-center flex-wrap">
              <div class="pricing-card text-center me-4">
                <h3 class="mb-5">Free Plan</h3>
                <div class="d-flex justify-center mb-14">
                  <div class="font-600 text-16 mr-2">$</div>
                  <h3 class="display-3 font-weight-black secondary--text">
                    0
                  </h3>
                </div>
                <p class="mb-16">
                  <!---brief description below-->
                  The free plan will allow end users to use the service with some restrictions.
                </p>
                <v-btn
                  outlined
                  color="secondary"
                  class="font-600 text-capitalize"
                  href="/sign-up"
                >
                  Get Started
                </v-btn>
              </div>
              <div class="pricing-card text-center primaryLight white--text">
                <h3 class="mb-5">Starter</h3>
                <div class="d-flex justify-center mb-14">
                  <div class="font-600 text-16 mr-2">$</div>
                  <h3 class="display-3 font-weight-black">TBC</h3>
                </div>
                <p class="mb-16">
                  The paid plan will allow end users to use the service without restrictions.
                </p>
                <v-btn
                  outlined
                  color="white"
                  class="font-600 text-capitalize"
                  href="/sign-up"
                >
                  Coming soon
                </v-btn>
              </div>
              <div class="pricing-card text-center primary white--text">
                <h3 class="mb-5">Pro</h3>
                <div class="d-flex justify-center mb-14">
                  <div class="font-600 text-16 mr-2">$</div>
                  <h3 class="display-3 font-weight-black">TBC</h3>
                </div>
                <p class="mb-16">
                  The paid plan will allow end users to use the service without restrictions.
                </p>
                <v-btn
                  outlined
                  color="white"
                  class="font-600 text-capitalize"
                  href="/sign-up"
                >
                  Coming soon
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      items: ["Demos", "Shop Pages", "User Account", "Vendor Account"],
      powerfulFeatures: [
        {
          img: require("@/assets/images/icons/landing/get_started.svg"),
          name: "Get Started Fast",
          tag: "",
          size: 64,
          icon: "mdi-rocket-launch"
        },
        {
          img: require("@/assets/images/icons/landing/schedule_message.svg"),
          name: "Schedule message for later",
          tag: "",
          size: 64,
          icon: "mdi-message-text-clock"
        },
        {
          img: require("@/assets/images/icons/landing/unlimited_group.svg"),
          name: "Unlimited Group Sizes",
          tag: "",
          size: 81,
          icon: "mdi-account-group"
        },
        {
          img: require("@/assets/images/icons/landing/bulk_import.svg"),
          name: "Bulk Contact Import",
          tag: "",
          size: 64,
          icon: "mdi-database-import"
        },
        {
          img: require("@/assets/images/icons/landing/track_results.svg"),
          name: "Track Check-in results",
          tag: "",
          size: 64,
          icon: "mdi-chart-bar"
        },
        {
          img: require("@/assets/images/icons/landing/out_of_office.svg"),
          name: "Set Out of Office",
          tag: "",
          size: 81,
          icon: "mdi-calendar-multiselect"
        },
        {
          img: require("@/assets/images/icons/landing/custom_message.svg"),
          name: "Personalised messages",
          tag: "coming soon",
          size: 64,
          icon: "mdi-email-edit"
        },
        {
          img: require("@/assets/images/icons/landing/followup_message.svg"),
          name: "Send automatic follow-ups",
          tag: "coming soon",
          size: 64,
          icon: "mdi-email-multiple"
        },
        {
          img: require("@/assets/images/icons/landing/followup_message.svg"),
          name: "AI generated questions",
          tag: "coming soon",
          size: 64,
          icon: "mdi-brain"
        }
      ],
      faqs: [
        {
          title:"What is CheqqMate?",
          content:"CheqqMate is a web application that allows you to check-in with your team members and colleagues to see how they are doing. It is a simple and easy way to keep in touch with your team and to make sure that everyone is doing well."
        },
        {
          title:"How does it work?",
          content: "With CheqqMate you can create a group and add your team members to it. You then create a recurring schedule which generates pairings within your group, CheqqMate then sends them a message on WhatsApp. Matched team members can then arrange to check-in. As the admin you can then view the results of the check-ins on your dashboard."
        },
        {
          title:"How do I get started?",
          content:"To get started, you can sign up for a free account. Once you have signed up, you can create a group and add your team members to it. You can then setup a recurring schedule for the group. Team members are then paired together. CheqqMate will send them a message on WhatsApp. Your team members can then arrange to check-in with each other. You can then view the results of the check-ins on your dashboard."
        },
        {
          title:"How much does it cost?",
          content: "During the beta period, CheqqMate is free to use. Once the beta period is over, CheqqMate will be available for a monthly subscription fee. There will always be a free tier available, but it will have limited features."
        },
        {
          title:"How do I get in touch?",
          content: "If you have any questions or feedback, you can get in touch with us by sending an email to cheqqmate@gmail.com"
        }
      ],
      userAccount: [
        {
          img: require("@/assets/images/landing/customer/page-5.png"),
          name: "Profile",
          link: "/view-profile",
        },
        {
          img: require("@/assets/images/landing/customer/page-6.png"),
          name: "Edit Profile",
          link: "/edit-profile",
        },
        {
          img: require("@/assets/images/landing/customer/page-2.png"),
          name: "Orders",
          link: "/order-list",
        },
        {
          img: require("@/assets/images/landing/customer/page-1.png"),
          name: "Addresses",
          link: "/list-address",
        },
        {
          img: require("@/assets/images/landing/customer/page-3.png"),
          name: "Order Details",
          link: "/order-details",
        },
       
        {
          img: require("@/assets/images/landing/customer/page-7.png"),
          name: "Support Tickets",
          link: "/all-tickets",
        },
        {
          img: require("@/assets/images/landing/customer/page-8.png"),
          name: "Ticket Details",
          link: "/ticket-details",
        },
        {
          img: require("@/assets/images/landing/customer/page-9.png"),
          name: "Wishlist",
          link: "/wishlist",
        },
      ],
      vendorAccount: [
        {
          img: require("@/assets/images/landing/vendor/page-1.png"),
          name: "Dashboard",
          link: "/dashboard",
        },
        {
          img: require("@/assets/images/landing/vendor/page-2.png"),
          name: "Account Settings",
          link: "/profile",
        },
        {
          img: require("@/assets/images/landing/vendor/page-3.png"),
          name: "Products",
          link: "/product-list",
        },
        {
          img: require("@/assets/images/landing/vendor/page-4.png"),
          name: "Add Product",
          link: "/edit-product",
        },
        {
          img: require("@/assets/images/landing/vendor/page-5.png"),
          name: "Orders",
          link: "/order-list",
        },
      ],
      technology: [
        {
          img: require("@/assets/images/logos/vue.svg"),
          name: "Vue.js",
        },
        {
          img: require("@/assets/images/logos/vuetify.svg"),
          name: "Vuetify",
        },
        {
          img: require("@/assets/images/logos/sass.png"),
          name: "Sass",
        },
        {
          img: require("@/assets/images/logos/apexchart.png"),
          name: "Apexchart",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#hero {
  margin-top: 64px;
}
.landing-features {
  z-index: 1;
  &::after {
    content: " ";
    position: absolute;
    height: 100px;
    width: 200px;
    background: rgb(227, 233, 239);
    z-index: -1;
    bottom: 0;

    left: 60px;
    border-bottom-right-radius: 300px;
    border-bottom-left-radius: 300px;
    margin-left: -100px;
    margin-bottom: -50px;
  }
  &::before {
    content: " ";
    position: absolute;
    height: 100px;
    width: 200px;
    background: rgb(227, 233, 239);
    z-index: -1;
    top: -65px;
    transform: rotate(180deg);
    right: 0;
    border-bottom-right-radius: 300px;
    border-bottom-left-radius: 300px;
    margin-right: 0px;
  }
}

.section-pricing {
  background-image: url("../../assets/images/landing/CheqqMateBackgroud.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgb(246, 249, 252);
}
.landing-card {
  &:hover {
  }
  .landing-card-body {
    box-shadow: none;
    cursor: unset;
    padding: 10% 10% 0px;
    background-color: rgb(243, 245, 249);
    border: 1px solid rgb(227, 233, 239);
    border-radius: 8px;
    position: relative;
    margin-bottom: 1.5rem;
    &:hover {
      .landing-card-overlay {
        opacity: 1;
      }
    }
    .landing-card-overlay {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.54);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      opacity: 0;
      transition: opacity 250ms ease-in-out 0s;
    }
  }
}

// section-demos
.section-demos {
  background-image: url("../../assets/images/landing/landing-bg-2.svg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.pricing-card {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
  border-radius: 8px;
  padding: 3rem 1rem 2rem;
  margin: 1.325rem;
  min-width: 270px;
  p {
    max-width: 216px;
    margin: 0 auto;
  }
}
</style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-file-input', {
    attrs: {
      "rules": _vm.rules,
      "accept": "image/png, image/jpg, image/jpeg, image/bmp",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "prepend-icon": _vm.icon ? _vm.icon : 'mdi-camera',
      "label": _vm.label ? _vm.label : 'Upload File',
      "type": "file"
    },
    on: {
      "change": _vm.handleFileSelection
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
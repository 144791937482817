<template>
    <v-container class="py-6">
      <v-snackbar
          v-model = "snackbar"
          color = "primary"
          elevation = "16"
          class = "snackbar"
          :timeout="500"
      >
        {{ snackbarMessage }}
      </v-snackbar>
        <v-row>
          <v-col cols="12">
              <div class="box-wrapper">
                  <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                  <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                     <DashbordSidebar />
                  </div>
                  <div class="box-content">
                      <div class="d-flex justify-end pa-2 d-block d-md-none">
                          <v-btn
                              icon   
                              @click="isSidebar = !isSidebar"
                          >
                              <v-icon dark>
                                  mdi-format-list-bulleted-square
                              </v-icon>
                          </v-btn>
                      </div>
                      <div class="box-container">
                         <v-row class="mb-n6">
                            <v-col cols="6">
                                <div class="d-flex align-center">
                              <v-avatar
                                  tile
                                  size="50"
                                  class="me-3"
                              >
                                <v-icon
                                    large
                                    color="grey darken-1"
                                    class="icon"
                                    >
                                    mdi-timer-sand
                                </v-icon>
                              </v-avatar>
                                  <h2 class="grey--text text--darken-1 ml-n2">Responsiveness</h2>
                              </div>
                            </v-col>
                            <v-col cols="3">
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                :items="items"
                                label="Date Range"
                                solo
                                dense
                                flat
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-4"></v-divider>
                       <base-card>
                          <div class="pa-4 mb-4">
                            <v-row>
                                <v-col cols="4">
                                    <div class="d-flex flex-column text-start align-start justify-start">
                                        <p class="mb-0 text-body-2 font-weight-bold secondary--text"> 
                                            <v-chip
                                                class="mr-2"
                                                label
                                                small
                                                color="blue lighten-5"
                                            >
                                            <span class="blue--text">#</span>
                                            </v-chip>    
                                            Avg. Time to 1st Response
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                    small
                                                    color="grey darken-1"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >
                                                    mdi-information-outline
                                                    </v-icon>
                                                </template>
                                                <span>Tooltip</span>
                                            </v-tooltip>
                                        </p>
                                        <h3 class="font-600 secondary--text ml-12"> 0 </h3>
                                    </div>
                                </v-col>
                                <v-col cols="4" >
                                    <div class="d-flex flex-column text-start align-start justify-start mb-n6">
                                        <p class="mb-0 text-body-2 font-weight-bold secondary--text"> 
                                          <v-chip
                                                class="mr-2"
                                                label
                                                small
                                                color="green lighten-5"
                                            >
                                            <span class="green--text">#</span>
                                            </v-chip>    
                                            Least Responsive Topic
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                    small
                                                    color="grey darken-1"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >
                                                    mdi-information-outline
                                                    </v-icon>
                                                </template>
                                                <span>Tooltip</span>
                                            </v-tooltip>
                                        </p>
                                        <h3 class="font-600 secondary--text ml-12"> #Topic Name </h3>
                                    </div>
                                </v-col>                                
                                <v-col cols="4" >
                                    <div class="d-flex flex-column text-start align-start justify-start mb-n6">
                                        <p class="mb-0 text-body-2 font-weight-bold secondary--text"> 
                                          <v-chip
                                                class="mr-2"
                                                label
                                                small
                                                color="grey lighten-2"
                                            >
                                            <span class="grey--text">#</span>
                                            </v-chip>    
                                            Least Responsive Group
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                    small
                                                    color="grey darken-1"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >
                                                    mdi-information-outline
                                                    </v-icon>
                                                </template>
                                                <span>Tooltip</span>
                                            </v-tooltip>
                                        </p>
                                        <h3 class="font-600 secondary--text ml-12"> Group Name </h3>
                                    </div>
                                </v-col>
                            </v-row>
                          </div>
                        </base-card>
                         <!--chart-->
                        <base-card>
                        <v-row class="ma-2">
                            <v-col cols="6" class="mt-2">
                                <span class="text-h5 font-weight-bold grey--text text--darken-1">Response Rate</span> 
                                <br/>
                                <span class="text-caption grey--text text--darken-1">Measures the % of messages that have been responded to.</span>
                            </v-col>
                            <v-col cols="3">
                                <v-checkbox
                                    dense
                                    v-model="areaChart"
                                    color="primary"
                                    off-icon="mdi-chart-bar"
                                    on-icon="mdi-chart-bell-curve-cumulative"
                                    class="bordered-checkbox"
                                    label="Chart"
                                >
                            </v-checkbox>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                :items="items"
                                label="Date Range"
                                outlined
                                dense
                                ></v-select>
                            </v-col>

                        </v-row>                     
                            <div class="mt-n10 pa-5">
                            <div id="chart">
                                <apexchart
                                :type="chartType"
                                height="250"
                                :options="chartOptions[0]"
                                :series="series"
                                />
                            </div>
                            </div>
                        </base-card>
                      </div>
                  </div>
              </div>
          </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import DashbordSidebar from "@/components/DashboardSidebar";
  import { mapState, mapActions, mapGetters } from 'vuex';
  import axios from 'axios';
  import countryList from "../../data/countryList";
  
  
  const defaultUserProfile = {
      id: 'Jane.Done@example.com',
      username: 'Jane.Done',
      type: 'userProfile',
      userId: 'Jane.Done@example.com',
      personalDetails: {
          id: "myemail@live.co.uk",
          name: "My Name",
          phoneNumber: "00000000012",
          emailAddres: "myemail@live.co.uk",
          resumeDate: "14/08/2023 10:46",
          isDeletedFromDirectory: false
      },
      subscription: {
          name: "Free",
          isActive: true,
          createdDate: "14/08/2023 10:46"
      },
      memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
      groups: [
          {
              groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
              groupName: "My Awesome Squad"
          }
      ],
      schedule: [
          {
              "id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
              "scheduleName": "My Awesome Squad Schedule"
          }
      ],
      matches: 1,
      matchesLastModifiedDate: "14/08/2023 10:46",
      meetups: 1,
      meetupsLastModifiedDate: "14/08/2023 10:46",
      pauses: 1,
      pausesLastModifiedDate: "14/08/2023 10:46",
      dashboard: {
          topUserGroups: [
              {
                  userGroup: null,
                  matches: 0,
                  meetups: 0,
                  pauses: 0
              }
          ]
      },
    };
  
  // const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
  const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
  const cheqqMateServiceConfig = {
    headers: {
      'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
      'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
    }
  };
  
  const http = axios.create();
  
  http.interceptors.request.use(
    config => {
      store.commit('app/START_LOADING');
      return config;
    },
    error => {
      store.commit('app/FINISH_LOADING');
      store.commit(
        'app/SET_ERROR',
        'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
      );
  
      return Promise.reject(error);
    }
  );
  
  http.interceptors.response.use(
    response => {
      store.commit('app/FINISH_LOADING');
      return response;
    },
    error => {
      store.commit('app/FINISH_LOADING');
      store.commit(
        'app/SET_ERROR',
        'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
      );
  
      return Promise.reject(error);
    }
  );
  
    export default {
      components: {
          DashbordSidebar
      },    
      data: () => ({
        snackbar: false,
        snackbarMessage: '',
        dialog: false,
        areaChart: true,
        file: {},
        fileData: "",
        _item: "",
        dialog2: false,
        isSidebar: false,
        matchArray: [],
            matchDate: '',
            options: {
                chart: {
                    height: 265,
                    type: 'area',
                    toolbar: {
                    show: false,
                    },
                },
            },
        groups:[],
        items: ['Last 7 days', 'Last 30 days'],
        members: [],
        editedIndex: -1,
        items: ['Tiano', 'Freshdreams', 'fizz', 'buzz', 'fizzbuzz', 'foobar'],
        value: ['Tiano', 'Freshdreams', 'fizz'],
      }),
  
      computed: {
          ...mapState('auth', ['authUser', 'memberDirectory','group']),
          authUser () {
            return this.$store.state.auth.authUser
          },
          countries(){
              return countryList
          },
          formTitle () {
              return this.editedIndex === -1 ? 'New Member' : 'Edit Member'
          },
          userProfile() {
            return this.$store.state.user.userProfile.userId == this.authUser.email
              ? this.$store.state.user.userProfile
              : defaultUserProfile
          },
          memberDirectory() {
              return this.$store.state.user.userProfile.userId == this.authUser.email
              ? this.$store.state.user.memberDirectory
              : []
          },
          activeMembers() {
              return this.members.filter(member => member.isDeletedFromDirectory == false)
          },
          chartType(){
                return this.areaChart ? 'area' : 'bar'
          },
          chartOptions () {
        return [{
        //   colors: ["#D53E59"],
        // https://github.com/apexcharts/vue-apexcharts/tree/master/example/src/charts
          chart: {
            height: 250,
            type: 'area',
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            show: true,
          },
          xaxis: {
            type: "string",
            labels: {
              show: true,
            },
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          },
          yaxis: {
            show: true,
          },
          stroke: {
            width: 2,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        //   fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shadeIntensity: 1,
        //         opacityFrom: 0.7,
        //         opacityTo: 0.9,
        //         stops: [0, 100]
        //     }
        // },
        }]
      },
      series() {
        return [{
          name: 'Response %',
          data: [31, 40, 28, 51, 42, 109, 100]
        }]
      },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialog2 (val) {
          val || this.close()
        },      
        dialogDelete (val) {
          val || this.closeDelete()
        },
        file(f) {
          if (f) {
              f.text().then((data) => {
              this.fileData = data;
              });
          }
        },
        memberDirectory() {
          this.members = this.memberDirectory.person
        }
      },
  
      created () {
        this.initialize()
      },
  
      methods: {
          async sleep(ms) {
              return new Promise(resolve => setTimeout(resolve, ms));
          },
          formatDateTime (date) {
              return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
              // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
          },       
          downloadCSVData() {
              let csv = 'Full Name,Area Code,Phone Number,Email Address\n';       
              const anchor = document.createElement('a');
              anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
              anchor.target = '_blank';
              anchor.download = 'Template.csv';
              anchor.click();
          },       
          nextPage () {
              if (this.page + 1 <= this.numberOfPages) this.page += 1
          },
          formerPage () {
              if (this.page - 1 >= 1) this.page -= 1
          },
          season (val) {
              return this.range[val]
          },
          hasHistory () { 
              return window.history.length > 2 
          },        
        initialize () {
          this.members = this.memberDirectory.person //? this.memberDirectory.person : _members
          this.memberDirectoryId = this.memberDirectory.memberDirectoryId
        },
  
          editItem (item) {
              this.editedIndex = this.members.indexOf(item)
              this.editedItem = Object.assign({}, item)
              this.dialog = true
          },
  
          deleteItem (item) {
              this._item = item
              this.editedIndex = this.members.indexOf(item)
              this.editedItem = Object.assign({}, item)
              this.dialogDelete = true
          },
  
          deleteItemConfirm () {
              // this.members.splice(this.editedIndex, 1)
              var payload = {
                  UserEmail: this.userProfile.userId,
                  MemberDirectoryId: this.userProfile.memberDirectoryId,
                  MemberList: [
                      {
                          Id: this.members[this._item].id
                      }
                  ]
              }
              var payloadV2 = {
                  userId: this.userProfile.userId,
                  memberDirectoryId: this.userProfile.memberDirectoryId
              };
              this.$store.dispatch('user/delMember', payload).then(() => {
                  this.$store.dispatch('user/getMemberDirectory', payloadV2);
              })
  
              this.closeDelete()
          },
          close () {
              this.dialog = false
              this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              })
          },
          close2 () {
              this.dialog2 = false
              this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              })
          },
          closeDelete () {
              this.dialogDelete = false
              this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              })
          },
      },
    }
  </script>
  <style lang="scss" scoped>
      .dashboard-nav-link {
          color: #2B3445 !important;
          text-decoration: none;
          align-items: center;
          display: flex;
          justify-content: space-between;
          border-left: 4px solid #fff;
          margin-bottom: 20px;
          svg {
              width: 20px;
          }
          &:hover, &.active {
              color: #2196F3 !important;
              border-left: 4px solid #2196F3;
          }
      }
    .rounded-table {
        border-radius: 10px !important;
        overflow: hidden;
    }
    .bordered-checkbox {
        border: 1px solid darkGrey;
        margin-top: 0px;
        padding: 10px;
        border-radius: 5px;
        height: 40px;
    }
  </style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center mt-n2"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-list-status ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1 mb-0"
  }, [_vm._v("Merchant Onboarding")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1)]), _c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Stripe Onboarding")])], 1), _c('v-tabs', {
    attrs: {
      "vertical": ""
    }
  }, [_c('v-tab', [!_vm.stripeAccountId ? _c('v-icon', {
    attrs: {
      "left": "",
      "color": "warning"
    }
  }, [_vm._v(" mdi-alert ")]) : _c('v-icon', {
    attrs: {
      "left": "",
      "color": "green"
    }
  }, [_vm._v(" mdi-check-circle ")]), _vm._v(" Step 1 ")], 1), _c('v-tab', [_vm.chargeStatus !== 'active' ? _c('v-icon', {
    attrs: {
      "left": "",
      "color": "warning"
    }
  }, [_vm._v(" mdi-alert ")]) : _c('v-icon', {
    attrs: {
      "left": "",
      "color": "green"
    }
  }, [_vm._v(" mdi-check-circle ")]), _vm._v(" Step 2 ")], 1), _c('v-tab', [_vm.chargeStatus !== 'active' ? _c('v-icon', {
    attrs: {
      "left": "",
      "color": "warning"
    }
  }, [_vm._v(" mdi-alert ")]) : _c('v-icon', {
    attrs: {
      "left": "",
      "color": "green"
    }
  }, [_vm._v(" mdi-check-circle ")]), _vm._v(" Step 3 ")], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" In order for you to create subscriptions for your community, you first need to create you own Stripe account. Generate Account Key to create a Stripe Express account. ")]), !_vm.stripeAccountId ? _c('div', [_c('v-btn', {
    staticClass: "font-weight-bold grey--text text--darken-2",
    attrs: {
      "x-large": "",
      "outlined": "",
      "color": "primary",
      "dark": "",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.generateAccountKey();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-key ")]), _vm._v(" Generate Account Key ")], 1)], 1) : _c('div', [_c('v-text-field', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "label": "Stripe Account Key"
    },
    model: {
      value: _vm.stripeAccountId,
      callback: function ($$v) {
        _vm.stripeAccountId = $$v;
      },
      expression: "stripeAccountId"
    }
  })], 1)])], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Generate a single-use Stripe URL that will take you through the Connect Onboarding flow. ")]), _c('v-btn', {
    staticClass: "text-capitalize font-600 mb-5",
    attrs: {
      "disabled": !_vm.stripeAccountId,
      "x-large": "",
      "outlined": "",
      "color": "primary",
      "dark": "",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.generateOnboardingLink();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-link-variant ")]), _vm._v(" Generate Onboarding Link ")], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Please supply all the relevant details as this will allow CheqqMate to process transactions on your behalf. ")]), _c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": _vm.accountLink ? _vm.accountLink.url : '',
      "target": "_blank"
    }
  }, [_c('v-btn', {
    staticClass: "font-weight-bold grey--text text--darken-2 mb-5",
    attrs: {
      "x-large": "",
      "outlined": "",
      "dark": "",
      "block": ""
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-open-in-new ")]), _vm._v(" Complete Stripe Onboarding ")], 1)], 1)])], 1)], 1)], 1)], 1), _vm.chargeStatus === 'active' ? _c('base-card', {
    staticClass: "mt-5"
  }, [_c('v-card-text', {
    staticClass: "text-center secondary--text"
  }, [_c('h1', {
    staticClass: "mt-1"
  }, [_vm._v("Congratulations!"), _c('v-icon', {
    staticClass: "ml-2 mt-n3",
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-party-popper")])], 1), _c('p', {
    staticClass: "mt-1 mb-n1"
  }, [_vm._v("Stripe Onboarding Completed")])])], 1) : _vm._e()], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
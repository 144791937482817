<template>
  <v-container class="py-6">
      <v-row>
        <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                   <DashbordSidebar />
                </div>
                <div class="box-content">
                    <div class="d-flex justify-end pa-2 d-block d-md-none">
                        <v-btn
                            icon   
                            @click="isSidebar = !isSidebar"
                        >
                            <v-icon dark>
                                mdi-format-list-bulleted-square
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="box-container">
                        <div class="d-flex justify-space-between flex-wrap mb-5">
                           <div class="d-flex align-center ">
                            <v-avatar
                                tile
                                size="25"
                                class="me-3"
                            >
                                <v-icon
                                color="grey darken-1"
                                >
                                mdi-history
                                </v-icon>
                            </v-avatar>
                                <h2 class="grey--text text--darken-1 mb-1">Matches</h2>
                            </div>
                            <v-btn 
                                outlined 
                                color="primary" 
                                class="text-capitalize font-600"
                                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                            >
                                BACK
                            </v-btn>
                       </div>                        
                          <template>
                              <v-data-table
                                  :headers="headers"
                                  :items="matches"
                                  sort-by="name"
                                  class="elevation-1"
                              >
                                  <template v-slot:top>
                                    <v-toolbar
                                      flat
                                    >
                                      <v-toolbar-title>Match History</v-toolbar-title>
                                    </v-toolbar>
                                  </template>
                                  <template v-slot:item.createdDate="{ item }">
                                    <v-chip small
                                    >
                                      <v-icon small
                                        class="icon"
                                      >
                                        mdi-clock-time-four
                                      </v-icon>
                                      <p class="ma-1 secondary--text text-sm">{{ item.createdDate }}</p>
                                    </v-chip>
                                </template>
                                <template v-slot:item.pairings.countOfPairings="{ item }">
                                  <p class="ml-6 mt-3 grey--text">
                                  <v-badge
                                    color="darkGrey"
                                    inline
                                    :content="item.pairings.countOfPairings"
                                  >
                                  </v-badge>
                                </p>
                                </template>
                                  <template v-slot:item.actions="{ item, index }">       
                                    <div>
                                      <router-link
                                        style="text-decoration: none !important;"
                                        :to="{ path: '/match-details',
                                              query: {
                                                matchId: matches[index].id
                                              }
                                        }"
                                      >
                                        <v-btn
                                          icon
                                        >
                                          <v-icon>
                                            mdi-arrow-right-circle
                                          </v-icon>
                                        </v-btn>
                                      </router-link>
                                    </div>
                                  </template>
                                  <template v-slot:no-data>
                                  <v-btn
                                      color="primary"
                                      @click="initialize"
                                  >
                                      Reset
                                  </v-btn>
                                  </template>
                              </v-data-table>
                          </template>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashbordSidebar from "@/components/DashboardSidebar.vue"
import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';

// const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
const cheqqMateServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
  }
};

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit('app/START_LOADING');
    return config;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    store.commit('app/FINISH_LOADING');
    return response;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);
const defaultMatchList = [
    {
        id: "be4718ab-ed06-4157-8fb3-87263d8d5acb",
        groupId: "c6d32027-4577-4d86-9fca-146d4a11debd",
        createdDate: "2023-10-22T22:19:09.5289389Z",
        pairings: {
            countOfPairings: 2
        }
    },
    {
        id: "79980537-2858-436a-9c98-827a65fb2cb8",
        groupId: "66f3c85d-90ed-4031-ae17-60231dffe9a5",
        createdDate: "2023-10-22T22:19:12.5224593Z",
        pairings: {
            countOfPairings: 1
        }
    }
];
  export default {
    components: {
        DashbordSidebar
    },    
    data: () => ({
      dialog: false,
      file: {},
      fileData: "",
      dialog2: false,
      isSidebar: false,
      dialogDelete: false,
      uploadedFile: '',
      headers: [
        {
          text: 'Created Date',
          align: 'start',
          sortable: true,
          value: 'createdDate',
        },
        { text: 'Group', value: 'groupName', sortable: false },
        { text: 'Pairs Generated', value: 'pairings.countOfPairings', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      members: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        phoneNumber: '',
        emailAddress: '',
        resumeDate: new Date().toJSON().slice(0,10),
        isDeletedFromDirectory: false,
      },
      defaultItem: {
        name: '',
        phoneNumber: '',
        emailAddress: '',
        resumeDate: new Date().toJSON().slice(0,10),
        isDeletedFromDirectory: false,
      },
    }),

    computed: {
      ...mapState('user', ['matchList','groupList']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Member' : 'Edit Member'
      },
      filteredMatches() {
        //find the group name from the group id in groups and return within the matchList
        return this.matchList.map(match => {
          return {
            ...match,
            groupName: this.groups.find(group => group.id === match.groupId).name
          }
        })
      },
      matches () {
          return this.matchList
            ? this.matchList.map(match => {
                return {
                  ...match,
                  groupName: this.groups.find(group => group.id === match.groupId).name,
                  createdDate: this.formatDateTime(match.createdDate)

                }
              })
            : defaultMatchList
        },
      groups () {
        return this.$store.state.user.groupList
          ? this.$store.state.user.groupList
          : defaultUserProfile.groups
      },  
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialog2 (val) {
        val || this.close()
      },      
      dialogDelete (val) {
        val || this.closeDelete()
      },
      file(f) {
        if (f) {
            f.text().then((data) => {
            this.fileData = data;
            });
        }
    }
    },

    created () {
      this.initialize()
    },

    methods: {
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        formatDateTime (date) {
          return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
            // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        }, 
        downloadCSVData() {
            let csv = 'Full Name,Phone Number,Email Address\n';       
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'Template.csv';
            anchor.click();
        },       
        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        season (val) {
            return this.range[val]
        },
        hasHistory () { 
            return window.history.length > 2 
        },        
      initialize () {
        this.members = [
          {
            name: 'Tom Jerry',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
          {
            name: 'Tom Jerry 1',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
          {
            name: 'Tom Jerry 2',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
          {
            name: 'Tom Jerry 3',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
          {
            name: 'Tom Jerry 4',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
          {
            name: 'Tom Jerry 5',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
          {
            name: 'Tom Jerry 6',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
          {
            name: 'Tom Jerry 7',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
          {
            name: 'Tom Jerry 8',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
          {
            name: 'Tom Jerry 9',
            phoneNumber: '441234567890',
            emailAddress: "jane.doe@gmail.com",
            resumeDate: "23/08/2023",
            isDeletedFromDirectory: false
          },
        ]
      },

        editItem (item) {
            this.editedIndex = this.members.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            this.editedIndex = this.members.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            this.members.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        close2 () {
            this.dialog2 = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.members[this.editedIndex], this.editedItem)
            } else {
            this.members.push(this.editedItem)
            }
            this.close()
        },
        save2 () {
           var jsonFile =  this.csvJSON(this.fileData)
            for (var i in jsonFile) {
                var csvItem = {
                    name: jsonFile[i]["Full Name"],
                    phoneNumber: jsonFile[i]["Phone Number"],
                    emailAddress: jsonFile[i]["Email Address"],
                    resumeDate: new Date().toJSON().slice(0,10),
                    isDeletedFromDirectory: false
                }
                this.members.push(csvItem)
            }
            this.close2()
        },
        /** file upload methods **/
        csvJSON (csv){
            var vm = this
            var lines = csv.split("\n")
            var result = []
            var headers = lines[0].split(",")
                        
            lines.map((line, indexLine) => {
                if (indexLine < 1) return // Jump header line
                
                const obj = {}
                var currentline = line.split(",")
                
                headers.map((header, indexHeader) => {
                   obj[header.trim()] = currentline[indexHeader]
                })
                
                result.push(obj)
            })
            result.pop() // remove the last item because undefined values
            // console.log(result)
            return result // JavaScript object
        },
        async submitFile(fileExtension) {
            let formData = new FormData();
            formData.append('file', this.uploadedFile);
            /*add a validation here to make sure the file variable contains an actual file. */
            if (this.uploadedFile.name == '') {
            } else {
                axios
                .post(
                    `${transformerServiceBaseUrl}/ActivityDraft${fileExtension}?OPID=${this.userDetails.openPublishId}&UserId=${this.userDetails.userId}`,
                    formData,
                    transformerServiceConfig,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                    }
                )
            }
        },
        update() {
        axios
        .post(
          `${cheqqMateServiceBaseUrl}/upsertMember`,
          {
            UserEmail: "tzishi@live.co.uk",
            MemberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
            MemberDetails: this.members
        },
          cheqqMateServiceConfig,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Content-Type': 'application/json'
            }
          }
        )
        .then(res => {
          if(res.data) {
            //save to vuex, so that it persists, instead of consistently calling api
            // store.commit('backend/ROUND_TRIP_ROUTE_DATA_UPDATED', res.data.RoundtripRoute);
            // store.commit('backend/ROUND_TRIP_DISTANCE_DATA_UPDATED', res.data.RoundtripDistance); 
            // store.commit('backend/ONE_WAY_TRIPS_DATA_UPDATED', res.data.Onewaytrips); 

            // this.calculationResults.RoundtripDistance = res.data.RoundtripDistance
            // this.calculationResults.RoundtripRoute = res.data.RoundtripRoute
            // this.calculationResults.Onewaytrips = res.data.Onewaytrip
          } else {
          }
        })
        .catch(error => {
          if (error.response) {
          // console.log(error) //localdev
          }
      });
      },        
    },
  }
</script>
<style lang="scss" scoped>
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #2196F3 !important;
            border-left: 4px solid #2196F3;
        }
    }
</style>
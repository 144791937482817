<template>
    <v-container class="py-6">
        <v-row>
          <v-col cols="12">
              <div class="box-wrapper">
                  <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                  <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                     <DashbordSidebar />
                  </div>
                  <div class="box-content">
                      <div class="d-flex justify-end pa-2 d-block d-md-none">
                          <v-btn
                              icon   
                              @click="isSidebar = !isSidebar"
                          >
                              <v-icon dark>
                                  mdi-format-list-bulleted-square
                              </v-icon>
                          </v-btn>
                      </div>
                      <div class="box-container">                        
                          <div class="d-flex justify-space-between flex-wrap mb-5">
                            <div class="d-flex align-center">
                              <v-avatar
                                  tile
                                  size="25"
                                  class="me-3"
                              >
                                  <v-icon
                                  color="grey darken-1"
                                  >
                                  mdi-credit-card
                                  </v-icon>
                              </v-avatar>
                              <h2 class="grey--text text--darken-1 mb-0">Manage Billing</h2>
                            </div>
                              <v-btn 
                                  outlined 
                                  color="primary" 
                                  class="text-capitalize font-600"
                                  @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                              >
                                  BACK
                              </v-btn>                             
                         </div>
                        <div class="mb-5">
                          <v-row>
                          <v-col cols="12">
                              <base-card>
                                <div class="pa-5">
                                    <div class="mb-4">
                                      <v-toolbar-title>Billing Details</v-toolbar-title>
                                      </div>
                                      <div class="d-flex align-center">
                                        <v-col cols="12" lg="6" >
                                          <v-text-field
                                              v-model="userProfile.subscription.name"
                                              color="secondary"
                                              disabled
                                              dense
                                              label="Subscription Plan"
                                          ></v-text-field>
                                          <p class="text-11 grey--text">Subscription Status</p>
                                          <v-switch
                                            class="mt-n2"
                                            color="success"
                                            disabled
                                            v-model="userProfile.subscription.isActive"
                                            inset
                                          ></v-switch>
                                          <p class="text-11 grey--text">Subscription Created Date</p>
                                          <v-chip>
                                            <v-icon
                                              class="icon"
                                            >
                                              mdi-clock-time-four
                                            </v-icon>
                                          <p class="ma-1 grey--text text--darken-4">{{ formatDateTime(userProfile.subscription.createdDate) }}</p>
                                          </v-chip>
                                        </v-col>
                                    </div>  
                                </div>
                              </base-card>
                          </v-col>
                          </v-row>
                        </div>
                        <!-- <template>
                            <v-data-table
                                :headers="headers"
                                :items="members"
                                sort-by="name"
                                class="elevation-1"
                            >
                                <template v-slot:top>
                                  <v-toolbar
                                    flat
                                  >
                                    <v-toolbar-title>Payment History</v-toolbar-title>
                                  </v-toolbar>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                <v-icon
                                    medium
                                    @click="deleteItem(item)"
                                >
                                    mdi-eye
                                </v-icon>
                                </template>
                                <template v-slot:no-data>
                                <v-btn
                                    color="primary"
                                    @click="initialize"
                                >
                                    Reset
                                </v-btn>
                                </template>
                            </v-data-table>
                        </template> -->
                      </div>
                  </div>
              </div>
          </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import DashbordSidebar from "@/components/DashboardSidebar.vue"
  import { mapState, mapActions, mapGetters } from 'vuex';

  const defaultUserProfile = {
    "id": "Jane.Done@example.com",
    "username": "Jane.Done",
    "type": "userProfile",
    "userId": "Jane.Done@example.com",
    "createdDate": "2011-11-02T12:10:07Z",
    "subscriptions": [{
    }],
    "locationsList": [
        {
            "locations": [{
            }]
        }
    ],
	"wishListProperties": 0,
	"zooplaProperties": 0,
	"zooplaPropertiiesLastModifiedDate": "",
	"rightMoveProperties": 0,
	"rightMovePropertiesLastModifiedDate": "",
	"onTheMarketProperties": 0,
	"onTheMarketPropertiesLastModifiedDate": "",
	"dashboard": {
		"history": [{}],
		"topRoundtripList": [{}]
	}
};
  
    export default {
      components: {
          DashbordSidebar
      },    
      data: () => ({
        selectedMembers: null,
        selectedDayOfWeek: '',
        selectedDayOfMonth: '',
        selectedRecurrencePattern: '',
        dialog: false,
        file: {},
        fileData: "",
        singleSelect: false,
        dialog2: false,
        isSidebar: false,
        dialogDelete: false,
        uploadedFile: '',
        scheduleName: 'My Schedule',
        headers: [
          {
            text: 'Full Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Phone Number', value: 'phoneNumber', sortable: false },
          { text: 'Email Address', value: 'emailAddress', sortable: false },
          { text: 'Resume Date', value: 'resumeDate', sortable: true },
          { text: 'Is Archived', value: 'isDeletedFromDirectory', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      }),
  
      computed: {
        ...mapState('user', ['userProfile']),
      },
  
      watch: {
      },
  
      created () {
        this.initialize()
      },
  
      methods: {
        initialize(){},
        formatDateTime (date) {
          return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
        // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        },        
      },
    }
  </script>
  <style lang="scss" scoped>
      .dashboard-nav-link {
          color: #2B3445 !important;
          text-decoration: none;
          align-items: center;
          display: flex;
          justify-content: space-between;
          border-left: 4px solid #fff;
          margin-bottom: 20px;
          svg {
              width: 20px;
          }
          &:hover, &.active {
              color: #2196F3 !important;
              border-left: 4px solid #2196F3;
          }
      }
  </style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-12"
  }, [_c('v-row', _vm._l(_vm.children, function (item, index) {
    return _c('v-col', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex justify-center"
    }, [index === 0 ? _c('v-chip', {
      staticClass: "ml-2 mt-n2 pl-8 pr-8 text-overline font-weight-bold darkGrey--text",
      attrs: {
        "label": "",
        "large": ""
      }
    }, [_vm._v(" No ")]) : _c('v-chip', {
      staticClass: "mr-3 mt-n2 pl-8 pr-8 text-overline font-weight-bold darkGrey--text",
      attrs: {
        "label": "",
        "large": ""
      }
    }, [_vm._v(" Yes ")])], 1), index === 0 && _vm.hasChildren(item) || item.id !== -1 ? _c('svg', {
      staticClass: "mb-n2",
      staticStyle: {
        "width": "100%",
        "height": "60px"
      }
    }, [_c('line', {
      staticStyle: {
        "stroke": "rgb(174,180,190)",
        "stroke-width": "1"
      },
      attrs: {
        "x1": "49.5%",
        "y1": "10",
        "x2": "49.5%",
        "y2": "50"
      }
    })]) : _vm.hasChildren(item) ? _c('svg', {
      staticClass: "mb-n3",
      staticStyle: {
        "width": "100%",
        "height": "60px"
      }
    }, [_c('line', {
      staticStyle: {
        "stroke": "rgb(174,180,190)",
        "stroke-width": "1"
      },
      attrs: {
        "x1": "48.5%",
        "y1": "10",
        "x2": "48.5%",
        "y2": "50"
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "d-flex justify-center"
    }, [_c('v-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: index === 0 && _vm.parent && _vm.parent.children[0].id === -1,
        expression: "index===0 && parent && parent.children[0].id ===-1"
      }],
      staticClass: "mt-n2 ml-4",
      attrs: {
        "fab": "",
        "x-small": "",
        "color": "blue"
      },
      on: {
        "click": function ($event) {
          return _vm.addItem(item);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "dark": ""
      }
    }, [_vm._v(" mdi-plus ")])], 1)], 1), _c('div', {
      staticClass: "d-flex justify-center"
    }, [_c('v-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: index !== 0 && _vm.parent && _vm.parent.children[1].id === -1,
        expression: "index!==0 && parent && parent.children[1].id ===-1"
      }],
      staticClass: "mt-n2 mr-3",
      attrs: {
        "fab": "",
        "x-small": "",
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.addItem(item);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "dark": ""
      }
    }, [_vm._v(" mdi-plus ")])], 1)], 1), index === 0 && item.id !== -1 && !_vm.parent && !_vm.parent.condition ? _c('svg', {
      staticClass: "mb-n3",
      staticStyle: {
        "width": "100%",
        "height": "60px"
      }
    }, [_c('line', {
      staticStyle: {
        "stroke": "rgb(174,180,190)",
        "stroke-width": "1"
      },
      attrs: {
        "x1": "49%",
        "y1": "10",
        "x2": "49%",
        "y2": "50"
      }
    })]) : !_vm.parent.condition && !_vm.parent ? _c('svg', {
      staticClass: "mt-n1 mb-n3",
      staticStyle: {
        "width": "100%",
        "height": "60px"
      }
    }, [_c('line', {
      staticStyle: {
        "stroke": "rgb(174,180,190)",
        "stroke-width": "1"
      },
      attrs: {
        "x1": "49%",
        "y1": "10",
        "x2": "49%",
        "y2": "50"
      }
    })]) : _vm._e(), item.id !== -1 ? _c('v-card', {
      staticClass: "ma-1 pa-2",
      staticStyle: {
        "min-height": "80px"
      },
      style: {
        'border-left': _vm.hoverId === item.id ? '4px solid #AEB4BE' : ''
      },
      attrs: {
        "outlined": ""
      },
      on: {
        "mouseover": function ($event) {
          _vm.hoverId = item.id;
        },
        "mouseleave": function ($event) {
          _vm.hoverId = null;
        }
      }
    }, [_c('div', {
      staticClass: "pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline",
      staticStyle: {
        "min-height": "36px"
      }
    }, [_c('span', {
      staticStyle: {
        "min-height": "36px"
      }
    }, [_c('v-chip', {
      staticClass: "mr-1",
      attrs: {
        "label": "",
        "small": "",
        "color": _vm.getBadge(item.type).color
      }
    }, [_c('v-icon', {
      attrs: {
        "color": _vm.getBadge(item.type).textColor,
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.getBadge(item.type).icon) + " ")])], 1), _vm._v(" " + _vm._s(item.type) + " ")], 1), _c('span', {
      staticStyle: {
        "min-height": "36px"
      }
    }, [_c('v-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hoverId === item.id,
        expression: "hoverId === item.id"
      }],
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "teal lighten-2"
      },
      on: {
        "click": function ($event) {
          return _vm.workflowEdit(item);
        }
      }
    }, [_vm._v(" mdi-pencil ")])], 1), _c('v-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hoverId === item.id,
        expression: "hoverId === item.id"
      }],
      attrs: {
        "icon": "",
        "disabled": _vm.hasChildren(item)
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "red lighten-2"
      },
      on: {
        "click": function ($event) {
          return _vm.workflowDelete(item);
        }
      }
    }, [_vm._v(" mdi-delete ")])], 1)], 1), _c('v-card-text', [_c('p', {
      staticClass: "text-none text-body-2 grey--text text--darken-1"
    }, [_vm._v(_vm._s(item.description))])])], 1)]) : _vm._e(), !_vm.hasChildren(item) ? _c('div', {
      staticClass: "d-flex justify-center"
    }, [_c('v-menu', {
      attrs: {
        "top": "",
        "offset-x": _vm.offset,
        "rounded": "lg",
        "nudge-width": 200
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            directives: [{
              name: "show",
              rawName: "v-show",
              value: item.children.length === 0 && index === 0,
              expression: "item.children.length === 0 && index===0"
            }],
            staticClass: "ml-n1",
            class: _vm.hasChildren(item) ? 'mt-n6 ml-1' : 'mt-n4 mr-n1',
            attrs: {
              "fab": "",
              "small": "",
              "color": "primary"
            },
            on: {
              "click": function ($event) {
                return _vm.addItem(item);
              }
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', {
            attrs: {
              "dark": ""
            }
          }, [_vm._v(" mdi-plus ")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item-content', {
      staticClass: "d-flex justify-center"
    }, [_c('v-list-item-title', {
      staticClass: "ml-4 grey--text text-overline text--darken-2"
    }, [_vm._v("Choose Workflow Item")])], 1), _c('v-divider'), _vm._l(_vm.items, function (itemType, i) {
      return _c('v-list-item', {
        key: i,
        attrs: {
          "link": ""
        }
      }, [_c('v-btn', {
        staticClass: "ml-8 d-flex justify-start",
        attrs: {
          "icon": "",
          "plain": "",
          "small": ""
        },
        on: {
          "click": function ($event) {
            return _vm.workflowAdd(item, itemType);
          }
        }
      }, [_c('v-icon', {
        attrs: {
          "color": itemType.textColor,
          "small": ""
        }
      }, [_vm._v(" " + _vm._s(itemType.icon) + " ")]), _vm._v(" " + _vm._s(itemType.type) + " ")], 1)], 1);
    })], 2)], 1)], 1) : _vm._e(), !_vm.hasChildren(item) ? _c('div', {
      staticClass: "d-flex justify-center"
    }, [_c('v-menu', {
      attrs: {
        "top": "",
        "offset-x": _vm.offset,
        "rounded": "lg",
        "nudge-width": 200
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            directives: [{
              name: "show",
              rawName: "v-show",
              value: item.children.length === 0 && index !== 0,
              expression: "item.children.length === 0 && index!==0"
            }],
            staticClass: "mt-n6 mr-4",
            attrs: {
              "fab": "",
              "small": "",
              "color": "primary"
            },
            on: {
              "click": function ($event) {
                return _vm.addItem(item);
              }
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', {
            attrs: {
              "dark": ""
            }
          }, [_vm._v(" mdi-plus ")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item-content', {
      staticClass: "d-flex justify-center"
    }, [_c('v-list-item-title', {
      staticClass: "ml-4 grey--text text-overline text--darken-2"
    }, [_vm._v("Choose Workflow Item")])], 1), _c('v-divider'), _vm._l(_vm.items, function (itemType, i) {
      return _c('v-list-item', {
        key: i,
        attrs: {
          "link": ""
        }
      }, [_c('v-btn', {
        staticClass: "ml-8 d-flex justify-start",
        attrs: {
          "icon": "",
          "plain": "",
          "small": ""
        },
        on: {
          "click": function ($event) {
            return _vm.workflowAdd(item, itemType);
          }
        }
      }, [_c('v-icon', {
        attrs: {
          "color": itemType.textColor,
          "small": ""
        }
      }, [_vm._v(" " + _vm._s(itemType.icon) + " ")]), _vm._v(" " + _vm._s(itemType.type) + " ")], 1)], 1);
    })], 2)], 1)], 1) : _vm._e(), item.children ? _c('v-row', [_vm.hasChildren(item) && item.children && item.children.length > 0 && item.id !== -1 ? _c('svg', {
      staticClass: "mt-1",
      staticStyle: {
        "width": "100%",
        "height": "60px"
      }
    }, [_c('line', {
      staticStyle: {
        "stroke": "rgb(174,180,190)",
        "stroke-width": "1"
      },
      attrs: {
        "x1": "48.5%",
        "y1": "10",
        "x2": "48.5%",
        "y2": "50"
      }
    })]) : _vm._e(), _vm._l(item.children, function (child, childIndex) {
      return _c('v-col', {
        key: ("child-" + childIndex)
      }, [child.id !== -1 ? _c('v-card', {
        staticClass: "mt-n4 ma-1 pa-2 rounded-card add-item",
        staticStyle: {
          "min-height": "80px"
        },
        style: {
          'border-left': _vm.hoverId === child.id ? '4px solid #AEB4BE' : ''
        },
        attrs: {
          "outlined": ""
        },
        on: {
          "mouseover": function ($event) {
            _vm.hoverId = child.id;
          },
          "mouseleave": function ($event) {
            _vm.hoverId = null;
          }
        }
      }, [_c('div', {
        staticClass: "pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline",
        staticStyle: {
          "min-height": "36px"
        }
      }, [_c('span', [_c('v-chip', {
        staticClass: "mr-1",
        attrs: {
          "label": "",
          "small": "",
          "color": _vm.getBadge(child.type).color
        }
      }, [_c('v-icon', {
        attrs: {
          "color": _vm.getBadge(child.type).textColor,
          "small": ""
        }
      }, [_vm._v(" " + _vm._s(_vm.getBadge(child.type).icon) + " ")])], 1), _vm._v(" " + _vm._s(child.type) + " "), _c('p', {
        staticClass: "text-none text-body-2 grey--text text--darken-1"
      }, [_vm._v(_vm._s(child.description))])], 1), _c('span', [_c('v-btn', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.hoverId === child.id,
          expression: "hoverId === child.id"
        }],
        attrs: {
          "icon": ""
        }
      }, [_c('v-icon', {
        attrs: {
          "color": "teal lighten-2"
        },
        on: {
          "click": function ($event) {
            return _vm.workflowEdit(child);
          }
        }
      }, [_vm._v(" mdi-pencil ")])], 1), _c('v-btn', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.hoverId === child.id,
          expression: "hoverId === child.id"
        }],
        attrs: {
          "icon": "",
          "disabled": _vm.hasChildren(child)
        }
      }, [_c('v-icon', {
        attrs: {
          "color": "red lighten-2"
        },
        on: {
          "click": function ($event) {
            return _vm.workflowDelete(child);
          }
        }
      }, [_vm._v(" mdi-delete ")])], 1)], 1)])]) : _vm._e(), _vm.parent ? _c('div', {
        staticClass: "d-flex justify-center"
      }, [_c('v-menu', {
        attrs: {
          "top": "",
          "offset-x": _vm.offset,
          "rounded": "lg",
          "nudge-width": 200
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref3) {
            var on = _ref3.on,
              attrs = _ref3.attrs;
            return [_c('v-btn', _vm._g(_vm._b({
              directives: [{
                name: "show",
                rawName: "v-show",
                value: child.children.length === 0 && _vm.parent,
                expression: "child.children.length === 0 && parent"
              }],
              staticClass: "mr-3",
              class: _vm.hasChildren(child) ? 'mt-n6' : 'mt-n4',
              attrs: {
                "fab": "",
                "small": "",
                "color": "primary"
              },
              on: {
                "click": function ($event) {
                  return _vm.addItem(child);
                }
              }
            }, 'v-btn', attrs, false), on), [_c('v-icon', {
              attrs: {
                "dark": ""
              }
            }, [_vm._v(" mdi-plus ")])], 1)];
          }
        }], null, true)
      }, [_c('v-list', [_c('v-list-item-content', {
        staticClass: "d-flex justify-start"
      }, [_c('v-list-item-title', {
        staticClass: "ml-4 grey--text text-overline text--darken-2"
      }, [_vm._v("Choose Workflow Item")])], 1), _c('v-divider'), _vm._l(_vm.items, function (itemType, i) {
        return _c('v-list-item', {
          key: i,
          attrs: {
            "link": ""
          }
        }, [_c('v-btn', {
          staticClass: "ml-8 d-flex justify-start",
          attrs: {
            "icon": "",
            "plain": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.workflowAdd(child, itemType);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": itemType.textColor,
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(itemType.icon) + " ")]), _vm._v(" " + _vm._s(itemType.type) + " ")], 1)], 1);
      })], 2)], 1)], 1) : _vm._e(), child.condition && child.id !== -1 ? _c('svg', {
        staticClass: "mb-2",
        staticStyle: {
          "width": "100%",
          "height": "100px"
        }
      }, [_c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "50%",
          "y1": "0",
          "x2": "50%",
          "y2": "40"
        }
      }), _c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "50%",
          "y1": "40",
          "x2": "25%",
          "y2": "40"
        }
      }), _c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "50%",
          "y1": "40",
          "x2": "75%",
          "y2": "40"
        }
      }), _c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "25%",
          "y1": "40",
          "x2": "25%",
          "y2": "100"
        }
      }), _c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "75%",
          "y1": "40",
          "x2": "75%",
          "y2": "100"
        }
      })]) : child.condition && child.children && child.children.length > 0 && child.id !== -1 ? _c('svg', {
        staticClass: "mt-n2",
        staticStyle: {
          "width": "100%",
          "height": "60px"
        }
      }, [_c('line', {
        staticStyle: {
          "stroke": "rgb(174,180,190)",
          "stroke-width": "1"
        },
        attrs: {
          "x1": "50%",
          "y1": "10",
          "x2": "50%",
          "y2": "50"
        }
      })]) : _vm._e(), child.children && child.children.length > 0 ? _c('workflow-children', {
        staticClass: "mt-n6",
        attrs: {
          "children": child.children
        }
      }) : _vm._e()], 1);
    })], 2) : _vm._e()], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-account-edit ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1 mb-0"
  }, [_vm._v("Edit Profile")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary",
      "href": "/view-profile"
    }
  }, [_vm._v(" Back ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('base-card', [_c('div', {
    staticClass: "pa-5"
  }, [_c('div', {
    staticClass: "mb-7"
  }, [_c('v-avatar', {
    staticClass: "me-4",
    attrs: {
      "size": "64"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._userProfile.avatar,
      "alt": ""
    }
  })])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "outlined": "",
      "counter": 30,
      "dense": "",
      "disabled": "",
      "label": "Username"
    },
    model: {
      value: _vm._userProfile.username,
      callback: function ($$v) {
        _vm.$set(_vm._userProfile, "username", $$v);
      },
      expression: "_userProfile.username"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "outlined": "",
      "counter": 30,
      "dense": "",
      "disabled": "",
      "label": "Email"
    },
    model: {
      value: _vm._userProfile.userId,
      callback: function ($$v) {
        _vm.$set(_vm._userProfile, "userId", $$v);
      },
      expression: "_userProfile.userId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "outlined": "",
      "counter": 30,
      "dense": "",
      "label": "Community"
    },
    model: {
      value: this._userProfile.personalDetails.organisation,
      callback: function ($$v) {
        _vm.$set(this._userProfile.personalDetails, "organisation", $$v);
      },
      expression: "this._userProfile.personalDetails.organisation"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "counter": "100",
      "outlined": "",
      "name": "input-7-4",
      "label": "Community Description",
      "value": "",
      "rows": "2"
    },
    model: {
      value: this._userProfile.personalDetails.organisationDescription,
      callback: function ($$v) {
        _vm.$set(this._userProfile.personalDetails, "organisationDescription", $$v);
      },
      expression: "this._userProfile.personalDetails.organisationDescription"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.updateUserName
    }
  }, [_vm._v(" Save Changes ")])], 1)], 1)], 1)])], 1)], 1)], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
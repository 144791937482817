var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "width": "400"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 primary white--text"
  }, [_vm._v(" Accept Cookies & Privacy Policy ")]), _c('v-card-text', {
    staticClass: "mt-5"
  }, [_vm._v(" " + _vm._s(_vm.showDialog) + " "), _c('br'), _vm._v(" This website uses cookies. By using this website you consent to our use of these cookies. For more information visit our "), _c('a', {
    attrs: {
      "href": "/"
    }
  }, [_vm._v("Privacy Policy")])]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false, _vm.acceptCookies();
      }
    }
  }, [_vm._v(" I accept ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="mb-12">
    <v-row>
    <v-col
      v-for="(item, index) in children"
      :key="index"
    >
      <!--yes no-->
      <div class="d-flex justify-center">
        
        <!--Chip.No--->
        <v-chip
          v-if="index===0"
          class="ml-2 mt-n2 pl-8 pr-8 text-overline font-weight-bold darkGrey--text"
          label
          large
        >
          No      
        </v-chip>

        <!--Chip.Yes--->
        <v-chip
          v-else
          class="mr-3 mt-n2 pl-8 pr-8 text-overline font-weight-bold darkGrey--text"
          label
          large
        >
          Yes
        </v-chip>
      </div>
      <!--Line.No--->
      <svg v-if="index===0 && hasChildren(item) || item.id !==-1" style="width:100%; height:60px;" class=" mb-n2">
          <line x1="49.5%" y1="10" x2="49.5%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
      </svg>
      <!--Line.Yes--->
      <!-- <svg v-else-if="!index===0 && hasChildren(item)" style="width:100%; height:60px;" class="mb-n3"> -->
        <svg v-else-if="hasChildren(item)" style="width:100%; height:60px;" class="mb-n3">
          <line x1="48.5%" y1="10" x2="48.5%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
      </svg>
      <!--Add.No--->
      <div class="d-flex justify-center">
        <v-btn
          v-show="index===0 && parent && parent.children[0].id ===-1"
          class="mt-n2 ml-4"
          fab
          x-small
          color="blue"
          @click="addItem(item)"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
      <!--Add.Yes--->
      <div class="d-flex justify-center">
        <v-btn
          v-show="index!==0 && parent && parent.children[1].id ===-1"
          class="mt-n2 mr-3"
          fab
          x-small
          color="primary"
          @click="addItem(item)"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </div> 
      <!-- Line.No-->
      <svg v-if="index===0 && item.id !==-1  && !parent && !parent.condition" style="width:100%; height:60px;" class=" mb-n3">
          <line x1="49%" y1="10" x2="49%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
      </svg>
      <!--Line.Yes-->
      <svg v-else-if ="!parent.condition && !parent"  style="width:100%; height:60px;" class="mt-n1 mb-n3">
        <line x1="49%" y1="10" x2="49%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
      </svg>
      <v-card
        v-if="item.id !==-1"
        class="ma-1 pa-2"
        style="min-height: 80px;"
        :style="{'border-left': hoverId === item.id ? '4px solid #AEB4BE' : ''}"
        outlined
        @mouseover="hoverId = item.id"
        @mouseleave="hoverId = null"
      >
        <div style="min-height: 36px;" class="pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline">
          <span  style="min-height: 36px;">
            <v-chip
                class="mr-1"
                label
                small
                :color="getBadge(item.type).color"
            >
            <v-icon :color="getBadge(item.type).textColor" small>
              {{ getBadge(item.type).icon }}
            </v-icon>
            </v-chip>
            {{ item.type }}
          </span>
          <span  style="min-height: 36px;">
            <v-btn
              v-show="hoverId === item.id"
              icon
            >
              <v-icon
                color="teal lighten-2"
                @click="workflowEdit(item)"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              v-show="hoverId === item.id"
              icon
              :disabled="hasChildren(item)"
            >
              <v-icon
                color="red lighten-2"
                @click="workflowDelete(item)"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </span>
          <v-card-text>
            <p class="text-none text-body-2 grey--text text--darken-1">{{ item.description }}</p>
          </v-card-text>
          <!-- <p class="text-none text-body-2 grey--text text--darken-1 ">{{ item.description }}</p>
            <p class="text-none text-body-2 grey--text text--darken-1 ">
              <br/> <strong>childV1</strong>:  {{ childV1 }}
              <br/> <strong>childV2</strong>:  {{ childV2 }}
              <br/> <strong>parentV2</strong>:  {{ parentV2 }}
              <br/> <strong>parent</strong>:  {{ parent }}
            </p> -->
        </div>
      </v-card>
      <!--Add.No button on connector/card--->
      <div v-if="!hasChildren(item)" class="d-flex justify-center">
        <v-menu
          top
          :offset-x="offset"
          rounded="lg"
          :nudge-width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="item.children.length === 0 && index===0"
              class="ml-n1"
              :class="hasChildren(item) ? 'mt-n6 ml-1' : 'mt-n4 mr-n1'"
              fab
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="addItem(item)"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-content class="d-flex justify-center">
                <v-list-item-title class="ml-4 grey--text text-overline text--darken-2">Choose Workflow Item</v-list-item-title>
              </v-list-item-content>
            <v-divider></v-divider>
            <v-list-item
              v-for="(itemType, i) in items"
              :key="i"
              link
            >
            <v-btn
              class="ml-8 d-flex justify-start"
              icon
              plain
              small
              @click="workflowAdd(item, itemType)"
            >
            <v-icon :color="itemType.textColor" small>
                  {{ itemType.icon }}
                </v-icon>              
                {{ itemType.type }}
            </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!--Add.Yes button on connector--->
      <div v-if="!hasChildren(item)" class="d-flex justify-center">
        <v-menu
          top
          :offset-x="offset"
          rounded="lg"
          :nudge-width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="item.children.length === 0 && index!==0"
              class="mt-n6 mr-4"
              fab
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="addItem(item)"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-content class="d-flex justify-center">
                <v-list-item-title class="ml-4 grey--text text-overline text--darken-2">Choose Workflow Item</v-list-item-title>
              </v-list-item-content>
            <v-divider></v-divider>
            <v-list-item
              v-for="(itemType, i) in items"
              :key="i"
              link
            >
            <v-btn
              class="ml-8 d-flex justify-start"
              icon
              plain
              small
              @click="workflowAdd(item, itemType)"
            >
            <v-icon :color="itemType.textColor" small>
                  {{ itemType.icon }}
                </v-icon>              
                {{ itemType.type }}
            </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-btn
          v-show="item.children.length === 0  && index!==0"
          class="mt-n6 mr-4"
          fab
          small
          color="primary"
          @click="addItem(item)"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn> -->
      </div> 
      <v-row v-if="item.children">
        <!--actions-->
        <!-- <svg v-if="child.type === 'condition'" style="width:100%; height:100px;" class="mb-2">
          <line x1="50%" y1="0" x2="50%" y2="40" style="stroke:rgb(174,180,190);stroke-width:1" />
          <line x1="50%" y1="40" x2="25%" y2="40" style="stroke:rgb(174,180,190);stroke-width:1" />
          <line x1="50%" y1="40" x2="75%" y2="40" style="stroke:rgb(174,180,190);stroke-width:1" />
          <line x1="25%" y1="40" x2="25%" y2="100" style="stroke:rgb(174,180,190);stroke-width:1" />
          <line x1="75%" y1="40" x2="75%" y2="100" style="stroke:rgb(174,180,190);stroke-width:1" />
          <circle cx="50%" cy="4" r="4" style="fill:rgb(174,180,190);" />
          <circle cx="25%" cy="96" r="4" style="fill:rgb(174,180,190);" />
          <circle cx="75%" cy="96" r="4" style="fill:rgb(174,180,190);" />
          <text x="25%" y="35" color="#BDBDBD" font-weight="bold" font-size="12" text-anchor="middle">No</text>
          <text x="75%" y="35" color="#BDBDBD" font-weight="bold" font-size="12" text-anchor="middle">Yes</text>
        </svg> -->

        <svg v-if="hasChildren(item) && item.children && item.children.length > 0 && item.id !==-1" style="width:100%; height:60px;" class="mt-1">
          <line x1="48.5%" y1="10" x2="48.5%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
            
              <!-- <circle cx="50%" cy="10" r="4" style="fill:rgb(174,180,190);" />
              <circle cx="50%" cy="50" r="4" style="fill:rgb(174,180,190);" /> -->
           
        </svg>
        <v-col
          v-for="(child, childIndex) in item.children"
          :key="`child-${childIndex}`"
        >
          <v-card
            v-if="child.id !==-1"
            class="mt-n4 ma-1 pa-2 rounded-card add-item"
            style="min-height: 80px;"
            :style="{'border-left': hoverId === child.id ? '4px solid #AEB4BE' : ''}"
            outlined
            @mouseover="hoverId = child.id"
            @mouseleave="hoverId = null"
          >
            <div style="min-height: 36px;" class="pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline">
              <span>
                <v-chip
                    class="mr-1"
                    label
                    small
                    :color="getBadge(child.type).color"
                >
                <v-icon :color="getBadge(child.type).textColor" small>
                  {{ getBadge(child.type).icon }}
                </v-icon>
                </v-chip>
                {{ child.type }}
                <p class="text-none text-body-2 grey--text text--darken-1 ">{{ child.description }}</p>
              </span>
            <span>
              <v-btn
                v-show="hoverId === child.id"
                icon
              >
                <v-icon
                  color="teal lighten-2"
                  @click="workflowEdit(child)"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                v-show="hoverId === child.id"
                icon
                :disabled="hasChildren(child)"
              >
                <v-icon
                  color="red lighten-2"
                  @click="workflowDelete(child)"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </span>  
          </div>
          </v-card>
          <!--Add.Yes button on card:  && parent.children[1].id ===-1--->
          <div v-if="parent" class="d-flex justify-center">
            <v-menu
              top
              :offset-x="offset"
              rounded="lg"
              :nudge-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="child.children.length === 0 && parent"
                  class="mr-3"
                  :class="hasChildren(child) ? 'mt-n6' : 'mt-n4'"
                  fab
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="addItem(child)"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-content class="d-flex justify-start">
                    <v-list-item-title class="ml-4 grey--text text-overline text--darken-2">Choose Workflow Item</v-list-item-title>
                  </v-list-item-content>
                <v-divider></v-divider>
                <v-list-item
                v-for="(itemType, i) in items"
                  :key="i"
                  link
                >
                <v-btn
                  class="ml-8 d-flex justify-start"
                  icon
                  plain
                  small
                  @click="workflowAdd(child, itemType)"
                >
                <v-icon :color="itemType.textColor" small>
                      {{ itemType.icon }}
                    </v-icon>              
                    {{ itemType.type }}
                </v-btn>

                </v-list-item>
              </v-list>
            </v-menu>
          </div> 
            <!--actions-->
            <svg v-if="child.condition && child.id !==-1" style="width:100%; height:100px;" class="mb-2">
              <line x1="50%" y1="0" x2="50%" y2="40" style="stroke:rgb(174,180,190);stroke-width:1" />
              <line x1="50%" y1="40" x2="25%" y2="40" style="stroke:rgb(174,180,190);stroke-width:1" />
              <line x1="50%" y1="40" x2="75%" y2="40" style="stroke:rgb(174,180,190);stroke-width:1" />
              <line x1="25%" y1="40" x2="25%" y2="100" style="stroke:rgb(174,180,190);stroke-width:1" />
              <line x1="75%" y1="40" x2="75%" y2="100" style="stroke:rgb(174,180,190);stroke-width:1" />
              <!--
                <circle cx="50%" cy="4" r="4" style="fill:rgb(174,180,190);" />
                <circle cx="25%" cy="96" r="4" style="fill:rgb(174,180,190);" />
                <circle cx="75%" cy="96" r="4" style="fill:rgb(174,180,190);" />
              -->
              <!-- <text x="25%" y="35" color="#BDBDBD" font-weight="bold" font-size="12" text-anchor="middle">No</text>
              <text x="75%" y="35" color="#BDBDBD" font-weight="bold" font-size="12" text-anchor="middle">Yes</text> -->
            </svg>
            <svg v-else-if="child.condition && child.children && child.children.length > 0 && child.id !==-1" style="width:100%; height:60px;" class="mt-n2">
              <line x1="50%" y1="10" x2="50%" y2="50" style="stroke:rgb(174,180,190);stroke-width:1" />
              <!--
                <circle cx="50%" cy="10" r="4" style="fill:rgb(174,180,190);" />
                <circle cx="50%" cy="50" r="4" style="fill:rgb(174,180,190);" />
              -->
            </svg>
            <!--nesting-->
            <workflow-children class="mt-n6" v-if="child.children && child.children.length > 0" :children="child.children" />
        </v-col>
      </v-row>
    </v-col>
</v-row>
  </div>
</template>

<script>
/* use of recursive components 
In this code, WorkflowEditor is a recursive component that calls itself when an item has children. The v-if="item.children" directive is used to check if the current item has children. If it does, the WorkflowEditor component is called with the children prop set to the children of the current item. This will create a new v-row for the children, and the process will repeat for each child.
Please note that recursive components must have a name, in this case 'WorkflowEditor', and that name is used to make the recursive call in the template.

*/
export default {
  name: 'WorkflowChildren',
  props: {
    children: Array,
    parent: Object,
    childV1: Object,
    childV2: String,
    parentV2: Object,
  },
  data() {
    return {
      hoverId: null,
      offset: true,
      deletedItem: {},
      items: [
      { type: 'trigger', icon: 'mdi-lightning-bolt', color: 'red lighten-4', textColor: 'red' },
      // { type: 'condition', icon: 'mdi-filter', color: 'blue lighten-4', textColor: 'blue' },
      { type: 'delay', icon: 'mdi-timer', color: 'green lighten-4', textColor: 'green' },
      { type: 'message', icon: 'mdi-message', color: 'blue-grey lighten-4', textColor: 'blue-grey' },
      { type: 'tag', icon: 'mdi-tag', color: 'orange lighten-4', textColor: 'orange' },
      { type: 'remove-tag', icon: 'mdi-tag-remove', color: 'purple lighten-4', textColor: 'purple' },
      ]
    };
  },
  methods: {
    editItem (item){
      this.$emit('edit-item', item);
    },
    addItem (item){
      this.$emit('add-item', item);
    },
    workflowAdd (item, itemType){
      this.$emit('workflow-add', {item, itemType});
    },
    workflowEdit (item){
      this.$emit('workflow-edit', item);
    },
    workflowDelete (item){
      this.$emit('workflow-delete', item);
    },
    getBadge(type) {
          /* https://help.threado.com/en/articles/6313321-what-are-the-different-blocks-you-can-use-to-perform-actions */
          switch (type) {
            case 'trigger':
              return { icon: 'mdi-lightning-bolt', color: 'red lighten-4', textColor: 'red', name: 'trigger'};
            case 'condition':
              return { icon: 'mdi-filter', color: 'blue lighten-4', textColor: 'blue', name: 'condition'};
            case 'delay':
              return { icon: 'mdi-timer', color: 'green lighten-4', textColor: 'green', name: 'delay'};
            case 'message':
              return { icon: 'mdi-message', color: 'blue-grey lighten-4', textColor: 'blue-grey', name: 'message'};
            case 'tag':
              return { icon: 'mdi-tag', color: 'orange lighten-4', textColor: 'orange', name: 'tag'};
            case 'remove-tag':
              return { icon: 'mdi-tag-remove', color: 'purple lighten-4', textColor: 'purple', name: 'remove-tag'};
            default:
              return {};
          }
    },
    hasChildren(item) {
      // filter out the deleted item from the children where id = -1
      return item.children.filter(child => child.id !== -1).length > 0;
    },

  },
};
</script>
<style lang="scss" scoped>
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #2196F3 !important;
            border-left: 4px solid #2196F3;
        }
    }
  .rounded-card {
      border-radius: 10px !important;
      overflow: hidden;
  }
  .add-item {
      // position: relative;
      margin: 0;
      margin-left: 0px;
      padding: 0rem;
      border: 1px solid #959eb1;
      // border-top: 1px solid #959eb1;

      border-radius: 5px;
      background: #f9fafb;
    }
</style>
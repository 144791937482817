var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    ref: "autocomplete",
    class: _vm.classname,
    attrs: {
      "type": "text",
      "id": _vm.id,
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "required": "",
      "label": "Location",
      "hint": "Start Typing Location",
      "persistent-hint": ""
    },
    on: {
      "focus": function ($event) {
        return _vm.onFocus();
      },
      "blur": function ($event) {
        return _vm.onBlur();
      },
      "change": _vm.onChange,
      "keypress": _vm.onKeyPress,
      "keyup": _vm.onKeyUp
    },
    model: {
      value: _vm.autocompleteText,
      callback: function ($$v) {
        _vm.autocompleteText = $$v;
      },
      expression: "autocompleteText"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('div', {
    staticClass: "bg-body d-flex flex-column justify-center align-center min-vh-100"
  }, [_c('div', {
    staticClass: "sign-up-card"
  }, [_c('div', {
    staticClass: "sign-up-card-container"
  }, [_c('div', {
    staticClass: "pa-4 mb-10 d-flex justify-space-around flex-wrap"
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "color": "success",
      "dark": ""
    },
    on: {
      "click": _vm.meet
    }
  }, [_vm._v(" Meet ")]), _c('v-btn', {
    attrs: {
      "x-large": "",
      "color": "error",
      "dark": ""
    },
    on: {
      "click": _vm.skip
    }
  }, [_vm._v(" Skip ")])], 1), _c('v-container', {
    staticClass: "px-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": ("Out of Office: " + (_vm.currentUserResumeDate ? 'On' : 'Off'))
    },
    model: {
      value: _vm.currentUserResumeDate,
      callback: function ($$v) {
        _vm.currentUserResumeDate = $$v;
      },
      expression: "currentUserResumeDate"
    }
  })], 1), _vm.currentUserResumeDate ? [_c('div', [_c('v-menu', {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "Out of Office",
            "prepend-icon": "mdi-calendar",
            "readonly": ""
          },
          model: {
            value: _vm.date,
            callback: function ($$v) {
              _vm.date = $$v;
            },
            expression: "date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 3070401989),
    model: {
      value: _vm.menu,
      callback: function ($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "active-picker": _vm.activePicker,
      "max": _vm.addThreeMonths,
      "min": "1950-01-01"
    },
    on: {
      "update:activePicker": function ($event) {
        _vm.activePicker = $event;
      },
      "update:active-picker": function ($event) {
        _vm.activePicker = $event;
      },
      "change": _vm.save
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)], 1)] : _vm._e(), _c('div', {
    staticClass: "mt-5 mb-10"
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v(" Save ")])], 1)], 2)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-footer
    dark
    color="background_primary"
    :class="{'d-none': $route.path == '/home-two'}"
  >
    <v-container>
      <v-row class="mb-n16 mt-n16">
        <v-col cols="12" xl="4" lg="4" md="4" sm="6">
          <v-avatar width="300" height="auto" tile >
            <!-- <img src="@/assets/images/unmove-homes/unmove_homes.logo.iconV2.svg" alt=""/> -->
            <!-- <img src="@/assets/images/unmove-homes/unmove-homes-white.svg" alt="" class="mt-2 ml-n2"/> -->
          </v-avatar>
          <p class="footer-text mb-5">
            Experience the thrill of serendipitous connections at your fingertips. 
            Our app creates spontaneous pairings among your organisation's members, fostering collaboration and breaking down barriers. 
            <br/><strong>Join us today and embark on your journey!</strong>
          </p>
          <!-- <div class="d-flex flex-sm-nowrap flex-wrap">
            <a href="#" class="me-3 mb-3">
              <v-img
                max-width="130"
                src="@/assets/images/app-store/google-play.png"
              ></v-img>
            </a>
            <a href="#">
              <v-img
                max-width="130"
                src="@/assets/images/app-store/app-store.png"
              ></v-img>
            </a>
          </div> -->
        </v-col>

        <v-col cols="12" xl="2" lg="2" md="4" sm="6">
          <h2 class="mt-4 mb-2 secondary--text">About Us</h2>
          <div v-for="(n, index) in aboutUs" :key="index" class="my-1">
            <a href="#" class="text-decoration-none footer-link-hover">{{
              n
            }}</a>
          </div>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="4" sm="6">
          <h2 class="mt-4 mb-2 secondary--text">Customer Care</h2>
          <div v-for="(n, index) in customerCare" :key="index" class="my-1">
            <a href="#" class="text-decoration-none footer-link-hover">{{
              n
            }}</a>
          </div>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="4" sm="6">
          <h2 class="mt-4 mb-2 secondary--text">Contact Us</h2>
          <!-- <p class="footer-text">
            70 Washington Square South, New York, NY 10012, United States
          </p> -->
          <p class="footer-text">Email: cheqqmate@gmail.com</p>
          <!-- <p class="footer-text mb-5">Phone: +1 1123 456 780</p> -->
          <v-btn
            v-for="icon in icons"
            :key="icon.name"
            :href="icon.link"
            class="me-2 white--text"
            elevation="0"
            x-small
            fab
            dark
            color="primary"
            target="_blank"
          >
            <v-icon small size="24px">
              {{ icon.name }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    icons: [
      // "mdi-facebook",
      { name: "mdi-twitter", link: "https://twitter.com/" },
      // "mdi-youtube",
      // "mdi-google",
      // "mdi-instagram",
    ],
    aboutUs: [
      // "Careers",
      // "Our Stores",
      // "Our Cares",
      "Terms & Conditions",
      "Privacy Policy",
    ],
    customerCare: [
      "Help Center",
      // "How to Buy",
      // "Track Your Order",
      // "Corporate & Bulk Purchasing",
      // "Returns & Funds",
    ],
  }),
};
</script>
<style lang="scss">
a.footer-link-hover {
  color: #616161 !important; //grey lighten-1
  &:hover {
    color: #D53E59 !important; //grey darken-1
  }
}
.v-footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
</style>
<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <dashbord-sidebar />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn
                icon
                @click="isSidebar = !isSidebar"
              >
                <v-icon dark>
                  mdi-format-list-bulleted-square
                </v-icon>
              </v-btn>
            </div>
            <!--Modal Start-->
            <location-list-modal
              :modal-title="modalTitle"
              :modal-paragraph="modalParagraph"
              :modal-type="modalType"
              @modal-response="modalAction($event)"
            />
            <!--Modal End-->
            <div class="box-container">
              <div class="d-flex justify-space-between flex-wrap mb-5">
                <div class="d-flex align-center ">
                  <v-avatar
                    tile
                    size="25"
                    class="me-3"
                  >
                    <v-icon
                      color="grey darken-1"
                    >
                      mdi-shape
                    </v-icon>
                  </v-avatar>
                  <h2 class="grey--text text--darken-1 mb-0">
                    Groups
                  </h2>
                </div>
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize font-600"
                  @click="addGroup()"
                >
                  Add New Group
                </v-btn>
              </div>
              <v-data-iterator
                :items="groups"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                hide-default-footer
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="(item, idx) in props.items"
                      :key="idx"
                      cols="12"
                    >
                      <base-card class="">
                        <div class="pt-3 pb-3 pr-5 pl-5 d-flex align-center flex-wrap justify-space-between">
                          <!--name-->
                          <div class="mr-6">
                            <a
                              :href="`/manage-group?groupId=${item.id}`"
                              class="text-decoration-none"
                            >
                              <v-row>
                                <span class="text-sm secondary--text mt-2 ml-2">
                                  Name
                                </span>
                              </v-row>
                              <v-row>
                                <p class="mb-6 mb-1 mt-1 ml-2 grey--text text--darken-4 cut-off-text">{{ item.name }}</p>
                              </v-row>
                            </a>
                          </div>
                          <!--members-->
                          <div>
                            <v-row>
                                <span class="text-sm secondary--text mt-1 ml-2">
                                  Members
                                </span>
                              </v-row>
                              <v-row>
                                <p class="ml-1 grey--text">
                                  <v-badge
                                    class="mt-1"
                                    color="darkGrey"
                                    inline
                                    :content="item.members?item.members:0"
                                  >
                                  </v-badge>
                                </p>
                              </v-row>
                          </div>
                          <!--createdDate-->
                          <div class="created-date">
                            <v-row>
                              <span class="text-sm secondary--text mb-2 ml-2 mr-3">
                                Created Date
                              </span>
                            </v-row>
                              <v-row>
                                <div class="ml-2 mt-n1 mb-2 d-flex align-center">
                                  <v-chip small>
                                  <v-icon small
                                    class="icon"
                                  >
                                    mdi-clock-time-four
                                  </v-icon>
                                <p class="ma-1 secondary--text text-sm">{{ formatDateTime(item.createdDate) }}</p>
                              </v-chip>
                                </div>
                              </v-row>
                          </div>
                          <!--delete-->
                          <!---only delete if there are no subscribers (set archive date) --> 
                           <!--archive instead of delete??? 01.04.2024-->
                          <div>
                            <v-row>
                              <span class="text-sm secondary--text ml-2 mr-4">
                                Delete
                              </span>
                            </v-row>
                            <v-row>
                                <v-btn
                                  icon
                                  @click="deleteAction(groups.indexOf(item))"
                                >
                                  <v-icon
                                  class="mb-2"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                            </v-row>
                          </div>
                          <!--edit-->
                          <div>
                            <v-row>
                              <span class="text-sm secondary--text ml-2 mr-1">
                                Edit
                              </span>
                            </v-row>
                            <v-row>
                              <div>
                                <router-link
                                  style="text-decoration: none !important;"
                                  :to="{ path: '/manage-group',
                                         query: {
                                           groupId: groups[idx].id
                                         }
                                  }"
                                >
                                  <v-btn
                                    icon
                                  >
                                    <v-icon
                                    class="mb-2"
                                    >
                                      mdi-pencil
                                    </v-icon>
                                  </v-btn>
                                </router-link>
                              </div>
                            </v-row>
                          </div>
                        </div>
                      </base-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:footer>
                  <v-row
                    class="my-5 me-1"
                    align="center"
                    justify="center"
                  >
                    <v-spacer />
                    <span
                      class="mr-4
                                grey--text"
                    >
                      Page {{ page }} of {{ numberOfPages }}
                    </span>
                    <v-btn
                      fab
                      :disabled="page == 1"
                      small
                      color="primary"
                      class="mr-1"
                      @click="formerPage"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      :disabled="page == numberOfPages"
                      small
                      color="primary"
                      class="ml-1"
                      @click="nextPage"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import LocationListModal from '../../components/shared/LocationListModal.vue'
  import DashbordSidebar from '@/components/DashboardSidebar.vue'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import ManageGroup from './ManageGroup'
  const defaultUserProfile = {
    id: 'Jane.Done@example.com',
    username: 'Jane.Done',
    type: 'userProfile',
    userId: 'Jane.Done@example.com',
    personalDetails: {
        id: "tzishi@live.co.uk",
        name: "Tino Zishiri",
        phoneNumber: "07943062109",
        emailAddres: "tzishi@live.co.uk",
        resumeDate: "14/08/2023 10:46",
        isDeletedFromDirectory: false
    },
    subscription: {
        name: "Free",
        isActive: true,
        createdDate: "14/08/2023 10:46"
    },
    memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
    groups: [
        {
            groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
            groupName: "Tino Zishiri's Awesome Squad"
        }
    ],
    schedule: [
        {
            "id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
            "scheduleName": "Tino Zishiri's Awesome Squad Schedule"
        }
    ],
    matches: 1,
    matchesLastModifiedDate: "14/08/2023 10:46",
    meetups: 1,
    meetupsLastModifiedDate: "14/08/2023 10:46",
    pauses: 1,
    pausesLastModifiedDate: "14/08/2023 10:46",
    dashboard: {
        topUserGroups: [
            {
                userGroup: null,
                matches: 0,
                meetups: 0,
                pauses: 0
            }
        ]
    },
  };
  export default {
    components: {
      DashbordSidebar,
      ManageGroup,
      LocationListModal,
    },
    data () {
      return {
        isSidebar: false,
        page: 1,
        itemsPerPage: 5,
        items: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20],
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        status: false,
        title: '',
        paragraph: '',
        modalType: '',
        _id: '',
      }
    },
    computed: {
      ...mapState('user', ['userProfile']),
      ...mapGetters('user', ['getCurrentUser']),
      ...mapState('auth', ['authUser']),
      authUser () {
        return this.$store.state.auth.authUser
      },
      userProfile() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.userProfile
          : defaultUserProfile
      },
      modalTitle () {
        var title = ''
        switch (this.modalType) {
          case 'archive':
            title = 'Are you sure you want to Archive?'
            break
          case 'publish':
            title = 'Are you sure you want to Publish?'
            break
          case 'privacy':
            title = 'Are you sure you want to change Archive?'
            break
            case 'delete':
            title = 'Are you sure you want to change Delete Group?'
            break
          default:
          // code block
        }
        return title
      },
      modalParagraph () {
        var paragraph = ''
        switch (this.modalType) {
          case 'archive':
            paragraph = 'Once you archive a list, you\'ll only be able to access it in read-only mode. You won\'t be able to use it within the chrome extension.'
            break
          case 'publish':
            paragraph = 'Once you publish, you won\'t be able to make further updates. Publishing a list allows you use it within the chrome extension'
            break
          case 'privacy':
            paragraph = 'Setting the list to private or public will restrict or allow access to other users respectively.'
            break
          case 'delete':
            paragraph = 'Deleting this group will prevent you from adding it within schedules or adding members to it.'
            break
          default:
          // code block
        }
        return paragraph
      },
      groups: {
        get () {
          return this.$store.state.user.groupList
            ? this.$store.state.user.groupList
            : defaultUserProfile.groups
        },
        set (value) {
          // this.$store.commit('user/USER_DETAILS_UPDATED', value)
        },
      },
      numberOfPages () {
        return Math.ceil(this.groups.length / this.itemsPerPage)
      },
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
      this.fetchUserProfile(this.authUser.email)
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      ...mapActions('user', ['getUser', 'updateLocationList','updateGroup','delGroup']),
      ...mapGetters('app', ['isVisible']),
      fetchUserProfile (userEmail) {
        this.getUser(userEmail)
      },
      formatDateTime (date) {
        return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
      // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
      updatePrivacy () {
        this.$store.commit('user/LOCATION_LIST_PRIVACY_UPDATED', this._id)
        this.updateUserProfile()
      },
      deleteGroup () {
        var payload = {
          Group: this.groups[this._id].id,
          UserEmail: this.userProfile.userId
        }
        this.delGroup(payload).then(() => {
          this.$store.dispatch('user/getUser', this.userProfile.userId)
        })
        // this.$store.commit('user/LOCATION_LIST_PRIVACY_UPDATED', this._id)
        // this.updateUserProfile()
      },
      updateArchiveStatus () {
        this.$store.commit('user/LOCATION_LIST_ARCHIVE_STATUS_UPDATED', this._id)
        this.updateUserProfile()
      },
      updatePublishStatus () {
        this.$store.commit('user/LOCATION_LIST_PUBLISH_STATUS_UPDATED', this._id)
        this.updateUserProfile()
      },
      publish (id) {
        this.modalType = 'publish'
        this.$store.commit('app/SET_MODAL_STATUS', this.isVisible == this.isVisible)
        this._id = id
      },
      archive (id) {
        this.modalType = 'archive'
        this.$store.commit('app/SET_MODAL_STATUS', this.isVisible == this.isVisible)
        this._id = id
      },
      privacy (id) {
        this.modalType = 'privacy'
        this.$store.commit('app/SET_MODAL_STATUS', this.isVisible == this.isVisible)
        this._id = id
      },
      deleteAction (id) {
        this._id = id
        this.modalType = 'delete'
        this.$store.commit('app/SET_MODAL_STATUS', this.isVisible == this.isVisible)
      },
      clone (id) { // keep
        this.modalType = 'archive'
        this.$store.commit('app/SET_MODAL_STATUS', this.isVisible == this.isVisible)
        this._id = id
      },
      updateUserProfile () {
        this.updateLocationList(this.authUser.email)
      },
      modalAction (type) {
        switch (type) {
          case 'archive':
            this.updateArchiveStatus()
            break
          case 'publish':
            this.updatePublishStatus()
            break
          case 'privacy':
            this.updatePrivacy()
            break
          case 'delete':
            this.deleteGroup()
            break            
          default:
          // code block
        }
      },
      addGroup() {
        var date = new Date().toJSON().slice(0, 10)
        var time = new Date().toJSON().slice(11, 19)
        var dateTime = date + '-' + time.replace(':', '-').replace(':', '-')
        var _groupName = `my-new-group-${dateTime}`
        var payload = {
          UserEmail: this.userProfile.userId,
          GroupDetails: [
            {
              "id": null,
              "name": _groupName,
              "isActive": true
            }    
            ]  
        }
        this.updateGroup(payload).then(() => {
          this.$store.dispatch('user/getUser', this.userProfile.userId)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
    .cut-off-text {
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #2196F3 !important;
            border-left: 4px solid #2196F3;
        }
    }
    @media (max-width: 550px) {
    .created-date {
      display: none;
    }
  }
</style>

<template>
  <ValidationProvider :name="$attrs.label" rules="alpha_dash|required" v-slot="{ errors, valid }">
    <v-text-field
      v-model="innerValue"
      :error-messages="errors"
      :success="valid"
      :placeholder="$attrs.placeholder"
      v-bind="$attrs"
      v-on="$listeners"
      outlined
      dense
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";
import { alpha_dash } from 'vee-validate/dist/rules';

extend('alpha_dash', alpha_dash);
export default {
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    class: {
      'd-none': _vm.$route.path == '/home-two'
    },
    attrs: {
      "dark": "",
      "color": "background_primary"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "mb-n16 mt-n16"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('v-avatar', {
    attrs: {
      "width": "300",
      "height": "auto",
      "tile": ""
    }
  }), _c('p', {
    staticClass: "footer-text mb-5"
  }, [_vm._v(" Experience the thrill of serendipitous connections at your fingertips. Our app creates spontaneous pairings among your organisation's members, fostering collaboration and breaking down barriers. "), _c('br'), _c('strong', [_vm._v("Join us today and embark on your journey!")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "2",
      "lg": "2",
      "md": "4",
      "sm": "6"
    }
  }, [_c('h2', {
    staticClass: "mt-4 mb-2 secondary--text"
  }, [_vm._v("About Us")]), _vm._l(_vm.aboutUs, function (n, index) {
    return _c('div', {
      key: index,
      staticClass: "my-1"
    }, [_c('a', {
      staticClass: "text-decoration-none footer-link-hover",
      attrs: {
        "href": "#"
      }
    }, [_vm._v(_vm._s(n))])]);
  })], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "3",
      "lg": "3",
      "md": "4",
      "sm": "6"
    }
  }, [_c('h2', {
    staticClass: "mt-4 mb-2 secondary--text"
  }, [_vm._v("Customer Care")]), _vm._l(_vm.customerCare, function (n, index) {
    return _c('div', {
      key: index,
      staticClass: "my-1"
    }, [_c('a', {
      staticClass: "text-decoration-none footer-link-hover",
      attrs: {
        "href": "#"
      }
    }, [_vm._v(_vm._s(n))])]);
  })], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "3",
      "lg": "3",
      "md": "4",
      "sm": "6"
    }
  }, [_c('h2', {
    staticClass: "mt-4 mb-2 secondary--text"
  }, [_vm._v("Contact Us")]), _c('p', {
    staticClass: "footer-text"
  }, [_vm._v("Email: cheqqmate@gmail.com")]), _vm._l(_vm.icons, function (icon) {
    return _c('v-btn', {
      key: icon.name,
      staticClass: "me-2 white--text",
      attrs: {
        "href": icon.link,
        "elevation": "0",
        "x-small": "",
        "fab": "",
        "dark": "",
        "color": "primary",
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": "",
        "size": "24px"
      }
    }, [_vm._v(" " + _vm._s(icon.name) + " ")])], 1);
  })], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
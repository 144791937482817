<template>
  <v-container class="py-6">
      <v-row>
        <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                    <DashbordSidebar />
                </div>
                <div class="box-content">
                    <div class="d-flex justify-end pa-2 d-block d-md-none">
                        <v-btn
                            icon   
                            @click="isSidebar = !isSidebar"
                        >
                            <v-icon dark>
                                mdi-format-list-bulleted-square
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="box-container">
                        <v-row >
                            <v-col
                                cols="12"
                                sm="6"
                                md="8"
                                class="ml-3"
                                color="secondary"
                            >
                                <v-text-field
                                    color="secondary"
                                    class="mt-n4 secondary--text"
                                    prepend-inner-icon="mdi-lead-pencil"
                                    v-model="locationsList[0].listName"
                                    :counter="50"
                                />
                            </v-col>
                            <v-spacer/>
                            <div class="d-flex flex-wrap mt-2 mb-5 mr-5">
                                <v-btn 
                                    outlined 
                                    color="secondary" 
                                    class="text-capitalize font-600"
                                    href='/places'
                                >
                                    My Groups
                                </v-btn>
                            </div>                            
                        </v-row>                       
                       
                        <div class="flex-1 ml-5">
                            <v-row>
                                <span class="text-sm grey--text text--darken-2 pb-5">
                                    {{$route.query.listId}}
                                </span>
                                <v-spacer/>
                                <span class="mr-2">
                                <v-col>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                x-large
                                                @click="bulkUpload()"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon
                                                    color="secondary"
                                                    large
                                                >
                                                    mdi-upload
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Bulk Upload</span>
                                    </v-tooltip>                                
                                </v-col>
                            </span>                                
                            </v-row>
                        </div>
                        <template>
                        <div class="mt-2">
                            <div style="border:2px solid #f3f4f6; border-radius: 5px;">
                                <v-expansion-panels  accordion class="mt-2" v-for="(item,i) in locationsList[0].locations" :key="i">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <div class="flex-1 mr-2">
                                                <v-row>
                                                    <span class="text-sm grey--text text--darken-2 pb-4">
                                                        <!-- Location {{i+1}}  -->
                                                        <br/>
                                                        <v-chip
                                                            small
                                                            color="primary"
                                                            label
                                                            text
                                                            class="text-sm mt-2"
                                                        >
                                                        Member {{item.locationOrder = i+1}}
                                                        </v-chip>
                                                    </span>
                                                    <div class="pa-4 d-flex align-center flex-wrap">
                                                        <div class="flex-1 mr-12">
                                                            <a

                                                            class="text-decoration-none"
                                                            >
                                                            <v-row>
                                                                <span class="text-sm grey--text text--darken-2 ml-12">
                                                                Name
                                                                </span>
                                                            </v-row>
                                                            </a>
                                                        </div>
                                                        <div class="mr-12">
                                                            <v-row>
                                                            <span class="text-sm grey--text text--darken-2 ml-12">
                                                                Resume Date
                                                            </span>
                                                            </v-row>
                                                        </div>
                                                    </div>                                                    
                                                </v-row>                                      
                                            </div>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div class="pa-4">
                                                <v-row>
                                                    <v-col cols="12" md="6" lg="6" xl="6">
                                                        <v-text-field
                                                        v-model="item.locationType"
                                                        :value="item.locationType=i<3?'primary':'secondary'"                                           
                                                        :items="types"
                                                        color="secondary"
                                                        outlined
                                                        dense
                                                        label="Name"
                                                        hint="Primary locations are used for Roundtrip calculations"
                                                        persistent-hint                                       
                                                    ></v-text-field>                                                                                                
                                                    </v-col>
                                                    <v-col cols="12" md="6" lg="6" xl="6">
                                                    <v-text-field
                                                        v-model="item.locationType"
                                                        :value="item.locationType=i<3?'primary':'secondary'"                                           
                                                        :items="types"
                                                        color="secondary"
                                                        outlined
                                                        dense                                                                
                                                        label="Phone Number"
                                                        hint="Primary locations are used for Roundtrip calculations"
                                                        persistent-hint                                       
                                                    ></v-text-field>                                             
                                                    </v-col>                                     
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="6" lg="6" xl="6">
                                                        <v-text-field
                                                        v-model="item.locationType"
                                                        :value="item.locationType=i<3?'primary':'secondary'"                                           
                                                        :items="types"
                                                        color="secondary"
                                                        outlined
                                                        dense
                                                        label="Out Of Office"
                                                        hint="Primary locations are used for Roundtrip calculations"
                                                        persistent-hint                                       
                                                    ></v-text-field>                                                                                                
                                                    </v-col>
                                                    <v-col cols="12" md="6" lg="6" xl="6">
                                                    <v-text-field
                                                        v-model="item.locationType"
                                                        :value="item.locationType=i<3?'primary':'secondary'"                                           
                                                        :items="types"
                                                        color="secondary"
                                                        outlined
                                                        dense
                                                        label="Is Deleted from Directory"
                                                        hint="Primary locations are used for Roundtrip calculations"
                                                        persistent-hint                                       
                                                    ></v-text-field>                                             
                                                    </v-col>                                   
                                                </v-row>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </div>      
                        </div>                        
                        <v-row>
                            <span class="text-sm grey--text text--darken-2 ml-2 mt-2 pt-2 pb-5">
                                <v-col>
                                    <v-btn 
                                        color="secondary" 
                                        class="font-600 text-capitalize"
                                        @click="saveLocationChanges($route.query.listId)"
                                    >
                                        Save Changes
                                    </v-btn>
                                </v-col>
                            </span>
                            <v-spacer/>
                            
                            <span class="pt-2 pb-5 mr-2">
                                <v-col>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                x-large
                                                @click="addLocation($route.query.listId)"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon
                                                    color="secondary"
                                                    large
                                                >
                                                mdi-plus-circle
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add Record</span>
                                    </v-tooltip>
                                    <!---:disabled="locationsList[0].isPublished || locationsList[0].isArchived"-->
                                </v-col>
                            </span>
                        </v-row>
                        </template>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>

const defaultUserProfile = {
    "id": "Jane.Done@example.com",
    "username": "Jane.Done",
    "type": "userProfile",
    "userId": "Jane.Done@example.com",
    "createdDate": "2011-11-02T12:10:07Z",
    "subscriptions": [{
    }],
    "locationsList": [
        {
            id: "51963bd3-21f8-4fba-94ff-e6448a2b1912",
            listName: "Places In Surrey",
            privacySetting: "public",
            isPublished: true,
            isArchived: false,
            locations: [
                {
                    locationOrder: 1,
                    locationTag: "gym",
                    locationType: "primary",
                    locationAddress: null,
                    locationUrl: "",
                    locationGPS: "51.31855100000001,-0.5717130000000001",
                    googleMapsAPIAddressName: "PureGym Woking",
                    googleMapsAPIFullAddress: "Arthurs Bridge Wharf, Horsell Moor, Woking GU21 4NR, UK",
                    googleMapsApiAddressGPS: "51.31855100000001,-0.5717130000000001",
                    googleMapsApiLocationType: [
                        "gym",
                        "health",
                        "point_of_interest",
                        "establishment"
                    ],
                    googleMapsApiPlaceId: "ChIJw6mmfo7XdUgRQOBVsp3lIac"
                },
                {
                    locationOrder: 2,
                    locationTag: "school",
                    locationType: "primary",
                    locationAddress: null,
                    locationUrl: "",
                    locationGPS: "51.3130173,-0.6195608",
                    googleMapsAPIAddressName: "Busy Bees in Woking",
                    googleMapsAPIFullAddress: "50 Cavell Way, Knaphill, Woking GU21 2TJ, UK",
                    googleMapsApiAddressGPS: "51.3130173,-0.6195608",
                    googleMapsApiLocationType: [
                        "school",
                        "point_of_interest",
                        "establishment"
                    ],
                    googleMapsApiPlaceId: "ChIJ2XWfa1DWdUgRFPnPy5CirMo"
                },
                {
                    locationOrder: 3,
                    locationTag: "university",
                    locationType: "primary",
                    locationAddress: null,
                    locationUrl: "",
                    locationGPS: "51.2431429,-0.5894663999999998",
                    googleMapsAPIAddressName: "University Of Surrey",
                    googleMapsAPIFullAddress: "Stag Hill, University Campus, Guildford GU2 7XH, UK",
                    googleMapsApiAddressGPS: "51.2431429,-0.5894663999999998",
                    googleMapsApiLocationType: [
                        "university",
                        "point_of_interest",
                        "establishment"
                    ],
                    googleMapsApiPlaceId: "ChIJOwhDSMLQdUgRoRNC7SrlnSk"
                },
                {
                    locationOrder: 4,
                    locationTag: "movie_theater",
                    locationType: "secondary",
                    locationAddress: null,
                    locationUrl: "",
                    locationGPS: "51.3367543,-0.7485896000000001",
                    googleMapsAPIAddressName: "Vue Cinema Camberley",
                    googleMapsAPIFullAddress: "The Atrium, Park St, Camberley GU15 3PL, UK",
                    googleMapsApiAddressGPS: "51.3367543,-0.7485896000000001",
                    googleMapsApiLocationType: [
                        "movie_theater",
                        "point_of_interest",
                        "establishment"
                    ],
                    googleMapsApiPlaceId: "ChIJ5U6rslPVdUgRXaujFhMRL2w"
                }
            ]
        },
        {
            id: "6a46cf34-2571-469d-94c3-350c7402c2c3",
            listName: "My Daily Commute",
            privacySetting: "public",
            isPublished: false,
            isArchived: false,
            locations: [
                {
                    locationOrder: 1,
                    locationTag: null,
                    locationType: "primary",
                    locationAddress: null,
                    locationUrl: null,
                    locationGPS: null,
                    googleMapsAPIAddressName: null,
                    googleMapsAPIFullAddress: null,
                    googleMapsApiAddressGPS: null,
                    googleMapsApiLocationType: null,
                    googleMapsApiPlaceId: null
                }
            ]
        }
    ],
    "wishListProperties": 0,
    "zooplaProperties": 0,
    "rightMoveProperties": 0,
    "onTheMarketProperties": 0,
};

import DashbordSidebar from "@/components/DashboardSidebar.vue";
import { mapState, mapActions, mapGetters } from 'vuex';
import VueGoogleAutocomplete from '../../components/shared/VueGoogleAutocomplete.vue';
import placeTypes from "../../data/placeType";
import axios from 'axios';
import { getAuth } from "firebase/auth";
import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag } from '../../components/utils/helpers.js'

const auth = getAuth();
const defaultUser = [{
  displayName: 'Hello',
  email: 'world'
}]

var backendServiceBaseUrl = 'http://localhost:7071/api';
var backendServiceConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    'Content-Type': 'application/json'
  }
};


export default {
    components: {
        DashbordSidebar,
        VueGoogleAutocomplete,
        Container, 
        Draggable
    },
    data() {
        return{
            isSidebar: false,
            isEditAddress: false,
            address: '',
            autocomplete: '',
            selectedAddress: '',
            currentUser: null
            // types: ['primary', 'secondary']
        }
    },
    computed: {
        ...mapState('user', ['userProfile']),
        ...mapGetters('user', ['getUserProfile', 'getCurrentUser']),
        ...mapState('auth', ['authUser']),
        authUser() {
            return this.$store.state.auth.authUser;
        },        
        places(){
            return placeTypes
        },
        types() {
            return ['primary', 'secondary']
        },
        locationsList: {
            get() {
                if(this.$route.query.listId != ''){
                    // return this.$store.state.user.userProfile.userId == this.authUser.email
                    // ? this.$store.state.user.userProfile.locationsList.filter(x => x.id == this.$route.query.listId)
                    // : defaultUserProfile.locationsList 
                    return this.$store.state.user.userProfile.userId == this.authUser.email
                    ? this.$store.state.user.userProfile.locationsList.filter(x => x.id == '')
                    : defaultUserProfile.locationsList                 
                }
                else {
                    return this.$store.state.user.userProfile.userId == this.authUser.email
                    ? this.$store.state.user.userProfile.locationsList.filter(x => x.id == '')
                    : defaultUserProfile.locationsList                              
                }
            },
            set(value) {
                // this.$store.commit('user/USER_DETAILS_UPDATED', value);
            }
        }
    },
    mounted(){
        // console.log(auth.currentUser)
    },
    created () {
        // this.fetchUserProfile(this.authUser.email)
        // // if(this.$route.query.listId == ''){
        //     this.$store.commit('user/ADD_LOCATION_LIST_BLANK_OBJECT',
        //         {
        //             "id": "",
        //             listName: this.$route.query.listName,
        //             "privacySetting": "public",
        //             "isPublished": false,
        //             "isArchived": false,
        //             "locations": [{}]
        //         }
        //     );
        // // }  

        const user = auth.currentUser;
        if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.currentUser = user;
        } else {
        // No user is signed in.
        }             
    },
    methods: {
        ...mapActions('user', ['getUser']),
        fetchUserProfile(userEmail) {
            this.getUser(userEmail);
        },
        /**
        * When the location found
        * @param {Object} addressData Data of the found location
        * @param {Object} placeResultData PlaceResult object
        * @param {String} id Input container ID
        */
        getAddressData(addressData, placeResultData, id, locationPosition) {
            var payload = {
                'locationOrder': locationPosition+1,
                "locationTag": this.locationsList[0].locations[locationPosition].locationTag,
                "locationType": this.locationsList[0].locations[locationPosition].locationType,
                "locationUrl": "",
                "locationGPS": addressData.latitude + ',' + addressData.longitude,
                'googleMapsAPIAddressName' : placeResultData.name,
                'googleMapsAPIFullAddress' : placeResultData.formatted_address,
                'googleMapsApiAddressGPS'  : addressData.latitude + ',' + addressData.longitude,
                'googleMapsApiPlaceId'     : placeResultData.place_id,
                'googleMapsApiLocationType': placeResultData.types
            }
            // update state
            this.$store.commit('user/LOCATION_LIST_LOCATION_UPDATED', {
                payload,
                id: this.$route.query.listId,
                name: this.locationsList[0].listName,
                idx: locationPosition
            });
            this.address = addressData;
        },

        handleError(error) {
            alert(error)
        },
        saveLocationChanges(id) {
            // (1) Add to Vuex
            if(this.getUserProfile && this.$route.query.listId != '') {
                this.$store.commit('user/LOCATION_LIST_FULL_UPDATED', [this.locationsList, id]);
            }
            // (2) Read from Vuex & Initialize
            var postData = {
                UserEmail: this.authUser.email,//this.currentUser.email,
                UserLocations: this.getUserProfile.locationsList
            }
            this.$store.commit('app/START_LOADING'); 
            // (3) Send post request
            axios
            .post(
                `${backendServiceBaseUrl}/upsertUserProfile`,
                postData,
                backendServiceConfig
            )
            .then(() => {
                /* update vuex */
                // this.$store.commit('USER_DETAILS_UPDATED', res.data);
                 
                // push & refresh page
                this.$router.go(this.$router.push({ path: '/locations' }));      
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                }
            });
            this.$store.commit('app/FINISH_LOADING');
        },
        bulkUpload(){},
        addLocation(id){
            this.$store.commit('user/ADD_LOCATION_LIST_BLANK_LOCATION_OBJECT', [               
                {
                    'locationOrder': this.locationsList[0].locations.length,
                    'locationTag': '',
                    'locationType': '',
                    'locationUrl': '',
                    'locationGPS': '',
                    'googleMapsAPIAddressName' : '',
                    'googleMapsAPIFullAddress' : '',
                    'googleMapsApiAddressGPS'  : '',
                    'googleMapsApiPlaceId'     : '',
                    'googleMapsApiLocationType': ''
                }, 
                id
            ]);
        },
        deleteLocation(id, i){
            this.$store.commit('user/LOCATION_LIST_DELETE_LOCATION', [i, id]);
        },
        onDrop (dropResult) {
            this.locationsList[0].locations = applyDrag(this.locationsList[0].locations, dropResult)
        } 
    }  
}
</script>
<style>
.group {
  display: flex;
  flex: 1;
  justify-content: space-around;
}
/* https://github.com/kutlugsahin/vue-smooth-dnd/blob/master/demo/src/assets/demos.css*/
.column-drag-handle {
    cursor: move;
    padding: 5px;
}
/* .draggable-item {
    height: 75px;
    line-height: 75px;
    text-align: center;
    display: block;
    background-color: #fff;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, .125);
    margin-bottom: 2px;
    margin-top: 2px;
    cursor: default;
    user-select: none;
} */
</style>

<template>
    <div
      draggable
      class="nestable-handle"
      @dragstart="dragstart"
      @touchstart="dragstart"
      @touchend="touchend"
      @touchmove="touchmove"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <div v-if="item.level==='Section'" style="min-height: 36px;" class="add-item pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline">
          Section {{item.id+1}}
          {{item.description?`: ${item.description}`:'' }}
          <span>
            <v-btn
              v-show="isHovered"
              icon
            >
              <v-icon
                color="grey"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              v-show="isHovered"
              icon
            >
              <v-icon
                color="grey"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </span>      
      </div>
      <div v-else-if="item.level==='Lesson' && !hasParent" style="min-height: 36px;" class="add-item pl-4 pt-2 error--text font-weight-bold d-flex justify-space-between flex-wrap text-body-2">
        Lessons can only be added inside a Section. Move this lesson to a Section or delete it.
        <span class="mt-n2">
              <v-btn
                icon
              >
                <v-icon
                  color="error"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
        </span>          
      </div>
      <div v-else style="min-height: 36px;" class="mt-n2 mb-n1 text-body-1 grey--text text--darken-1 font-weight-medium d-flex justify-space-between flex-wrap text-none">
        <span>
          <slot />
          {{item.description?`: ${item.description}`:'' }}
        </span> 
        <span class="mt-n2">
            <v-btn
                icon
                v-show="isHovered"
              >
                <v-icon
                  color="grey"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                icon
                v-show="isHovered"
              >
                <v-icon
                  color="grey"
                  @click="editItemFullScreen(item)"
                >
                  mdi-fullscreen
                </v-icon>
              </v-btn>
              <v-btn
                v-show="isHovered"
                icon
              >
                <v-icon
                  color="grey"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
        </span>  
      </div>     
    </div>
  </template>
  
  <script type="text/babel">
  import groupsObserver from './groups-observer.js'
  
  export default {
    name: 'VueNestableHandle',
  
    mixins: [groupsObserver],
  
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({})
      },
      hasParent: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data () {
      return {
        isHovered: false,
      }
    },  
    inject: ['group', 'onDragEnd'],
  
    methods: {
      dragstart (event) {
        const item = this.item || this.$parent.item
        this.notifyDragStart(this.group, event, item)
      },
      touchend (event) {
        this.onDragEnd(event)
      },
      touchmove (event) {
        this.notifyMouseMove(this.group, event)
      },
      editItem (item){
        this.$emit('edit-item', item);
      },
      editItemFullScreen (item){
        this.$emit('edit-item-full-screen', item);
      },
      deleteItem (item){
        this.$emit('delete-item', item);
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .add-item {
    margin: 0;
    margin-left: 0px;
    padding: 0rem;
    border-radius: 5px;
    background: #f9fafb;
  }
</style>
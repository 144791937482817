import Vue from 'vue';
import Vuex from 'vuex';

import appModule from './modules/app.js';
import userProfileModule from './modules/userProfile.js';
import authModule from './modules/authentication';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app: appModule,
    user: userProfileModule,
    auth: authModule,
  }
});

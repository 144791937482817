var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center mt-n2"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/icons/clock-time-four.svg"),
      "alt": ""
    }
  })]), _c('h2', {
    staticClass: "mb-0 secondary--text"
  }, [_vm._v("Manage Events")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), _c('v-card', {
    staticClass: "mb-8"
  }, [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Event Details")])], 1), _c('v-tabs', {
    attrs: {
      "vertical": ""
    }
  }, [_c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-information-outline ")]), _c('span', {
    staticClass: "text-none"
  }, [_vm._v("Information")])], 1), _c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-clock-time-four ")]), _c('span', {
    staticClass: "text-none"
  }, [_vm._v("Time & Date")])], 1), _c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-palette ")]), _c('span', {
    staticClass: "text-none"
  }, [_vm._v("Appearance")])], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Event Information ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "outlined": "",
      "counter": 30,
      "dense": "",
      "label": "Event Name"
    },
    model: {
      value: _vm.eventDetails.eventName,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "eventName", $$v);
      },
      expression: "eventDetails.eventName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.groups,
      "item-text": 'name',
      "item-value": 'id',
      "color": "secondary",
      "label": "Group",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.eventDetails.groupId,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "groupId", $$v);
      },
      expression: "eventDetails.groupId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "counter": "250",
      "outlined": "",
      "name": "input-7-4",
      "label": "Message",
      "value": ""
    },
    model: {
      value: _vm.eventDetails.eventMessage,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "eventMessage", $$v);
      },
      expression: "eventDetails.eventMessage"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('p', {
    staticClass: "text-11 mt-n8"
  }, [_vm._v("Disable")]), _c('v-switch', {
    staticClass: "mt-n2",
    attrs: {
      "label": ("Event " + (_vm.eventDetails.isDisabled === true ? 'Disabled' : 'Enabled')),
      "inset": ""
    },
    model: {
      value: _vm.eventDetails.isDisabled,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "isDisabled", $$v);
      },
      expression: "eventDetails.isDisabled"
    }
  }), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": _vm.updateEvent
    }
  }, [_vm._v(" Save Changes ")])], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Event Date and Time Settings ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "outlined": "",
      "counter": 30,
      "dense": "",
      "label": "Event Name"
    },
    model: {
      value: _vm.eventDetails.eventName,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "eventName", $$v);
      },
      expression: "eventDetails.eventName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.groups,
      "item-text": 'name',
      "item-value": 'id',
      "color": "secondary",
      "label": "Group",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.eventDetails.groupId,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "groupId", $$v);
      },
      expression: "eventDetails.groupId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "ma-5"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.recurrencePatterns,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Recurrence Pattern"
    },
    model: {
      value: _vm.eventDetails.recurrencePattern,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "recurrencePattern", $$v);
      },
      expression: "eventDetails.recurrencePattern"
    }
  }), _vm.eventDetails.recurrencePattern == 'Daily' ? _c('div') : _c('div', [_vm.eventDetails.recurrencePattern == 'Weekly' ? _c('div', [_c('v-select', {
    attrs: {
      "items": _vm.dayOfWeek,
      "item-text": 'name',
      "item-value": 'code',
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Day of Week"
    },
    model: {
      value: _vm.eventDetails.dayOfWeek,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "dayOfWeek", $$v);
      },
      expression: "eventDetails.dayOfWeek"
    }
  })], 1) : _c('div', [_vm.eventDetails.recurrencePattern == 'Monthly' ? _c('div', [_c('v-select', {
    attrs: {
      "items": _vm.dayOfMonth,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Day of Month"
    },
    model: {
      value: _vm.eventDetails.dayOfMonth,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "dayOfMonth", $$v);
      },
      expression: "eventDetails.dayOfMonth"
    }
  })], 1) : _vm._e()])])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "outlined": ""
    }
  }, [_c('p', {
    staticClass: "text-11"
  }, [_vm._v("Set Event (Optional)")]), _c('v-row', [_c('v-spacer'), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "300px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "disabled": !_vm.eventDetails.isEditable,
            "label": "Next Event Date",
            "prepend-icon": "mdi-calendar",
            "readonly": ""
          },
          model: {
            value: _vm.eventDetails.eventDate,
            callback: function ($$v) {
              _vm.$set(_vm.eventDetails, "eventDate", $$v);
            },
            expression: "eventDetails.eventDate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.modal,
      callback: function ($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "scrollable": ""
    },
    model: {
      value: _vm.eventDetails.eventDate,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "eventDate", $$v);
      },
      expression: "eventDetails.eventDate"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.modal = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.modal = false;
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1), _c('v-spacer'), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "300px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "disabled": !_vm.eventDetails.isEditable,
            "label": "Event Time",
            "prepend-icon": "mdi-clock-time-four-outline",
            "readonly": ""
          },
          model: {
            value: _vm.eventDetails.eventTime,
            callback: function ($$v) {
              _vm.$set(_vm.eventDetails, "eventTime", $$v);
            },
            expression: "eventDetails.eventTime"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.modal2,
      callback: function ($$v) {
        _vm.modal2 = $$v;
      },
      expression: "modal2"
    }
  }, [_vm.modal2 ? _c('v-time-picker', {
    attrs: {
      "allowed-hours": [0, 3, 6, 9, 12, 15, 18, 21],
      "allowed-minutes": [0],
      "full-width": ""
    },
    model: {
      value: _vm.eventDetails.eventTime,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "eventTime", $$v);
      },
      expression: "eventDetails.eventTime"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.modal2 = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.modal2 = false;
      }
    }
  }, [_vm._v(" OK ")])], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('p', {
    staticClass: "text-11 mt-n8"
  }, [_vm._v("Disable")]), _c('v-switch', {
    staticClass: "mt-n2",
    attrs: {
      "label": ("Event " + (_vm.eventDetails.isDisabled === true ? 'Disabled' : 'Enabled')),
      "inset": ""
    },
    model: {
      value: _vm.eventDetails.isDisabled,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "isDisabled", $$v);
      },
      expression: "eventDetails.isDisabled"
    }
  }), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": _vm.updateEvent
    }
  }, [_vm._v(" Save Changes ")])], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Icon Color Scheme ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('p', [_c('strong', [_vm._v("Background")])]), _c('v-color-picker', {
    attrs: {
      "dot-size": "25",
      "hide-canvas": "",
      "hide-mode-switch": "",
      "mode": "hexa",
      "swatches-max-height": "100"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('p', [_c('strong', [_vm._v("Foreground")])]), _c('v-color-picker', {
    attrs: {
      "dot-size": "25",
      "hide-canvas": "",
      "hide-mode-switch": "",
      "mode": "hexa",
      "swatches-max-height": "100"
    },
    model: {
      value: _vm.eventDetails.foregroundColor,
      callback: function ($$v) {
        _vm.$set(_vm.eventDetails, "foregroundColor", $$v);
      },
      expression: "eventDetails.foregroundColor"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.eventDetails.eventHistory,
      "sort-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("Registrations")])], 1)];
      },
      proxy: true
    }, {
      key: "item.sentDate",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-chip', [_c('v-icon', {
          staticClass: "icon mr-2"
        }, [_vm._v(" mdi-clock-time-four ")]), _vm._v(" " + _vm._s(_vm.formatDateTime(item.sentDate)) + " ")], 1)];
      }
    }, {
      key: "item.actions",
      fn: function (_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return [_c('div', [_c('router-link', {
          staticStyle: {
            "text-decoration": "none !important"
          },
          attrs: {
            "to": {
              path: '/match-details',
              query: {
                matchId: _vm.eventDetails.eventHistory[index].matchId
              }
            }
          }
        }, [_c('v-btn', {
          attrs: {
            "icon": ""
          }
        }, [_c('v-icon', [_vm._v(" mdi-arrow-right-circle ")])], 1)], 1)], 1)];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "elevation": "0",
            "color": "primary"
          },
          on: {
            "click": _vm.initialize
          }
        }, [_vm._v(" Reset ")])];
      },
      proxy: true
    }])
  })]], 2)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['nestable', ("nestable-" + (_vm.group)), _vm.rtl ? 'nestable-rtl' : '']
  }, [_c('ol', {
    staticClass: "nestable-list nestable-group",
    class: {
      'expandable': _vm.expandable
    }
  }, [_vm.listIsEmpty ? _c('Placeholder', {
    attrs: {
      "options": _vm.itemOptions
    }
  }, [_vm._t("placeholder", function () {
    return [_vm._v(" No content ")];
  })], 2) : _vm._e(), _vm._l(_vm.value, function (item, index) {
    return [_c('v-card', {
      staticClass: "ma-2 pa-2",
      staticStyle: {
        "border-radius": "8px"
      },
      attrs: {
        "outlined": ""
      }
    }, [_c('NestableItem', {
      key: item[_vm.keyProp],
      attrs: {
        "index": index,
        "item": item,
        "options": _vm.itemOptions
      },
      scopedSlots: _vm._u([_vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
        return {
          key: slot,
          fn: function (scope) {
            return [_vm._t(slot, null, null, scope)];
          }
        };
      })], null, true)
    })], 1)];
  })], 2), _vm.dragItem ? [_c('div', {
    staticClass: "nestable-drag-layer"
  }, [_c('ol', {
    staticClass: "nestable-list",
    style: _vm.listStyles
  }, [_c('NestableItem', {
    attrs: {
      "item": _vm.dragItem,
      "options": _vm.itemOptions,
      "is-copy": true
    },
    scopedSlots: _vm._u([_vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
      return {
        key: slot,
        fn: function (scope) {
          return [_vm._t(slot, null, null, scope)];
        }
      };
    })], null, true)
  })], 1)])] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
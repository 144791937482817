<template>
<div>
  <div v-if="this.$store.state.auth.authUser && loggedIn">
<!--logged In state--->
  <v-bottom-navigation
    v-model="value"
    class="d-md-none d-flex mobile-navigation-bottom"
    color="primary"
    fixed
    
  >
    <v-btn to="/dashboard">
      <span class="">Dashboard</span>
      <img v-svg-inline class="icon mr-0" src="@/assets/images/icons/chart-bar.svg" alt="" />
    </v-btn>

    <v-btn to="/view-profile">
      <span class="">Profile</span>
      <img v-svg-inline class="icon mr-0" src="@/assets/images/icons/user_filled.svg" alt="" />
    </v-btn>

    <v-btn to="/manage-subscription">
      <span>Subscription</span>
      <img v-svg-inline class="icon mr-0" src="@/assets/images/icons/credit-card_filled.svg" alt="" />
    </v-btn>

    <v-btn to="/"
      @click="logOut">
      <span>Logout</span>
      <img v-svg-inline class="icon mr-0" src="@/assets/images/icons/logout.svg" alt="" />
    </v-btn>
  </v-bottom-navigation>
  </div>
  <div v-else>
<!--logged Out state--->
  <v-bottom-navigation
    v-model="value2"
    class="d-md-none d-flex mobile-navigation-bottom"
    color="primary"
    fixed
    
  >
    <v-btn to="/">
      <span class="">Home</span>
      <img v-svg-inline class="icon mr-0" src="@/assets/images/images/icons/home-2.svg" alt="" />
    </v-btn>

    <v-btn to="/#features">
      <span>Features</span>

      <img v-svg-inline class="icon mr-0" src="@/assets/images/images/icons/list.svg" alt="" />
    </v-btn>

    <v-btn to="/#faqs">
      <span>Faqs</span>
      <img v-svg-inline class="icon mr-0" src="@/assets/images/images/icons/faqs.svg" alt="" />
    </v-btn>
    <v-btn to="/#pricing">
      <span>Price</span>
      <img v-svg-inline class="icon mr-0" src="@/assets/images/images/icons/dollar-2.svg" alt="" />
    </v-btn>
    <v-btn to="/sign-in">
      <span>Sign-in</span>

      <img v-svg-inline class="icon mr-0" src="@/assets/images/icons/login.svg" alt="" />
    </v-btn>
  </v-bottom-navigation>
  </div>
</div>
</template>

<script>
  export default {
    data: () => ({ 
      value: 0, 
      value2: 0,
      loggedIn: true
    }),
    methods: {
      logOut() {
        this.$store.commit('app/START_LOADING'); 
        this.$store.dispatch('auth/logOut')
        this.$store.commit('app/FINISH_LOADING'); 
        this.loggedIn = !this.loggedIn;
        // this.$router.push({ path: '/' })//.then(refresh-page)
        // push & refresh page
        this.$router.push({ path: '/' })
        // this.$router.go(this.$router.push({ path: '/' }));


        //reset app-bar
        // this.resetAppBar = true;
      },     
    },
    computed: {
    //   color () {
    //     switch (this.value) {
    //       case 0: return 'blue-grey'
    //       case 1: return 'teal'
    //       case 2: return 'brown'
    //       case 3: return 'indigo'
    //       default: return 'blue-grey'
    //     }
    //   },
    },
  }
</script>
<style lang="scss">
    .mobile-navigation-bottom {
        bottom: 0;
        z-index: 100 !important;
    }
</style>

<template>
  <v-overlay :value="isLoading">
    <v-progress-circular indeterminate size="64"> </v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("app", ["isLoading"])
  }
};
</script>

// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "../store";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/base/Index.vue"),
      children: [
        {
          path: "/",
          name: "Home",
          component: () => import("@/views/Landing.vue"),
          meta: {
            hideNavbar: true, /* https://stackoverflow.com/questions/58615754/how-to-hide-global-component-e-g-navbar-on-some-routes */
           }
        },   
        {
          path: "/dashboard",
          name: "Dashboard",
          component: () => import("@/views/Dashboard.vue"),
          meta: {
            hideNavbar: false
           }
        },
        {
          path: "/components",
          name: "Components",
          component: () => import("@/views/home/Index.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/listing-detail",
          name: "ListingDetail",
          component: () => import("@/views/listing/ListingDetail.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/member-directory",
          name: "MemberDirectory",
          component: () => import("@/views/memberDirectory/manageMemberDirectory.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/group-directory",
          name: "GroupDirectory",
          component: () => import("@/views/groups/GroupDirectory.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/manage-group",
          name: "ManageGroup",
          component: () => import("@/views/groups/ManageGroup.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/events",
          name: "Events",
          component: () => import("@/views/events/Events.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/manage-event",
          name: "ManageEvent",
          component: () => import("@/views/events/ManageEvent.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/courses",
          name: "Courses",
          component: () => import("@/views/courses/Courses.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/manage-course",
          name: "ManageCourse",
          component: () => import("@/views/courses/ManageCourse.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/workflows",
          name: "Worfklows",
          component: () => import("@/views/workflows/Workflows.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/manage-workflow",
          name: "ManageWorkflow",
          component: () => import("@/views/workflows/ManageWorkflow.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/schedules",
          name: "Schedules",
          component: () => import("@/views/schedules/Schedules.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/manage-schedule",
          name: "ManageSchedule",
          component: () => import("@/views/schedules/ManageSchedule.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/matches",
          name: "Matches",
          component: () => import("@/views/matches/Matches.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/match-details",
          name: "MatchDetails",
          component: () => import("@/views/matches/MatchDetails.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/edit-list",
          name: "EditList",
          component: () => import("@/views/places/EditAddress.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/manage-billing",
          name: "ManageBilling",
          component: () => import("@/views/payment/ManageBilling.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/manage-paywall",
          name: "ManagePaywall",
          component: () => import("@/views/payment/ManagePaywall.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/merchant-onboarding",
          name: "MerchantOnboarding",
          component: () => import("@/views/payment/MerchantOnboarding.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/manage-transactions",
          name: "ManageTransactions",
          component: () => import("@/views/payment/ManageTransactions.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/view-profile",
          name: "ViewProfile",
          component: () => import("@/views/profile/ViewProfile.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/edit-profile",
          name: "EditProfile",
          component: () => import("@/views/profile/EditProfile.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/summary",
          name: "Summary",
          component: () => import("@/views/analytics/Summary.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/posts",
          name: "Posts",
          component: () => import("@/views/analytics/Posts.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/events",
          name: "Events",
          component: () => import("@/views/analytics/Events.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/courses",
          name: "Courses",
          component: () => import("@/views/analytics/Courses.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/meetups",
          name: "Meetups",
          component: () => import("@/views/analytics/Meetups.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/total-members",
          name: "TotalMembers",
          component: () => import("@/views/analytics/TotalMembers.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/new-members",
          name: "NewMembers",
          component: () => import("@/views/analytics/NewMembers.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/top-members",
          name: "TopMembers",
          component: () => import("@/views/analytics/TopMembers.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/potential-champions",
          name: "PotentialChampions",
          component: () => import("@/views/analytics/PotentialChampions.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/retention",
          name: "Retention",
          component: () => import("@/views/analytics/Retention.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/flight-risk",
          name: "FlightRisk",
          component: () => import("@/views/analytics/FlightRisk.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/analytics/responsiveness",
          name: "Responsiveness",
          component: () => import("@/views/analytics/Responsiveness.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
        {
          path: "/workflows",
          name: "Workflows",
          component: () => import("@/views/workflows/Workflows.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
            }
        },
        {
          path: "/workflow-editor",
          name: "WorkflowEditor",
          component: () => import("@/views/workflows/WorkflowEditor.vue"),
          meta: {
            hideNavbar: false,
            requiresAuth: true
           }
        },
      ]
    },
    {
      path: "/sign-in",
      name: "Sign In",
      component: () => import("@/views/SignIn.vue"),
      meta: {
        hideNavbar: false,
       }
    },    
    {
      path: "/sign-up",
      name: "Sign Up",
      component: () => import("@/views/SignUp.vue"),
      meta: {
        hideNavbar: false,
       }
    },
    {
      path: "/forgot-password",
      name: "Forgot Password",
      component: () => import("@/views/ForgotPassword.vue"),
      meta: {
        hideNavbar: false,
       }
    },
    {
      path: "/manage",
      name: "Manage",
      component: () => import("@/views/Manage.vue"),
      meta: {
        hideNavbar: false,
        requiresAuth: true
      }
    }
  ]
});
// router.beforeEach((to, from, next) => {
  
//   document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`
//   next()
// })
router.beforeEach((to, from, next) => {
  // const user = store.state.authUser; // Add this line
  //get user object from loacal storage
  const user = JSON.parse(localStorage.getItem('user'));
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the user is not logged in
    if (!user) {
      // Redirect to the login page
      next('/');
    } else {
      // Proceed to the route
      next();
    }
  } else {
    // If the route does not require authentication, proceed
    next();
  }

  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`;
});
export default router;

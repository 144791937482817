export default [
{ label: "Tech" },
{ label: "Music" },
{ label: "Travel" },
{ label: "Sports" },
{ label: "Food and Cooking" },
{ label: "Art and Creativity" },
{ label: "Gaming" },
{ label: "Books and Literature" },
{ label: "Fitness and Health" },
{ label: "Movies and TV Shows" },
{ label: "Fashion" },
{ label: "Photography" },
{ label: "Outdoor Activities" },
{ label: "Science" },
{ label: "Nature and Environment" },
{ label: "DIY and Crafts" },
{ label: "History" },
{ label: "Pets" },
{ label: "Cars and Automobiles" },
{ label: "Finance and Investing" },
{ label: "Travel and Adventure" },
{ label: "Parenting and Family" },
{ label: "Hobbies and Collectibles" },
{ label: "Pets and Animal Lovers" },
{ label: "Fashion and Style" },
{ label: "Home Decor and Interior Design" },
{ label: "Health and Wellness" },
{ label: "Fitness and Exercise" },
{ label: "Foodie and Culinary Delights" },
{ label: "Craft Beer and Brewing" },
{ label: "Movies and Film Buffs" },
{ label: "Music and Concert Enthusiasts" },
{ label: "Pop Culture and Entertainment" },
{ label: "Environmental Conservation" },
{ label: "Human Rights and Social Justice" },
{ label: "Entrepreneurship and Startups" },
{ label: "Science Fiction and Fantasy" },
{ label: "Gardening and Horticulture" },
{ label: "Fitness and Yoga" },
{ label: "Digital Marketing and SEO" },
{ label: "Travel Photography" },
{ label: "Virtual Reality and Augmented Reality" },
{ label: "Astronomy and Space Exploration" },
{ label: "Sustainability and Green Living" },
{ label: "Comics and Graphic Novels" },
{ label: "Vintage Cars and Restoration" },
{ label: "Interior Decorating and DIY Home Projects" },
{ label: "Mental Health and Self-Care" },
{ label: "Culinary Arts and Cooking Techniques" },
{ label: "Coding and Programming" },
{ label: "Fine Arts and Painting" },
{ label: "Eco-Friendly Fashion and Ethical Clothing" },
{ label: "Nature Conservation and Wildlife Protection" },
{ label: "Political Analysis and International Relations" },
{ label: "Business and Finance News" },
{ label: "Anime and Manga" },
{ label: "Vintage Collectibles and Antiques" },
{ label: "Alternative and Holistic Medicine" },
{ label: "Rock Climbing and Outdoor Adventure Sports" },
{ label: "Fitness Gadgets and Wearables" }
];
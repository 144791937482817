var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-6"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "secondary",
      "href": "'/wishlist'"
    }
  }, [_vm._v(" Back To Wishlist ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('div', {
    staticClass: "product-slider"
  }, [_c('VueSlickCarousel', {
    ref: "c1",
    attrs: {
      "fade": true,
      "asNavFor": _vm.$refs.c2,
      "focusOnSelect": true
    }
  }, _vm._l(_vm.wishListImages, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('v-img', {
      staticClass: "mx-auto",
      attrs: {
        "width": "auto",
        "height": "auto",
        "src": item.img
      }
    })], 1);
  }), 0), _c('VueSlickCarousel', {
    ref: "c2",
    staticClass: "product-slider-click",
    attrs: {
      "asNavFor": _vm.$refs.c1,
      "slidesToShow": 2,
      "focusOnSelect": true
    }
  }, _vm._l(_vm.wishListImages, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('v-avatar', {
      staticClass: "white rounded mr-3",
      attrs: {
        "tile": "",
        "size": "78"
      }
    }, [_c('img', {
      attrs: {
        "src": item.img,
        "alt": ""
      }
    })])], 1);
  }), 0)], 1)]), _c('v-col', {
    staticClass: "secondary--text",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('h1', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.wishListById[0].trips[0].propertyTitle))]), _c('div', {
    staticClass: "d-flex mb-4"
  }, [_c('p', {
    staticClass: "secondary--text mb-0"
  }, [_vm._v(_vm._s(_vm.wishListById[0].trips[0].propertyDescription))])]), _c('div', {
    staticClass: "mb-4"
  }, [_vm.wishListById[0].trips[0].propertyPrice[1] >= 1000 ? _c('div', [_c('h2', {
    staticClass: "font-weight-bold primary--text mb-2"
  }, [_vm._v(" £" + _vm._s(_vm.wishListById[0].trips[0].propertyPrice[0] / 1000) + "M - £" + _vm._s(_vm.wishListById[0].trips[0].propertyPrice[1] / 1000) + "M")])]) : _c('div', [_c('h2', {
    staticClass: "font-weight-bold primary--text mb-2"
  }, [_vm._v("£" + _vm._s(_vm.wishListById[0].trips[0].propertyPrice[0]) + "K - £" + _vm._s(_vm.wishListById[0].trips[0].propertyPrice[1]) + "K")])])]), _c('div', {
    staticClass: "mb-6"
  }, [_c('v-btn', {
    staticClass: "text-capitalize mb-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.AddToShortList();
      }
    }
  }, [_vm._v(" Add to Shortlist ")]), _c('div', {
    staticClass: "align-center",
    class: _vm.singleProductData.qty <= 0 ? 'd-none' : 'd-flex'
  }, [_c('v-btn', {
    staticClass: "br-8",
    attrs: {
      "color": "primary",
      "outlined": "",
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.removeCartx(_vm.singleProductData);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1), _c('h3', {
    staticClass: "mx-5"
  }, [_vm._v(_vm._s(_vm.singleProductData.qty))]), _c('v-btn', {
    staticClass: "br-8",
    attrs: {
      "color": "primary",
      "outlined": "",
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.addCartx(_vm.singleProductData);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "grey--text me-3"
  }, [_vm._v("As seen on:")]), _c('a', {
    attrs: {
      "href": _vm.wishListById[0].trips[0].url.split('?')[0],
      "target": "_blank"
    }
  }, [_c('p', {
    staticClass: "font-600"
  }, [_vm._v(_vm._s(_vm.wishListById[0].trips[0].portal))])])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "grey--text me-3"
  }, [_vm._v("Date Added:")]), _c('p', {
    staticClass: "font-600 secondary--text"
  }, [_vm._v(_vm._s(_vm.wishListById[0].createdDate))])]), _vm.myPlaces[0].listName != 'Unknown' ? _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "grey--text me-3"
  }, [_vm._v("My Places List:")]), _c('router-link', {
    attrs: {
      "to": ("/edit-list?listId=" + (_vm.wishListById[0].trips[0].locationListId))
    }
  }, [_c('p', {
    staticClass: "font-600"
  }, [_vm._v(_vm._s(_vm.myPlaces[0].listName))])])], 1) : _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "grey--text me-3"
  }, [_vm._v("My Places List:")]), _c('p', {
    staticClass: "font-600 secondary--text"
  }, [_vm._v(_vm._s(_vm.myPlaces[0].listName))])]), _c('v-btn', {
    staticClass: "mb-8",
    attrs: {
      "depressed": "",
      "href": _vm.getlogo.link,
      "bottom": "",
      "target": "_blank"
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "150"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.getlogo.value,
      "alt": ""
    }
  })])], 1)], 1), _c('v-col', {
    staticClass: "mt-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "background-color": "transparent",
      "color": "primary"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    staticClass: "font-600 text-capitalize"
  }, [_vm._v(" Distance ")]), _c('v-tab', {
    staticClass: "font-600 text-capitalize"
  }, [_vm._v(" Routes ")])], 1), _c('v-tabs-items', {
    staticClass: "transparent my-6 pb-6",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('div', [_c('h5', {
    staticClass: "py-3 secondary--text"
  }, [_vm._v("Oneway trips")]), _c('v-row', [_c('div', {
    staticClass: "mb-n5 mt-2"
  }, [_c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('apexchart', {
    attrs: {
      "options": _vm.chartOptions[0],
      "series": _vm.chartData
    }
  })], 1)])]), _c('sub', [_vm._v(" Distance calculated from Current Listing ")])], 1)]), _c('v-row', [_c('v-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('v-divider', {
    staticClass: "mt-3"
  }), _c('h5', {
    staticClass: "py-3 secondary--text"
  }, [_vm._v("Oneway trips within walking distance")]), _c('div', {
    staticClass: "mt-4"
  }, [_vm.onewaytripsWalking.length > 0 ? _c('div', _vm._l(_vm.onewaytripsWalking, function (path, i) {
    return _c('div', {
      key: i
    }, [_c('v-row', {
      staticClass: "ml-1"
    }, [_c('v-icon', {
      staticClass: "mt-n5"
    }, [_vm._v("mdi-walk")]), _c('v-col', [_c('div', {
      staticClass: "font-weight-normal"
    }, [_c('strong', [_vm._v(" Current Listing")]), _c('v-icon', [_vm._v("mdi-map-marker-distance")]), _vm._v(" " + _vm._s(path.name) + " ")], 1), _c('div', {}, [_vm._v(" " + _vm._s(path.distance) + " miles ")])])], 1)], 1);
  }), 0) : _c('div', {
    staticClass: "mb-15 secondary--text"
  }, [_vm._v(" No data available ")])])], 1)])], 1), _c('v-row', [_c('v-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('v-divider', {
    staticClass: "mt-3"
  }), _c('h5', {
    staticClass: "py-3 secondary--text",
    staticStyle: {
      "height": "65px"
    }
  }, [_vm._v(" % Oneway trips under 5 miles")]), _c('base-card', {
    staticStyle: {
      "max-width": "250px"
    }
  }, [_c('div', {
    staticClass: "mt-n4 px-4 py-4 text-center"
  }, [_c('h1', {
    staticClass: "font-600 grey--text text--darken-2 mb-1"
  }, [_vm._v(_vm._s(_vm.onewaytripsUnder5Miles * 100) + "%")]), _c('sub', [_vm._v("Total Trips from Current Listing: " + _vm._s(this.wishListById[0].trips[0].onewaytrips.length))])])])], 1)])], 1)], 1)], 1), _c('v-tab-item', [_c('h5', {
    staticClass: "pt-6 secondary--text",
    staticStyle: {
      "height": "62px"
    }
  }, [_vm._v("Roundtrip Distance")]), _c('v-row', {
    staticClass: "mt-1 mb-5 ml-1"
  }, [_c('v-chip', {
    attrs: {
      "label": "",
      "color": "primary",
      "medium": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.wishListById[0].trips[0].roundtripDistance) + " miles ")])], 1), _c('v-divider', {
    staticClass: "mt-3"
  }), _c('h5', {
    staticClass: "py-3 secondary--text"
  }, [_vm._v("Routes")]), _c('v-window', {
    model: {
      value: _vm.step,
      callback: function ($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-window-item', {
    attrs: {
      "value": 1
    }
  }, [_c('v-card-text', {
    staticClass: "pl-0 pr-0 pb-0 pt-0 mb-5"
  }, [_vm.routeOne.length > 0 ? _c('div', [_c('v-timeline', {
    staticClass: "pa-0",
    attrs: {
      "align-top": "",
      "dense": ""
    }
  }, _vm._l(_vm.routeOne, function (path, idx) {
    return _c('v-timeline-item', {
      key: idx,
      attrs: {
        "small": "",
        "color": "secondary"
      }
    }, [_c('div', {
      staticClass: "font-weight-normal ml-n5"
    }, [_c('strong', [_vm._v(_vm._s(path.From))]), _c('v-icon', [_vm._v("mdi-map-marker-distance")]), _vm._v(" " + _vm._s(path.To) + " ")], 1), _c('div', {
      staticClass: "ml-n5"
    }, [_vm._v(" " + _vm._s(path.Distance) + " miles ")]), _c('div', {
      staticClass: "ml-n7"
    }, [_c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary",
        "text-color": "white",
        "href": ("https://maps.google.com/?saddr=" + (path.FromGps) + "&daddr=" + (path.ToGps) + "&mode=driving"),
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-car ")]), _vm._v(" Driving ")], 1), _c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary",
        "text-color": "white",
        "href": ("https://maps.google.com/?saddr=" + (path.FromGps) + "&daddr=" + (path.ToGps) + "&dirflg=w"),
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-walk ")]), _vm._v(" Walking ")], 1), _c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary",
        "text-color": "white",
        "href": ("https://maps.google.com/?saddr=" + (path.FromGps) + "&daddr=" + (path.ToGps) + "&mode=transit&dirflg=r"),
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-bus ")]), _vm._v(" Public Transport ")], 1), _c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary",
        "text-color": "white",
        "href": ("https://maps.google.com/?saddr=" + (path.FromGps) + "&daddr=" + (path.ToGps) + "&mode=bicycling&dirflg=b"),
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-bike ")]), _vm._v(" Cycling ")], 1)], 1)]);
  }), 1)], 1) : _vm._e()])], 1), _c('v-window-item', {
    attrs: {
      "value": 2
    }
  }, [_c('v-card-text', {
    staticClass: "pl-0 pr-0 pb-0 pt-0 mb-5"
  }, [_vm.routeTwo.length > 0 ? _c('div', [_c('v-timeline', {
    staticClass: "pa-0",
    attrs: {
      "align-top": "",
      "dense": ""
    }
  }, _vm._l(_vm.routeTwo, function (path, idx) {
    return _c('v-timeline-item', {
      key: idx,
      attrs: {
        "small": "",
        "color": "secondary"
      }
    }, [_c('div', {
      staticClass: "font-weight-normal ml-n5"
    }, [_c('strong', [_vm._v(_vm._s(path.From))]), _c('v-icon', [_vm._v("mdi-map-marker-distance")]), _vm._v(" " + _vm._s(path.To) + " ")], 1), _c('div', {
      staticClass: "ml-n5"
    }, [_vm._v(" " + _vm._s(path.Distance) + " miles ")]), _c('div', {
      staticClass: "ml-n7"
    }, [_c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary",
        "text-color": "white",
        "href": ("https://maps.google.com/?saddr=" + (path.FromGps) + "&daddr=" + (path.ToGps) + "&mode=driving"),
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-car ")]), _vm._v(" Driving ")], 1), _c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary",
        "text-color": "white",
        "href": ("https://maps.google.com/?saddr=" + (path.FromGps) + "&daddr=" + (path.ToGps) + "&dirflg=w"),
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-walk ")]), _vm._v(" Walking ")], 1), _c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary",
        "text-color": "white",
        "href": ("https://maps.google.com/?saddr=" + (path.FromGps) + "&daddr=" + (path.ToGps) + "&mode=transit&dirflg=r"),
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-bus ")]), _vm._v(" Public Transport ")], 1), _c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "color": "primary",
        "text-color": "white",
        "href": ("https://maps.google.com/?saddr=" + (path.FromGps) + "&daddr=" + (path.ToGps) + "&mode=bicycling&dirflg=b"),
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-bike ")]), _vm._v(" Cycling ")], 1)], 1)]);
  }), 1)], 1) : _vm._e()])], 1)], 1), _c('v-btn-toggle', {
    attrs: {
      "tile": "",
      "color": "secondary",
      "group": "",
      "mandatory": ""
    },
    model: {
      value: _vm.toggle_one,
      callback: function ($$v) {
        _vm.toggle_one = $$v;
      },
      expression: "toggle_one"
    }
  }, [_c('v-btn', {
    on: {
      "click": function ($event) {
        _vm.step--;
      }
    }
  }, [_vm._v(" Route 1 ")]), _c('v-btn', {
    on: {
      "click": function ($event) {
        _vm.step++;
      }
    }
  }, [_vm._v(" Route 2 ")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "container"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-space-between mb-2"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-2",
    attrs: {
      "src": require("@/assets/images/icons/clock-time-four-outline.svg"),
      "alt": ""
    }
  }), _c('h3', {
    staticClass: "font-weight-bold secondary--text"
  }, [_vm._v("Recent History (" + _vm._s(_vm.recentHistory.length) + ")")])])])]), _c('v-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {}, [_c('VueSlickCarousel', _vm._b({
    staticClass: "slick-secondary",
    attrs: {
      "arrows": true,
      "dots": false
    },
    scopedSlots: _vm._u([{
      key: "prevArrow",
      fn: function (arrowOption) {
        return [_c('v-btn', {
          staticClass: "mx-2 slick-arrow prev slick-prev",
          attrs: {
            "fab": "",
            "dark": "",
            "small": "",
            "color": "secondary"
          }
        }, [_c('v-icon', {
          attrs: {
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(arrowOption) + " mdi-arrow-left ")])], 1)];
      }
    }, {
      key: "nextArrow",
      fn: function (arrowOption) {
        return [_c('v-btn', {
          staticClass: "mx-2 slick-arrow next slick-next",
          attrs: {
            "fab": "",
            "dark": "",
            "small": "",
            "color": "secondary"
          }
        }, [_c('v-icon', {
          attrs: {
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(arrowOption) + " mdi-arrow-right ")])], 1)];
      }
    }])
  }, 'VueSlickCarousel', _vm.slickSettings, false), _vm._l(_vm.recentHistory, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('CardListingDetail', {
      staticClass: "m-5",
      attrs: {
        "content-img": item.trips[0].propertyImageUrl,
        "content-text": item.trips[0].propertyTitle,
        "counter": item.trips[0].roundtripDistance,
        "amount": item.trips[0].propertyPrice,
        "nearest-place": item.trips[0].nearestPlace,
        "portal": item.trips[0].portal,
        "tripId": item.id,
        "dateAdded": item.createdDate
      },
      on: {
        "cartRemove": function ($event) {
          return _vm.removeCart(item);
        },
        "cartAdd": function ($event) {
          return _vm.addCart(item);
        }
      }
    })], 1);
  }), 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "container"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-space-between mb-2"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-2",
    attrs: {
      "src": require("@/assets/images/icons/home-clock.svg"),
      "alt": ""
    }
  }), _c('h3', {
    staticClass: "font-weight-bold secondary--text"
  }, [_vm._v("Shortest Roundtrips (" + _vm._s(_vm.shortestRoundtrips.length) + ")")])])])]), _c('v-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {}, [_c('VueSlickCarousel', _vm._b({
    staticClass: "slick-secondary",
    attrs: {
      "arrows": true,
      "dots": false
    },
    scopedSlots: _vm._u([{
      key: "prevArrow",
      fn: function (arrowOption) {
        return [_c('v-btn', {
          staticClass: "mx-2 slick-arrow prev slick-prev",
          attrs: {
            "fab": "",
            "dark": "",
            "small": "",
            "color": "secondary"
          }
        }, [_c('v-icon', {
          attrs: {
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(arrowOption) + " mdi-arrow-left ")])], 1)];
      }
    }, {
      key: "nextArrow",
      fn: function (arrowOption) {
        return [_c('v-btn', {
          staticClass: "mx-2 slick-arrow next slick-next",
          attrs: {
            "fab": "",
            "dark": "",
            "small": "",
            "color": "secondary"
          }
        }, [_c('v-icon', {
          attrs: {
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(arrowOption) + " mdi-arrow-right ")])], 1)];
      }
    }])
  }, 'VueSlickCarousel', _vm.slickSettings, false), _vm._l(_vm.shortestRoundtrips, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('CardListingDetail', {
      staticClass: "m-5",
      attrs: {
        "content-img": item.trips[0].propertyImageUrl,
        "content-text": item.trips[0].propertyTitle,
        "counter": item.trips[0].roundtripDistance,
        "amount": item.trips[0].propertyPrice,
        "nearest-place": item.trips[0].nearestPlace,
        "portal": item.trips[0].portal,
        "tripId": item.id,
        "dateAdded": item.createdDate
      },
      on: {
        "cartRemove": function ($event) {
          return _vm.removeCart(item);
        },
        "cartAdd": function ($event) {
          return _vm.addCart(item);
        }
      }
    })], 1);
  }), 1)], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [this.$store.state.auth.authUser && _vm.loggedIn ? _c('div', [_c('v-bottom-navigation', {
    staticClass: "d-md-none d-flex mobile-navigation-bottom",
    attrs: {
      "color": "primary",
      "fixed": ""
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": "/dashboard"
    }
  }, [_c('span', {}, [_vm._v("Dashboard")]), _c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/icons/chart-bar.svg"),
      "alt": ""
    }
  })]), _c('v-btn', {
    attrs: {
      "to": "/view-profile"
    }
  }, [_c('span', {}, [_vm._v("Profile")]), _c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/icons/user_filled.svg"),
      "alt": ""
    }
  })]), _c('v-btn', {
    attrs: {
      "to": "/manage-subscription"
    }
  }, [_c('span', [_vm._v("Subscription")]), _c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/icons/credit-card_filled.svg"),
      "alt": ""
    }
  })]), _c('v-btn', {
    attrs: {
      "to": "/"
    },
    on: {
      "click": _vm.logOut
    }
  }, [_c('span', [_vm._v("Logout")]), _c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/icons/logout.svg"),
      "alt": ""
    }
  })])], 1)], 1) : _c('div', [_c('v-bottom-navigation', {
    staticClass: "d-md-none d-flex mobile-navigation-bottom",
    attrs: {
      "color": "primary",
      "fixed": ""
    },
    model: {
      value: _vm.value2,
      callback: function ($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": "/"
    }
  }, [_c('span', {}, [_vm._v("Home")]), _c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/images/icons/home-2.svg"),
      "alt": ""
    }
  })]), _c('v-btn', {
    attrs: {
      "to": "/#features"
    }
  }, [_c('span', [_vm._v("Features")]), _c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/images/icons/list.svg"),
      "alt": ""
    }
  })]), _c('v-btn', {
    attrs: {
      "to": "/#faqs"
    }
  }, [_c('span', [_vm._v("Faqs")]), _c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/images/icons/faqs.svg"),
      "alt": ""
    }
  })]), _c('v-btn', {
    attrs: {
      "to": "/#pricing"
    }
  }, [_c('span', [_vm._v("Price")]), _c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/images/icons/dollar-2.svg"),
      "alt": ""
    }
  })]), _c('v-btn', {
    attrs: {
      "to": "/sign-in"
    }
  }, [_c('span', [_vm._v("Sign-in")]), _c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/icons/login.svg"),
      "alt": ""
    }
  })])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCVXXZtrBQy4kES_P12qZAdkhPGgGM6qEA",
  authDomain: "cheqqmate.firebaseapp.com",
  projectId: "cheqqmate",
  storageBucket: "cheqqmate.appspot.com",
  messagingSenderId: "1089146615750",
  appId: "1:1089146615750:web:adad8a6764a202314024c7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
const auth = getAuth();

export default {
  namespaced: true,
  state: {
    authUser: null,
    authenticationErrors: null
  },

  actions: {
    // async login({ commit }, email) {
    //   await m.auth.loginWithMagicLink(email);
    //   const data = await m.user.getMetadata();
    //   commit('SET_USER_DATA', data);
    // },

     login({ commit }, user) {
      signInWithEmailAndPassword(auth, user.email, user.password)
        .then((userCredential) => {
          // Signed in 
          commit('SET_USER_DATA', userCredential.user);
          return userCredential.user;
        })
        .catch((error) => {
          //https://stackoverflow.com/questions/39152004/where-can-i-find-a-list-of-all-error-codes-and-messages-for-firebase-authenticat
          var errorMessage = null
            switch (error) {
              case "auth/invalid-email":
                errorMessage = "Your email address appears to be malformed.";
                break;
              case "auth/invalid-password":
                errorMessage = "Your password is wrong.";
                break;
              case "auth/user-not-found":
                errorMessage = "User with this email doesn't exist.";
                break;
              case "auth/user-disabled":
                errorMessage = "User with this email has been disabled.";
                break;
              case "auth/email-already-in-use":
                errorMessage = "Email already used. Go to login page.";
                break;
              case "auth/wrong-password":
                errorMessage = "Wrong email/password combination.";
                break;
              case "auth/user-not-found":
                errorMessage = "No user found with this email.";
                break;
              case "auth/too-many-requests":
                errorMessage = "Too many requests to log into this account.";
                break;
              case "auth/invalid-email":
                errorMessage = "Email address is invalid.";
                break;
              case "auth/email-not-found":
                errorMessage = "Email address not found please try again.";
                break;
              case "auth/user-mismatch":
                errorMessage = "The supplied credentials do not correspond to the previously signed in user.";
                break; 
              default:
                errorMessage = "Login failed. Please try again.";
                break;                
            }
            commit('SET_AUTH_ERROR_DATA', errorMessage);
          })
    },
    logOut({ commit }) {
      signOut(auth).then(() => {
        commit('CLEAR_USER_DATA');
      })
    }
  },
  getters : {
    getAuthenticationErrors : state => state.authenticationErrors,
    getAuthUser: state => state.authUser,
},  
  mutations: {
    SET_USER_DATA(state, userData) {
      state.authUser = userData
      localStorage.setItem('user', JSON.stringify(userData)); // do I want to do this?
      /* We are also using localStorage to store our user object, 
      which we will use later in this guide to auto-login our users. */
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem('user');
      // localStorage.reload();
    },
    SET_AUTH_ERROR_DATA(state, errorMessage) {

      // String getMessageFromErrorCode() {
      //   switch (this.errorCode) {
      //     case "ERROR_EMAIL_ALREADY_IN_USE":
      //     case "account-exists-with-different-credential":
      //     case "email-already-in-use":
      //       errorMessage = "Email already used. Go to login page.";
      //       break;
      //     case "ERROR_WRONG_PASSWORD":
      //     case "wrong-password":
      //       errorMessage = "Wrong email/password combination.";
      //       break;
      //     case "ERROR_USER_NOT_FOUND":
      //     case "user-not-found":
      //       errorMessage = "No user found with this email.";
      //       break;
      //     case "ERROR_USER_DISABLED":
      //     case "user-disabled":
      //       errorMessage = "User disabled.";
      //       break;
      //     case "ERROR_TOO_MANY_REQUESTS":
      //     case "operation-not-allowed":
      //       errorMessage = "Too many requests to log into this account.";
      //       break;
      //     case "ERROR_OPERATION_NOT_ALLOWED":
      //     case "operation-not-allowed":
      //       errorMessage = "Server error, please try again later.";
      //       break;
      //     case "ERROR_INVALID_EMAIL":
      //     case "invalid-email":
      //       errorMessage = "Email address is invalid.";
      //       break;
      //     default:
      //       errorMessage = "Login failed. Please try again.";
      //       break;
      //   }
      // }

      state.authenticationErrors = errorMessage;
    },
  }
};

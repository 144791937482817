var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('div', {
    staticClass: "bg-body d-flex flex-column justify-center align-center min-vh-100"
  }, [_c('v-snackbar', {
    staticClass: "snackbar",
    attrs: {
      "color": "primary",
      "elevation": "16"
    },
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]), _c('div', {
    staticClass: "sign-up-card"
  }, [_c('v-snackbar', {
    attrs: {
      "timeout": 5000,
      "value": _vm.getAuthenticationErrors,
      "absolute": "",
      "top": "",
      "color": "red darken-2",
      "left": "",
      "text": ""
    }
  }, [_c('strong', [_vm._v("Login Failed: ")]), _vm._v(_vm._s(_vm.getAuthenticationErrors) + " ")]), _c('validation-observer', {
    ref: "obs",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid,
          validated = _ref.validated,
          handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "sign-up-card-container"
        }, [_c('div', {
          staticClass: "text-center mb-10"
        }, [_c('h3', {
          staticClass: "mb-3"
        }, [_vm._v("Create Your Account")]), _c('h5', {
          staticClass: "text-sm font-600 grey--text text--darken-4"
        }, [_vm._v("Please provide your details")])]), _c('div', {
          staticClass: "mb-1"
        }, [_c('v-text-field-with-validation', {
          attrs: {
            "rules": "required",
            "label": "Username",
            "placeholder": "John_S"
          },
          model: {
            value: _vm.user.username,
            callback: function ($$v) {
              _vm.$set(_vm.user, "username", $$v);
            },
            expression: "user.username"
          }
        })], 1), _c('div', {
          staticClass: "mb-1"
        }, [_c('v-text-field-without-spaces', {
          attrs: {
            "label": "Community",
            "placeholder": "",
            "hint": "No spaces allowed"
          },
          model: {
            value: _vm.user.community,
            callback: function ($$v) {
              _vm.$set(_vm.user, "community", $$v);
            },
            expression: "user.community"
          }
        })], 1), _c('div', {
          staticClass: "mb-1"
        }, [_c('v-text-field-with-validation', {
          attrs: {
            "rules": "required|email",
            "label": "Email",
            "placeholder": "example@gmail.com"
          },
          model: {
            value: _vm.user.email,
            callback: function ($$v) {
              _vm.$set(_vm.user, "email", $$v);
            },
            expression: "user.email"
          }
        })], 1), _c('div', {
          staticClass: "mb-1"
        }, [_c('v-text-field-password-with-validation', {
          attrs: {
            "rules": "required",
            "label": "Password",
            "placeholder": "**********"
          },
          model: {
            value: _vm.user.password,
            callback: function ($$v) {
              _vm.$set(_vm.user, "password", $$v);
            },
            expression: "user.password"
          }
        })], 1), _c('div', {
          staticClass: "mb-4"
        }, [_c('v-checkbox', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('div', [_vm._v(" By signing up, you agree to "), _c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function (_ref2) {
                    var on = _ref2.on;
                    return [_c('a', _vm._g({
                      attrs: {
                        "target": "_blank",
                        "href": "#"
                      },
                      on: {
                        "click": function ($event) {
                          $event.stopPropagation();
                        }
                      }
                    }, on), [_vm._v(" Terms & Condtion ")])];
                  }
                }], null, true)
              }, [_vm._v(" Opens in new window ")])], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.checkbox,
            callback: function ($$v) {
              _vm.checkbox = $$v;
            },
            expression: "checkbox"
          }
        })], 1), _c('div', {
          staticClass: "mb-4 mt-n4"
        }, [_c('v-btn', {
          staticClass: "text-capitalize font-600",
          attrs: {
            "block": "",
            "elevation": "0",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return handleSubmit(_vm.userRegistration);
            }
          }
        }, [_vm._v(" Create Account ")])], 1)])];
      }
    }])
  }), _c('div', {
    staticClass: "py-4 grey lighten-2"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "text-14 text-center my-3"
  }, [_vm._v("Already Have Account? "), _c('router-link', {
    staticClass: "grey--text text--darken-4 font-600",
    attrs: {
      "to": "/sign-in"
    }
  }, [_vm._v("Sign In")])], 1)])])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
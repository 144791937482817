<template>
  <div class="header-landing">
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              v-svg-inline
              src="@/assets/images/landing/CheqqMateLogo.svg"
              alt=""
            />
          </v-list-item-avatar>
          <!-- <v-list-item-content>
            <v-list-item-title class="title">Calango</v-list-item-title>
            <v-list-item-subtitle>WEB</v-list-item-subtitle>
          </v-list-item-content> -->
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-container>
      <v-app-bar
        app
        :flat="flat"
        elevation="0"
        class="px-15 header-landing-appbar"
        :class="{ expand: flat }"
      >
        <v-toolbar-title>
          <v-img
            class="cursor-pointer"
            @click="$vuetify.goTo('#home')"
            src="@/assets/CheqqMateforCreators.Logo.png"
            max-width="300"
            max-height="50"
          />
        </v-toolbar-title>
        <v-spacer />
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="mr-4"
          v-if="isXs"
        />
        <div v-else>
          <v-btn text @click="$vuetify.goTo('#features')">
            <span class="mr-2 text-capitalize grey--text text--darken-3"
              >Features</span
            >
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#faqs')">
            <span class="mr-2 text-capitalize grey--text text--darken-3"
              >FAQs</span
            >
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#pricing')">
            <span class="mr-2 text-capitalize grey--text text--darken-3"
              >Pricing</span
            >
          </v-btn>
          <!-- <v-btn
            class="mx-2"
            outlined
            color="primary"
            href="https://store.vuetifyjs.com/products/bonik-multipurpose-ecommerce-template"
          >
            <span class="font-600 text-capitalize">Login</span>
          </v-btn> -->
            <span v-if="!this.$store.state.auth.authUser">            
              <v-btn
                href="/sign-in"
                outlined
                color="primary"
                class="mx-2 font-600 text-capitalize"
              >
              Sign In
              </v-btn>
            </span>
            <span v-else>
              <router-link to="/view-profile">
                <v-avatar
                  size="40"
                  class="mx-2"
                >
                <v-img :src="this.$store.state.user.userProfile.avatar"></v-img>
                </v-avatar>
              </router-link>              
              <v-btn
              outlined
              color="primary"
              class="mx-2 font-600 text-capitalize"
              @click="logOut"
            >
              Sign Out
            </v-btn>
            </span>
        </div>
      </v-app-bar>

      <section id="home" class="p-relative pt-100">
        <v-row class="text-center">
          <v-col cols="12">
            <v-img
              max-width="500"
              class="mx-auto"
              contain
              src="@/assets/images/landing/JoinCheqqMateforCreators.svg"
              alt=""
            ></v-img>
            <h4 class="font-600 primary--text mb-4 mt-4">Spark meaningful connections with automated pairings throughout your community.</h4>
            <h2 class="font-weight-bold secondary--text mb-3">
              <!-- Credible Alone. Incredible Together. -->
              Ready to create a community around your
              <span style="font-size: 1em;" class="primary--text font-weight-bold text-animation">{{ animatedText }}</span>
            </h2>
            <div class="d-flex justify-center landing-button-wrapper mt-5">
              <div v-if="!this.$store.state.auth.authUser">
                <v-btn
                  href="/sign-in"
                  outlined
                  color="primary"
                  class="font-600 text-capitalize mr-4"
                >
                  Login
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  href="/dashboard"
                  outlined
                  color="primary"
                  class="font-600 text-capitalize mr-4"
              >
                Go to Dashboard
              </v-btn>
              </div>
            </div>
          </v-col>
          <!--AS-IS: Bonik app screenshots; TO-BE: CheqqMate app screenshot-->
          <v-col cols="12" class="pb-0">
            <v-img
              max-width="1200"
              class="mx-auto"
              contain
              src="@/assets/images/landing/cheqq-mate-hero-image.svg"
            ></v-img>
          </v-col>
          <div class="p-absolute landing-figma-button">
            <v-btn
                  href="/"
                  elevation="4"
                  x-large
                  color="primary"
                  class="font-600 text-capitalize mr-4"
                >
                  Get the App
                </v-btn>
            <!-- <base-card class="secondary--text font-weight-bold pt-4 pb-4 pl-12 pr-12">
              <span class="mb-4">
                <strong>Get the App</strong>
                <br />
              </span>
            </base-card> -->
              <!-- <v-avatar size="48">
                <img
                  v-svg-inline
                  class="icon"
                  src="@/assets/images/icons/landing/cheqq_mate_logoV2.svg"
                />
              </v-avatar> -->
              <!-- <v-avatar class="ml-n1 mr-2">
                <img
                  v-svg-inline
                  class="icon"
                  src="@/assets/images/icons/landing/outlook.svg"
                  alt="Outlook"
                />        
              </v-avatar>
              <img
                  v-svg-inline
                  class="icon"
                  src="@/assets/images/icons/landing/Gmail.svg"
                  alt="Gmail"
                />     -->
          </div>
        </v-row>
      </section>
    </v-container>
  </div>
</template>
<script>

export default {
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["Features", "#features"],
      ["FAQs", "#faqs"],
      ["Pricing", "#pricing"],
    ],
    texts: ['Product?', 'Service?', 'Hobby?', 'Passion?'],
    animatedText: '',
    currentTextIndex: 0,
    currentIndex: 0,
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    logOut () {
        this.$store.commit('app/START_LOADING')
        this.$store.dispatch('auth/logOut')
        this.$store.commit('app/FINISH_LOADING')
        // this.$router.push({ path: '/' })//.then(refresh-page)
        // push & refresh page
        this.$router.push({ path: '/' })
      // this.$router.go(this.$router.push({ path: '/' }));

      // reset app-bar
      // this.resetAppBar = true;
      },
    typeWriter() {
      if (this.currentIndex < this.texts[this.currentTextIndex].length) {
        this.animatedText += this.texts[this.currentTextIndex].charAt(this.currentIndex);
        this.currentIndex++;
        setTimeout(this.typeWriter, 100); // adjust the typing speed here
      } else {
        // Move to the next text and start over after a delay
        setTimeout(() => {
          this.animatedText = '';
          this.currentIndex = 0;
          this.currentTextIndex = (this.currentTextIndex + 1) % this.texts.length;
          this.typeWriter();
        }, 2000); // adjust the delay here
      }
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.typeWriter();
  },
};
</script>
<style lang="scss" scoped>
.v-toolbar {
  transition: 0.6s;
}
.pt-100 {
  padding-top: 100px;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
.header-landing {
  background-image: url("../../assets/images/landing/landing-bg-1.svg");
  background-size: cover;
  margin-bottom: 100px;
}
.landing-button-wrapper {
  margin-bottom: 100px;
}
.landing-figma-button {
  left: 50%;
  transform: translateX(-50%);
  bottom: -25px;
}
.header-landing-appbar {
  background-color: transparent !important;
  &.v-app-bar--is-scrolled {
    background-color: #fff !important;
    box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px !important;
  }
}

.text-animation {
  border-right: 0.15em solid;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 6s steps(40, end), blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #d53e59 }
}
</style>

<template>
  <v-container class="my-6">
    <div class="d-flex justify-space-between flex-wrap mb-5">
        <v-spacer></v-spacer>
        <v-btn 
            outlined 
            color="secondary" 
            class="text-capitalize font-600"
            href="'/wishlist'"
        >
            Back To Wishlist
        </v-btn>
    </div>
      <v-row>
        <!-- {{getShortList}} -->
         <v-col cols="12" md="6" lg="6" xl="6">
          <div class="product-slider">
           
            <VueSlickCarousel
              ref="c1"
              :fade="true"
              :asNavFor="$refs.c2"
              :focusOnSelect="true">
                <div v-for="(item, index) in wishListImages" :key="index">
                   <v-img class="mx-auto" width="auto" height="auto" :src="item.img"></v-img>
                </div>
              
            </VueSlickCarousel>
            <VueSlickCarousel
              ref="c2"
              class="product-slider-click"
              :asNavFor="$refs.c1"
              :slidesToShow="2"
              :focusOnSelect="true">
              <div v-for="(item, index) in wishListImages" :key="index">
                <v-avatar 
                    class="white rounded mr-3" 
                    tile 
                    size="78"
                >
                    <img :src="item.img" alt="">
                </v-avatar>
              </div>
            </VueSlickCarousel>
          </div>
         </v-col>
          <v-col cols="12" lg="6" class="secondary--text">
              <h1 class="mb-4">{{wishListById[0].trips[0].propertyTitle}}</h1>
              <div class="d-flex mb-4">
                  <!-- <p class="grey--text text--grey-darken-1 me-3 mb-0">Description:</p> -->
                  <p class="secondary--text mb-0">{{wishListById[0].trips[0].propertyDescription}}</p>
              </div>
              <div class="mb-4">
                  <!-- <h2 class="primary--text">{{wishListById[0].trips[0].propertyPrice}}</h2> -->
                <div v-if="wishListById[0].trips[0].propertyPrice[1] >=1000">
                    <h2 class="font-weight-bold primary--text mb-2"> £{{wishListById[0].trips[0].propertyPrice[0]/1000}}M - £{{wishListById[0].trips[0].propertyPrice[1]/1000}}M</h2>
                </div>
                <div v-else>
                    <h2 class="font-weight-bold primary--text mb-2">£{{wishListById[0].trips[0].propertyPrice[0]}}K - £{{wishListById[0].trips[0].propertyPrice[1]}}K</h2>
                </div>                    
                  <!-- <p class="">Stock Available</p> -->
              </div>
              <div class="mb-6">
                    <v-btn 
                        @click="AddToShortList()"
                        color="primary" 
                        class="text-capitalize mb-3"
                    >
                        Add to Shortlist 
                    </v-btn>
                    <div 
                        class="align-center"
                        :class="singleProductData.qty <= 0 ? 'd-none': 'd-flex'"
                    >
                        <v-btn 
                            color="primary" 
                            outlined
                            icon
                            tile
                            class="br-8"
                            @click="removeCartx(singleProductData)"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <h3 class="mx-5">{{singleProductData.qty}}</h3>
                        <v-btn 
                            color="primary" 
                            outlined
                            icon
                            tile
                            class="br-8"
                            @click="addCartx(singleProductData)"
                             
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>
              </div>
              <div class="d-flex">
                  <p class="grey--text me-3">As seen on:</p>
                  <a :href="wishListById[0].trips[0].url.split('?')[0]" target="_blank">
                    <p class="font-600">{{wishListById[0].trips[0].portal}}</p>
                  </a>
              </div>
              <div class="d-flex">
                <p class="grey--text me-3">Date Added:</p>
                <p class="font-600 secondary--text">{{wishListById[0].createdDate}}</p>
              </div>
              <div v-if="myPlaces[0].listName != 'Unknown'" class="d-flex">
                <p class="grey--text me-3">My Places List:</p>
                <router-link :to="`/edit-list?listId=${wishListById[0].trips[0].locationListId}`">
                    <p class="font-600">{{myPlaces[0].listName}}</p>
                </router-link>
              </div>
              <div v-else class="d-flex">
                <p class="grey--text me-3">My Places List:</p>
                    <p class="font-600 secondary--text">{{myPlaces[0].listName}}</p>
              </div>
              <v-btn class="mb-8"
                depressed
                :href="getlogo.link"
                bottom
                target="_blank"
              >
                <v-avatar class="" size="150">
                    <img :src="getlogo.value" alt=""> 
                </v-avatar>
              </v-btn>              
          </v-col>
          <v-col cols="12" class="mt-12">
            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="primary"
                class=""
            >
                <v-tab class="font-600 text-capitalize">
                    Distance
                </v-tab>
                <v-tab class="font-600 text-capitalize">
                    Routes
                </v-tab>
            </v-tabs>
            <v-tabs-items class="transparent my-6 pb-6"  v-model="tab">
            <!--Distance tab 1-->
                <v-tab-item>
                    <v-col cols="12">
                        <!--Content-->
                        <v-row>
                            <!-- <v-col cols="12" class="px-0"> -->
                                <!--Oneway trips-->
                                <div>
                                    <h5 class="py-3 secondary--text" >Oneway trips</h5>
                                    <v-row>
                                        <div class=" mb-n5 mt-2">
                                            <div id="chart">
                                                <apexchart :options="chartOptions[0]" :series="chartData"></apexchart>
                                            </div>
                                        </div>            
                                    </v-row>                                         
                                    <sub>
                                        Distance calculated from Current Listing
                                    </sub>
                                </div>
                            <!-- </v-col> -->
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="px-0">
                                <!--WalkingDistance-->
                                <div>
                                    <v-divider class="mt-3"></v-divider>                            
                                    <h5 class="py-3 secondary--text" >Oneway trips within walking distance</h5> 
                                    <div class="mt-4">
                                        <div v-if="onewaytripsWalking.length > 0">
                                            <div
                                                v-for="(path, i) in onewaytripsWalking"
                                                :key="i"
                                            >
                                                <v-row class="ml-1">
                                                    <v-icon class="mt-n5">mdi-walk</v-icon> 
                                                    <v-col>
                                                    <div class="font-weight-normal">
                                                        <strong> Current Listing</strong>
                                                        <v-icon>mdi-map-marker-distance</v-icon> 
                                                        {{ path.name }}
                                                    </div>
                                                    <div class="">
                                                        {{ path.distance}} miles
                                                    </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                        <div v-else class="mb-15 secondary--text">
                                            No data available
                                        </div>
                                    </div>
                                    <!-- <sub>
                                        Based on oneway trips
                                    </sub> -->
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="px-0">
                                <!--TripsUnder5miles-->
                                <div>
                                    <v-divider class="mt-3"></v-divider>                            
                                    <h5 class="py-3 secondary--text" style="height: 65px;"> % Oneway trips under 5 miles</h5>
                                        <base-card style="max-width: 250px;">
                                            <div class="mt-n4 px-4 py-4 text-center">
                                                <h1 class="font-600 grey--text text--darken-2 mb-1">{{onewaytripsUnder5Miles * 100}}%</h1>
                                                <sub>Total Trips from Current Listing: {{this.wishListById[0].trips[0].onewaytrips.length}}</sub>
                                            </div>
                                        </base-card>
                                    <!-- <sub>
                                        Based on oneway trips
                                    </sub> -->
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>
            <!--Routes tab 2-->
                <v-tab-item>
                <!--Roundtrip Distance Start-->
                    <h5 class="pt-6 secondary--text" style="height: 62px;">Roundtrip Distance</h5>
                    <v-row
                        class="mt-1 mb-5 ml-1"
                    >
                        <v-chip
                            label
                            color="primary"
                            medium>
                            {{wishListById[0].trips[0].roundtripDistance}} miles
                        </v-chip>
                    </v-row>
                <!--Roundtrip Distance End-->
                <!--Route Start-->
                    <v-divider class="mt-3"></v-divider>
                    <h5 class="py-3 secondary--text">Routes</h5>
                    <v-window v-model="step">
                        <v-window-item :value="1">
                            <v-card-text class="pl-0 pr-0 pb-0 pt-0 mb-5">
                                <div v-if="routeOne.length > 0">
                                    <v-timeline
                                        align-top
                                        dense
                                        class="pa-0"
                                    >
                                        <v-timeline-item
                                            v-for="(path, idx) in routeOne"
                                            :key="idx"
                                            small
                                            color="secondary"
                                        >
                                            <div class="font-weight-normal ml-n5">
                                                <strong>{{ path.From }}</strong>
                                                <v-icon>mdi-map-marker-distance</v-icon> 
                                                {{ path.To }}
                                            </div>
                                            <div class="ml-n5">
                                                {{ path.Distance }} miles
                                            </div>
                                            <div class="ml-n7">
                                                <v-chip
                                                class="ma-2"
                                                label
                                                color="primary"
                                                text-color="white"
                                                :href="`https://maps.google.com/?saddr=${path.FromGps}&daddr=${path.ToGps}&mode=driving`"
                                                target="_blank"
                                                >
                                                <v-icon left>
                                                    mdi-car
                                                </v-icon>
                                                Driving
                                                </v-chip>
                                                <v-chip
                                                class="ma-2"
                                                label
                                                color="primary"
                                                text-color="white"
                                                :href="`https://maps.google.com/?saddr=${path.FromGps}&daddr=${path.ToGps}&dirflg=w`"
                                                target="_blank"
                                                >
                                                <v-icon left>
                                                    mdi-walk
                                                </v-icon>
                                                Walking
                                                </v-chip>
                                                <v-chip
                                                class="ma-2"
                                                label
                                                color="primary"
                                                text-color="white"
                                                :href="`https://maps.google.com/?saddr=${path.FromGps}&daddr=${path.ToGps}&mode=transit&dirflg=r`"
                                                target="_blank"                                                
                                                >
                                                <v-icon left>
                                                    mdi-bus
                                                </v-icon>
                                                Public Transport
                                                </v-chip>
                                                <v-chip
                                                class="ma-2"
                                                label
                                                color="primary"
                                                text-color="white"
                                                :href="`https://maps.google.com/?saddr=${path.FromGps}&daddr=${path.ToGps}&mode=bicycling&dirflg=b`"
                                                target="_blank"                                                
                                                >
                                                <v-icon left>
                                                    mdi-bike
                                                </v-icon>
                                                Cycling
                                                </v-chip>
                                            </div>
                                        </v-timeline-item>
                                    </v-timeline>
                                </div>            
                            </v-card-text>
                        </v-window-item>
                        <v-window-item :value="2">
                            <v-card-text class="pl-0 pr-0 pb-0 pt-0 mb-5">
                                <div v-if="routeTwo.length > 0">
                                    <v-timeline
                                        align-top
                                        dense
                                        class="pa-0"
                                    >
                                        <v-timeline-item
                                            v-for="(path, idx) in routeTwo"
                                            :key="idx"
                                            small
                                            color="secondary"
                                        >
                                            <div class="font-weight-normal ml-n5">
                                                <strong>{{ path.From }}</strong>
                                                <v-icon>mdi-map-marker-distance</v-icon> 
                                                {{ path.To }}
                                            </div>
                                            <div class="ml-n5">
                                                {{ path.Distance }} miles
                                            </div>
                                            <div class="ml-n7">
                                                <v-chip
                                                class="ma-2"
                                                label
                                                color="primary"
                                                text-color="white"
                                                :href="`https://maps.google.com/?saddr=${path.FromGps}&daddr=${path.ToGps}&mode=driving`"
                                                target="_blank"
                                                >
                                                <v-icon left>
                                                    mdi-car
                                                </v-icon>
                                                Driving
                                                </v-chip>
                                                <v-chip
                                                class="ma-2"
                                                label
                                                color="primary"
                                                text-color="white"
                                                :href="`https://maps.google.com/?saddr=${path.FromGps}&daddr=${path.ToGps}&dirflg=w`"
                                                target="_blank"
                                                >
                                                <v-icon left>
                                                    mdi-walk
                                                </v-icon>
                                                Walking
                                                </v-chip>
                                                <v-chip
                                                class="ma-2"
                                                label
                                                color="primary"
                                                text-color="white"
                                                :href="`https://maps.google.com/?saddr=${path.FromGps}&daddr=${path.ToGps}&mode=transit&dirflg=r`"
                                                target="_blank"                                                
                                                >
                                                <v-icon left>
                                                    mdi-bus
                                                </v-icon>
                                                Public Transport
                                                </v-chip>
                                                <v-chip
                                                class="ma-2"
                                                label
                                                color="primary"
                                                text-color="white"
                                                :href="`https://maps.google.com/?saddr=${path.FromGps}&daddr=${path.ToGps}&mode=bicycling&dirflg=b`"
                                                target="_blank"                                                
                                                >
                                                <v-icon left>
                                                    mdi-bike
                                                </v-icon>
                                                Cycling
                                                </v-chip>
                                            </div>                                            
                                        </v-timeline-item>
                                    </v-timeline>
                                </div>            
                            </v-card-text>
                        </v-window-item> 
                    </v-window>
                    <v-btn-toggle
                        tile
                        color="secondary"
                        group
                        v-model="toggle_one"
                        mandatory
                    >
                        <v-btn
                            @click="step--"
                        >
                        Route 1
                        </v-btn>
                        <v-btn
                            @click="step++"
                        >
                        Route 2
                        </v-btn>
                    </v-btn-toggle>
                <!--Route End-->
                </v-tab-item>
            </v-tabs-items>
            <!--Upselling-->
            <!-- <h3 class="mb-6 secondary--text">Plan your moving day </h3>
            <div class="d-flex align-center flex-wrap">
                <base-card class="text-center me-6 mb-6">
                    <router-link to="/vendor-store" class="text-decoration-none">
                        <v-card-text class="px-12 py-8 ">
                            <v-avatar size="48" class="mb-3">
                                <img src="@/assets/images/faces/propic.png" alt="">
                            </v-avatar>
                            <h4 class="font-600 grey--text text--darken-3">Tech Friend</h4>
                        </v-card-text>
                    </router-link>
                </base-card>
                <base-card class="text-center me-6 mb-6">
                   <router-link to="/vendor-store" class="text-decoration-none">
                    <v-card-text class="px-12 py-8 ">
                        <v-avatar size="48" class="mb-3">
                            <img src="@/assets/images/faces/propic(1).png" alt="">
                        </v-avatar>
                        <h4 class="font-600 grey--text text--darken-3">Smart Shop</h4>
                    </v-card-text>
                   </router-link>
                </base-card>
                <base-card class="text-center mb-6">
                    <router-link to="/vendor-store" class="text-decoration-none">   
                        <v-card-text class="px-12 py-8 ">
                            <v-avatar size="48" class="mb-3">
                                <img src="@/assets/images/faces/propic.png" alt="">
                            </v-avatar>
                            <h4 class="font-600 grey--text text--darken-3">Gadget 360</h4>
                        </v-card-text>
                    </router-link>
                </base-card>
            </div> -->


            
            <!-- for testing  -->
            <!-- <v-row class="my-6">
                <v-col cols="12" sm="6" lg="2" md="4">
                    <div class="d-flex align-center">
                        <base-card>
                            <v-card-text>
                                <router-link to="/single-product" class="text-decoration-none">
                                    <v-img contain class="card-overlay-item mb-2 br-8" src="@/assets/images/products/Groceries/2.PremiumGroceryCollection.png"></v-img>
                                    <h5 class="grey--text text--darken-4">Premium Grocery</h5>
                                    <div class="d-flex">
                                    <h5 class="primary--text me-2">$250</h5>
                                    <h5 class="grey--text text--darken-1 text-decoration-line-through">$250</h5>

                                    </div>
                                </router-link>
                            </v-card-text>
                        </base-card>
                        <div class="ms-1">
                            <h2 class="mb-0 grey--text text--darken-2">+</h2>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" lg="2" md="4">
                    <div class="d-flex align-center">
                        <base-card>
                            <v-card-text>
                                <router-link to="/single-product" class="text-decoration-none">
                                    <v-img contain class="card-overlay-item mb-2 br-8" src="@/assets/images/products/Groceries/3.PremiumGroceryPack.png"></v-img>
                                    <h5 class="grey--text text--darken-4">Premium Grocery Pack</h5>
                                    <div class="d-flex">
                                        <h5 class="primary--text me-2">$250</h5>
                                        <h5 class="grey--text text--darken-1 text-decoration-line-through">$250</h5>
                                    </div>
                                </router-link>
                            </v-card-text>
                        </base-card>
                        <div class="ms-1">
                            <h2 class="mb-0 grey--text text--darken-2">+</h2>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" lg="2" md="4">
                    <div class="d-flex align-center">
                        <base-card>
                            <v-card-text>
                                <router-link to="/single-product" class="text-decoration-none">
                                    <v-img contain class="card-overlay-item mb-2 br-8" src="@/assets/images/products/Groceries/4.FreashRealCholeMasala.png"></v-img>
                                    <h5 class="grey--text text--darken-4">Freash Masala</h5>
                                    <div class="d-flex">
                                        <h5 class="primary--text me-2">$250</h5>
                                        <h5 class="grey--text text--darken-1 text-decoration-line-through">$250</h5>
                                    </div>
                                </router-link>
                                
                            </v-card-text>
                        </base-card>
                        <div class="ms-1">
                            <h2 class="mb-0 grey--text text--darken-2">+</h2>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" lg="2" md="4">
                    <div class="d-flex align-center">
                        <base-card>
                            <v-card-text>
                                <router-link to="/single-product" class="text-decoration-none">
                                    <v-img contain class="card-overlay-item mb-2 br-8" src="@/assets/images/products/Groceries/5.GumPack.png"></v-img>
                                    <h5 class="grey--text text--darken-4">Gum Pack</h5>
                                    <div class="d-flex">
                                        <h5 class="primary--text me-2">$250</h5>
                                        <h5 class="grey--text text--darken-1 text-decoration-line-through">$250</h5>
                                    </div>
                                </router-link>
                            </v-card-text>
                        </base-card>
                        <div class="ms-1">
                            <h2 class="mb-0 grey--text text--darken-2"> = </h2>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4" >
                    <base-card class="transparent h-100 d-flex align-center" >
                        <v-card-text class="d-flex flex-column align-center">
                            <h3 class="primary--text mb-2">$2500</h3>
                            <span class="text-14 grey--text text--darken-2 mb-6">Save $500</span>
                            <div class="d-flex flex-wrap">
                                <v-btn color="primary" class="text-capitalize font-600 me-3 mb-2">
                                    Add to cart
                                </v-btn>
                                <v-btn color="primary" outlined class="text-capitalize font-600 mb-2">
                                    Add to cart
                                </v-btn>
                            </div>
                        </v-card-text>
                    </base-card>
                </v-col>
            </v-row> -->


            <!-- <h3 class="mb-6 secondary--text">Vendors</h3>
            <div class="d-flex align-center flex-wrap">
                <base-card class="text-center me-6 mb-6">
                    <router-link to="/vendor-store" class="text-decoration-none">
                        <v-card-text class="px-12 py-8 ">
                            <v-avatar size="48" class="mb-3">
                                <img src="@/assets/images/faces/propic.png" alt="">
                            </v-avatar>
                            <h4 class="font-600 grey--text text--darken-3">Tech Friend</h4>
                        </v-card-text>
                    </router-link>
                </base-card>
                <base-card class="text-center me-6 mb-6">
                   <router-link to="/vendor-store" class="text-decoration-none">
                    <v-card-text class="px-12 py-8 ">
                        <v-avatar size="48" class="mb-3">
                            <img src="@/assets/images/faces/propic(1).png" alt="">
                        </v-avatar>
                        <h4 class="font-600 grey--text text--darken-3">Smart Shop</h4>
                    </v-card-text>
                   </router-link>
                </base-card>
                <base-card class="text-center mb-6">
                    <router-link to="/vendor-store" class="text-decoration-none">   
                        <v-card-text class="px-12 py-8 ">
                            <v-avatar size="48" class="mb-3">
                                <img src="@/assets/images/faces/propic.png" alt="">
                            </v-avatar>
                            <h4 class="font-600 grey--text text--darken-3">Gadget 360</h4>
                        </v-card-text>
                    </router-link>
                </base-card>
            </div> -->
          </v-col>
    <!-- Recent Searches  -->
        <div class="container">
        <v-row>
            <v-col cols="12">
            <div class="d-flex justify-space-between mb-2">
                <div class="d-flex align-center">
                <img
                    v-svg-inline
                    class="icon mr-2"
                    src="@/assets/images/icons/clock-time-four-outline.svg"
                    alt=""
                />

                <h3 class="font-weight-bold secondary--text">Recent History ({{recentHistory.length}})</h3>
                </div>
            </div>
            </v-col>
            <v-col cols="12" class="px-0">
            <div class="">
                <VueSlickCarousel
                class="slick-secondary"
                :arrows="true"
                :dots="false"
                v-bind="slickSettings"
                >
                <v-col cols="12" v-for="(item, index) in recentHistory" :key="index">
                    <CardListingDetail
                    :content-img="item.trips[0].propertyImageUrl"
                    :content-text="item.trips[0].propertyTitle"
                    :counter="item.trips[0].roundtripDistance"
                    :amount="item.trips[0].propertyPrice"
                    :nearest-place="item.trips[0].nearestPlace"
                    :portal="item.trips[0].portal"
                    :tripId="item.id"
                    :dateAdded="item.createdDate"
                    class="m-5"
                    @cartRemove="removeCart(item)"
                    @cartAdd="addCart(item)"
                    />
                </v-col>

                <template #prevArrow="arrowOption">
                    <v-btn
                    class="mx-2 slick-arrow prev slick-prev"
                    fab
                    dark
                    small
                    color="secondary"
                    >
                    <v-icon dark>
                        {{ arrowOption }}
                        mdi-arrow-left
                    </v-icon>
                    </v-btn>
                </template>
                <template #nextArrow="arrowOption">
                    <v-btn
                    class="mx-2 slick-arrow next slick-next"
                    fab
                    dark
                    small
                    color="secondary"
                    >
                    <v-icon dark>
                        {{ arrowOption }}
                        mdi-arrow-right
                    </v-icon>
                    </v-btn>
                </template>
                </VueSlickCarousel>
            </div>
            </v-col>
        </v-row>
        </div>
    <!-- Shortest Roundtrips  -->
        <div class="container">
        <v-row>
            <v-col cols="12">
            <div class="d-flex justify-space-between mb-2">
                <div class="d-flex align-center">
                <img
                    v-svg-inline
                    class="icon mr-2"
                    src="@/assets/images/icons/home-clock.svg"
                    alt=""
                />

                <h3 class="font-weight-bold secondary--text">Shortest Roundtrips ({{shortestRoundtrips.length}})</h3>
                </div>
            </div>
            </v-col>
            <v-col cols="12" class="px-0">
            <div class="">
                <VueSlickCarousel
                class="slick-secondary"
                :arrows="true"
                :dots="false"
                v-bind="slickSettings"
                >
                <v-col cols="12" v-for="(item, index) in shortestRoundtrips" :key="index">
                    <CardListingDetail
                    :content-img="item.trips[0].propertyImageUrl"
                    :content-text="item.trips[0].propertyTitle"
                    :counter="item.trips[0].roundtripDistance"
                    :amount="item.trips[0].propertyPrice"
                    :nearest-place="item.trips[0].nearestPlace"
                    :portal="item.trips[0].portal"
                    :tripId="item.id"
                    :dateAdded="item.createdDate"
                    class="m-5"
                    @cartRemove="removeCart(item)"
                    @cartAdd="addCart(item)"
                    />
                </v-col>

                <template #prevArrow="arrowOption">
                    <v-btn
                    class="mx-2 slick-arrow prev slick-prev"
                    fab
                    dark
                    small
                    color="secondary"
                    >
                    <v-icon dark>
                        {{ arrowOption }}
                        mdi-arrow-left
                    </v-icon>
                    </v-btn>
                </template>
                <template #nextArrow="arrowOption">
                    <v-btn
                    class="mx-2 slick-arrow next slick-next"
                    fab
                    dark
                    small
                    color="secondary"
                    >
                    <v-icon dark>
                        {{ arrowOption }}
                        mdi-arrow-right
                    </v-icon>
                    </v-btn>
                </template>
                </VueSlickCarousel>
            </div>
            </v-col>
        </v-row>
        </div>
      </v-row>
  </v-container>
</template>

<script>
const defaultWishList = [ 
    {
        "id": "",
        "type": "",
        "userId": "Jane.Done@example.com",
        "createdDate": "2011-11-02T12:10:07Z",
        "trips": [
            {
                "portal": "",
                "url": "",
                "propertyImageUrl": "",
                "mapImageUrl": null,
                "propertyTitle": "",
                "propertyDescription": null,
                "propertyPrice": "£0",
                "propertyGPS": "10,10",
                "roundTrip": [
                    {
                    }
                ],
                "roundtripDistance": 0,
                "onewaytrips": [
                    {
                    }
                ]
            }
        ]
    }
];

const defaultOnewayTrips = [0, 1, 2, 3];
const defaultOnewayTripNames = ["Location 1", "Location 2", "Location 3", "Location 4"];
const defaultUserProfile = {
    "id": "Jane.Done@example.com",
    "username": "Jane.Done",
    "type": "userProfile",
    "userId": "Jane.Done@example.com",
    "createdDate": "2011-11-02T12:10:07Z",
    "subscriptions": [{
    }],
    "locationsList": [
        {
            "locations": [{
            }]
        }
    ],
	"wishListProperties": 0,
	"zooplaProperties": 0,
	"zooplaPropertiiesLastModifiedDate": "",
	"rightMoveProperties": 0,
	"rightMovePropertiesLastModifiedDate": "",
	"onTheMarketProperties": 0,
	"onTheMarketPropertiesLastModifiedDate": "",
	"dashboard": {
		"history": [{}],
		"topRoundtripList": [{}]
	},
    "shortList": {
        "Portal": "",
		"Url": "",
		"PropertyImageUrl": "",
		"PropertyTitle": "",
		"Agent": "",
		"PropertyPrice": "",
		"PropertyGPS": "",
        "RoundTrip": [{}],
        "RoundtripDistance": 0,
        "RouteOne": [{}],
        "RouteTwo": [{}],
        "RoundtripDistance": 0,
        "OnewaytripsUnder5Miles": 0,
        "OnewaytripsCount": 0,
        "OnewaytripsWalking": [{}],
        "OnewayTrips": [{}]      
    }
};

import { mapGetters, mapActions, mapState  } from 'vuex';
// import CardListingDetail from "@/components/cartCard/CardListingDetail";
export default {
    components: {
        CardListingDetail
    },
    computed: {
        ...mapGetters('store', ['getProducts']),
        ...mapGetters('user', ['getShortList', 'getCurrentUser']),
        ...mapState('user', ['wishList']),
        ...mapState('auth', ['authUser']),
        authUser() {
            return this.$store.state.auth.authUser;
        },
        myPlaces(){
        const myPlaces =  [{
            "listName": "Unknown"
        }];
        var name = this.$store.state.user.userProfile.locationsList.filter(list => list.id == this.wishListById[0].trips[0].locationListId)
        return name.length > 0 ? name : myPlaces
        },
        getlogo() {
            var payload = {
            link: "",
            value: ""
            }
            return payload
        },
        wishListById: {
            get() {
                const messages = this.$store.state.user.wishList//[0].userId == this.getCurrentUser.email
                ? this.$store.state.user.wishList
                : defaultWishList;
                return messages.filter(row => row.id == this.listingId);
            },
            set(value) {
                this.$store.commit('user/WISH_LIST_UPDATED', value);
            }
        },
        onewaytripsWalking(){
            var x = this.wishListById[0].trips[0]
            var z = x.onewaytrips.filter(item => item.distance < 2)
            return z;      
        },
        onewaytripsUnder5Miles(){
            var x = this.wishListById[0].trips[0]
            var y = x.onewaytrips.filter(item => item.distance < 5)
            return y.length > 0 ? y.length/x.onewaytrips.length : 0    
        },
        routeOne(){
        var pathOne = this.wishListById[0].trips[0].roundTrip.filter(line => line.Path == 1)
        return pathOne
        },
        routeTwo(){
        var pathTwo = this.wishListById[0].trips[0].roundTrip.filter(line => line.Path == 2)
        return pathTwo
        },
        chartData(){
            const data = this.wishListById[0].trips[0].onewaytrips ?
            this.wishListById[0].trips[0].onewaytrips.map(a => a.distance):
            defaultOnewayTrips;
            return [{data: data, name: this.myPlaces[0].listName?this.myPlaces[0].listName:"unknown"}]
        },
        chartOptions() {
            const names = this.wishListById[0].trips[0].onewaytrips ?
            this.wishListById[0].trips[0].onewaytrips.map(a => a.name): // change to a.name
            defaultOnewayTripNames;
            return [{
                colors: ["#2196F3"],
                chart: {
                    height: 'auto',
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                    tooltip: {
                        show: false
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                labels: names,
                yaxis: {
                    reversed: true
                },
                annotations: {
                    xaxis: [
                        {
                            x: 5,
                            borderColor: '#00E396',
                            label: {
                                borderColor: '#00E396',
                                    style: {
                                    color: '#fff',
                                    background: '#00E396'
                                    },
                                text: '>5m'
                            }
                        }
                    ]
                }
            }]
        },
        getPropertyAvatar(){
            var value
            if(this.wishListById[0].trips[0].portal == 'zoopla'){
            value = require(`@/assets/images/unmove-homes/zoopla_${this.wishListById[0].trips[0].propertyImageUrl}.png`)
            
            }
            if(this.wishListById[0].trips[0].portal == 'rightmove'){
            value = require(`@/assets/images/unmove-homes/rightmove_${this.wishListById[0].trips[0].propertyImageUrl}.png`)
            }

            if(this.wishListById[0].trips[0].portal == 'onthemarket'){
            value = require(`@/assets/images/unmove-homes/onthemarket_${this.wishListById[0].trips[0].propertyImageUrl}.png`)
            }
            return value
        },
        wishListImages(){
            var image = [
                {
                img: this.getPropertyAvatar, //this.wishListById[0].trips[0].propertyImageUrl,
                name: "Property",
                },
                {
                // img: this.wishListById[0].trips[0].mapImageUrl != null ? 
                //     this.wishListById[0].trips[0].mapImageUrl :
                img:  require('@/assets/images/unmove-homes/no-map-found.png'),
                name: "Map",
                },
            ]       
        return image        
        },
        recentHistory(){
            const recentHistoryId = this.$store.state.user.userProfile.userId == this.authUser.email
            ? this.$store.state.user.userProfile.dashboard.history.map(a => a.id)
            : defaultUserProfile.dashboard.history 

            const messages = this.$store.state.user.wishList//[0].userId == this.getCurrentUser.email
            ? this.$store.state.user.wishList
            : defaultWishList;
            return messages.filter(item => recentHistoryId.includes(item.id));
        },
        shortestRoundtrips(){
            const recentHistoryId = this.$store.state.user.userProfile.userId == this.authUser.email
            ? this.$store.state.user.userProfile.dashboard.topRoundtripList.map(a => a.id)
            : defaultUserProfile.dashboard.topRoundtripList 

            const messages = this.$store.state.user.wishList//[0].userId == this.getCurrentUser.email
            ? this.$store.state.user.wishList
            : defaultWishList;
            return messages.filter(item => recentHistoryId.includes(item.id)).sort();
        },
    },
    watch: {
    "$route.query.Id"(){
            this.listingId = this.$route.query.Id
        } 
    }, 
    data(){
        return {
           google: 'google.com',
           tab: null,
           toggle_one: 0,
           step: 1,
           isSidebar: false,
           isQuantity: 0,
           listingId: "",
           singleProductData: {
                id: 1,
                title: 'Sneakers',
                category: 'Sneakers',
                amount: 300,
                rating: 3,
                qty: 0
            },
            slickSettings: {
                infinite: false,
                speed: 500,
                slidesToShow: 6,
                slidesToScroll: 6,
                initialSlide: 0,

                responsive: [
                {
                    breakpoint: 2100,
                    settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    infinite: true,
                    },
                },
                {
                    breakpoint: 1900,
                    settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    },
                },
                {
                    breakpoint: 1800,
                    settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    },
                },
                {
                    breakpoint: 1264,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    },
                },
                {
                    breakpoint: 960,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    },
                },
                ],
            },
            slickSettingsTwo: {
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 4,
                initialSlide: 0,

                responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    },
                },
                {
                    breakpoint: 1264,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    },
                },
                {
                    breakpoint: 960,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    },
                },
                {
                    breakpoint: 650,
                    settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    },
                },
                ],
            },
            slickSettingsThree: {
                infinite: false,
                speed: 500,
                slidesToShow: 6,
                slidesToScroll: 6,
                initialSlide: 0,

                responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    infinite: true,
                    },
                },
                {
                    breakpoint: 1264,
                    settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    },
                },
                {
                    breakpoint: 960,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    },
                },
                ],
            },                           
      }
    },
    created () {
        this.listingId = this.$route.query.Id
    },
    methods: {
        ...mapActions('store', ["addCart", "removeCart"]),
        ...mapActions('user', ['getWishListById', 'updateShortlist']),
        // fetchWishlist(payload) {
        //     this.getWishListById(payload);
        // },        
        addCartx(data) {
            this.addCart(data)
        },
        removeCartx(data) {
            this.removeCart(data)
        },
        AddToShortList(){
            var x = this.wishListById[0].trips[0]
            var y = x.onewaytrips.filter(item => item.distance < 5)
            var z = x.onewaytrips.filter(item => item.distance < 2)
            var payload = {
                    "id": this.wishListById[0].id,
                    "portal": x.portal,
                    "url": x.url,
                    "propertyImageUrl": x.propertyImageUrl,
                    "propertyTitle": x.propertyTitle,
                    "propertyDescription": x.propertyDescription ? x.propertyDescription : 'Unknown',
                    "propertyPrice": x.propertyPrice,
                    "propertyGPS": x.propertyGPS,
                    "roundTrip": x.roundTrip,
                    "roundtripDistance": x.roundtripDistance,
                    "routeOne": this.routeOne,
                    "routeTwo": this.routeTwo,
                    "onewaytripsUnder5Miles": y.length,
                    "onewaytripsCount": x.onewaytrips.length,
                    "onewaytripsWalking": z,
                    "onewayTrips": x.onewaytrips
                }
            this.$store.commit('user/ADD_SHORT_LIST', payload);
            this.updateShortlist(this.authUser.email);
        }
    }
}
</script>
<style lang="scss" scoped>
    .product-slider {
        
        .product-slider-click {
            display: flex;
            justify-content: center;
            ::v-deep .slick-slide {
               width: 80px !important;
               margin-right: 16px;
               border: 1px solid rgb(218, 225, 231);
               border-radius: 5px;
            }
            ::v-deep .slick-current {
                border: 1px solid #D23F57;
                border-radius: 5px;
            }
        }
    }
</style>

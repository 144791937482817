var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-snackbar', {
    staticClass: "snackbar",
    attrs: {
      "color": "primary",
      "elevation": "16",
      "timeout": 500
    },
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "50"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": "",
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-account-alert-outline ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v("Flight Risk")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), [_c('v-data-table', {
    staticClass: "elevation-1 rounded-table",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.members,
      "sort-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "item.avatar",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-img', {
          attrs: {
            "src": item.avatar,
            "max-height": "50px",
            "max-width": "50px"
          }
        })];
      }
    }, {
      key: "item.authProvider",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-avatar', {
          attrs: {
            "size": "25"
          }
        }, [_c('img', {
          attrs: {
            "src": require(("@/assets/images/logos/" + (item.authProvider) + ".svg"))
          }
        })])];
      }
    }, {
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": "",
            "color": "landing_grey"
          }
        }, [_c('div', {
          staticClass: "mb-2 mt-2"
        }, [_c('v-row', [_c('h4', {
          staticClass: "text-xl secondary--text ml-2 mt-3"
        }, [_vm._v(" Flight Risk Members ")])]), _c('v-row', [_c('p', {
          staticClass: "grey--text text--darken-4 ml-2 mb-1 text-caption text--weight-bold"
        }, [_vm._v(" Flight Risk Members are members who were active or joined during the past 30 days but are now inactive in the community. ")])])], 1)])];
      },
      proxy: true
    }, {
      key: "item.groups",
      fn: function (_ref3) {
        var item1 = _ref3.item1;
        return [_c('div', {
          staticClass: "ml-n4 mt-2 mb-n4 text-sm"
        }, [_c('v-select', {
          attrs: {
            "dense": "",
            "items": _vm.items2,
            "multiple": "",
            "readonly": "",
            "solo": "",
            "flat": "",
            "color": "grey"
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function (_ref4) {
              var item = _ref4.item,
                index = _ref4.index;
              return [index < 2 ? _c('v-chip', {
                staticClass: "ma-1",
                attrs: {
                  "small": "",
                  "color": "grey lighten-2"
                }
              }, [_c('span', {
                staticClass: "grey--text"
              }, [_vm._v(_vm._s(item))])]) : _vm._e(), index === 1 ? _c('span', {
                staticClass: "grey--text"
              }, [_c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function (_ref5) {
                    var on = _ref5.on,
                      attrs = _ref5.attrs;
                    return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v("(+" + _vm._s(_vm.value.length - 1) + " others)")])];
                  }
                }], null, true)
              }, [_c('span', [_c('ul', {
                staticStyle: {
                  "list-style-type": "none"
                }
              }, _vm._l(_vm.items2, function (item, index) {
                return _c('li', {
                  key: index
                }, [_vm._v(" " + _vm._s(item) + " ")]);
              }), 0)])])], 1) : _vm._e()];
            }
          }], null, true),
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v;
            },
            expression: "value"
          }
        })], 1)];
      }
    }, {
      key: "item.joinedDate",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-chip', {
          attrs: {
            "color": "grey lighten-2"
          }
        }, [_c('v-icon', {
          staticClass: "icon",
          attrs: {
            "color": "grey"
          }
        }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
          staticClass: "ma-1 secondary--text text-sm"
        }, [_vm._v(_vm._s(_vm.formatDateTime(0)))])], 1)];
      }
    }, {
      key: "item.lastActive",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-chip', {
          attrs: {
            "color": "grey lighten-2"
          }
        }, [_c('v-icon', {
          staticClass: "icon",
          attrs: {
            "color": "grey"
          }
        }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
          staticClass: "ma-1 secondary--text text-sm"
        }, [_vm._v(_vm._s(_vm.formatDateTime(0)))])], 1)];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.initialize
          }
        }, [_vm._v(" Reset ")])];
      },
      proxy: true
    }])
  })]], 2)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
      <div class="sign-up-card">
        <v-snackbar
          class=""
          :timeout="5000"
          :value="getAuthenticationErrors"
          color="primary"
        >
          <strong>{{ getAuthenticationErrors }} </strong>
        </v-snackbar>
        <validation-observer
          ref="obs"
          v-slot="{ invalid, validated, handleSubmit }"
        >
          <div class="sign-up-card-container">
            <div class="d-md-block ">
              <div class="px-3 px-md-10 py-8 ">
                <h3 class="text-center">
                  <v-avatar
                    width="auto"
                    height="50"
                    tile
                  >
                    <img
                      src="@/assets/CheqqMateforCreators.Logo.png"
                      class="mt-1"
                    >
                  </v-avatar>
                </h3>
                <h5 class="font-600 grey--text text--darken-3 text-sm mb-9 text-center">
                  Log in with email & password
                </h5>
                <v-text-field-with-validation
                  v-model="user.email"
                  rules="required|email"
                  label="Email"
                  placeholder="example@gmail.com"
                />
                <div class="mt-1" />
                <v-text-field-password-with-validation
                  v-model="user.password"
                  rules="required"
                  label="Password"
                  placeholder="**********"
                />
                <div class="mt-1" />
                <v-btn
                  :disabled="invalid || !validated"
                  block
                  color="primary"
                  class="text-capitalize font-600"
                  @click="handleSubmit(userLogin)"
                >
                  Login
                </v-btn>
                <!-- <v-col cols="10" lg="8" class="mx-auto">
                    <div class="d-flex align-center my-1">
                      <v-divider></v-divider>
                      <span class="mx-4">or</span>
                      <v-divider></v-divider>
                    </div>
                  </v-col> -->
                <!-- <v-btn
                    block
                    dark
                    color="indigo"
                    class="text-capitalize font-600 mb-4"
                  >
                    <v-icon left class="me-3">mdi-facebook</v-icon>
                      Continue with facebook
                  </v-btn> -->
                <!-- <v-btn
                    block
                    dark
                    color="red darken-2"
                    class="text-capitalize font-600 mb-4"
                  >
                        <v-icon left class="me-3">mdi-google</v-icon>
                      Continue with Google
                  </v-btn> -->
                <div class="text-14 text-center my-3">
                  Don't have account? <router-link
                    to="/sign-up"
                    class=" grey--text text--darken-4 font-600"
                  >
                    Sign Up
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </validation-observer>
        <div class="py-4 grey lighten-2">
          <div class="text-center">
            <span class="grey--text text--darken-1">Forgot Your Password <router-link
              to="/forgot-password"
              class="ms-2 grey--text text--darken-4 font-600"
            >Reset It</router-link> </span>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import VTextFieldWithValidation from '../components/inputs/VTextFieldWithValidation.vue'
  import VTextFieldPasswordWithValidation from '../components/inputs/VTextFieldPasswordWithValidation.vue'
  import VSelectWithValidation from '../components/inputs/VSelectWithValidation.vue'

  // import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
  // const auth = getAuth();
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VTextFieldWithValidation,
      VSelectWithValidation,
      VTextFieldPasswordWithValidation,
    },
    data () {
      return {
        checkbox: false,
        user: {
          email: '',
          password: '',
        },
      }
    },
    computed: {
      ...mapGetters('auth', ['getAuthenticationErrors']),
    },
    methods: {
      userLogin () {
        // this.$store.commit('app/START_LOADING');
        // signInWithEmailAndPassword(auth, this.user.email, this.user.password)
        //   .then((userCredential) => {
        //     // Signed in
        //     // const user = userCredential.user;
        //     this.$store.commit('app/FINISH_LOADING');
        //     // redirect
        //     this.$router.push({ path: '/dashboard' });
        //   })
        //   .catch((error) => {
        //     const errorCode = error.code;
        //     const errorMessage = error.message;
        //   });

        this.$store.commit('app/START_LOADING')
        this.$store.dispatch('auth/login', this.user)
          .then(() => {
            // Signed in
            this.$store.commit('app/FINISH_LOADING')

            if (this.getAuthenticationErrors == null) {
            // redirect, TODO: redirect conditionally
            this.$router.push({ path: '/dashboard' })
            } else {
              this.$router.push({ path: '/dashboard' })
            }
          })
          .catch((error) => {
            // An error happened.
            this.$store.commit('app/FINISH_LOADING')
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
      .sign-up-card {
        width:500px;
        overflow: hidden;
        background-color: #fff;
        border-radius: 8px;
        margin: 2rem auto;
        box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
        @media(max-width: 500px){
          width: 100%;
        }
      }
      .sign-up-card-container {
        padding: 3rem 3.75rem 0px;
        @media(max-width: 500px){
          padding: 3rem 1rem 0px;
        }
      }
</style>

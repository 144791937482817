<template>
    <div class="course-editor">
        <!--preview-->
        <div class="d-flex justify-content-between align-end">
            <span>
                <v-btn
                    icon
                    class="mb-2"
                    @click="dialog = false, closeDialog()"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </span>
            <v-spacer></v-spacer>
            <h2 class="mb-2">{{ (lessonData && lessonData.description) ? lessonData.description : 'New Lesson' }}</h2>
            <v-spacer></v-spacer>
            <span>
              <v-btn 
                text
                class="mb-2 mr-2 text-capitalize font-600" 
                @click="dialog = true" elevation="0">
                preview
              </v-btn>
              <v-btn
                class="text-none mt-n2"
                color = "primary"
                elevation="0"
                @click = "updateLessonContent(), closeDialog()"
              >
                <span class="ml-1">Save</span>
              </v-btn>
            </span>
        </div>

      <!--editor-->
      <quill-editor
        class="editor"
        ref="myQuillEditor"
        :value="content"
        :options="editorOption"
        @change="onEditorChange"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
      <!--preview-->
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            color="#f6f9fc"
            elevation="0"
          >
            <v-btn
              icon
              color="darkGrey"
              @click="dialog = false"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-toolbar-title>Preview of {{ (lessonData && lessonData.description) ? lessonData.description : 'New Lesson' }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-toolbar-items>
              <v-btn
                dark
                text
                @click="dialog = false"
              >
                Save
              </v-btn>
            </v-toolbar-items> -->
          </v-toolbar>
        <div class="output ql-snow">
            <div class="ql-editor" v-html="content"></div>
        </div>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
    import dedent from 'dedent';
    import hljs from 'highlight.js';
    import debounce from 'lodash/debounce';
    import QuillEditor from './QuillEditor.vue'
  
    // highlight.js style (for syntax highlighting)
    import 'highlight.js/styles/tomorrow-night-bright.css'
  
    // import theme style
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'

    import { mapState, mapActions, mapGetters } from 'vuex';
    import axios from 'axios';
    // const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
    const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
    const cheqqMateServiceConfig = {
      headers: {
        'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
        'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
      }
    };

    const http = axios.create();

    http.interceptors.request.use(
      config => {
        store.commit('app/START_LOADING');
        return config;
      },
      error => {
        store.commit('app/FINISH_LOADING');
        store.commit(
          'app/SET_ERROR',
          'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
        );

        return Promise.reject(error);
      }
    );

    http.interceptors.response.use(
      response => {
        store.commit('app/FINISH_LOADING');
        return response;
      },
      error => {
        store.commit('app/FINISH_LOADING');
        store.commit(
          'app/SET_ERROR',
          'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
        );

        return Promise.reject(error);
      }
    );

    export default {
      name: 'Editor',
      components: {
        QuillEditor
      },
      props: {
      lessonData: {
        type: Object,
        required: false,
        default: () => ({})
      },
    },
      data() {
        return {
          dialog: false,
          editorOption: {
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'font': [] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'align': [] }],
                ['clean'],
                ['link', 'image', 'video']
              ],
              syntax: {
                highlight: text => hljs.highlightAuto(text).value
              },
            }
          },
          content: dedent ``
          // content: dedent`
          // <div id="kt_docs_quill_basic" name="kt_docs_quill_basic">
          //     <h1>Quick and Simple Quill Integration</h1>

          //     <p>Here goes the&nbsp;<a href="#">Minitial content of the editor</a>. Lorem Ipsum is simply dummy text of the&nbsp;<em>printing and typesetting</em>&nbsp;industry.</p>
          //     <blockquote>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</blockquote>
          //     <h3 style="text-align: left;">Flexible &amp; Powerful</h3>
          //     <p style="text-align: left;"><strong>Lorem Ipsum has been the industry's</strong>&nbsp;standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
          //     <ul>
          //         <li>List item 1</li>
          //         <li>List item 2</li>
          //         <li>List item 3</li>
          //         <li>List item 4</li>
          //     </ul>
          // </div>
          // `,
        }
      },
      methods: {
        ...mapActions('user', ['updateLessonFromState']),
        onEditorChange: debounce(function(value) {
          this.content = value.html
        }, 466),
        onEditorBlur(editor) {
          console.log('editor blur!', editor)
        },
        onEditorFocus(editor) {
          console.log('editor focus!', editor)
        },
        onEditorReady(editor) {
          console.log('editor ready!', editor)
        },
        closeDialog() {
            this.$emit('reset-full-screen-mode');
        },
        updateLessonContent() {
          //update lesson content
          this.lesson = {
            HtmlContent: this.content,
            Duration: this.lessonData.duration
          };
          //update lesson in db
          this.updateLessonFromState();
        },
        readLessonById() {
          axios
          .get(
            `${cheqqMateServiceBaseUrl}/getLessonById`+
              '?UserEmail=' + this.authUser.email + '&LessonId=' + this.lessonData.id,
            cheqqMateServiceConfig,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
              }
            }
          )
          .then(res => {
            if(res.data) {
              //save to vuex, so that it persists, instead of consistently calling api
              this.$store.commit('user/LESSON_DETAILS_UPDATED', res.data.lesson);
              this.content = dedent `${res.data.lesson.HtmlContent}`;
            } 
            else {
            }
          })
          .catch(error => {
            if (error.response) {
            }
        });
        }
      },
      computed: {
        ...mapState('auth', ['authUser']),
        authUser () {
          return this.$store.state.auth.authUser
        },
        editor() {
            return this.$refs['myQuillEditor'].quill
        },
        contentCode() {
          return hljs.highlightAuto(this.content).value
        },
        lesson: {
          get() {
            return this.$store.state.user.lesson;
          },
          set(value) {
            this.$store.commit('user/LESSON_CONTENT_UPDATED', value);
          }
        }
      },
      mounted() {
        console.log('this is Quill instance:', this.editor)
        this.readLessonById()
      }
    }
  </script>
  
  <style lang="scss" scoped>
    .course-editor {
      display: flex;
      flex-direction: column;
  
      .editor {
        height: 40rem;
        padding-bottom: 50px;
        padding-left:5px;
        // overflow: hidden;
      }
  
      .output {
        width: 100%;
        height: 20rem;
        margin: 0;
        border: 1px solid #ccc;
        overflow-y: auto;
        resize: vertical;
  
        &.code {
          padding: 1rem;
          height: 16rem;
        }
  
        &.ql-snow {
          border-top: none;
          height: 24rem;
        }
      }

    }
  </style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('welcome-message', {
    attrs: {
      "dialog": _vm.showDialog
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('dashboard-sidebar-two')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex align-center mb-5 secondary--text"
  }, [_c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-3",
    attrs: {
      "src": require("@/assets/images/icons/chart-bar.svg"),
      "alt": ""
    }
  }), _c('h2', {
    staticClass: "mb-0 secondary--text"
  }, [_vm._v(" Dashboard ")])]), _c('div', {
    staticClass: "row"
  }, [_vm._l(_vm.dashboardCard, function (dashBoardItem, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "sm": "6",
        "lg": "4"
      }
    }, [_c('base-card', [_c('div', {
      staticClass: "px-5 py-9 text-center"
    }, [_c('h4', {
      staticClass: "font-600 grey--text text--darken-2 mb-2"
    }, [_vm._v(" " + _vm._s(dashBoardItem.titleOne) + " ")]), _c('h1', {
      staticClass: "grey--text text--darken-4 mb-1"
    }, [_vm._v(" " + _vm._s(dashBoardItem.titleTwo) + " ")])])])], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8",
      "sm": "12"
    }
  }, [_c('base-card', [_c('v-card-text', {
    staticClass: "text-16 font-600 grey--text text--darken-4"
  }, [_vm._v(" Schedule Snapshot ")]), _c('div', {
    staticClass: "pa-4 d-flex justify-space-between flex-wrap"
  }, [_vm.matchArray.length > 0 ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Group ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.groupsV2 ? _vm.groupsV2[0].name : ''))])]) : _vm._e(), _vm.matchDate ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Date ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.matchDate))])]) : _vm._e()]), _c('div', {
    staticClass: "pa-5"
  }, [_c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "250",
      "options": _vm.chartOptions[0],
      "series": _vm.series
    }
  })], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4",
      "sm": "12"
    }
  }, [_c('base-card', [_c('div', {
    staticClass: "pa-5 secondary--text"
  }, [_c('h4', [_vm._v("Advanced Stats")]), _vm._l(_vm.advancedStats, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "d-flex justify-space-between align-center my-2 text-truncate"
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('span', {
      staticClass: "text-truncate",
      staticStyle: {
        "max-width": "115px"
      }
    }, [_vm._v(_vm._s(item.titleOne) + " ")])]), _c('h6', {
      staticClass: "text-12 font-600"
    }, [_vm._v(" " + _vm._s(item.titleTwo ? item.titleTwo.toFixed(2) : 0) + " % ")])]);
  })], 2)]), _c('base-card', {
    staticClass: "mt-6"
  }, [_c('div', {
    staticClass: "pa-5 secondary--text"
  }, [_c('h4', [_vm._v("Filters")]), _c('v-col', [_c('v-select', {
    staticClass: "mb-n6 ml-n3 text-10",
    attrs: {
      "items": _vm.dates,
      "item-text": 'label',
      "item-value": 'label',
      "chips": "",
      "dense": "",
      "label": "Date",
      "outlined": ""
    },
    model: {
      value: _vm.matchDate,
      callback: function ($$v) {
        _vm.matchDate = $$v;
      },
      expression: "matchDate"
    }
  })], 1), _c('v-col', [_c('v-select', {
    staticClass: "mb-n6 ml-n3",
    attrs: {
      "items": _vm.filteredGroupsV2,
      "item-text": 'name',
      "item-value": 'matchId',
      "chips": "",
      "dense": "",
      "label": "Group",
      "multiple": "",
      "outlined": ""
    },
    model: {
      value: _vm.matchArray,
      callback: function ($$v) {
        _vm.matchArray = $$v;
      },
      expression: "matchArray"
    }
  })], 1)], 1)])], 1)], 2)])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container class="py-6">
        <v-row>
          <v-col cols="12">
              <div class="box-wrapper">
                  <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                  <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                     <DashbordSidebar />
                  </div>
                  <div class="box-content">
                      <div class="d-flex justify-end pa-2 d-block d-md-none">
                          <v-btn
                              icon   
                              @click="isSidebar = !isSidebar"
                          >
                              <v-icon dark>
                                  mdi-format-list-bulleted-square
                              </v-icon>
                          </v-btn>
                      </div>
                      <div class="box-container">                        
                        <div class="d-flex justify-space-between flex-wrap mb-5">
                            <div class="d-flex align-center mt-n2 ">
                                <v-avatar tile size="25" class="me-3">
                                    <img src="@/assets/images/icons/history.svg" alt="">
                                </v-avatar>
                                <h2 class="mb-0 secondary--text">Match Details</h2>
                            </div>
                            <v-btn 
                                outlined 
                                color="primary" 
                                class="text-capitalize font-600"
                                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                            >
                                BACK
                            </v-btn>                             
                        </div>
                        <div class="mb-5">
                        <v-row>
                          <v-col cols="12">
                              <base-card>
                                <div class="pa-5">
                                    <div class="mb-4">
                                      <v-toolbar-title>Match Overview</v-toolbar-title>
                                      </div>
                                      <div v-if="matchDetails.createdDate" class="my-n2">
                                          <p class="text-sm grey--text text--darken-1 mb-3">
                                              Date: {{ this.formatDateTime(matchDetails.createdDate) }}
                                          </p>
                                          
                                      </div>                                      
                                    <v-row>
                                      <v-col v-for="(dashBoardItem, index) in dashboardCard"
                                        :key="index"
                                        cols="12"
                                        sm="6"
                                        lg="4"
                                      >
                                        <base-card>
                                          <div class="px-5 py-9 text-center">
                                            <h4 class="font-600 grey--text text--darken-2 mb-2">
                                              {{ dashBoardItem.titleOne }}
                                            </h4>
                                            <h1 class="grey--text text--darken-4 mb-1">
                                              {{ dashBoardItem.titleTwo }}
                                            </h1>
                                          </div>
                                        </base-card>
                                      </v-col>
                                    </v-row>
                                </div>
                              </base-card>
                          </v-col>
                      </v-row>
                        </div>
                        <!--table-->
                        <template>
                            <v-data-table
                                :headers="headers"
                                :items="pairsV2"
                                sort-by="pair"
                                class="elevation-1"
                            >
                                <template v-slot:top>
                                  <v-toolbar
                                    flat
                                  >
                                    <v-toolbar-title>Match Records</v-toolbar-title>
                                  </v-toolbar>
                                </template>
                                <template v-slot:item.pair="{ item }">
                                  <p class="mt-3 grey--text">
                                  <v-badge
                                    color="darkGrey mt-3"
                                    inline
                                    :content="item.pair"
                                  >
                                  </v-badge>
                                </p>
                                </template>
                              <template v-slot:item.responseDate="{ item }">
                                    <v-chip small
                                    >
                                      <v-icon small
                                        class="icon"
                                      >
                                        mdi-clock-time-four
                                      </v-icon>
                                      <p class="ma-1 secondary--text text-sm">{{ item.responseDate?formatDateTime(item.responseDate):'31/12/9999, 00:00' }}</p>
                                    </v-chip>
                                </template>
                              <template v-slot:item.reportStatus="{ item }">
                                <v-chip small
                                  :color="getColor(item.reportStatus)"
                                  dark
                                >
                                <p class="ma-1 text-sm">{{ item.reportStatus }}</p>
                                </v-chip>
                              </template>                                                      
                                <template v-slot:item.actions="{ item }">
                                <v-icon
                                    medium
                                    @click="viewRecord(item)"
                                >
                                    mdi-eye
                                </v-icon>
                                </template>
                                <template v-slot:no-data>
                                <v-btn
                                    color="primary"
                                    @click="initialize"
                                >
                                    Reset
                                </v-btn>
                                </template>
                            </v-data-table>
                        </template>
                        <!--view match card-->
                        <v-dialog v-model="dialog" max-width="600px" :retain-focus="false">
                          <base-card>
                            <v-card-title>
                              <h3 class="pa-4 ml-3 secondary--text text-wrap">Match Card</h3>
                            </v-card-title>
                            <div class="pa-4 d-flex justify-space-around flex-wrap">
                                <div class="my-n6">
                                  <v-row>
                                    <span class="text-11 grey--text text--darken-4 mb-8"> </span>
                                  </v-row>
                                  <v-row>
                                    <span class="text-11 grey--text text--darken-4 mt-9 mb-2">Name: </span>
                                  </v-row>
                                  <v-row>
                                    <span class="text-11 grey--text text--darken-4 mt-2 mb-2">Report Status: </span>
                                  </v-row>
                                  <v-row>
                                    <span class="text-11 grey--text text--darken-4 mt-3 mb-2">Response Date: </span>
                                  </v-row>
                                  <v-row>
                                    <span class="text-11 grey--text text--darken-4 mt-3 mb-1">Days to Respond: </span>
                                  </v-row>
                                </div>
                                
                                <div class="my-n6">
                                    <p class="ml-n3 text-11 grey--text text--darken-4 mt-n8 mb-4">
                                      <v-avatar>
                                        <v-img :src="`https://api.dicebear.com/7.x/initials/svg?seed=${pairsMoreDetails[0].name}`"></v-img>
                                      </v-avatar>
                                    </p>
                                    <p class="ml-n3 text-11 grey--text text--darken-4 mb-4">
                                      Champion
                                    </p>
                                    <v-row>
                                      <span class="mb-2 ml-1 grey--text text--darken-4"> {{ pairsMoreDetails[0].name }} </span>
                                    </v-row>
                                    <v-row>
                                      <span class="secondary--text mt-1 mb-2"> 
                                        <v-chip small
                                          :color="getColor(pairsMoreDetails[0].reportStatus)"
                                          dark
                                        >
                                        <p class="ma-1 text-sm">{{ pairsMoreDetails[0].reportStatus }}</p>
                                        </v-chip>
                                      </span>
                                    </v-row>
                                    <v-row>
                                      <span class="mt-1 mb-2">
                                        <v-chip small
                                        >
                                          <v-icon small
                                            class="icon"
                                          >
                                            mdi-clock-time-four
                                          </v-icon>
                                          <p class="ma-1 secondary--text text-sm">{{ pairsMoreDetails[0].responseDateV2?formatDateTime(pairsMoreDetails[0].responseDateV2):'tbc' }}</p>
                                        </v-chip>
                                      </span>
                                    </v-row>
                                    <v-row>
                                      <span class="secondary--text mt-1 mb-1">
                                        <v-badge
                                          color="darkGrey"
                                          inline
                                          :content="subtractDates(pairsMoreDetails[0].responseDate?pairsMoreDetails[0].responseDate:new Date(),matchDetails.createdDate)"
                                        >
                                        </v-badge>
                                      </span>
                                    </v-row>
                                </div>
                                <div class="my-n6">
                                    <p class="ml-n3 text-11 grey--text text--darken-4 mt-n8 mb-4">
                                      <v-avatar>
                                        <v-img :src="`https://api.dicebear.com/7.x/initials/svg?seed=${pairsMoreDetails[1].name}`"></v-img>
                                      </v-avatar>
                                    </p>
                                    <p class="ml-n3 text-11 grey--text text--darken-4 mb-4">
                                      Challenger
                                    </p>
                                    <v-row>
                                      <span class="mb-2 ml-1 grey--text text--darken-4">{{ pairsMoreDetails[1].name }} </span>
                                    </v-row>
                                    <v-row>
                                      <span class="mb-2 mt-1">
                                        <v-chip small
                                          :color="getColor(pairsMoreDetails[1].reportStatus)"
                                          dark
                                        >
                                        <p class="ma-1 text-sm">{{ pairsMoreDetails[1].reportStatus }}</p>
                                        </v-chip>
                                      </span>
                                    </v-row>
                                    <v-row>
                                      <span class="mt-1 mb-2">
                                        <v-chip small
                                        >
                                          <v-icon small
                                            class="icon"
                                          >
                                            mdi-clock-time-four
                                          </v-icon>
                                          <p class="ma-1 secondary--text text-sm">{{ pairsMoreDetails[1].responseDateV2?formatDateTime(pairsMoreDetails[1].responseDateV2):'tbc' }}</p>
                                        </v-chip>
                                      </span>
                                    </v-row>
                                    <v-row>
                                      <span class="secondary--text mt-1 mb-1">
                                        <v-badge
                                          color="darkGrey"
                                          inline
                                          :content="subtractDates(pairsMoreDetails[1].responseDate?pairsMoreDetails[1].responseDate:new Date(),matchDetails.createdDate)"
                                        >
                                        </v-badge>
                                      </span>
                                    </v-row>
                                </div>
                            </div>
                              <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                  color="primary"
                                  dark
                                  @click="close">Close
                              </v-btn>
                              </v-card-actions>                    
                          </base-card>
                        </v-dialog>
                      </div>
                  </div>
              </div>
          </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import DashbordSidebar from "@/components/DashboardSidebar.vue"
  import { mapState, mapActions, mapGetters } from 'vuex';
  import axios from 'axios';
  
  // const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
  const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
  const cheqqMateServiceConfig = {
    headers: {
      'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
      'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
    }
  };
  
  const http = axios.create();
  
  http.interceptors.request.use(
    config => {
      store.commit('app/START_LOADING');
      return config;
    },
    error => {
      store.commit('app/FINISH_LOADING');
      store.commit(
        'app/SET_ERROR',
        'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
      );
  
      return Promise.reject(error);
    }
  );
  
  http.interceptors.response.use(
    response => {
      store.commit('app/FINISH_LOADING');
      return response;
    },
    error => {
      store.commit('app/FINISH_LOADING');
      store.commit(
        'app/SET_ERROR',
        'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
      );
  
      return Promise.reject(error);
    }
  );
  const defaultMatch = {
    id: "be4718ab-ed06-4157-8fb3-87263d8d5acb",
    type: "Matches",
    userId: "tzishi@live.co.uk",
    groupId: "c6d32027-4577-4d86-9fca-146d4a11debd",
    createdDate: "2023-10-22T22:19:09.5289389Z",
    pairs: [],
    matchReport: []
  };
    export default {
      components: {
          DashbordSidebar
      },    
      data: () => ({
        selectedMembers: null,
        dialog: false,
        file: {},
        fileData: "",
        matchId: '',
        currentPair: 0,
        singleSelect: false,
        dialog2: false,
        isSidebar: false,
        dialogDelete: false,
        uploadedFile: '',
        scheduleName: 'My Schedule',
        headers: [
          { text: 'Pair', align: 'start', value: 'pair', sortable: true },
          { text: 'Full Name', value: 'name',sortable: true},
          { text: 'Paired With', value: 'pairName', sortable: true },
          { text: 'Response Date', value: 'responseDate', sortable: false },
          { text: 'Status', value: 'reportStatus', sortable: true },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        groupIds: ['3b9ca61d-3484-479c-8153-91a6ef2500c3','3b9ca61d-3484-479c-8153-91a6ef2500c5', '3b9ca61d-3484-479c-8153-91a6ef2500c8'],
        members: [],
        members2: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          phoneNumber: '',
          emailAddress: '',
          resumeDate: new Date().toJSON().slice(0,10),
          isDeletedFromDirectory: false,
        },
        defaultItem: {
          name: '',
          phoneNumber: '',
          emailAddress: '',
          resumeDate: new Date().toJSON().slice(0,10),
          isDeletedFromDirectory: false,
        },
      }),
  
      computed: {
        ...mapState('auth', ['authUser']),
        ...mapState('user', ['memberDirectory', 'matchDetails','match']),
        authUser () {
          return this.$store.state.auth.authUser
        },        
        formTitle () {
          return this.editedIndex === -1 ? 'Add Members' : 'Add Member'
        },
        matchDetails () {
        return this.match
          ?           
            { // format lastModifiedDate using formatDateTime
              ...this.match,
              matchReport: this.match.matchReport 
                ? this.match.matchReport.map(a => {
                    return {
                      ...a,
                      lastModifiedDateV2: this.formatDateTime(a.lastModifiedDate)
                    }
                  })
                : [],
            }
          : defaultMatch
        },
        matchDetailsPairs () {
          return this.match.pairs
            ? this.match.pairs
            : []
        },
        reportStatus(){
        },
        pairsV2() {
          // add a filter by matchDate  
          return this.matchDetails.pairs.map(a => {
            return {
              pair: a.pairNumber + 1,
              createdDate: this.formatDateTime(a.createdDate), // Reponse Lag createDate - responseDate
              name: this.memberDirectory.person.filter(b => b.id == a.memberId)[0].name,
              pairName: this.memberDirectory.person.filter(b => b.id == a.pairMemberId)[0].name,
              resumeDate: this.memberDirectory.person.filter(b => b.id == a.memberId)[0].resumeDate,
              // return lastModifiedDate from matchReport
              responseDate: this.matchDetails.matchReport.filter(b => b.checkInConfirmedBy == a.memberId).length > 0 ? this.matchDetails.matchReport.filter(b => b.checkInConfirmedBy == a.memberId)[0].lastModifiedDateV2 : this.matchDetails.matchReport.filter(b => b.checkInSkippedBy == a.memberId).length > 0 ? this.matchDetails.matchReport.filter(b => b.checkInSkippedBy == a.memberId)[0].lastModifiedDateV2 : '',
              // check match report if checkInConfirmedBy or match a.memberId then return 'met', checkInSkippedBy match a.memberId then return 'skipped' default 'pending'
              // reportStatus: this.matchDetails.matchReport.filter(b => b.checkInConfirmedBy == a.memberId || b.checkInSkippedBy == a.memberId).length > 0 ? 'met' : 'skipped'
              reportStatus: this.matchDetails.matchReport.filter(b => b.checkInConfirmedBy == a.memberId).length > 0 ? 'met' : this.matchDetails.matchReport.filter(b => b.checkInSkippedBy == a.memberId).length > 0 ? 'skipped' : 'pending'
            }
          })
        },
        pairsMoreDetails() {
          return this.matchDetails.pairs.map(a => {
            return {
              pair: a.pairNumber,
              name: this.memberDirectory.person.filter(b => b.id == a.memberId)[0].name,
              pairName: this.memberDirectory.person.filter(b => b.id == a.pairMemberId)[0].name,
              resumeDate: this.memberDirectory.person.filter(b => b.id == a.memberId)[0].resumeDate,
              pairResumeDate: this.memberDirectory.person.filter(b => b.id == a.pairMemberId)[0].resumeDate,
              responseDate: this.matchDetails.matchReport.filter(b => b.checkInConfirmedBy == a.memberId).length > 0 ? this.matchDetails.matchReport.filter(b => b.checkInConfirmedBy == a.memberId)[0].lastModifiedDate : this.matchDetails.matchReport.filter(b => b.checkInSkippedBy == a.memberId).length > 0 ? this.matchDetails.matchReport.filter(b => b.checkInSkippedBy == a.memberId)[0].lastModifiedDate : '',
              responseDateV2: this.matchDetails.matchReport.filter(b => b.checkInConfirmedBy == a.memberId).length > 0 ? this.matchDetails.matchReport.filter(b => b.checkInConfirmedBy == a.memberId)[0].lastModifiedDateV2 : this.matchDetails.matchReport.filter(b => b.checkInSkippedBy == a.memberId).length > 0 ? this.matchDetails.matchReport.filter(b => b.checkInSkippedBy == a.memberId)[0].lastModifiedDateV2 : '',
              reportStatus: this.matchDetails.matchReport.filter(b => b.checkInConfirmedBy == a.memberId).length > 0 ? 'met' : this.matchDetails.matchReport.filter(b => b.checkInSkippedBy == a.memberId).length > 0 ? 'skipped' : 'pending'
            }
          }).filter(a => a.pair == this.currentPair)
        },
        dashboardCard () {
          var payload = [
            {
              titleOne: 'Meetups',
              // pairsV2.filter(a => a.reportStatus == 'met').length
              titleTwo: this.pairsV2.filter(a => a.reportStatus == 'met').length
            },
            {
              titleOne: 'Skipped',
              // pairsV2.filter(a => a.reportStatus == 'skipped').length
              titleTwo: this.pairsV2.filter(a => a.reportStatus == 'skipped').length
            },
            {
              titleOne: 'Pending',
              // pairsV2.filter(a => a.reportStatus == 'pending').length
              titleTwo: this.pairsV2.filter(a => a.reportStatus == 'pending').length

            },
          ]
          return payload
        },
      },
      watch: {
        dialog (val) {
          val || this.close()
        },
        "$route.query.matchId"(){
          this.matchId = this.$route.query.matchId
        }  
      },
      mounted () {
        if(this.$route.query.matchId) {
          this.matchId = this.$route.query.matchId
          this.readMatchesById()
        }
      },  
      created () {
        this.initialize()
      },
      methods: {
        formatDateTime (date) {
          return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
            // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        },
        getColor (status) {
          if (status == 'skipped') return 'red'
          else if (status == 'pending') return 'orange'
          else return 'green'
        },
        // subtractDates (date1, date2) {
        //   return new Date(date1) - new Date(date2)
        // },
        // subtract two dates and return the difference in days
        subtractDates (date1, date2) {
          const diffTime = Math.abs(new Date(date2) - new Date(date1));
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          return diffDays
        },
          async sleep(ms) {
              return new Promise(resolve => setTimeout(resolve, ms));
          },      
          nextPage () {
              if (this.page + 1 <= this.numberOfPages) this.page += 1
          },
          formerPage () {
              if (this.page - 1 >= 1) this.page -= 1
          },
          season (val) {
              return this.range[val]
          },
          hasHistory () { 
              return window.history.length > 2 
          },
          updateSchedule(){},

          initialize () {
            this.members = [
              {
                name: 'Tom Jerry',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
              {
                name: 'Tom Jerry 1',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
              {
                name: 'Tom Jerry 2',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
              {
                name: 'Tom Jerry 3',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
              {
                name: 'Tom Jerry 4',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
              {
                name: 'Tom Jerry 5',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              }
            ],
            this.members2 = [
              {
                name: 'Tom Jerry 5',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
              {
                name: 'Tom Jerry 6',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
              {
                name: 'Tom Jerry 7',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
              {
                name: 'Tom Jerry 8',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
              {
                name: 'Tom Jerry 9',
                phoneNumber: '441234567890',
                emailAddress: "jane.doe@gmail.com",
                resumeDate: "23/08/2023",
                isDeletedFromDirectory: false
              },
            ]
          },
          editItem (item) {
              this.editedIndex = this.members.indexOf(item)
              this.editedItem = Object.assign({}, item)
              this.dialog = true
          },
          viewRecord (item) {
            this.currentPair = item.pair-1
            this.dialog = true
          },
          close () {
              this.dialog = false
              this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              })
          },
          close2 () {
            this.dialog2 = false
          },
          closeDelete () {
              this.dialogDelete = false
              this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              })
          },
          save () {
              if (this.editedIndex > -1) {
              Object.assign(this.members[this.editedIndex], this.editedItem)
              } else {
              this.members.push(this.editedItem)
              }
              this.close()
          },
          readMatchesById() {
          axios
          .get(
            `${cheqqMateServiceBaseUrl}/getMatchById`+
              '?UserEmail=' + this.authUser.email + '&MatchId=' + this.matchId,
            cheqqMateServiceConfig,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
              }
            }
          )
          .then(res => {
            if(res.data) {
              //save to vuex, so that it persists, instead of consistently calling api
              this.$store.commit('user/MATCH_DETAILS_UPDATEDV2', res.data.match);
            } 
            else {
            }
          })
          .catch(error => {
            if (error.response) {
            }
        });
        },       
      },
    }
  </script>
  <style lang="scss" scoped>
      .dashboard-nav-link {
          color: #2B3445 !important;
          text-decoration: none;
          align-items: center;
          display: flex;
          justify-content: space-between;
          border-left: 4px solid #fff;
          margin-bottom: 20px;
          svg {
              width: 20px;
          }
          &:hover, &.active {
              color: #2196F3 !important;
              border-left: 4px solid #2196F3;
          }
      }
  </style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('v-row', {
    staticClass: "mb-n6"
  }, [_c('v-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-avatar', {
    staticClass: "me-3 mb-2",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-forum ")])], 1), _c('span', {
    staticClass: "text-h5 font-weight-bold grey--text text--darken-1"
  }, [_vm._v("Posts")])], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.items,
      "label": "Date Range",
      "solo": "",
      "dense": "",
      "flat": ""
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-card', [_c('v-card-text', {
    staticClass: "text-body-2 font-weight-bold grey--text text--darken-2"
  }, [_vm._v(" Number of members who posted messages ")]), _c('div', {
    staticClass: "pa-4 d-flex justify-space-between flex-wrap"
  }, [_vm.matchArray.length > 0 ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Group ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.groupsV2 ? _vm.groupsV2[0].name : ''))])]) : _vm._e(), _vm.matchDate ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Date ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.matchDate))])]) : _vm._e()]), _c('div', {
    staticClass: "mt-n10 pa-5"
  }, [_c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": "area",
      "height": "250",
      "options": _vm.chartOptions[0],
      "series": _vm.series
    }
  })], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-card', [_c('v-card-text', {
    staticClass: "text-body-2 font-weight-bold grey--text text--darken-2"
  }, [_vm._v(" Number of Members who posted reactions ")]), _c('div', {
    staticClass: "pa-4 d-flex justify-space-between flex-wrap"
  }, [_vm.matchArray.length > 0 ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Group ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.groupsV2 ? _vm.groupsV2[0].name : ''))])]) : _vm._e(), _vm.matchDate ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Date ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.matchDate))])]) : _vm._e()]), _c('div', {
    staticClass: "mt-n10 pa-5"
  }, [_c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": "area",
      "height": "250",
      "options": _vm.chartOptions[0],
      "series": _vm.series
    }
  })], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-card', [_c('v-card-text', {
    staticClass: "text-body-2 font-weight-bold grey--text text--darken-2"
  }, [_vm._v(" Number of posted messages ")]), _c('div', {
    staticClass: "pa-4 d-flex justify-space-between flex-wrap"
  }, [_vm.matchArray.length > 0 ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Group ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.groupsV2 ? _vm.groupsV2[0].name : ''))])]) : _vm._e(), _vm.matchDate ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Date ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.matchDate))])]) : _vm._e()]), _c('div', {
    staticClass: "mt-n10 pa-5"
  }, [_c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": "area",
      "height": "250",
      "options": _vm.chartOptions[0],
      "series": _vm.series
    }
  })], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-card', [_c('v-card-text', {
    staticClass: "text-body-2 font-weight-bold grey--text text--darken-2"
  }, [_vm._v(" Number of posted reactions ")]), _c('div', {
    staticClass: "pa-4 d-flex justify-space-between flex-wrap"
  }, [_vm.matchArray.length > 0 ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Group ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.groupsV2 ? _vm.groupsV2[0].name : ''))])]) : _vm._e(), _vm.matchDate ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Date ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.matchDate))])]) : _vm._e()]), _c('div', {
    staticClass: "mt-n10 pa-5"
  }, [_c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": "area",
      "height": "250",
      "options": _vm.chartOptions[0],
      "series": _vm.series
    }
  })], 1)])], 1)], 1)], 1)], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="card-hover-icon">
        <div class="d-flex flex-column p-absolute right-0 z-1 mr-2 mt-1 card-hover-icon">
            <!-- dialog -- modal  -->
            <v-dialog  v-model="dialog" max-width="600px">
                <base-card>
                    <v-card-title>
                        <h3 class="mt-4 mb-4 secondary--text text-wrap">{{modalTitle}}</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <p class="grey--text text--darken-1 mb-3">{{modalParagraph}}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        elevation="0"
                        color="primary"
                        dark
                        @click.stop="close(false)"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        elevation="0"
                        color="primary"
                        dark
                        @click.stop="confirm(false)"
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>                    
                </base-card>
            </v-dialog>
            <!-- end dialog end modal  -->
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
  export default {
    props: {
      modalTitle: {
        type: String,
        default: 'Please confirm action'
      },
      modalParagraph: {
        type: String,
        default: 'Are you sure you want to do this?'
      },
      modalType: {
        type: String,
        default: ''
      },      
    },
    data() {
        return {
            modal: this.isVisible
        }
    },
    updated(){
      // this.modal = false
    },
    computed: {
        ...mapGetters('app', ['isVisible']),
        dialog: {
            get() {
                this.modal = this.isVisible
                return this.modal;
            },
            set(value){
                this.modal = value
            },
        },
    },
    methods: {
        close(status){
            this.$store.commit('app/SET_MODAL_STATUS', status);
        },
        confirm(status){
          this.$store.commit('app/SET_MODAL_STATUS', status);
          this.$emit('modal-response', this.modalType);  
        }
    }
  }
</script>

<style>

</style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "content-class": "category_menu"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          class: {
            'd-none': _vm.$route.path == '/home-two'
          },
          attrs: {
            "icon": "",
            "tile": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-view-dashboard")])], 1)];
      }
    }])
  }, [_c('Navbar')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
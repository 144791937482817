var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('workflow-editor', {
    attrs: {
      "showEditor": _vm.lessonFullScreen,
      "lessonData": _vm.lessonPayload
    },
    on: {
      "reset-full-screen-mode": function ($event) {
        _vm.lessonFullScreen = false;
      }
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center mt-n2"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-certificate ")])], 1), _c('h2', {
    staticClass: "mb-0 grey--text text--darken-1 mb-1"
  }, [_vm._v("Manage Workflow")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), _c('v-card', {
    staticClass: "mb-8"
  }, [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "text-h5 font-weight-bold"
  }, [_vm._v("Workflow Details")])], 1), _c('v-tabs', {
    attrs: {
      "vertical": ""
    }
  }, [_c('v-tab', [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-information-outline ")]), _c('span', {
    staticClass: "text-none text-body-1 grey--text text--darken-2"
  }, [_vm._v("Information")])], 1), _c('v-tab', [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-palette ")]), _c('span', {
    staticClass: "text-none text-body-1 grey--text text--darken-2"
  }, [_vm._v("Appearance")])], 1), _c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-security ")]), _c('span', {
    staticClass: "text-none text-body-2 grey--text text--darken-2"
  }, [_vm._v("Access Control")])], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-expansion-panels', {
    staticClass: "mt-n4",
    attrs: {
      "flat": "",
      "bordered": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "text-h6 font-weight-bold grey--text text--darken-2"
  }, [_vm._v(" Workflow Information ")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "outlined": "",
      "counter": 30,
      "dense": "",
      "label": "Workflow Name"
    },
    model: {
      value: _vm.workflowDetails.workflowName,
      callback: function ($$v) {
        _vm.$set(_vm.workflowDetails, "workflowName", $$v);
      },
      expression: "workflowDetails.workflowName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "mt-n4",
    attrs: {
      "rows": "2",
      "color": "secondary",
      "label": "Workflow Description",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.workflowDetails.workflowDescription,
      callback: function ($$v) {
        _vm.$set(_vm.workflowDetails, "workflowDescription", $$v);
      },
      expression: "workflowDetails.workflowDescription"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "mt-n4",
    attrs: {
      "rows": "2",
      "color": "secondary",
      "label": "Learning Objectives",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.workflowDetails.learningObjectives,
      callback: function ($$v) {
        _vm.$set(_vm.workflowDetails, "learningObjectives", $$v);
      },
      expression: "workflowDetails.learningObjectives"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "mt-n4",
    attrs: {
      "rows": "2",
      "color": "secondary",
      "label": "Prerequisites",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.workflowDetails.prerequisites,
      callback: function ($$v) {
        _vm.$set(_vm.workflowDetails, "prerequisites", $$v);
      },
      expression: "workflowDetails.prerequisites"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    staticClass: "pb-2 mt-n4",
    attrs: {
      "items": _vm.tags,
      "item-text": 'label',
      "item-value": 'label',
      "chips": "",
      "label": "Tags",
      "multiple": "",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.workflowDetails.tags,
      callback: function ($$v) {
        _vm.$set(_vm.workflowDetails, "tags", $$v);
      },
      expression: "workflowDetails.tags"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    staticClass: "mt-n6",
    attrs: {
      "items": ['Beginner', 'Intermediate', 'Advanced'],
      "item-text": 'name',
      "color": "grey--text text--darken-1",
      "label": "Difficulty",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.workflowDetails.difficulty,
      callback: function ($$v) {
        _vm.$set(_vm.workflowDetails, "difficulty", $$v);
      },
      expression: "workflowDetails.difficulty"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "ml-6 mr-6 pt-2",
    attrs: {
      "outlined": ""
    }
  }, [_c('p', {
    staticClass: "ml-6 grey--text text--darken-2"
  }, [_vm._v("Duration: "), _c('strong', [_vm._v(_vm._s(_vm.sumOfWorkflowDuration) + " mins")])]), _c('p', {
    staticClass: "ml-6 mt-n1 grey--text text--darken-2"
  }, [_vm._v("Sections: "), _c('strong', [_vm._v(_vm._s(_vm.countOfSections))])]), _c('p', {
    staticClass: "ml-6 mt-n1 grey--text text--darken-2"
  }, [_vm._v("Lessons: "), _c('strong', [_vm._v(_vm._s(_vm.countOfLessons))])])])], 1)], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "text-h6 font-weight-bold grey--text text--darken-2"
  }, [_vm._v("Thumbnail Image")]), _vm.workflowDetails.thumbnailImageUrl ? _c('v-card', {
    staticClass: "text-center mb-2 mt-n2",
    staticStyle: {
      "max-width": "125px"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "100",
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "src": _vm.workflowDetails.thumbnailImageUrl
    }
  })], 1)], 1) : _vm._e(), _c('upload-file', {
    ref: "uploadFile",
    attrs: {
      "icon": "mdi-image",
      "label": "Thumbnail",
      "type": "Thumbnail"
    },
    on: {
      "upload-details": function ($event) {
        _vm.uploadDetails = $event;
      },
      "media-url": function ($event) {
        _vm.workflowDetails.thumbnailImageUrl = $event;
      },
      "media-name": function ($event) {
        _vm.workflowDetails.thumbnailImage = $event;
      }
    }
  }), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "disabled": !_vm.uploadDetails || _vm.uploadDetails.type !== 'Thumbnail'
    },
    on: {
      "click": _vm.uploadThumbnail
    }
  }, [_vm._v(" Upload ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "text-h6 font-weight-bold grey--text text--darken-2"
  }, [_vm._v("Cover Image")]), _vm.workflowDetails.coverImageUrl ? _c('v-card', {
    staticClass: "text-center mb-2 mt-n2",
    staticStyle: {
      "max-width": "125px"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('v-avatar', {
    staticClass: "align-self-stretch",
    attrs: {
      "size": "100",
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "src": _vm.workflowDetails.coverImageUrl
    }
  })], 1)], 1) : _vm._e(), _c('upload-file', {
    ref: "uploadFile2",
    attrs: {
      "icon": "mdi-image",
      "label": "Cover",
      "type": "Cover"
    },
    on: {
      "upload-details": function ($event) {
        _vm.uploadDetails = $event;
      },
      "media-url": function ($event) {
        _vm.workflowDetails.coverImageUrl = $event;
      },
      "media-name": function ($event) {
        _vm.workflowDetails.coverImage = $event;
      }
    }
  }), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "disabled": !_vm.uploadDetails || _vm.uploadDetails.type !== 'Cover'
    },
    on: {
      "click": _vm.uploadCoverImage
    }
  }, [_vm._v(" Upload ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Control which group has access to this workflow ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.groups,
      "item-text": 'name',
      "color": "grey--text text--darken-1",
      "label": "Group Access",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.workflowDetails.accessControl,
      callback: function ($$v) {
        _vm.$set(_vm.workflowDetails, "accessControl", $$v);
      },
      expression: "workflowDetails.accessControl"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-8",
    attrs: {
      "color": "transparent",
      "outlined": ""
    }
  }, [_c('vue-nestable', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var item = _ref.item;
        return _c('vue-nestable-handle', {
          attrs: {
            "item": item,
            "hasParent": _vm.hasParent(item)
          },
          on: {
            "edit-item": function ($event) {
              return _vm.editModal($event);
            },
            "edit-item-full-screen": function ($event) {
              return _vm.editLessonFullScreen($event);
            },
            "delete-item": function ($event) {
              return _vm.deleteModal($event);
            }
          }
        }, [_c('sub', {
          staticClass: "ml-n12 mr-2",
          staticStyle: {
            "min-width": "50px"
          }
        }, [_vm._v(" " + _vm._s(item.duration) + "mins ")]), _c('v-icon', {
          staticClass: "mt-n1",
          attrs: {
            "color": "grey",
            "small": ""
          }
        }, [_vm._v(" mdi-dots-vertical ")]), _c('v-icon', {
          staticClass: "ml-n5 mt-n1",
          attrs: {
            "color": "grey",
            "small": ""
          }
        }, [_vm._v(" mdi-dots-vertical ")]), _c('span', {
          staticClass: "text-body-1 grey--text text--darken-2 font-weight-medium ml-4 text-none"
        }, [_vm._v(_vm._s(item.level))])], 1);
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return [_c('div', {
          staticClass: "add-item"
        }, [_c('v-btn', {
          staticClass: "ml-10",
          attrs: {
            "icon": "",
            "plain": ""
          },
          on: {
            "click": function ($event) {
              return _vm.addRow(item, index);
            }
          }
        }, [_c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-plus ")]), _vm._v(" Add Lesson ")], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.workflowDetails.tableOfContents,
      callback: function ($$v) {
        _vm.$set(_vm.workflowDetails, "tableOfContents", $$v);
      },
      expression: "workflowDetails.tableOfContents"
    }
  }), _c('div', {
    staticClass: "add-item ma-2 pa-4"
  }, [_c('v-btn', {
    staticClass: "ml-12",
    attrs: {
      "icon": "",
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.addSection();
      }
    }
  }, [_c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" Add Section ")], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "height": "150px"
    }
  })], 1)])])])], 1), _vm.saveButton ? _c('v-btn', {
    staticClass: "text-none text-bold mb-12",
    attrs: {
      "fixed": "",
      "dark": "",
      "color": "darkGrey",
      "bottom": "",
      "right": "",
      "plain": "",
      "elevation": "10"
    },
    on: {
      "click": function ($event) {
        _vm.saveButton = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mt-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-close ")]), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("Cancel")])], 1) : _vm._e(), _vm.saveButton ? _c('v-btn', {
    staticClass: "text-none text-bold",
    attrs: {
      "fixed": "",
      "dark": "",
      "color": "primary",
      "bottom": "",
      "right": "",
      "elevation": "10"
    },
    on: {
      "click": function ($event) {
        _vm.updateWorkflow(), _vm.saveButton = false;
      }
    }
  }, [_c('span', {
    staticClass: "ml-1"
  }, [_vm._v("Save Changes")])]) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.deleteItemModal,
      callback: function ($$v) {
        _vm.deleteItemModal = $$v;
      },
      expression: "deleteItemModal"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('h3', {
    staticClass: "secondary--text text-wrap"
  }, [_vm._v("Please confirm action")])]), _c('v-card-text', [_c('p', {
    staticClass: "grey--text text--darken-2 mb-3"
  }, [_vm._v("Are you sure you want to delete this?")])]), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.deleteItemModal = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.removeItem(_vm.deleteItemPayload), _vm.deleteItemModal = false;
      }
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.editItemModal,
      callback: function ($$v) {
        _vm.editItemModal = $$v;
      },
      expression: "editItemModal"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('h3', {
    staticClass: "secondary--text text-wrap"
  }, [_vm._v(_vm._s(("Edit " + (_vm.editItemPayload ? _vm.editItemPayload.level : '') + " Name")))])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "outlined": "",
      "counter": 30,
      "dense": "",
      "label": "Name"
    },
    model: {
      value: _vm.foundItemDescription,
      callback: function ($$v) {
        _vm.foundItemDescription = $$v;
      },
      expression: "foundItemDescription"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_vm.editItemPayload && _vm.editItemPayload.level === 'Lesson' ? _c('v-select', {
    staticClass: "mt-n4 mb-n8",
    attrs: {
      "items": _vm.durations,
      "color": "secondary",
      "outlined": "",
      "dense": "",
      "label": "Duration (mins)"
    },
    model: {
      value: _vm.editItemPayload.duration,
      callback: function ($$v) {
        _vm.$set(_vm.editItemPayload, "duration", $$v);
      },
      expression: "editItemPayload.duration"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_vm.editItemPayload && _vm.editItemPayload.level === 'Lesson' ? _c('v-btn', {
    staticClass: "ml-12 mt-n1 text-none",
    attrs: {
      "color": "primary",
      "icon": "",
      "text": "",
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.editLesson(_vm.editItemPayload);
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-fullscreen ")]), _vm._v(" Edit Lesson ")], 1) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none mr-2 mb-2 mt-n2",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.editItemModal = false;
      }
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pt-5"
  }, [_c('router-link', {
    staticClass: "dashboard-nav-link px-4",
    attrs: {
      "to": "/dashboard"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-2",
    attrs: {
      "src": require("@/assets/images/icons/chart-bar.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v("Dashboard")])])]), _c('router-link', {
    staticClass: "dashboard-nav-link px-4",
    attrs: {
      "to": "/view-profile"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-2",
    attrs: {
      "src": require("@/assets/images/icons/user_filled.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v("Profile")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "text-sm secondary--text px-5 pt-6"
  }, [_vm._v(" DASHBOARD ")]), _c('v-list-group', {
    staticClass: "ml-1 mt-n3 mb-2",
    attrs: {
      "no-action": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-icon', {
          staticClass: "icon mr-2",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" mdi-view-dashboard ")]), _c('v-list-item-content', [_c('v-list-item-subtitle', {
          staticClass: "primary--text",
          domProps: {
            "textContent": _vm._s('Analytics')
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ml-4 mt-1"
  }, [_c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/summary"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-chart-pie ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Summary")])], 1)])], 1), _c('p', {
    staticClass: "text-sm secondary--text px-1 pt-n4"
  }, [_vm._v(" REPORTS ")]), _c('v-list-group', {
    staticClass: "ml-1 mt-n3",
    attrs: {
      "no-action": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-list-item-content', [_c('v-list-item-subtitle', {
          staticClass: "primary--text",
          domProps: {
            "textContent": _vm._s('Members')
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ml-4 mt-1"
  }, [_c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/total-members"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "mr-n1 font-weight-bold primary--text text-sm"
  }, [_vm._v("#")]), _c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-account ")]), _c('span', {
    staticClass: "primary--text ml-n1"
  }, [_vm._v("Total Members")])], 1)])], 1), _c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/new-members"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-account-plus ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("New Members")])], 1)])], 1), _c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/top-members"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-account-star ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Top Members")])], 1)])], 1), _c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/potential-champions"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-medal ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Potential Champions")])], 1)])], 1)], 1)]), _c('v-list-group', {
    staticClass: "ml-1 mt-n2",
    attrs: {
      "no-action": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-list-item-content', [_c('v-list-item-subtitle', {
          staticClass: "primary--text",
          domProps: {
            "textContent": _vm._s('Engagement')
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ml-4"
  }, [_c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2 mt-2",
    attrs: {
      "to": "/analytics/posts"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-forum ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Posts")])], 1)])], 1), _c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/events"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-ticket ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Events")])], 1)])], 1), _c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/courses"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-certificate ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Courses")])], 1)])], 1), _c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/meetups"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-account-multiple-check ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Meetups")])], 1)])], 1)], 1)]), _c('v-list-group', {
    staticClass: "ml-1 mt-n2",
    attrs: {
      "no-action": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-list-item-content', [_c('v-list-item-subtitle', {
          staticClass: "primary--text",
          domProps: {
            "textContent": _vm._s('Retention')
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ml-4"
  }, [_c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/retention"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-account-clock ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Retention")])], 1)])], 1), _c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/flight-risk"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-account-alert-outline ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Flight Risk")])], 1)])], 1)], 1)]), _c('v-list-group', {
    staticClass: "ml-1 mt-n2",
    attrs: {
      "no-action": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-list-item-content', [_c('v-list-item-subtitle', {
          staticClass: "primary--text",
          domProps: {
            "textContent": _vm._s('Responsiveness')
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ml-4"
  }, [_c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/analytics/responsiveness"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-timer-sand ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Responsiveness")])], 1)])], 1)], 1)])], 1)]), _c('router-link', {
    staticClass: "dashboard-nav-link px-4",
    attrs: {
      "to": "/member-directory"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-account-group ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Members")])], 1), _c('span', {
    staticClass: "secondary--text mb-1"
  }, [_c('v-badge', {
    attrs: {
      "color": "primary",
      "inline": "",
      "content": _vm.memberDirectory.person ? _vm.memberDirectory.person.length > 0 ? _vm.memberDirectory.person.length : '0' : '0'
    }
  })], 1)]), _c('router-link', {
    staticClass: "dashboard-nav-link px-4",
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-shape ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Groups")])], 1), _c('span', {
    staticClass: "secondary--text mb-1"
  }, [_c('v-badge', {
    attrs: {
      "color": "primary",
      "inline": "",
      "content": _vm.groupList ? _vm.groupList.length : 0
    }
  })], 1)]), _c('router-link', {
    staticClass: "dashboard-nav-link px-4",
    attrs: {
      "to": "/matches"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-history ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Matches")])], 1), _c('v-badge', {
    attrs: {
      "color": "primary",
      "inline": "",
      "content": _vm.matchList ? _vm.matchList.length > 0 ? _vm.matchList.length : '0' : '0'
    }
  })], 1), _c('router-link', {
    staticClass: "dashboard-nav-link px-4",
    attrs: {
      "to": "/events"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-ticket ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Events")])], 1), _c('v-badge', {
    attrs: {
      "color": "primary",
      "inline": "",
      "content": _vm.matchList ? _vm.matchList.length > 0 ? _vm.matchList.length : '0' : '0'
    }
  })], 1), _c('router-link', {
    staticClass: "dashboard-nav-link px-4",
    attrs: {
      "to": "/courses"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-certificate ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Courses")])], 1), _c('v-badge', {
    attrs: {
      "color": "primary",
      "inline": "",
      "content": _vm.matchList ? _vm.matchList.length > 0 ? _vm.matchList.length : '0' : '0'
    }
  })], 1), _c('v-list-group', {
    staticClass: "ml-1 mt-n3",
    attrs: {
      "no-action": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-icon', {
          staticClass: "icon mr-2",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" mdi-lightning-bolt ")]), _c('v-list-item-content', [_c('v-list-item-subtitle', {
          staticClass: "primary--text",
          domProps: {
            "textContent": _vm._s('Automations')
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ml-8 mt-1"
  }, [_c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/schedules"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-clock-time-four ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Schedules")])], 1), _c('div', {
    staticClass: "ml-9"
  }), _c('span', {
    staticClass: "secondary--text mb-1 ml-10"
  }, [_c('v-badge', {
    attrs: {
      "color": "primary",
      "inline": "",
      "content": _vm.scheduleList ? _vm.scheduleList.length : 0
    }
  })], 1)])], 1), _c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link px-2",
    attrs: {
      "to": "/workflows"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-sitemap ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Workflows")])], 1), _c('div', {
    staticClass: "ml-9"
  }), _c('span', {
    staticClass: "secondary--text mb-1 ml-9"
  }, [_c('v-badge', {
    attrs: {
      "color": "primary",
      "inline": "",
      "content": _vm.memberDirectory ? _vm.memberDirectory.workflows.length > 0 ? _vm.memberDirectory.workflows.length : '0' : '0'
    }
  })], 1)])], 1)], 1)]), _c('p', {
    staticClass: "text-sm secondary--text px-5 pt-6"
  }, [_vm._v(" ACCOUNT SETTINGS ")]), _c('router-link', {
    staticClass: "dashboard-nav-link px-4",
    attrs: {
      "to": "/view-profile"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-account ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Profile")])], 1)]), _c('router-link', {
    staticClass: "dashboard-nav-link px-4",
    attrs: {
      "to": "/manage-billing"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-credit-card ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Billing")])], 1)]), _c('v-list-group', {
    staticClass: "ml-1 mt-n3",
    attrs: {
      "no-action": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-icon', {
          staticClass: "icon mr-2",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" mdi-storefront ")]), _c('v-list-item-content', [_c('v-list-item-subtitle', {
          staticClass: "primary--text",
          domProps: {
            "textContent": _vm._s('Merchant')
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ml-8 mt-1"
  }, [_c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link",
    attrs: {
      "to": "/manage-paywall"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-wallet ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Paywall")])], 1)])], 1), _c('v-list-item', [_c('router-link', {
    staticClass: "dashboard-nav-link",
    attrs: {
      "to": "/manage-transactions"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "icon mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-invoice-text ")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Transactions")])], 1)])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center mt-n2"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/icons/account-group.svg"),
      "alt": ""
    }
  })]), _c('h2', {
    staticClass: "mb-0 secondary--text"
  }, [_vm._v("Manage Group")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Group Settings")])], 1), _c('v-tabs', {
    attrs: {
      "vertical": ""
    }
  }, [_c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-information ")]), _vm._v(" Details ")], 1), _c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-tag ")]), _vm._v(" Pricing ")], 1), _c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-cog ")]), _vm._v(" Config ")], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Group Details ")]), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "5",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "outlined": "",
      "counter": 30,
      "dense": "",
      "label": "Name"
    },
    model: {
      value: _vm.groupDetails.name,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails, "name", $$v);
      },
      expression: "groupDetails.name"
    }
  })], 1), _c('v-col', [_c('v-textarea', {
    staticClass: "mt-n4",
    attrs: {
      "counter": "100",
      "outlined": "",
      "name": "input-7-4",
      "label": "Description",
      "value": "",
      "rows": "1"
    },
    model: {
      value: _vm.groupDetails.description,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails, "description", $$v);
      },
      expression: "groupDetails.description"
    }
  })], 1), _c('v-col', [_c('v-select', {
    staticClass: "mb-n6 mt-n4",
    attrs: {
      "items": _vm.interests,
      "item-text": 'label',
      "item-value": 'label',
      "chips": "",
      "label": "Interests",
      "multiple": "",
      "outlined": ""
    },
    model: {
      value: _vm.groupDetails.interests,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails, "interests", $$v);
      },
      expression: "groupDetails.interests"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.groupNameUpdated();
      }
    }
  }, [_vm._v(" Save Changes ")])], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Subscription Details ")]), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "outlined": "",
      "counter": 50,
      "dense": "",
      "label": "Name"
    },
    model: {
      value: _vm.groupDetails.subscriptionModel.productName,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails.subscriptionModel, "productName", $$v);
      },
      expression: "groupDetails.subscriptionModel.productName"
    }
  })], 1), _c('v-col', [_c('v-textarea', {
    staticClass: "mt-n4",
    attrs: {
      "counter": "100",
      "outlined": "",
      "name": "input-7-4",
      "label": "Description",
      "value": "",
      "rows": "1"
    },
    model: {
      value: _vm.groupDetails.subscriptionModel.productDescription,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails.subscriptionModel, "productDescription", $$v);
      },
      expression: "groupDetails.subscriptionModel.productDescription"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "5",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-select', {
    staticClass: "mt-n4",
    attrs: {
      "items": ['Free', 'Chargeable'],
      "color": "secondary",
      "label": "Charge Status",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.groupDetails.subscriptionModel.chargeStatus,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails.subscriptionModel, "chargeStatus", $$v);
      },
      expression: "groupDetails.subscriptionModel.chargeStatus"
    }
  })], 1), _c('v-col', [_vm.groupDetails.subscriptionModel.chargeStatus == 'Chargeable' ? _c('v-card', {
    staticClass: "mt-n6",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Price",
      "prepend-inner-icon": "mdi-currency-usd"
    },
    model: {
      value: _vm.groupDetails.subscriptionModel.price,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails.subscriptionModel, "price", $$v);
      },
      expression: "groupDetails.subscriptionModel.price"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n10 mb-n4"
  }, [_c('sub', {}, [_vm._v("How often the subscription will recur. For example, Recurrence=month and \"Recurrence Frequency\"=3 bills every 3 months")])]), _c('v-col', [_c('v-select', {
    attrs: {
      "items": ['day', 'week', 'month', 'year'],
      "label": "Recurrence",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.groupDetails.subscriptionModel.recurringInterval,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails.subscriptionModel, "recurringInterval", $$v);
      },
      expression: "groupDetails.subscriptionModel.recurringInterval"
    }
  }), _c('v-select', {
    attrs: {
      "items": [1, 3],
      "outlined": "",
      "dense": "",
      "label": "Recurrence Frequency"
    },
    model: {
      value: _vm.groupDetails.subscriptionModel.recurringCount,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails.subscriptionModel, "recurringCount", $$v);
      },
      expression: "groupDetails.subscriptionModel.recurringCount"
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "pa-4 d-flex justify-space-between flex-wrap",
    class: _vm.groupDetails.subscriptionModel.chargeStatus == 'Chargeable' ? '' : 'mt-n10',
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.groupNameUpdated();
      }
    }
  }, [_vm._v(" Save Changes ")]), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "color": "primary",
      "outlined": "",
      "loading": _vm.isLoading,
      "disabled": _vm.groupDetails.subscriptionModel.price === 0 || _vm.isLoading
    },
    on: {
      "click": function ($event) {
        return _vm.createProduct();
      }
    }
  }, [_vm._v(" Add to Stripe ")])], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Group Configuration ")]), _c('div', {
    staticClass: "d-flex justify-start ml-n3 mb-n2"
  }, [_c('v-switch', {
    staticClass: "mt-1 ml-3",
    attrs: {
      "color": "primary",
      "label": "Active"
    },
    model: {
      value: _vm.groupDetails.isActive,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails, "isActive", $$v);
      },
      expression: "groupDetails.isActive"
    }
  }), _c('v-switch', {
    staticClass: "mt-1 ml-6",
    attrs: {
      "color": "primary",
      "label": "Public"
    },
    model: {
      value: _vm.groupDetails.isPublic,
      callback: function ($$v) {
        _vm.$set(_vm.groupDetails, "isPublic", $$v);
      },
      expression: "groupDetails.isPublic"
    }
  })], 1)]), _c('v-col', {
    staticClass: "mt-n2 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.groupNameUpdated();
      }
    }
  }, [_vm._v(" Save Changes ")])], 1)], 1)], 1)], 1)], 1), [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.filteredMembers,
      "sort-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "item.avatar",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-img', {
          attrs: {
            "src": item.avatar,
            "max-height": "50px",
            "max-width": "50px"
          }
        })];
      }
    }, {
      key: "item.authProvider",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-avatar', {
          attrs: {
            "size": "25"
          }
        }, [_c('img', {
          attrs: {
            "src": require(("@/assets/images/logos/" + (item.authProvider) + ".svg"))
          }
        })])];
      }
    }, {
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-dialog', {
          attrs: {
            "max-width": "600px",
            "retain-focus": false
          },
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v;
            },
            expression: "dialogDelete"
          }
        }, [_c('base-card', [_c('v-card-title', [_c('h3', {
          staticClass: "mt-4 mb-2 secondary--text text-wrap"
        }, [_vm._v("Are you sure you want to block this member?")])]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('p', {
          staticClass: "grey--text text--darken-5 mb-3"
        }, [_vm._v("Blocked members will no longer be included in regular check-ins.")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "primary",
            "dark": ""
          },
          on: {
            "click": _vm.closeDelete
          }
        }, [_vm._v("Cancel ")]), _c('v-btn', {
          attrs: {
            "color": "primary",
            "dark": ""
          },
          on: {
            "click": _vm.deleteItemConfirm
          }
        }, [_vm._v(" Confirm ")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.resumeDate",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_c('v-icon', {
          staticClass: "icon",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
          staticClass: "ma-1 secondary--text text-sm"
        }, [_vm._v(_vm._s(_vm.formatDateTime(item.resumeDate)))])], 1)];
      }
    }, {
      key: "item.isDeletedFromDirectory",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-switch', {
          attrs: {
            "color": "primary"
          },
          model: {
            value: item.isDeletedFromDirectory,
            callback: function ($$v) {
              _vm.$set(item, "isDeletedFromDirectory", $$v);
            },
            expression: "item.isDeletedFromDirectory"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-icon', {
          attrs: {
            "medium": ""
          },
          on: {
            "click": function ($event) {
              _vm.deleteItem(_vm.filteredMembers.indexOf(item));
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.initialize
          }
        }, [_vm._v(" Reset ")])];
      },
      proxy: true
    }])
  })]], 2)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import {
  readUserProfile,
  readScheduleHistory,
  readScheduleHistoryAggregate,
  readMemberDirectory,
  upsertUserProfile,
  readGroup,
  readGroups,
  deleteGroup,
  upsertGroup,
  readSchedule,
  readSchedules,
  upsertSchedule,
  readEvent,
  readEvents,
  upsertEvent,
  readCourse,
  readCourses,
  upsertCourse,
  readLesson,
  upsertLesson,
  readMatches,
  upsertGroupName,
  insertMerchantDetails,
  readOnboardingLink,
  upsertChargeStatus,
  createProduct,
  upsertGroupMemberById,
  deleteGroupMemberById,
  deleteMember,
  createUserProfile,
  upsertWorkflow
} from '../../common/api.service';
import router from '../../router';

export default {
  namespaced: true,
  state: {
    colorCombinations: [],
    userProfile: [],
    scheduleHistory: [],
    scheduleHistoryAggregate: [],
    memberDirectory: [],
    group: [],
    groupList: [],
    matchList: [],
    match: [],
    schedule: [],
    scheduleList: [],
    tempGroup: [],
    currentUser: [],
    wishList: [],
    shortList: [],
    deletedMemberListId: [],
    deletedListId: null,
    event: [],
    eventList: [],
    course: [],
    courseList: [],
    lesson: [],
    workflow: [],
    workflowList: [],
  },

  actions: {
    getUser(context, userEmail) {
      return readUserProfile.getById(userEmail).then(res => {
        context.commit('USER_DETAILS_UPDATED', res.data.userProfile)
      });
    },
    getScheduleHistory(context, payload) {
      return readScheduleHistory.getById(payload).then(res => {
        context.commit('SCHEDULE_HISTORY_UPDATED', res.data.schedule)
      });
    },
    getScheduleHistoryAggregate(context, userEmail) {
      return readScheduleHistoryAggregate.getById(userEmail).then(res => {
        context.commit('SCHEDULE_HISTORY_AGGREGATE_UPDATED', res.data.ScheduleHistoryAggregate)
      });
    },   
    getMemberDirectory(context, payload) {
      return readMemberDirectory.getById(payload).then(res => {
        context.commit('MEMBER_DIRECTORY_DETAILS_UPDATED', res.data.memberDirectory)
      });
    },
    getGroup(context, payload) {
      return readGroup.getById(payload).then(res => {
        context.commit('GROUP_DETAILS_UPDATED', res.data.group)
      });
    },
    getGroups(context, payload) {
      return readGroups.get(payload).then(res => {
        context.commit('GROUP_LIST_UPDATED', res.data.groups)
      });
    },
    getSchedule(context, payload) {
      return readSchedule.getById(payload).then(res => {
        context.commit('SCHEDULE_DETAILS_UPDATED', res.data.schedule)
      });
    },
    getSchedules(context, payload) {
      return readSchedules.get(payload).then(res => {
        context.commit('SCHEDULE_LIST_UPDATED', res.data.schedules)
      });
    },
    getMatches({ state, commit }) {
      return readMatches.get(state.userProfile.userId).then(res => {
        commit('MATCH_LIST_UPDATED', res.data.matches)
      });
    },
    getEvent(context, payload) {
      return readEvent.getById(payload).then(res => {
        context.commit('EVENT_DETAILS_UPDATED', res.data.event)
      });
    },
    getEvents(context, payload) {
      return readEvents.get(payload).then(res => {
        context.commit('EVENT_LIST_UPDATED', res.data.events)
      });
    },
    async updateEvent({ state }, payload){
      return await upsertEvent.post(payload).then(res => {
        router.push({ path: `/manage-event?eventId=${res.data.NewEventId}` });
      })
    },
    getCourse(context, payload) {
      return readCourse.getById(payload).then(res => {
        context.commit('COURSE_DETAILS_UPDATED', res.data.course)
      });
    },
    getCourses(context, payload) {
      return readCourses.get(payload).then(res => {
        context.commit('COURSE_LIST_UPDATED', res.data.courses)
      });
    },
    async updateWorkflow({ state }, payload){
      return await upsertWorkflow.post(payload).then(res => {
        router.push({ path: `/workflow-editor?id=${res.data.NewWorkflowId}` });
      })
    },
    updateWorkflowNoRedirect({state}){
      var postData = {
        UserEmail: state.workflow.userId,
        WorkflowDetails: state.workflow
      }
      return upsertWorkflow.post(postData)
    },
    async updateCourse({ state }, payload){
      return await upsertCourse.post(payload).then(res => {
        router.push({ path: `/manage-course?courseId=${res.data.NewCourseId}` });
      })
    },
    updateCourseNoRedirect({state}){
      var postData = {
        UserEmail: state.course.userId,
        CourseDetails: state.course
      }
      return upsertCourse.post(postData)
    },
    updateLesson({state}, payload){
      return upsertLesson.post(payload)
    },
    updateLessonFromState({state}){
      var postData = {
        UserEmail: state.lesson.userId,
        Lesson: state.lesson
      }
      return upsertLesson.post(postData)
    },
    getLesson(context, payload) {
      return readLesson.getById(payload).then(res => {
        context.commit('LESSON_DETAILS_UPDATED', res.data.lesson)
      });
    },
    createUser({ commit }, payload) {
      console.log(payload)
      const postData = {
        ContactDetails: {
          Name: payload.username,
          Community: payload.community,
          PhoneNumber: payload.phoneNumber,
          EmailAddress: payload.email
        }
      };
      return createUserProfile.post(postData).then(res => {
        commit('USER_DETAILS_UPDATED', res.data);
      });
    },
    updateUser({ state, commit }, userEmail) {
      const postData = {
        UserEmail: state.userProfile.userId,
        UserProfileDetails: {
          UserName: state.userProfile.username,
          PersonalDetails: {
            name: state.userProfile.personalDetails.username,
            organisation: state.userProfile.personalDetails.organisation,
            areaCode : state.userProfile.personalDetails.areaCode,
            phoneNumber: state.userProfile.personalDetails.phoneNumber         
          },
        }
      };
      return upsertUserProfile.post(postData).then(res => {
        commit('USER_DETAILS_UPDATED', res.data.Resource);
      });
    },
    async updateGroup({ state }, payload){
      return await upsertGroup.post(payload).then(res => {
        router.push({ path: `/manage-group?groupId=${res.data.NewGroupId}` });
      })
    },
    addMerchantDetails({state}){
      return insertMerchantDetails.post({UserEmail: state.userProfile.id})
    },
    updateChargeStatusV2({context}, payload){
      const res = upsertChargeStatus.post(payload);
      context.commit('USER_DETAILS_UPDATED', res.data.userProfile);
    },

    getOnboardingLink({state}){
      const postData = {
        UserEmail: state.userProfile.id,
        AccountId: state.userProfile.merchant.stripeAccountId
      }
      return readOnboardingLink.get(postData)
    },
    updateGroupName({state}) {
      const postData = {
        UserEmail: state.group.userId,
        GroupDetails: [{
          id: state.group.id,
          name: state.group.name,
          description: state.group.description,
          isActive: true,
          interests: state.group.interests,
          subscriptionModel: state.group.subscriptionModel
        }]
      };
      return upsertGroupName.post(postData)
    },
    insertProduct({state}){
      const postData = {
        UserEmail: state.group.userId,
        Group: state.group.id
      };   
      return createProduct.post(postData)
    },
    updateGroupMemberById({state}) {
      const postData = {
        UserEmail: state.group.userId,
        Group:  state.group.id,       
        // take state.group.memberId and assign it a key of id for each element in the array
        MemberList: state.group.memberId.map((item, index) => {
          return { Id: item }
        })
      };
      return upsertGroupMemberById.post(postData)
    },
    deleteGroupMemberById({state}) {
      const postData = {
        UserEmail: state.group.userId,
        Group:  state.group.id,
        // take state.group.memberId and assign it a key of id for each element in the array     
        MemberList: state.deletedMemberListId.map((item, index) => {
          return { Id: item }
        })
      };

      return deleteGroupMemberById.post(postData)
    },
    delGroup({state}, payload) {
      return deleteGroup.post(payload)
    },
    delMember({state}, payload) {
      // console.log(postData)
      return deleteMember.post(payload)
    },
    updateSchedule({state}, payload){
      return upsertSchedule.post(payload)
    },
    setColorCombinations({state, commit}, payload){
      commit('SET_COLOR_COMBINATIONS', payload)
    }

  },
  getters : {
    getShortList : state => state.shortList,
    getWishList : state => state.wishList,
    getUserProfile: state => state.userProfile,
    getCurrentUser: state => state.currentUser,
    getMemberDirectoryGetter: state => state.memberDirectory,
},
  mutations: {
    RESET_USER_DETAILS_UPDATED_STATE: state => {
      state.userProfile = [];
    },
    CURRENT_USER_UPDATED: (state, payload) => {
      state.currentUser = payload;
    },    
    USER_DETAILS_UPDATED: (state, payload) => {
      state.userProfile = payload;
      state.shortList = payload.Shortlist;
    },
    MATCH_DETAILS_UPDATEDV2: (state, payload) => {
      state.match = payload;
    },
    SCHEDULE_HISTORY_UPDATED: (state, payload) => {
      state.scheduleHistory = payload;
    },
    SCHEDULE_HISTORY_AGGREGATE_UPDATED: (state, payload) => {
      state.scheduleHistoryAggregate = payload;
    },
    MEMBER_DIRECTORY_DETAILS_UPDATED: (state, payload) => {
      state.memberDirectory = payload;
    },
    GROUP_DETAILS_UPDATED: (state, payload) => {
      state.group = payload;
    },
    GROUP_LIST_UPDATED: (state, payload) => {
      state.groupList = payload;
    },
    MATCH_LIST_UPDATED: (state, payload) => {
      state.matchList = payload;
    },
    SCHEDULE_DETAILS_UPDATED: (state, payload) => {
      state.schedule = payload;
    },
    SCHEDULE_LIST_UPDATED: (state, payload) => {
      state.scheduleList = payload;
    },   
    EVENT_DETAILS_UPDATED: (state, payload) => {
      state.event = payload;
    },
    EVENT_LIST_UPDATED: (state, payload) => {
      state.eventList = payload;
    },
    WORKFLOW_DETAILS_UPDATED: (state, payload) => {
      state.workflow = payload;
    },
    WORKFLOW_LIST_UPDATED: (state, payload) => {
      state.workflowList = payload;
    },
    COURSE_DETAILS_UPDATED: (state, payload) => {
      state.course = payload;
    },
    COURSE_LIST_UPDATED: (state, payload) => {
      state.courseList = payload;
    },
    LESSON_DETAILS_UPDATED: (state, payload) => {
      state.lesson = payload;
    },
    LESSON_CONTENT_UPDATED: (state, payload) => {
      state.lesson.HtmlContent = payload.HtmlContent;
      state.lesson.duration = payload.Duration;
    },
    GROUP_NAME_UPDATED: (state, obj) => { 
      console.log(obj)    
      var foundIndex = state.group.findIndex(x => x.id == obj.id);
      state.group[foundIndex].groupName = obj.groupName
    },
    DELETE_USER_DETAILS_UPDATED: (state, Id) => {
      state.deletedListId = Id;
    },
    /* Add blank object to UserData - https://javascript.plainenglish.io/insert-an-element-in-specific-index-in-javascript-array-9c059e941a67 */
    ADD_USER_DATA_BLANK_OBJECT: (state, payload) => {
      state.userProfile.push(payload);
    },
    ADD_GROUP_LIST_BLANK_OBJECT: (state, payload) => {
      state.tempGroup.push(payload);
    },
    ADD_MEMBER_TO_GROUP: (state, payload) => {
      state.group.memberId.push(payload);
    },
    // ADD_MEMBER_TO_GROUP: (state, payload) => {
    //   state.deletedMemberListId.push(payload);
    // },
    REMOVE_MEMBER_FROM_GROUP: (state, payload) => {
      var foundIndex = state.group.memberId.findIndex(x => x == payload.id);
      state.group.memberId.splice(foundIndex, 1)
    }, 
    DELETED_MEMBER_LIST_ID_UPDATED: (state, payload) => {
      state.deletedMemberListId.push(payload);
    },
    SET_COLOR_COMBINATIONS: (state, payload) => {
      if(state.colorCombinations.length === 0) {
        state.colorCombinations = payload;
      }
    }  
  }
};

<template>
  <v-container class="py-6">
      <v-row>
        <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                   <DashbordSidebar />
                </div>
                <div class="box-content">
                    <div class="d-flex justify-end pa-2 d-block d-md-none">
                        <v-btn
                            icon   
                            @click="isSidebar = !isSidebar"
                        >
                            <v-icon dark>
                                mdi-format-list-bulleted-square
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="box-container">                        
                        <div class="d-flex justify-space-between flex-wrap mb-5">
                           <div class="d-flex align-center mt-n2 ">
                                <v-avatar tile size="25" class="me-3">
                                    <img src="@/assets/images/icons/clock-time-four.svg" alt="">
                                </v-avatar>
                                <h2 class="mb-0 secondary--text">Manage Schedules</h2>
                            </div>
                            <v-btn 
                                outlined 
                                color="primary" 
                                class="text-capitalize font-600"
                                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                            >
                                BACK
                            </v-btn>                             
                       </div>
                       <div class="mb-5">
                       <v-row>
                        <v-col cols="12">
                            <base-card>
                              <div class="pa-5">
                                  <div class="mb-4">
                                    <v-toolbar-title>Schedule Details</v-toolbar-title>
                                    </div>
                                  <v-row>
                                      <v-col cols="12" lg="6" >
                                          <v-text-field
                                              v-model="scheduleDetails.scheduleName"
                                              color="secondary"
                                              outlined
                                              :counter="30"
                                              dense
                                              label="Schedule Name"
                                          ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" lg="6">
                                        <v-select
                                          v-model="scheduleDetails.groupId"
                                          :items="groups"
                                          :item-text="'name'"
                                          :item-value="'id'"
                                          color="secondary"
                                          label="Group"
                                          outlined
                                          dense
                                      ></v-select>
                                      </v-col>
                                      <v-col cols="12" lg="6" >
                                        <v-card
                                          class="mx-auto"
                                          outlined
                                        >
                                        <div class="ma-5">
                                          <v-select
                                              v-model="scheduleDetails.recurrencePattern"
                                              :items="recurrencePatterns"
                                              color="secondary"
                                              outlined
                                              dense
                                              label="Recurrence Pattern"
                                          ></v-select>
                                          <!--https://stackoverflow.com/questions/51296834/vuejs-vuetify-how-to-access-properties-of-object-in-v-select-->
                                         <div v-if="scheduleDetails.recurrencePattern =='Daily'">
                                         </div>
                                         <div v-else>
                                            <div v-if="scheduleDetails.recurrencePattern =='Weekly'">
                                              <v-select
                                                  v-model="scheduleDetails.dayOfWeek"
                                                  :items="dayOfWeek"
                                                  :item-text="'name'"
                                                  :item-value="'code'"
                                                  color="secondary"
                                                  outlined
                                                  dense
                                                  label="Day of Week"
                                              ></v-select>
                                            </div>
                                            <div v-else>
                                              <div v-if="scheduleDetails.recurrencePattern =='Monthly'">
                                                <v-select
                                                    v-model="scheduleDetails.dayOfMonth"
                                                    :items="dayOfMonth"
                                                    color="secondary"
                                                    outlined
                                                    dense
                                                    label="Day of Month"
                                                ></v-select>
                                              </div>
                                            </div>
                                         </div>
                                        </div>
                                        </v-card>
                                      </v-col>
                                      <!-- <v-col cols="12" lg="6" >
                                        <v-select
                                              v-model="scheduleDetails.timeOfDay"
                                              :items="timeOfDay"
                                              :item-text="'label'"
                                              :item-value="'value'"
                                              color="secondary"
                                              outlined
                                              dense
                                              label="Time of Day"
                                          ></v-select>
                                      </v-col> -->
                                      <v-col cols="12" lg="6" >
                                        <v-card
                                          class="pa-4"
                                          outlined
                                        >
                                        <p class="text-11">Set Schedule (Optional)</p>
                                        <v-row>
                                          <v-spacer></v-spacer>
                                          <v-dialog
                                            v-model="modal"
                                            persistent
                                            width="300px"
                                          >
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-text-field
                                                v-model="scheduleDetails.scheduleDate"
                                                :disabled="!scheduleDetails.isEditable"
                                                label="Next Schedule Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                              ></v-text-field>
                                            </template>
                                            <v-date-picker
                                              v-model="scheduleDetails.scheduleDate"
                                              scrollable
                                            >
                                              <v-spacer></v-spacer>
                                              <v-btn
                                                text
                                                color="primary"
                                                @click="modal = false"
                                              >
                                                Cancel
                                              </v-btn>
                                              <v-btn
                                                text
                                                color="primary"
                                                @click="modal = false"
                                              >
                                                OK
                                              </v-btn>
                                            </v-date-picker>
                                          </v-dialog>  
                                          <v-spacer></v-spacer>
                                          <v-dialog
                                            v-model="modal2"
                                            persistent
                                            width="300px"
                                          >
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-text-field
                                                v-model="scheduleDetails.scheduleTime"
                                                :disabled="!scheduleDetails.isEditable"
                                                label="Schedule Time"
                                                prepend-icon="mdi-clock-time-four-outline"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                              ></v-text-field>
                                            </template>
                                            <v-time-picker
                                              v-if="modal2"
                                              v-model="scheduleDetails.scheduleTime"
                                              :allowed-hours="[0,3,6,9,12,15,18,21]"
                                              :allowed-minutes="[0]"
                                              full-width
                                            >
                                              <v-spacer></v-spacer>
                                              <v-btn
                                                text
                                                color="primary"
                                                @click="modal2 = false"
                                              >
                                                Cancel
                                              </v-btn>
                                              <v-btn
                                                text
                                                color="primary"
                                                @click="modal2 = false"
                                              >
                                                OK
                                              </v-btn>
                                            </v-time-picker>
                                          </v-dialog>
                                        </v-row>
                                        </v-card>
                                      </v-col>
                                      <v-col cols="12" lg="6" >
                                        <v-textarea
                                          v-model="scheduleDetails.scheduleMessage"
                                          counter="250"
                                          outlined
                                          name="input-7-4"
                                          label="Message"
                                          value=""
                                        >
                                      </v-textarea>
                                      </v-col>
                                      <v-col cols="12" lg="6"></v-col>
                                      <v-col cols="12" lg="6">
                                        <p class="text-11 mt-n8">Disable</p>
                                        <v-switch
                                          class="mt-n2"
                                          :label="`Schedule ${scheduleDetails.isDisabled===true? 'Disabled' : 'Enabled'}`"
                                          v-model="scheduleDetails.isDisabled"
                                          inset
                                        ></v-switch>
                                        <v-btn color="primary" class="text-capitalize font-600" @click="updateSchedule">
                                            Save Changes
                                        </v-btn>
                                      </v-col>
                                  </v-row>
                              </div>
                            </base-card>
                        </v-col>
                    </v-row>
                    </div>
                        <template>
                            <v-data-table
                                :headers="headers"
                                :items="scheduleDetails.scheduleHistory"
                                sort-by="name"
                                class="elevation-1"
                            >
                                <template v-slot:top>
                                  <v-toolbar
                                    flat
                                  >
                                    <v-toolbar-title>Schedule History</v-toolbar-title>
                                  </v-toolbar>
                                </template>
                                <template v-slot:item.sentDate="{ item }">
                                  <v-chip
                                  >
                                  <v-icon
                                    class="icon mr-2"
                                  >
                                    mdi-clock-time-four
                                  </v-icon>
                                    {{ formatDateTime(item.sentDate) }}
                                  </v-chip>
                                </template>                                
                                <template v-slot:item.actions="{ item, index }">       
                                  <div>
                                    <router-link
                                      style="text-decoration: none !important;"
                                      :to="{ path: '/match-details',
                                            query: {
                                              matchId: scheduleDetails.scheduleHistory[index].matchId
                                            }
                                      }"
                                    >
                                      <v-btn
                                        icon
                                      >
                                        <v-icon
                                        >
                                          mdi-arrow-right-circle
                                        </v-icon>
                                      </v-btn>
                                    </router-link>
                                  </div>
                                </template>
                                <template v-slot:no-data>
                                <v-btn
                                    color="primary"
                                    @click="initialize"
                                >
                                    Reset
                                </v-btn>
                                </template>
                            </v-data-table>
                        </template>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashbordSidebar from "@/components/DashboardSidebar.vue"
import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';

// const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
const cheqqMateServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
  }
};

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit('app/START_LOADING');
    return config;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    store.commit('app/FINISH_LOADING');
    return response;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

const defaultScheduleDetails = {
  id: "98bbc81e-d9c6-4b72-b57c-d4bd40da104b",
  type: "Schedules",
  userId: "tzishi@live.co.uk",
  groupId: "c6d32027-4577-4d86-9fca-146d4a11debd",
  scheduleName: "Ruzivo's Awesome Squad Schedule",
  recurrencePattern: null,
  dayOfWeek: 0,
  dayOfMonth: 0,
  timeOfDay: 0,
  scheduleDateTime: "2023-10-28T12:42:41.7290347Z",
  scheduleHistory: [],
};

  export default {
    components: {
        DashbordSidebar
    },    
    data: () => ({
      selectedMembers: null,
      scheduleId: '',
      selectedDayOfWeek: '',
      selectedDayOfMonth: '',
      selectedRecurrencePattern: '',
      dialog: false,
      file: {},
      fileData: "",
      singleSelect: false,
      dialog2: false,
      isSidebar: false,
      dialogDelete: false,
      uploadedFile: '',
      scheduleName: 'My Schedule',
      headers: [
        { text: 'Sent Date', align: 'start', sortable: true, value: 'sentDate'},
        // { text: 'Id', sortable: true, value: 'id'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headers2: [
        {
          text: 'Full Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Phone Number', value: 'phoneNumber', sortable: false },
        { text: 'Email Address', value: 'emailAddress', sortable: false },
        // { text: 'Resume Date', value: 'resumeDate', sortable: true },
        // { text: 'Is Archived', value: 'isDeletedFromDirectory', sortable: false },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],
      groupIds: ['3b9ca61d-3484-479c-8153-91a6ef2500c3','3b9ca61d-3484-479c-8153-91a6ef2500c5', '3b9ca61d-3484-479c-8153-91a6ef2500c8'],
      timeOfDay:[
        { label: 'Early Morning (3am - 6am)' ,value: 3 },
        { label: 'Morning (6am - 9am)' ,value: 6 },
        { label: 'Late Morning (9am-  12pm)' ,value: 9 },
        { label: 'Afternoon (12pm - 3pm)' ,value: 12 },
        { label: 'Late Afternoon (3pm - 6pm)' ,value: 15 },
        { label: 'Evening (6pm - 9pm)' ,value: 18 },
        { label: 'Late Evening (9pm - 12am)' ,value: 21 },
        { label: 'Night (12am - 3am)' ,value: 0 },
      ],
      recurrencePatterns: [
        'Daily',
        'Weekly',
        'Monthly'
      ],
      dayOfWeek: [
        {
          code: 0,
          name: 'Sunday',
        },
        {
          code: 1,
          name: 'Monday',
        },
        {
          code: 2,
          name: 'Tuesday',
        },
        {
          code: 3,
          name: 'Wednesday',
        },
        {
          code: 4,
          name: 'Thursday',
        },
        {
          code: 5,
          name: 'Friday',
        },
        {
          code: 6,
          name: 'Saturday',
        }
      ],
      date: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
      menu: false,
      modal: false,
      menu2: false,
      menu2: false,
      modal2: false,
      time: ['09:00'],
      dayOfMonth:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28],
      members: [],
      members2: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        phoneNumber: '',
        emailAddress: '',
        resumeDate: new Date().toJSON().slice(0,10),
        isDeletedFromDirectory: false,
      },
      defaultItem: {
        name: '',
        phoneNumber: '',
        emailAddress: '',
        resumeDate: new Date().toJSON().slice(0,10),
        isDeletedFromDirectory: false,
      },
    }),

    computed: {
      ...mapState('auth', ['authUser']),
      ...mapState('user', [ 'memberDirectory','schedule']),
      authUser () {
        return this.$store.state.auth.authUser
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Add Members' : 'Add Member'
      },
      scheduleDetails () {
        return this.schedule
      },
      // scheduleDetails: {
      //   get() {
      //     return [this.schedule]
      //       ? [this.schedule].map(schedule => {
      //           return {
      //             ...schedule,
      //             scheduleHistory: schedule.scheduleHistory? schedule.scheduleHistory.map(history => {
      //               return {
      //                 ...history,
      //                 sentDate: this.formatDateTime(history.sentDate)
      //               }
      //             })
      //             :[]
      //           }
      //         })[0]
      //       : defaultScheduleDetails  
      //   },
      //   set(value) {
      //     //update schedule array
      //     // this.$store.commit('user/SCHEDULE_DETAILS_UPDATED', value)
      //   },
      // },
      groups() {
        return this.$store.state.user.groupList
          ? this.$store.state.user.groupList
          : defaultUserProfile.groups
      },
      memberDirectory() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.memberDirectory
          : defaultMemberDirectory
      },
      userProfile() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.userProfile
          : defaultUserProfile
      },
      memberDirectoryPerson() {
        // return members where isDeletedFromDirectory is false
        return this.memberDirectory.person.filter(member => {
          return member.isDeletedFromDirectory == false;
        });
      } 
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialog2 (val) {
        val || this.close()
      },      
      dialogDelete (val) {
        val || this.closeDelete()
      },
      file(f) {
        if (f) {
            f.text().then((data) => {
            this.fileData = data;
            });
        }
      },
      "$route.query.scheduleId"(){
        this.scheduleId = this.$route.query.scheduleId
      }     
    },
    mounted () {
      if(this.$route.query.scheduleId) {
        this.scheduleId = this.$route.query.scheduleId
        this.readScheduleById()
      }
    },
    created () {
      this.initialize()
    },

    methods: {
      ...mapActions('user', ['getMemberDirectory','updateGroupName','updateScheduleById']),
      ...mapGetters('user', ['getShortList', 'getWishList', 'getUserProfile', 'getCurrentUser','getMemberDirectoryGetter']),    
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        formatDateTime (date) {
          return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
          // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        },
        convertDate (date) {
          return new Date(date).toISOString().split('T')[0]
        },
        convertTime (date) {
          return new Date(date).toLocaleTimeString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
        },
        createDateTime(dateString, time, idx) {
          const date = new Date(dateString[idx]);
          var timeParts = time[idx]?time[idx].split(":"):'00:00';
    
          date.setHours(timeParts[0]);
          date.setMinutes(timeParts[1]);
          return date;
        },                        
        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        season (val) {
            return this.range[val]
        },
        hasHistory () { 
            return window.history.length > 2 
        },        
        updateSchedule(){
          this.updateScheduleById();
        },
        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        close2 () {
          this.dialog2 = false
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        readScheduleById() {
          axios
          .get(
            `${cheqqMateServiceBaseUrl}/getScheduleById`+
              '?UserEmail=' + this.authUser.email + '&ScheduleId=' + this.scheduleId,
            cheqqMateServiceConfig,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
              }
            }
          )
          .then(res => {
            if(res.data) {
              //save to vuex, so that it persists, instead of consistently calling api
              this.$store.commit('user/SCHEDULE_DETAILS_UPDATED', res.data.schedule);
            } 
            else {
            }
          })
          .catch(error => {
            if (error.response) {
            }
        });
      },
      initialize () {
          // this.members = this.memberDirectory.person
          // loop through memberdirectory and get the id of each person
          // loop through groupDetails and get the memberId
          // compare the two arrays and return the person object that matches the memberId

          // var _groupMembers = [];
          // for (let i = 0; i < this.groupDetails.memberId.length; i++) {
          //   if(this.groupDetails.memberId.includes(this.memberDirectory.person[i].id)){
          //     _groupMembers.push(this.memberDirectory.person[i])
          //   }
          // }
          // this.members = _groupMembers
        },
    },
  }
</script>
<style lang="scss" scoped>
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #2196F3 !important;
            border-left: 4px solid #2196F3;
        }
    }
</style>
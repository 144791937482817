var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationProvider', {
    attrs: {
      "name": _vm.$attrs.label,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors,
          valid = _ref.valid;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "error-messages": errors,
            "success": valid,
            "placeholder": _vm.$attrs.placeholder,
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.innerValue,
            callback: function ($$v) {
              _vm.innerValue = $$v;
            },
            expression: "innerValue"
          }
        }, 'v-text-field', _vm.$attrs, false), _vm.$listeners))];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
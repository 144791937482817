<template>
  <v-container class="py-6">
      <v-row>
        <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                    <DashbordSidebar />
                </div>
                <div class="box-content">
                    <div class="d-flex justify-end pa-2 d-block d-md-none">
                        <v-btn
                            icon   
                            @click="isSidebar = !isSidebar"
                        >
                            <v-icon dark>
                                mdi-format-list-bulleted-square
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="box-container">
                       <div class="d-flex justify-space-between flex-wrap mb-5">
                           <div class="d-flex align-center ">
                                <v-avatar
                                    tile
                                    size="25"
                                    class="me-3"
                                >
                                    <v-icon
                                    color="grey darken-1"
                                    >
                                    mdi-account-edit
                                    </v-icon>
                                </v-avatar>
                                <h2 class="grey--text text--darken-1 mb-0">Edit Profile</h2>
                            </div>
                            <v-btn 
                                outlined 
                                color="primary" 
                                class="text-capitalize font-600"
                                href='/view-profile'
                            >
                                Back
                            </v-btn>
                       </div>
                      <v-row>
                          <v-col cols="12">
                              <base-card>
                                <div class="pa-5">
                                    <div class="mb-7">
                                        <v-avatar size="64" class="me-4">
                                            <img :src="_userProfile.avatar" alt=""/>
                                        </v-avatar>
                                    </div>
                                    <v-row>
                                        <v-col cols="12" lg="6" >
                                            <v-text-field
                                                v-model="_userProfile.username"
                                                color="secondary"
                                                outlined
                                                :counter="30"
                                                dense
                                                disabled
                                                label="Username"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="6" >
                                            <v-text-field
                                                v-model="_userProfile.userId"
                                                color="secondary"
                                                outlined
                                                :counter="30"
                                                dense
                                                disabled
                                                label="Email"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="6" >
                                            <v-text-field
                                                v-model="this._userProfile.personalDetails.organisation"
                                                color="secondary"
                                                outlined
                                                :counter="30"
                                                dense
                                                label="Community"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="6" >
                                            <v-textarea
                                                v-model="this._userProfile.personalDetails.organisationDescription"
                                                counter="100"
                                                outlined
                                                name="input-7-4"
                                                label="Community Description"
                                                value=""
                                                rows="2"
                                                >
                                            </v-textarea>
                                        </v-col>
                                        
                                        <!-- <v-col cols="12" lg="6" >
                                            <v-text-field
                                                v-model="_userProfile.personalDetails.phoneNumber"
                                                color="secondary"
                                                outlined
                                                :counter="20"
                                                dense
                                                label="Phone Number"
                                            ></v-text-field>
                                        </v-col> -->
                                        <!-- <v-col cols="12" lg="6" >
                                            <p class="text-14 mb-1">Phone</p>
                                                <v-text-field
                                                    
                                                    outlined
                                                    dense
                                                    hide-details=""
                                                    class=""
                                                ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="6" >
                                            <p class="text-14 mb-1">Birth Date</p>
                                                <v-text-field
                                                    type="date"
                                                    outlined
                                                    dense
                                                    hide-details=""
                                                    class=""
                                                ></v-text-field>
                                        </v-col> -->
                                        <v-col cols="12">
                                            <v-btn color="primary" class="text-capitalize font-600" @click="updateUserName">
                                                Save Changes
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                              </base-card>
                          </v-col>
                      </v-row>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
const defaultUserProfile = {
    "id": "Jane.Done@example.com",
    "username": "Jane.Done",
    "type": "userProfile",
    "userId": "Jane.Done@example.com",
    "createdDate": "2011-11-02T12:10:07Z",
    "subscriptions": [{
    }],
    "locationsList": [
        {
            "locations": [{
            }]
        }
    ],
	"wishListProperties": 0,
	"zooplaProperties": 0,
	"zooplaPropertiiesLastModifiedDate": "",
	"rightMoveProperties": 0,
	"rightMovePropertiesLastModifiedDate": "",
	"onTheMarketProperties": 0,
	"onTheMarketPropertiesLastModifiedDate": "",
	"dashboard": {
		"history": [{}],
		"topRoundtripList": [{}]
	}
};

import DashbordSidebar from "@/components/DashboardSidebar"
import { mapState, mapActions, mapGetters } from 'vuex';
import VueLetterAvatar from 'vue-letter-avatar';
import Vue from 'vue';
import countryList from "../../data/countryList";

Vue.use(VueLetterAvatar);
export default {
    components: {
        DashbordSidebar,
    },
    data() {
        return{
            isSidebar: false,
            rules: [
                value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
        }
    }, 
  computed: {
    ...mapState('user', ['userProfile']),
    ...mapGetters('user', ['getUserProfile', 'getCurrentUser']),
    ...mapState('auth', ['authUser']),
    authUser() {
      return this.$store.state.auth.authUser;
    },
    countries(){
            return countryList
        },      
    _userProfile: {
      get() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.userProfile
          : defaultUserProfile
      },
      set(value) {
        // this.$store.commit('user/USER_DETAILS_UPDATED', value);
      }
    },
  },
  created(){
    this.fetchUserProfile(this.authUser.email)
  },
  methods: {
    ...mapActions('user', ['getUser', 'updateUser']),
    updateUserName(){
        this.updateUser()
    },
    fetchUserProfile(userEmail) {
        this.getUser(userEmail);
    },
  }
}
</script>

<template>
  <v-container class="py-6">
    <v-snackbar
        v-model = "snackbar"
        color = "primary"
        elevation = "16"
        class = "snackbar"
        :timeout="500"
    >
      {{ snackbarMessage }}
    </v-snackbar>
      <v-row>
        <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                   <DashbordSidebar />
                </div>
                <div class="box-content">
                    <div class="d-flex justify-end pa-2 d-block d-md-none">
                        <v-btn
                            icon   
                            @click="isSidebar = !isSidebar"
                        >
                            <v-icon dark>
                                mdi-format-list-bulleted-square
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="box-container">
                        <div class="d-flex justify-space-between flex-wrap mb-5">
                           <div class="d-flex align-center">
                            <v-avatar
                                tile
                                size="25"
                                class="me-3"
                            >
                                <v-icon
                                color="grey darken-1"
                                >
                                mdi-account-group
                                </v-icon>
                            </v-avatar>
                                <h2 class="grey--text text--darken-1">Member Directory</h2>
                            </div>
                            <v-btn 
                                outlined 
                                color="primary" 
                                class="text-capitalize font-600"
                                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                            >
                                BACK
                            </v-btn>
                       </div>
                       <!---Join Community-->
                       <base-card class="mt-n2">
                          <div class="pa-4 mb-4">
                            <v-row>
                                <h4 class="text-xl secondary--text mb-2 ml-6">
                                Invite Members
                                </h4>
                            </v-row>
                            <v-row>
                                <p class="grey--text text--darken-4 ml-6">
                                    Share this link to invite members to join your community.
                                    New members will be added to your member directory.
                                </p>
                            </v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="invitationLink"
                                    color="secondary"
                                    outlined
                                    dense
                                    label="Invitation Link"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="primary" class="mt-n8 text-capitalize font-600" @click="copyToClipboard()">
                                    Copy Link
                                </v-btn>
                            </v-col>
                          </div>
                        </base-card>
                        <template>
                            <v-data-table
                                :headers="headers"
                                :items="members"
                                sort-by="name"
                                class="elevation-1"
                            >
                                <template v-slot:item.avatar="{ item }">
                                    <v-img :src="item.avatar" max-height="50px" max-width="50px"></v-img>
                                </template>
                                <template v-slot:item.authProvider="{ item }">
                                  <v-avatar size="25">
                                    <img :src="require(`@/assets/images/logos/${item.authProvider}.svg`)">
                                  </v-avatar>
                                </template>
                                <template v-slot:top>
                                <v-toolbar
                                    flat
                                >
                                <div class="mb-2 mt-2">
                                    <v-row>
                                        <h4 class="text-xl secondary--text ml-2 mt-3">
                                            {{memberDirectory.name}}
                                        </h4>
                                    </v-row>
                                    <v-row>
                                        <p class="grey--text text--darken-4 ml-2 mb-1">
                                            {{memberDirectory.description}}
                                        </p>
                                    </v-row>
                                </div>
                                </v-toolbar>
                                </template>
                                <template v-slot:item.resumeDate="{ item }">
                                    <v-chip
                                    >
                                      <v-icon
                                        class="icon"
                                      >
                                        mdi-clock-time-four
                                      </v-icon>
                                      <!-- {{ formatDateTime(item.resumeDate) }} -->
                                      <p class="ma-1 secondary--text text-sm">{{ formatDateTime(item.resumeDate) }}</p>
                                    </v-chip>
                                </template>
                                <template v-slot:item.isDeletedFromDirectory="{ item }">
                                    <v-switch color="primary" v-model="item.isDeletedFromDirectory"></v-switch> 
                                </template>
                                <template v-slot:item.actions="{ item }">
                                <div v-if="item.isDeletedFromDirectory">
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-cancel</v-icon>
                                    </template>
                                    <span>
                                        Archived
                                    </span>
                                </v-tooltip>
                                </div>
                                <div v-else>                               
                                    <v-icon
                                        medium
                                        class="mr-2"
                                        @click="editItem(item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                        medium
                                        @click="deleteItem(members.indexOf(item))"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </div>
                                </template>
                                <template v-slot:no-data>
                                <v-btn
                                    color="primary"
                                    @click="initialize"
                                >
                                    Reset
                                </v-btn>
                                </template>
                            </v-data-table>                        
                        </template>
                        <!--edit member in community-->
                        <v-dialog
                            v-model="dialog"
                            max-width="500px"
                            :retain-focus="false"
                        >
                            <v-card>
                              <v-card-title>
                                  <h3 class="mt-4 mb-2 secondary--text text-wrap">{{ formTitle }}</h3>
                              <!-- <span class="text-h5"></span> -->
                              </v-card-title>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                        v-model="editedItem.name"
                                        label="Name"
                                        disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                    <v-text-field
                                        v-model="editedItem.authProvider"
                                        label="Login"
                                        disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                    <v-select
                                        v-model="editedItem.isDeletedFromDirectory"
                                        :items="[false, true]"
                                        color="secondary"
                                        label="Endorsed"
                                    ></v-select>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                    <v-select
                                        v-model="editedItem.isDeletedFromDirectory"
                                        :items="[false, true]"
                                        color="secondary"
                                        label="Blocked"
                                    ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="close"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="save"
                                >
                                    Confirm
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <!--delete member from community-->
                        <v-dialog v-model="dialogDelete" max-width="600px" :retain-focus="false">
                            <base-card>
                                <v-card-title>
                                    <h3 class="mt-4 mb-2 secondary--text text-wrap">Are you sure you want to block this member?</h3>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <p class="secondary--text mb-3">Blocked members get archived and and can no longer be added to groups</p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="closeDelete">Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="deleteItemConfirm"
                                >
                                    Confirm
                                </v-btn>
                                </v-card-actions>                    
                            </base-card>
                        </v-dialog>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashbordSidebar from "@/components/DashboardSidebar.vue"
import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import countryList from "../../data/countryList";


const defaultUserProfile = {
    id: 'Jane.Done@example.com',
    username: 'Jane.Done',
    type: 'userProfile',
    userId: 'Jane.Done@example.com',
    personalDetails: {
        id: "myemail@live.co.uk",
        name: "My Name",
        phoneNumber: "00000000012",
        emailAddres: "myemail@live.co.uk",
        resumeDate: "14/08/2023 10:46",
        isDeletedFromDirectory: false
    },
    subscription: {
        name: "Free",
        isActive: true,
        createdDate: "14/08/2023 10:46"
    },
    memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
    groups: [
        {
            groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
            groupName: "My Awesome Squad"
        }
    ],
    schedule: [
        {
            "id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
            "scheduleName": "My Awesome Squad Schedule"
        }
    ],
    matches: 1,
    matchesLastModifiedDate: "14/08/2023 10:46",
    meetups: 1,
    meetupsLastModifiedDate: "14/08/2023 10:46",
    pauses: 1,
    pausesLastModifiedDate: "14/08/2023 10:46",
    dashboard: {
        topUserGroups: [
            {
                userGroup: null,
                matches: 0,
                meetups: 0,
                pauses: 0
            }
        ]
    },
  };

// const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
const cheqqMateServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
  }
};

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit('app/START_LOADING');
    return config;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    store.commit('app/FINISH_LOADING');
    return response;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

  export default {
    components: {
        DashbordSidebar
    },    
    data: () => ({
      snackbar: false,
      snackbarMessage: '',
      dialog: false,
      file: {},
      fileData: "",
      _item: "",
      dialog2: false,
      isSidebar: false,
      dialogDelete: false,
      uploadedFile: '',
      headers: [
        { text: '', value: 'avatar', sortable: false },
        { text: 'Name', align: 'start', sortable: true, value: 'name'},
        { text: 'Login', value: 'authProvider', sortable: false },
        { text: 'Resume Date', value: 'resumeDate', sortable: true },
        { text: 'Blocked', value: 'isDeletedFromDirectory', sortable: false },
        { text: 'Remove', value: 'actions', sortable: false },
      ],
      members: [],
      chatBaseUrl: 'http://localhost:4280/signup',//process.env.VUE_APP_CHAT_BASE_URL,
      editedIndex: -1,
      editedItem: {
        name: '',
        authProvider: '',
        phoneNumber: '',
        emailAddress: '',
        resumeDate: new Date().toJSON().slice(0,10),
        isDeletedFromDirectory: false,
      },
      defaultItem: {
        name: '',
        areaCode: '',
        phoneNumber: '',
        emailAddress: '',
        resumeDate: new Date().toJSON().slice(0,10),
        isDeletedFromDirectory: false,
      },
    }),

    computed: {
        ...mapState('auth', ['authUser', 'memberDirectory','group']),
        invitationLink() {
            return `${this.chatBaseUrl}/${this.userProfile.memberDirectoryId}`
            // return `${this.chatBaseUrl}?join=${this.group.groupId}`
        },
        authUser () {
          return this.$store.state.auth.authUser
        },
        countries(){
            return countryList
        },
        formTitle () {
            return this.editedIndex === -1 ? 'New Member' : 'Edit Member'
        },
        userProfile() {
          return this.$store.state.user.userProfile.userId == this.authUser.email
            ? this.$store.state.user.userProfile
            : defaultUserProfile
        },
        memberDirectory() {
            return this.$store.state.user.userProfile.userId == this.authUser.email
            ? this.$store.state.user.memberDirectory
            : []
        },
        activeMembers() {
            return this.members.filter(member => member.isDeletedFromDirectory == false)
        },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialog2 (val) {
        val || this.close()
      },      
      dialogDelete (val) {
        val || this.closeDelete()
      },
      file(f) {
        if (f) {
            f.text().then((data) => {
            this.fileData = data;
            });
        }
      },
      memberDirectory() {
        this.members = this.memberDirectory.person
      }
    },

    created () {
      this.initialize()
    },

    methods: {
        copyToClipboard() {
            this.snackbar = true;
            this.snackbarMessage = "Copied to clipboard";
            const el = document.createElement('textarea');
            el.value = this.invitationLink;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        formatDateTime (date) {
            return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
            // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        },       
        downloadCSVData() {
            let csv = 'Full Name,Area Code,Phone Number,Email Address\n';       
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'Template.csv';
            anchor.click();
        },       
        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        season (val) {
            return this.range[val]
        },
        hasHistory () { 
            return window.history.length > 2 
        },        
      initialize () {
        this.members = this.memberDirectory.person //? this.memberDirectory.person : _members
        this.memberDirectoryId = this.memberDirectory.memberDirectoryId
      },

        editItem (item) {
            this.editedIndex = this.members.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            this._item = item
            this.editedIndex = this.members.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            // this.members.splice(this.editedIndex, 1)
            var payload = {
                UserEmail: this.userProfile.userId,
                MemberDirectoryId: this.userProfile.memberDirectoryId,
                MemberList: [
                    {
                        Id: this.members[this._item].id
                    }
                ]
            }
            var payloadV2 = {
                userId: this.userProfile.userId,
                memberDirectoryId: this.userProfile.memberDirectoryId
            };
            this.$store.dispatch('user/delMember', payload).then(() => {
                this.$store.dispatch('user/getMemberDirectory', payloadV2);
            })

            this.closeDelete()
        },
        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        close2 () {
            this.dialog2 = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        save () {
            if (this.editedIndex > -1) {
            Object.assign(this.members[this.editedIndex], this.editedItem)
            } else {
            this.members.push(this.editedItem)
            }
            this.close()
        },
        save2 () {
           var jsonFile =  this.csvJSON(this.fileData)
            for (var i in jsonFile) {
                var csvItem = {
                    name: jsonFile[i]["Full Name"],
                    areaCode: jsonFile[i]["Area Code"],
                    phoneNumber: jsonFile[i]["Phone Number"],
                    emailAddress: jsonFile[i]["Email Address"],
                    resumeDate: new Date().toJSON().slice(0,10),
                    isDeletedFromDirectory: false
                }
                this.members.push(csvItem)
            }
            this.close2()
        },
        /** file upload methods **/
        csvJSON (csv){
            var vm = this
            var lines = csv.split("\n")
            var result = []
            var headers = lines[0].split(",")
                        
            lines.map((line, indexLine) => {
                if (indexLine < 1) return // Jump header line
                
                const obj = {}
                var currentline = line.split(",")
                
                headers.map((header, indexHeader) => {
                   obj[header.trim()] = currentline[indexHeader]
                })
                
                result.push(obj)
            })
            result.pop() // remove the last item because undefined values
            // console.log(result)
            return result // JavaScript object
        },
        update() {
        axios
        .post(
          `${cheqqMateServiceBaseUrl}/upsertMember`,
          {
            UserEmail: this.userProfile.userId,
            MemberDirectoryId: this.userProfile.memberDirectoryId,
            MemberDetails: this.members
        },
          cheqqMateServiceConfig,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Content-Type': 'application/json'
            }
          }
        )
        .then(res => {
          if(res.data) {
            //save to vuex, so that it persists, instead of consistently calling api
            // store.commit('backend/ROUND_TRIP_ROUTE_DATA_UPDATED', res.data.RoundtripRoute);
            // store.commit('backend/ROUND_TRIP_DISTANCE_DATA_UPDATED', res.data.RoundtripDistance); 
            // store.commit('backend/ONE_WAY_TRIPS_DATA_UPDATED', res.data.Onewaytrips); 

            // this.calculationResults.RoundtripDistance = res.data.RoundtripDistance
            // this.calculationResults.RoundtripRoute = res.data.RoundtripRoute
            // this.calculationResults.Onewaytrips = res.data.Onewaytrip
          } else {
          }
        })
        .catch(error => {
          if (error.response) {
          // console.log(error) //localdev
          }
      });
      },        
    },
  }
</script>
<style lang="scss" scoped>
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #2196F3 !important;
            border-left: 4px solid #2196F3;
        }
    }
</style>
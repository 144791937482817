var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    class: _vm.itemClasses
  }, [_c('div', {
    staticClass: "nestable-item-content pa-2",
    on: {
      "mouseenter": _vm.onMouseEnter,
      "mouseleave": _vm.onMouseLeave,
      "mousemove": _vm.onMouseMove
    }
  }, [_vm._t("default", null, {
    "index": _vm.index,
    "item": _vm.item,
    "isChild": _vm.isChild
  })], 2), _vm.hasChildren ? _c('ol', {
    staticClass: "nestable-list ma-4"
  }, [_vm._l(_vm.item[_vm.options.childrenProp], function (child, childIndex) {
    return [_c('NestableItem', {
      key: child[_vm.keyProp],
      attrs: {
        "item": child,
        "index": childIndex,
        "options": _vm.options,
        "is-copy": _vm.isCopy,
        "is-child": ""
      },
      scopedSlots: _vm._u([_vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
        return {
          key: slot,
          fn: function (scope) {
            return [_vm._t(slot, null, null, scope)];
          }
        };
      })], null, true)
    })];
  }), _vm._t("footer", null, null, {
    item: _vm.item,
    index: _vm.index
  })], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
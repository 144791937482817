var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-snackbar', {
    staticClass: "snackbar",
    attrs: {
      "color": "primary",
      "elevation": "16",
      "timeout": 500
    },
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "50"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": "",
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-account-multiple-check ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v("Meetups")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), _c('base-card', {
    staticClass: "mt-n2"
  }, [_c('div', {
    staticClass: "pa-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column text-start align-start justify-start"
  }, [_c('p', {
    staticClass: "mb-0 text-body-2 font-weight-bold secondary--text"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "label": "",
      "small": "",
      "color": "green lighten-5"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "green",
      "small": ""
    }
  }, [_vm._v(" mdi-check ")])], 1), _vm._v(" Accepted ")], 1), _c('h3', {
    staticClass: "font-600 secondary--text ml-12"
  }, [_vm._v(" 0 ")])])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column text-start align-start justify-start"
  }, [_c('p', {
    staticClass: "mb-0 text-body-2 font-weight-bold secondary--text"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "label": "",
      "small": "",
      "color": "red lighten-5"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "red",
      "small": ""
    }
  }, [_vm._v(" mdi-close ")])], 1), _vm._v(" Rejected ")], 1), _c('h3', {
    staticClass: "font-600 secondary--text ml-12"
  }, [_vm._v(" 0 ")])])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column text-start align-start justify-start"
  }, [_c('p', {
    staticClass: "mb-0 text-body-2 font-weight-bold secondary--text"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "label": "",
      "small": "",
      "color": "grey lighten-2"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey",
      "small": ""
    }
  }, [_vm._v(" mdi-emoticon-sad ")])], 1), _vm._v(" No Response ")], 1), _c('h3', {
    staticClass: "font-600 secondary--text ml-12"
  }, [_vm._v(" 0 ")])])])], 1)], 1)]), [_c('v-data-table', {
    staticClass: "elevation-1 rounded-table",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.members,
      "sort-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "item.avatar",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-img', {
          attrs: {
            "src": item.avatar,
            "max-height": "50px",
            "max-width": "50px"
          }
        })];
      }
    }, {
      key: "item.authProvider",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-avatar', {
          attrs: {
            "size": "25"
          }
        }, [_c('img', {
          attrs: {
            "src": require(("@/assets/images/logos/" + (item.authProvider) + ".svg"))
          }
        })])];
      }
    }, {
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": "",
            "color": "landing_grey"
          }
        }, [_c('div', {
          staticClass: "mb-2 mt-2"
        }, [_c('v-row', [_c('h4', {
          staticClass: "text-xl secondary--text ml-2 mt-3"
        }, [_vm._v(" Meetup Performance ")])]), _c('v-row', [_c('p', {
          staticClass: "grey--text text--darken-4 ml-2 mb-1 text-caption text--weight-bold"
        }, [_vm._v(" The amount of people who accepted, rejected or did not response to meetup invitations ")])])], 1)])];
      },
      proxy: true
    }, {
      key: "item.resumeDate",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-chip', [_c('v-icon', {
          staticClass: "icon"
        }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
          staticClass: "ma-1 secondary--text text-sm"
        }, [_vm._v(_vm._s(_vm.formatDateTime(item.resumeDate)))])], 1)];
      }
    }, {
      key: "item.isDeletedFromDirectory",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-switch', {
          attrs: {
            "color": "primary"
          },
          model: {
            value: item.isDeletedFromDirectory,
            callback: function ($$v) {
              _vm.$set(item, "isDeletedFromDirectory", $$v);
            },
            expression: "item.isDeletedFromDirectory"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.isDeletedFromDirectory ? _c('div', [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var on = _ref6.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-cancel")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" Archived ")])])], 1) : _c('div', [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "medium": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editItem(item);
            }
          }
        }, [_vm._v(" mdi-pencil ")]), _c('v-icon', {
          attrs: {
            "medium": ""
          },
          on: {
            "click": function ($event) {
              _vm.deleteItem(_vm.members.indexOf(item));
            }
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.initialize
          }
        }, [_vm._v(" Reset ")])];
      },
      proxy: true
    }])
  })], _c('v-dialog', {
    attrs: {
      "max-width": "500px",
      "retain-focus": false
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('h3', {
    staticClass: "mt-4 mb-2 secondary--text text-wrap"
  }, [_vm._v(_vm._s(_vm.formTitle))])]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "disabled": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function ($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Login",
      "disabled": ""
    },
    model: {
      value: _vm.editedItem.authProvider,
      callback: function ($$v) {
        _vm.$set(_vm.editedItem, "authProvider", $$v);
      },
      expression: "editedItem.authProvider"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": [false, true],
      "color": "secondary",
      "label": "Endorsed"
    },
    model: {
      value: _vm.editedItem.isDeletedFromDirectory,
      callback: function ($$v) {
        _vm.$set(_vm.editedItem, "isDeletedFromDirectory", $$v);
      },
      expression: "editedItem.isDeletedFromDirectory"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": [false, true],
      "color": "secondary",
      "label": "Blocked"
    },
    model: {
      value: _vm.editedItem.isDeletedFromDirectory,
      callback: function ($$v) {
        _vm.$set(_vm.editedItem, "isDeletedFromDirectory", $$v);
      },
      expression: "editedItem.isDeletedFromDirectory"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "dark": ""
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "retain-focus": false
    },
    model: {
      value: _vm.dialogDelete,
      callback: function ($$v) {
        _vm.dialogDelete = $$v;
      },
      expression: "dialogDelete"
    }
  }, [_c('base-card', [_c('v-card-title', [_c('h3', {
    staticClass: "mt-4 mb-2 secondary--text text-wrap"
  }, [_vm._v("Are you sure you want to block this member?")])]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('p', {
    staticClass: "secondary--text mb-3"
  }, [_vm._v("Blocked members get archived and and can no longer be added to groups")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": _vm.closeDelete
    }
  }, [_vm._v("Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": _vm.deleteItemConfirm
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1)], 2)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
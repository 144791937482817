var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-account ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1 mb-0"
  }, [_vm._v("Profile")])], 1), _c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "primary",
      "href": "/edit-profile"
    }
  }, [_vm._v(" Edit Profile ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "4"
    }
  }, [_c('base-card', [_c('div', {
    staticClass: "pa-5"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center flex-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-4",
    attrs: {
      "size": "64"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.userProfile.avatar,
      "alt": ""
    }
  })]), _c('div', [_c('v-row', [_c('h4', {
    staticClass: "ml-2 font-600 secondary--text"
  }, [_vm._v(_vm._s(_vm.userProfile.personalDetails.name))])]), _c('v-row')], 1)], 1)])])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.groupList ? _vm.groupList.length : 0) + " ")]), _c('p', {
    staticClass: "mb-0 text-12 grey--text text--darken-1"
  }, [_vm._v(" Groups ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/schedules"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.scheduleList ? _vm.scheduleList.length : 0) + " ")]), _c('p', {
    staticClass: "mb-0 text-12 grey--text text--darken-1"
  }, [_vm._v(" Schedules ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/member-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.memberDirectory.person.length) + " ")]), _c('p', {
    staticClass: "mb-0 text-12 grey--text text--darken-1"
  }, [_vm._v(" Members ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/matches"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.matchList.length) + " ")]), _c('p', {
    staticClass: "mb-0 text-12 grey--text text--darken-1"
  }, [_vm._v("Matches")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.groupList ? _vm.groupList.length : 0) + " ")]), _c('p', {
    staticClass: "mb-0 text-12 grey--text text--darken-1"
  }, [_vm._v(" Events ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.groupList ? _vm.groupList.length : 0) + " ")]), _c('p', {
    staticClass: "mb-0 text-12 grey--text text--darken-1"
  }, [_vm._v(" Courses ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.groupList ? _vm.groupList.length : 0) + " ")]), _c('p', {
    staticClass: "mb-0 text-12 grey--text text--darken-1"
  }, [_vm._v(" Workflows ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('base-card', [_c('div', {
    staticClass: "pa-4 d-flex justify-space-between flex-wrap"
  }, [_c('div', {
    staticClass: "mx-2 my-2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" User Name ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.userProfile.personalDetails.name))])]), _c('div', {
    staticClass: "mx-2 my-2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Email ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.userProfile.personalDetails.emailAddress))])]), _c('div', {
    staticClass: "mx-2 my-2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Member Since ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.userProfile.subscription.createdDate))])]), _c('div', {
    staticClass: "mx-2 my-2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Community ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.userProfile.personalDetails.organisation))])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-expansion-panels', [_c('v-expansion-panel', {
    on: {
      "change": function ($event) {
        return _vm.generateLoginLink();
      }
    }
  }, [_c('v-expansion-panel-header', [_c('p', {
    staticClass: "mb-0 text-12 grey--text text--darken-1"
  }, [_vm._v(" Stripe Account ")])]), _c('v-expansion-panel-content', [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap"
  }, [_c('div', {
    staticClass: "mx-2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Stripe ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": _vm.loginlink ? _vm.loginlink.url : '',
      "target": "_blank"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-600 mb-5",
    attrs: {
      "dense": "",
      "outlined": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-open-in-new ")]), _vm._v(" View Stripe Dashboard ")], 1)], 1)])]), _c('div', {
    staticClass: "mx-2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Your Balance ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("$0.00")])]), _c('div', {
    staticClass: "mx-2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Member Since ")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.userProfile.subscription.createdDate))])]), _c('div', {
    staticClass: "mx-2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-1"
  }, [_vm._v(" Onboarding ")]), _c('div', {
    staticClass: "text-center"
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none !important"
    },
    attrs: {
      "to": {
        path: '/merchant-onboarding'
      }
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v(" mdi-arrow-right-circle ")])], 1)], 1)], 1)])])])], 1)], 1)], 1)], 1)], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <dashbord-sidebar />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn
                icon
                @click="isSidebar = !isSidebar"
              >
                <v-icon dark>
                  mdi-format-list-bulleted-square
                </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex justify-space-between flex-wrap mb-5">
                <div class="d-flex align-center ">
                  <v-avatar
                    tile
                    size="25"
                    class="me-3"
                  >
                    <v-icon
                      color="grey darken-1"
                    >
                      mdi-clock-time-four
                    </v-icon>
                  </v-avatar>
                  <h2 class="grey--text text--darken-1 mb-1">
                    Schedules
                  </h2>
                </div>
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize font-600"
                  @click="addSchedule()"
                >
                  Add New Schedule
                </v-btn>
              </div>
              <v-data-iterator
                :items="schedules"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                hide-default-footer
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="(item, idx) in props.items"
                      :key="idx"
                      cols="12"
                    >
                      <base-card>
                        <div class="pa-5 d-flex align-center flex-wrap justify-space-between">
                          <!--name-->
                          <div class="mr-6">
                            <a
                              :href="`/manage-schedule?scheduleId=${item.id}`"
                              class="text-decoration-none"
                            >
                              <v-row>
                                <span class="text-sm secondary--text ml-2 mt-1">
                                  Name
                                </span>
                              </v-row>
                              <v-row>
                                <p class="ma-2 secondary--text">{{ item.scheduleName }}</p>
                              </v-row>
                            </a>
                          </div>
                          <!--status-->
                          <div class="status">
                            <v-row>
                              <span class="text-sm secondary--text ml-2 mb-1">
                                Status
                              </span>
                            </v-row>
                            <v-row>
                                <div v-if="item.isDisabled=='false'">
                                  <v-chip
                                    class="text-sm white--text mb-2 mr-2 ml-2"
                                    small
                                    color="success"
                                    label
                                    dark
                                  >
                                    Active
                                  </v-chip>
                                </div>
                                <div v-else>
                                  <v-chip
                                    class="text-sm white--text mb-2 mr-2 ml-2"
                                    small
                                    color="secondary"
                                    label
                                    dark
                                  >
                                    Inactive
                                  </v-chip>
                                </div>
                              </v-row>
                          </div>
                          <!--date-->
                          <div>
                            <v-row>
                              <span class="text-sm secondary--text mt-n2 mr-3 pb-1">
                                Next Schedule Date
                              </span>
                            </v-row>
                            <v-row>
                              <div class="d-flex align-center">
                                <v-chip small>
                                  <v-icon
                                    small
                                    class="icon"
                                  >
                                    mdi-clock-time-four
                                  </v-icon>
                                <p class="ma-1 secondary--text text-sm">{{ formatDateTime(item.scheduleDateTime) }}</p>
                              </v-chip>
                              </div>
                              </v-row>
                          </div>
                          <!---edit-->
                          <div>
                            <v-row>
                              <span class="text-sm secondary--text ml-2 mr-1">
                                Edit
                              </span>
                            </v-row>
                            <v-row>
                              <div>
                                <router-link
                                  style="text-decoration: none !important;"
                                  :to="{ path: '/manage-schedule',
                                         query: {
                                           scheduleId: schedules[idx].id
                                         }
                                  }"
                                >
                                  <v-btn
                                    icon
                                  >
                                    <v-icon
                                    >
                                      mdi-pencil
                                    </v-icon>
                                  </v-btn>
                                </router-link>
                              </div>
                            </v-row>
                          </div>
                        </div>
                      </base-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:footer>
                  <v-row
                    class="my-5 me-1"
                    align="center"
                    justify="center"
                  >
                    <v-spacer />
                    <span
                      class="mr-4
                                grey--text"
                    >
                      Page {{ page }} of {{ numberOfPages }}
                    </span>
                    <v-btn
                      fab
                      :disabled="page == 1"
                      small
                      color="primary"
                      class="mr-1"
                      @click="formerPage"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      :disabled="page == numberOfPages"
                      small
                      color="primary"
                      class="ml-1"
                      @click="nextPage"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import LocationListModal from '../../components/shared/LocationListModal.vue'
  import DashbordSidebar from '@/components/DashboardSidebar.vue'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import ManageSchedule from './ManageSchedule'
    const defaultUserProfile = {
    id: 'Jane.Done@example.com',
    username: 'Jane.Done',
    type: 'userProfile',
    userId: 'Jane.Done@example.com',
    personalDetails: {
        id: "tzishi@live.co.uk",
        name: "Tino Zishiri",
        phoneNumber: "07943062109",
        emailAddres: "tzishi@live.co.uk",
        resumeDate: "14/08/2023 10:46",
        isDeletedFromDirectory: false
    },
    subscription: {
        name: "Free",
        isActive: true,
        createdDate: "14/08/2023 10:46"
    },
    memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
    groups: [
        {
            groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
            groupName: "Tino Zishiri's Awesome Squad"
        }
    ],
    schedule: [
        {
            "Id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
            "ScheduleName": "Tino Zishiri's Awesome Squad Schedule",
            "ScheduleDateTime": "14/08/2023 10:46"
        }
    ],
    matches: 1,
    matchesLastModifiedDate: "14/08/2023 10:46",
    meetups: 1,
    meetupsLastModifiedDate: "14/08/2023 10:46",
    pauses: 1,
    pausesLastModifiedDate: "14/08/2023 10:46",
    dashboard: {
        topUserGroups: [
            {
                userGroup: null,
                matches: 0,
                meetups: 0,
                pauses: 0
            }
        ]
    },
  };
  export default {
    components: {
      DashbordSidebar,
      ManageSchedule,
      LocationListModal,
    },
    data () {
      return {
        isSidebar: false,
        page: 1,
        itemsPerPage: 5,
        items: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20],
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        status: false,
        title: '',
        paragraph: '',
        modalType: '',
        _id: '',
              }
    },
    computed: {
      ...mapState('user', ['userProfile']),
      ...mapGetters('user', ['getCurrentUser']),
      ...mapState('auth', ['authUser']),
      authUser () {
        return this.$store.state.auth.authUser
      },
      userProfile() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.userProfile
          : defaultUserProfile
      },
      schedules: {
        get () {
          return this.$store.state.user.scheduleList
            ? this.$store.state.user.scheduleList
            : defaultUserProfile.schedule
        },
        set (value) {
          // this.$store.commit('user/USER_DETAILS_UPDATED', value)
        },
      },
      numberOfPages () {
        return Math.ceil(this.schedules.length / this.itemsPerPage)
      },
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
      this.fetchUserProfile(this.authUser.email)
          },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      ...mapActions('user', ['getUser', 'updateLocationList','updateSchedule','delSchedule']),
      ...mapGetters('app', ['isVisible']),
      formatDateTime (date) {
        return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
      // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
      },
      fetchUserProfile (userEmail) {
        this.getUser(userEmail)
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
      addSchedule() {
        var date = new Date().toJSON().slice(0, 10)
        var time = new Date().toJSON().slice(11, 16)
        var dateTime = date + '-' + time.replace(':', '-').replace(':', '-')
        var _scheduleName = `new-schedule-${dateTime}`
        var payload = {
          UserEmail: this.userProfile.userId,
          ScheduleDetails: 
            {
              Id: null,
              GroupId: "",
              ScheduleName: _scheduleName,
              RecurrencePattern: null,
              DayOfWeek: 0,
              DayOfMonth: 0,
              TimeOfDay: 0,
              IsDisabled: 0
		        }
        }
        this.updateSchedule(payload).then(() => {
          this.$store.dispatch('user/getUser', this.userProfile.userId)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #2196F3 !important;
            border-left: 4px solid #2196F3;
        }
    }
    @media (max-width: 550px) {
    .status {
      display: none;
    }
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "ml-3",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "8",
      "color": "secondary"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-n4 secondary--text",
    attrs: {
      "color": "secondary",
      "prepend-inner-icon": "mdi-lead-pencil",
      "counter": 50
    },
    model: {
      value: _vm.locationsList[0].listName,
      callback: function ($$v) {
        _vm.$set(_vm.locationsList[0], "listName", $$v);
      },
      expression: "locationsList[0].listName"
    }
  })], 1), _c('v-spacer'), _c('div', {
    staticClass: "d-flex flex-wrap mt-2 mb-5 mr-5"
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "secondary",
      "href": "/places"
    }
  }, [_vm._v(" My Groups ")])], 1)], 1), _c('div', {
    staticClass: "flex-1 ml-5"
  }, [_c('v-row', [_c('span', {
    staticClass: "text-sm grey--text text--darken-2 pb-5"
  }, [_vm._v(" " + _vm._s(_vm.$route.query.listId) + " ")]), _c('v-spacer'), _c('span', {
    staticClass: "mr-2"
  }, [_c('v-col', [_c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              return _vm.bulkUpload();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "secondary",
            "large": ""
          }
        }, [_vm._v(" mdi-upload ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Bulk Upload")])])], 1)], 1)], 1)], 1), [_c('div', {
    staticClass: "mt-2"
  }, [_c('div', {
    staticStyle: {
      "border": "2px solid #f3f4f6",
      "border-radius": "5px"
    }
  }, _vm._l(_vm.locationsList[0].locations, function (item, i) {
    return _c('v-expansion-panels', {
      key: i,
      staticClass: "mt-2",
      attrs: {
        "accordion": ""
      }
    }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', {
      staticClass: "flex-1 mr-2"
    }, [_c('v-row', [_c('span', {
      staticClass: "text-sm grey--text text--darken-2 pb-4"
    }, [_c('br'), _c('v-chip', {
      staticClass: "text-sm mt-2",
      attrs: {
        "small": "",
        "color": "primary",
        "label": "",
        "text": ""
      }
    }, [_vm._v(" Member " + _vm._s(item.locationOrder = i + 1) + " ")])], 1), _c('div', {
      staticClass: "pa-4 d-flex align-center flex-wrap"
    }, [_c('div', {
      staticClass: "flex-1 mr-12"
    }, [_c('a', {
      staticClass: "text-decoration-none"
    }, [_c('v-row', [_c('span', {
      staticClass: "text-sm grey--text text--darken-2 ml-12"
    }, [_vm._v(" Name ")])])], 1)]), _c('div', {
      staticClass: "mr-12"
    }, [_c('v-row', [_c('span', {
      staticClass: "text-sm grey--text text--darken-2 ml-12"
    }, [_vm._v(" Resume Date ")])])], 1)])])], 1)]), _c('v-expansion-panel-content', [_c('div', {
      staticClass: "pa-4"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "6",
        "xl": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": item.locationType = i < 3 ? 'primary' : 'secondary',
        "items": _vm.types,
        "color": "secondary",
        "outlined": "",
        "dense": "",
        "label": "Name",
        "hint": "Primary locations are used for Roundtrip calculations",
        "persistent-hint": ""
      },
      model: {
        value: item.locationType,
        callback: function ($$v) {
          _vm.$set(item, "locationType", $$v);
        },
        expression: "item.locationType"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "6",
        "xl": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": item.locationType = i < 3 ? 'primary' : 'secondary',
        "items": _vm.types,
        "color": "secondary",
        "outlined": "",
        "dense": "",
        "label": "Phone Number",
        "hint": "Primary locations are used for Roundtrip calculations",
        "persistent-hint": ""
      },
      model: {
        value: item.locationType,
        callback: function ($$v) {
          _vm.$set(item, "locationType", $$v);
        },
        expression: "item.locationType"
      }
    })], 1)], 1), _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "6",
        "xl": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": item.locationType = i < 3 ? 'primary' : 'secondary',
        "items": _vm.types,
        "color": "secondary",
        "outlined": "",
        "dense": "",
        "label": "Out Of Office",
        "hint": "Primary locations are used for Roundtrip calculations",
        "persistent-hint": ""
      },
      model: {
        value: item.locationType,
        callback: function ($$v) {
          _vm.$set(item, "locationType", $$v);
        },
        expression: "item.locationType"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "6",
        "xl": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "value": item.locationType = i < 3 ? 'primary' : 'secondary',
        "items": _vm.types,
        "color": "secondary",
        "outlined": "",
        "dense": "",
        "label": "Is Deleted from Directory",
        "hint": "Primary locations are used for Roundtrip calculations",
        "persistent-hint": ""
      },
      model: {
        value: item.locationType,
        callback: function ($$v) {
          _vm.$set(item, "locationType", $$v);
        },
        expression: "item.locationType"
      }
    })], 1)], 1)], 1)])], 1)], 1);
  }), 1)]), _c('v-row', [_c('span', {
    staticClass: "text-sm grey--text text--darken-2 ml-2 mt-2 pt-2 pb-5"
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "font-600 text-capitalize",
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.saveLocationChanges(_vm.$route.query.listId);
      }
    }
  }, [_vm._v(" Save Changes ")])], 1)], 1), _c('v-spacer'), _c('span', {
    staticClass: "pt-2 pb-5 mr-2"
  }, [_c('v-col', [_c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              return _vm.addLocation(_vm.$route.query.listId);
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "secondary",
            "large": ""
          }
        }, [_vm._v(" mdi-plus-circle ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Add Record")])])], 1)], 1)], 1)]], 2)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "container"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', [_vm._v("Mobile Navbar")]), _c('HeaderNavbar')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', {
    staticClass: "mb-3"
  }, [_vm._v("Navbar Component")]), _c('Navbar')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', {
    staticClass: "mb-3"
  }, [_vm._v("Flash Deals Single Item")])]), _c('v-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12",
      "md": "3",
      "sm": "6"
    }
  }, [_c('CardWishList')], 1), _c('v-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', [_vm._v("Top Categories")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('base-card', {}, [_c('v-card-text', {
    staticClass: "d-flex justify-content-center p-4 mx-auto"
  }, [_c('div', {
    staticClass: "card-overlay-item br-8 overflow-hidden"
  }, [_c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" Primary ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('base-card', {}, [_c('v-card-text', {
    staticClass: "d-flex justify-content-center p-4 mx-auto"
  }, [_c('div', {
    staticClass: "card-overlay-item br-8 overflow-hidden"
  })])], 1)], 1), _c('v-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Top Ratings ")]), _c('v-col', {
    staticClass: "mb-5",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('base-card', [_c('div', {
    staticClass: "px-3"
  }, [_c('v-row', _vm._l(4, function (index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "3",
        "sm": "6",
        "lg": "3"
      }
    }, [_c('div', {
      staticClass: "text-center"
    }, [_c('div', {
      staticClass: "d-flex justify-center mb-1"
    }, [_c('v-rating', {
      attrs: {
        "value": 4.5,
        "color": "amber",
        "dense": "",
        "half-increments": "",
        "readonly": "",
        "size": "18"
      }
    }), _vm._v(" (49) ")], 1), _c('h6', {
      staticClass: "mb-1"
    }, [_vm._v("Camera")]), _c('h6', {
      staticClass: "primary--text"
    }, [_vm._v("$150")])])]);
  }), 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('base-card', [_c('div', {
    staticClass: "px-3"
  }, [_c('v-row', _vm._l(6, function (index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3",
        "lg": "2"
      }
    }, [_c('h6', {
      staticClass: "mb-1"
    }, [_vm._v("Sunglass")]), _c('h6', {
      staticClass: "primary--text"
    }, [_vm._v("$150")])]);
  }), 1)], 1)])], 1), _vm._l(12, function (index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3",
        "lg": "2"
      }
    }, [_c('base-card', {
      staticClass: "card-hover-shadow"
    }, [_c('v-card-text', [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('v-avatar', {
      attrs: {
        "tile": ""
      }
    }), _c('p', {
      staticClass: "font-weight-bold mb-0 text-truncate"
    }, [_vm._v("Automobile")])], 1)])], 1)], 1);
  }), _c('v-col', [_c('v-card', [_c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(4, function (i) {
    return _c('v-tab-item', {
      key: i,
      attrs: {
        "value": 'tab-' + i
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-card-text', [_vm._v(_vm._s(_vm.text))])], 1)], 1);
  }), 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', {
    staticClass: "mb-5"
  }, [_vm._v("Avatar Input Component")]), _c('div', {
    staticClass: "cover-upload-wrapper"
  }, [_c('label', {
    attrs: {
      "for": "cover-image"
    }
  }, [_c('span', {
    staticClass: "primary cover-upload"
  }, [_c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    staticClass: "icon mr-0",
    attrs: {
      "src": require("@/assets/images/icons/camera.svg"),
      "alt": ""
    }
  })])]), _c('input', {
    attrs: {
      "type": "file",
      "id": "cover-image"
    }
  })])])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-history ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1 mb-1"
  }, [_vm._v("Matches")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.matches,
      "sort-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("Match History")])], 1)];
      },
      proxy: true
    }, {
      key: "item.createdDate",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_c('v-icon', {
          staticClass: "icon",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
          staticClass: "ma-1 secondary--text text-sm"
        }, [_vm._v(_vm._s(item.createdDate))])], 1)];
      }
    }, {
      key: "item.pairings.countOfPairings",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('p', {
          staticClass: "ml-6 mt-3 grey--text"
        }, [_c('v-badge', {
          attrs: {
            "color": "darkGrey",
            "inline": "",
            "content": item.pairings.countOfPairings
          }
        })], 1)];
      }
    }, {
      key: "item.actions",
      fn: function (_ref3) {
        var item = _ref3.item,
          index = _ref3.index;
        return [_c('div', [_c('router-link', {
          staticStyle: {
            "text-decoration": "none !important"
          },
          attrs: {
            "to": {
              path: '/match-details',
              query: {
                matchId: _vm.matches[index].id
              }
            }
          }
        }, [_c('v-btn', {
          attrs: {
            "icon": ""
          }
        }, [_c('v-icon', [_vm._v(" mdi-arrow-right-circle ")])], 1)], 1)], 1)];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.initialize
          }
        }, [_vm._v(" Reset ")])];
      },
      proxy: true
    }])
  })]], 2)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-content>
    <loading-overlay />
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-content>
</template>

<script>
import LoadingOverlay from "./components/shared/LoadingOverlay.vue";

export default { 
  name: "App",
  components: {
    LoadingOverlay
  },
  data() {
    return {
    }
  },
  methods: {
  },
  mounted(){
  }
};

</script>

<style lang="scss"></style>

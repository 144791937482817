var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "course-editor"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-end"
  }, [_c('span', [_c('v-btn', {
    staticClass: "mb-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false, _vm.closeDialog();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-spacer'), _c('h2', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.lessonData && _vm.lessonData.description ? _vm.lessonData.description : 'New Lesson'))]), _c('v-spacer'), _c('span', [_c('v-btn', {
    staticClass: "mb-2 mr-2 text-capitalize font-600",
    attrs: {
      "text": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.dialog = true;
      }
    }
  }, [_vm._v(" preview ")]), _c('v-btn', {
    staticClass: "text-none mt-n2",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.updateLessonContent(), _vm.closeDialog();
      }
    }
  }, [_c('span', {
    staticClass: "ml-1"
  }, [_vm._v("Save")])])], 1)], 1), _c('quill-editor', {
    ref: "myQuillEditor",
    staticClass: "editor",
    attrs: {
      "value": _vm.content,
      "options": _vm.editorOption
    },
    on: {
      "change": _vm.onEditorChange,
      "blur": function ($event) {
        return _vm.onEditorBlur($event);
      },
      "focus": function ($event) {
        return _vm.onEditorFocus($event);
      },
      "ready": function ($event) {
        return _vm.onEditorReady($event);
      }
    }
  }), _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "#f6f9fc",
      "elevation": "0"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "darkGrey"
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-toolbar-title', [_vm._v("Preview of " + _vm._s(_vm.lessonData && _vm.lessonData.description ? _vm.lessonData.description : 'New Lesson'))]), _c('v-spacer')], 1), _c('div', {
    staticClass: "output ql-snow"
  }, [_c('div', {
    staticClass: "ql-editor",
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
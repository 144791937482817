export default {
  namespaced: true,
  state: {
    loadingCounter: 0,
    errorMessage: null,
    modalStatus: false,
    lookups: null
  },
  getters: {
    isLoading: state => {
      return state.loadingCounter > 0;
    },
    hasError: state => {
      return !!state.errorMessage;
    },
    isVisible: state => {
      return state.modalStatus;
    }
  },
  mutations: {
    START_LOADING: state => {
      state.loadingCounter++;
    },
    FINISH_LOADING: state => {
      state.loadingCounter--;
    },
    SET_ERROR: (state, errorMessage) => {
      state.errorMessage = errorMessage;
    },
    CLEAR_ERROR: state => {
      state.errorMessage = null;
    },
    SET_LOOKUPS: (state, value) => {
      state.lookups = value;
    },
    SET_MODAL_STATUS: (state, value) => {
      state.modalStatus = value;
    }
  }
};

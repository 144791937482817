var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('v-row', {
    staticClass: "mb-n6"
  }, [_c('v-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-avatar', {
    staticClass: "me-3 mb-2",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-chart-pie ")])], 1), _c('span', {
    staticClass: "text-h5 font-weight-bold grey--text text--darken-1"
  }, [_vm._v("Summary")])], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.items,
      "label": "Date Range",
      "solo": "",
      "dense": "",
      "flat": ""
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" 0 ")]), _c('p', {
    staticClass: "mb-0 text-caption grey--text text--darken-1"
  }, [_vm._v(" Total Members ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" 0 ")]), _c('p', {
    staticClass: "mb-0 text-caption grey--text text--darken-1"
  }, [_vm._v(" New Members ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" 0 ")]), _c('p', {
    staticClass: "mb-0 text-caption grey--text text--darken-1"
  }, [_vm._v(" Active Members ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" 0 ")]), _c('p', {
    staticClass: "mb-0 text-caption grey--text text--darken-1"
  }, [_vm._v(" Messages Posted ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" 0 ")]), _c('p', {
    staticClass: "mb-0 text-caption grey--text text--darken-1"
  }, [_vm._v(" Messages posted on #Topic")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/group-directory"
    }
  }, [_c('base-card', {
    staticClass: "h-100 pa-4 d-flex flex-column text-center align-center justify-center"
  }, [_c('h3', {
    staticClass: "font-600 secondary--text mb-0"
  }, [_vm._v(" 0 ")]), _c('p', {
    staticClass: "mb-0 text-caption grey--text text--darken-1"
  }, [_vm._v(" Unanswered questions ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-card', [_c('v-card-text', {
    staticClass: "text-16 mb-n8 font-weight-bold grey--text text--darken-1"
  }, [_vm._v(" Top Contributors "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "color": "grey darken-1"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip")])])], 1), _c('v-list', {
    staticClass: "mt-2 pa-3",
    attrs: {
      "one-line": ""
    }
  }, [_vm._l(_vm.items2, function (item, index) {
    return [item.divider ? _c('v-divider', {
      key: index,
      attrs: {
        "inset": item.inset
      }
    }) : _c('v-list-item', {
      key: item.title,
      on: {
        "mouseover": function ($event) {
          _vm.hoveredIndex0 = index;
        },
        "mouseleave": function ($event) {
          _vm.hoveredIndex0 = null;
        }
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.avatar
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    })], 1), _c('v-list-item-action', [_c('v-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hoveredIndex0 === index,
        expression: "hoveredIndex0 === index"
      }],
      attrs: {
        "icon": "",
        "small": "",
        "plain": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey"
      }
    }, [_vm._v(" mdi-message ")])], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-card', [_c('v-card-text', {
    staticClass: "text-16 mb-n8 font-weight-bold grey--text text--darken-1"
  }, [_vm._v(" Most engaged members "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "color": "grey darken-1"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip")])])], 1), _c('v-list', {
    staticClass: "mt-2 pa-3",
    attrs: {
      "one-line": ""
    }
  }, [_vm._l(_vm.items2, function (item, index) {
    return [item.divider ? _c('v-divider', {
      key: index,
      attrs: {
        "inset": item.inset
      }
    }) : _c('v-list-item', {
      key: item.title,
      on: {
        "mouseover": function ($event) {
          _vm.hoveredIndex1 = index;
        },
        "mouseleave": function ($event) {
          _vm.hoveredIndex1 = null;
        }
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.avatar
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    })], 1), _c('v-list-item-action', [_c('v-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hoveredIndex1 === index,
        expression: "hoveredIndex1 === index"
      }],
      attrs: {
        "icon": "",
        "small": "",
        "plain": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey"
      }
    }, [_vm._v(" mdi-message ")])], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-card', [_c('v-card-text', {
    staticClass: "text-16 mb-n8 font-weight-bold grey--text text--darken-1"
  }, [_vm._v(" Inactive new members "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "color": "grey darken-1"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip")])])], 1), _c('v-list', {
    staticClass: "mt-2 pa-3",
    attrs: {
      "one-line": ""
    }
  }, [_vm._l(_vm.items2, function (item, index) {
    return [item.divider ? _c('v-divider', {
      key: index,
      attrs: {
        "inset": item.inset
      }
    }) : _c('v-list-item', {
      key: item.title,
      on: {
        "mouseover": function ($event) {
          _vm.hoveredIndex2 = index;
        },
        "mouseleave": function ($event) {
          _vm.hoveredIndex2 = null;
        }
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.avatar
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    })], 1), _c('v-list-item-action', [_c('v-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hoveredIndex2 === index,
        expression: "hoveredIndex2 === index"
      }],
      attrs: {
        "icon": "",
        "small": "",
        "plain": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey"
      }
    }, [_vm._v(" mdi-message ")])], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-card', [_c('v-card-text', {
    staticClass: "text-16 mb-n8 font-weight-bold grey--text text--darken-1"
  }, [_vm._v(" Top messages "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "color": "grey darken-1"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip")])])], 1), _c('v-list', {
    staticClass: "mt-2 pa-3",
    attrs: {
      "two-line": ""
    }
  }, [_vm._l(_vm.items2, function (item, index) {
    return [item.divider ? _c('v-divider', {
      key: index,
      attrs: {
        "inset": item.inset
      }
    }) : _c('v-list-item', {
      key: item.title,
      on: {
        "mouseover": function ($event) {
          _vm.hoveredIndex3 = index;
        },
        "mouseleave": function ($event) {
          _vm.hoveredIndex3 = null;
        }
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.avatar
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    }), _c('v-list-item-subtitle', {
      staticClass: "mt-1"
    }, [_c('v-chip', {
      staticClass: "darkGrey--text text--darken-2 font-weight-bold",
      attrs: {
        "label": "",
        "small": ""
      }
    }, [_c('v-icon', {
      staticClass: "pr-1",
      attrs: {
        "small": "",
        "color": "darkGrey lighten-3",
        "left": ""
      }
    }, [_vm._v(" mdi-message ")]), _vm._v(" 458 ")], 1), _vm._v("   "), _c('v-chip', {
      staticClass: "darkGrey--text text--darken-2 font-weight-bold",
      attrs: {
        "label": "",
        "small": ""
      }
    }, [_c('v-icon', {
      staticClass: "pr-1",
      attrs: {
        "small": "",
        "color": "darkGrey lighten-3",
        "left": ""
      }
    }, [_vm._v(" mdi-thumb-up ")]), _vm._v(" 4 ")], 1)], 1)], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container class="py-6">
        <v-row>
          <v-col cols="12">
              <div class="box-wrapper">
                  <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                  <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                     <DashbordSidebar />
                  </div>
                  <div class="box-content">
                      <div class="d-flex justify-end pa-2 d-block d-md-none">
                          <v-btn
                              icon   
                              @click="isSidebar = !isSidebar"
                          >
                              <v-icon dark>
                                  mdi-format-list-bulleted-square
                              </v-icon>
                          </v-btn>
                      </div>
                      <div class="box-container">                        
                          <div class="d-flex justify-space-between flex-wrap mb-5">
                             <div class="d-flex align-center mt-n2">
                              <v-avatar
                                  tile
                                  size="25"
                                  class="me-3"
                              >
                                  <v-icon
                                  color="grey darken-1"
                                  >
                                  mdi-list-status
                                  </v-icon>
                              </v-avatar>
                                  <h2 class="grey--text text--darken-1 mb-0">Merchant Onboarding</h2>
                              </div>
                              <v-btn 
                                  outlined 
                                  color="primary" 
                                  class="text-capitalize font-600"
                                  @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                              >
                                  BACK
                              </v-btn>                             
                         </div>
                      </div>
                    <!---Onboarding tabs-->
                      <v-card>
                        <v-toolbar
                          flat
                          color="primary"
                          dark
                        >
                          <v-toolbar-title>Stripe Onboarding</v-toolbar-title>
                        </v-toolbar>
                        <v-tabs vertical>
                          <v-tab>
                            <v-icon v-if="!stripeAccountId" left color="warning">
                              mdi-alert
                            </v-icon>
                            <v-icon v-else left color="green">
                              mdi-check-circle
                            </v-icon>
                            Step 1
                          </v-tab>
                          <v-tab>
                            <v-icon v-if="chargeStatus!=='active'" left color="warning">
                              mdi-alert
                            </v-icon>
                            <v-icon v-else left color="green">
                              mdi-check-circle
                            </v-icon>
                            Step 2
                          </v-tab>
                          <v-tab>
                            <v-icon v-if="chargeStatus!=='active'" left color="warning">
                              mdi-alert
                            </v-icon>
                            <v-icon v-else left color="green">
                              mdi-check-circle
                            </v-icon>
                            Step 3
                          </v-tab>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <p>
                                  In order for you to create subscriptions for your community, you first need to create you own Stripe account. 
                                  Generate Account Key to create a Stripe Express account.
                                </p>

                                <div v-if="!stripeAccountId">
                                  <v-btn
                                    x-large
                                    outlined
                                    color="primary"
                                    dark
                                    block
                                    class="font-weight-bold grey--text text--darken-2"
                                    @click=generateAccountKey()
                                >
                                <v-icon dark class="mr-2">
                                  mdi-key
                                </v-icon>
                                  Generate Account Key
                                </v-btn>
                                </div>
                                <div v-else>
                                  <v-text-field
                                    v-model="stripeAccountId"
                                    color="primary"
                                    outlined
                                    label="Stripe Account Key"  
                                ></v-text-field>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <p>
                                  Generate a single-use Stripe URL that will take you through the Connect Onboarding flow.
                                </p>
                                <v-btn
                                  :disabled="!stripeAccountId"
                                  x-large
                                  outlined
                                  color="primary"
                                  dark
                                  block
                                  class="text-capitalize font-600 mb-5"
                                  @click=generateOnboardingLink()
                                >
                                <v-icon dark class="mr-2">
                                  mdi-link-variant
                                </v-icon>
                                  Generate Onboarding Link
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <p>
                                  Please supply all the relevant details as this will allow CheqqMate to process transactions on your behalf.
                                </p>
                                <a :href="accountLink?accountLink.url:''" target="_blank" style="text-decoration: none;">
                                  <v-btn
                                    x-large
                                    outlined
                                    
                                    dark
                                    block
                                    class="font-weight-bold grey--text text--darken-2 mb-5"
                                  >
                                  <v-icon dark class="mr-2">
                                    mdi-open-in-new
                                  </v-icon>
                                    Complete Stripe Onboarding
                                  </v-btn>
                                </a>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                        </v-tabs>
                      </v-card>

                    <!---completion card-->
                    <base-card v-if="chargeStatus==='active'" class="mt-5">                   
                      <v-card-text class="text-center secondary--text">
                        <h1 class="mt-1">Congratulations!<v-icon class="ml-2 mt-n3" x-large color="primary">mdi-party-popper</v-icon></h1>
                        <p class="mt-1 mb-n1">Stripe Onboarding Completed</p>     
                      </v-card-text>
                    </base-card>
                  </div>
              </div>
          </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import DashbordSidebar from "@/components/DashboardSidebar.vue"
  import { mapState, mapActions, mapGetters } from 'vuex';
  import axios from 'axios';

  const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
  const cheqqMateServiceConfig = {
    headers: {
      'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
      'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
    }
  };

  const http = axios.create();

  http.interceptors.request.use(
    config => {
      store.commit('app/START_LOADING');
      return config;
    },
    error => {
      store.commit('app/FINISH_LOADING');
      store.commit(
        'app/SET_ERROR',
        'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
      );

      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    response => {
      store.commit('app/FINISH_LOADING');
      return response;
    },
    error => {
      store.commit('app/FINISH_LOADING');
      store.commit(
        'app/SET_ERROR',
        'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
      );

      return Promise.reject(error);
    }
  );
  const defaultUserProfile = {
    id: null,
    username: 'Jane.Done',
    type: 'userProfile',
    userId: '',
    personalDetails: {
        id: "",
        name: "Tino Zishiri",
        phoneNumber: "07943062109",
        emailAddres: "",
        resumeDate: "14/08/2023 10:46",
        isDeletedFromDirectory: false
    },
    subscription: {
        name: "Free",
        isActive: true,
        createdDate: "14/08/2023 10:46"
    },
    memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
    groups: [
        {
            groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
            groupName: "Tino Zishiri's Awesome Squad"
        }
    ],
    schedule: [
        {
            "id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
            "scheduleName": "Tino Zishiri's Awesome Squad Schedule"
        }
    ],
    matches: 1,
    matchesLastModifiedDate: "14/08/2023 10:46",
    meetups: 1,
    meetupsLastModifiedDate: "14/08/2023 10:46",
    pauses: 1,
    pausesLastModifiedDate: "14/08/2023 10:46",
    dashboard: {
        topUserGroups: [
            {
                userGroup: null,
                matches: 0,
                meetups: 0,
                pauses: 0
            }
        ]
    },
  };
  
    export default {
      components: {
          DashbordSidebar
      },    
      data: () => ({
        accountLink: null,
        completionCard: false,
        steps: [
            {name: "Start", description: "Generate Key",},
            {name: "Step 2", description: "Generate Link"},
            {name: "Step 3", description: "Navigate to Stripe to complete onboarding"},
            {name: "Complete"},
        ],
        e6: 1,
        selectedMembers: null,
        selectedDayOfWeek: '',
        selectedDayOfMonth: '',
        selectedRecurrencePattern: '',
        dialog: false,
        file: {},
        fileData: "",
        singleSelect: false,
        dialog2: false,
        isSidebar: false,
        dialogDelete: false,
        uploadedFile: '',
        scheduleName: 'My Schedule',
        headers: [
          {
            text: 'Full Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Phone Number', value: 'phoneNumber', sortable: false },
          { text: 'Email Address', value: 'emailAddress', sortable: false },
          { text: 'Resume Date', value: 'resumeDate', sortable: true },
          { text: 'Is Archived', value: 'isDeletedFromDirectory', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      }),
  
      computed: {
        authUser () {
          return this.$store.state.auth.authUser
        },
        userProfile() {
          return this.$store.state.user.userProfile.userId == this.authUser.email
            ? this.$store.state.user.userProfile
            : defaultUserProfile
        },
        stripeAccountId() {
          return this.$store.getters['user/getUserProfile'].merchant ? this.$store.getters['user/getUserProfile'].merchant.stripeAccountId:'';
        },
        chargeStatus() {
          return this.$store.getters['user/getUserProfile'].merchant ? this.$store.getters['user/getUserProfile'].merchant.chargeStatus:'';
        },
      },
  
      watch: {
      },
  
      created () {
        this.initialize()
      },
      mounted() {
        // check if user has completed onboarding
        if(this.chargeStatus === 'inactive') {
          // retrieve Account & update ChargeStatus
          this.updateChargeStatus()
        }
      },
      methods: {
        ...mapActions('user', ['addMerchantDetails','updateChargeStatusV2']),
        initialize(){},
        formatDateTime (date) {
          return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
        // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        },
        generateAccountKey() {
          this.addMerchantDetails()
        },
        updateChargeStatus() {
          const postData = {
            UserEmail: this.authUser.email,
            AccountId: this.stripeAccountId
          }
          this.updateChargeStatusV2(postData)
        },
        generateOnboardingLink() {
          axios
          .get(
            `${cheqqMateServiceBaseUrl}/accountLink`+
              '?UserEmail=' + this.authUser.email + '&AccountId=' + this.stripeAccountId,
            cheqqMateServiceConfig,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
              }
            }
          )
          .then(res => {
            if(res.data) {
              //save to local property
              this.accountLink = res.data.account
            } 
            else {
            }
          })
          .catch(error => {
            if (error.response) {
            }
        });
        }, 
      },
    }
  </script>
  <style lang="scss" scoped>
      .dashboard-nav-link {
          color: #2B3445 !important;
          text-decoration: none;
          align-items: center;
          display: flex;
          justify-content: space-between;
          border-left: 4px solid #fff;
          margin-bottom: 20px;
          svg {
              width: 20px;
          }
          &:hover, &.active {
              color: #2196F3 !important;
              border-left: 4px solid #2196F3;
          }
      }
  </style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('div', {
    staticClass: "bg-body d-flex flex-column justify-center align-center min-vh-100"
  }, [_c('div', {
    staticClass: "sign-up-card"
  }, [_c('v-snackbar', {
    attrs: {
      "timeout": 5000,
      "value": _vm.getAuthenticationErrors,
      "color": "primary"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.getAuthenticationErrors) + " ")])]), _c('validation-observer', {
    ref: "obs",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid,
          validated = _ref.validated,
          handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "sign-up-card-container"
        }, [_c('div', {
          staticClass: "d-md-block"
        }, [_c('div', {
          staticClass: "px-3 px-md-10 py-8"
        }, [_c('h3', {
          staticClass: "text-center"
        }, [_c('v-avatar', {
          attrs: {
            "width": "auto",
            "height": "50",
            "tile": ""
          }
        }, [_c('img', {
          staticClass: "mt-1",
          attrs: {
            "src": require("@/assets/CheqqMateforCreators.Logo.png")
          }
        })])], 1), _c('h5', {
          staticClass: "font-600 grey--text text--darken-3 text-sm mb-9 text-center"
        }, [_vm._v(" Log in with email & password ")]), _c('v-text-field-with-validation', {
          attrs: {
            "rules": "required|email",
            "label": "Email",
            "placeholder": "example@gmail.com"
          },
          model: {
            value: _vm.user.email,
            callback: function ($$v) {
              _vm.$set(_vm.user, "email", $$v);
            },
            expression: "user.email"
          }
        }), _c('div', {
          staticClass: "mt-1"
        }), _c('v-text-field-password-with-validation', {
          attrs: {
            "rules": "required",
            "label": "Password",
            "placeholder": "**********"
          },
          model: {
            value: _vm.user.password,
            callback: function ($$v) {
              _vm.$set(_vm.user, "password", $$v);
            },
            expression: "user.password"
          }
        }), _c('div', {
          staticClass: "mt-1"
        }), _c('v-btn', {
          staticClass: "text-capitalize font-600",
          attrs: {
            "disabled": invalid || !validated,
            "block": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return handleSubmit(_vm.userLogin);
            }
          }
        }, [_vm._v(" Login ")]), _c('div', {
          staticClass: "text-14 text-center my-3"
        }, [_vm._v(" Don't have account? "), _c('router-link', {
          staticClass: "grey--text text--darken-4 font-600",
          attrs: {
            "to": "/sign-up"
          }
        }, [_vm._v(" Sign Up ")])], 1)], 1)])])];
      }
    }])
  }), _c('div', {
    staticClass: "py-4 grey lighten-2"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v("Forgot Your Password "), _c('router-link', {
    staticClass: "ms-2 grey--text text--darken-4 font-600",
    attrs: {
      "to": "/forgot-password"
    }
  }, [_vm._v("Reset It")])], 1)])])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
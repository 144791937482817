var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "mb-15",
    attrs: {
      "id": "features"
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "text-center mb-15",
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', {
    staticClass: "secondary--text text-40 font-weight-bold"
  }, [_vm._v(" Powerful Features ")])])], 1), _c('v-row', {
    staticClass: "landing-features p-relative"
  }, _vm._l(_vm.powerfulFeatures, function (n, index) {
    return _c('v-col', {
      key: index,
      staticClass: "text-center",
      attrs: {
        "cols": "12",
        "md": "4",
        "lg": "4"
      }
    }, [_c('base-card', {
      staticClass: "shadow"
    }, [_c('div', {
      staticClass: "py-14"
    }, [_c('v-icon', {
      attrs: {
        "x-large": "",
        "color": "primary"
      }
    }, [_vm._v(_vm._s(n.icon))]), _c('h4', {
      staticClass: "mt-6 secondary--text"
    }, [_vm._v(_vm._s(n.name))]), n.tag ? _c('div', [_c('v-chip', {
      staticClass: "text-sm white--text mt-2",
      attrs: {
        "small": "",
        "color": "secondary",
        "label": "",
        "dark": ""
      }
    }, [_vm._v(" " + _vm._s(n.tag) + " ")])], 1) : _vm._e()], 1)])], 1);
  }), 1)], 1)], 1), _c('section', {
    staticClass: "py-15",
    attrs: {
      "id": "faqs"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "mb-5"
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-40 secondary--text font-weight-black"
  }, [_vm._v(" Frequently Asked Questions ")])])], 1), _c('v-row', [[_c('v-expansion-panels', _vm._l(_vm.faqs, function (item, i) {
    return _c('v-expansion-panel', {
      key: i
    }, [_c('v-expansion-panel-header', [_c('div', {
      staticClass: "secondary--text"
    }, [_c('strong', [_vm._v(" " + _vm._s(item.title) + " ")])])]), _c('v-expansion-panel-content', [_c('div', {
      staticClass: "secondary--text"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])])], 1);
  }), 1)]], 2)], 1)], 1), _c('section', {
    staticClass: "py-15 section-pricing",
    attrs: {
      "id": "pricing"
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "mb-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-30 text-center secondary--text"
  }, [_vm._v(" Choose the plan that fits your needs. ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center flex-wrap"
  }, [_c('div', {
    staticClass: "pricing-card text-center me-4"
  }, [_c('h3', {
    staticClass: "mb-5"
  }, [_vm._v("Free Plan")]), _c('div', {
    staticClass: "d-flex justify-center mb-14"
  }, [_c('div', {
    staticClass: "font-600 text-16 mr-2"
  }, [_vm._v("$")]), _c('h3', {
    staticClass: "display-3 font-weight-black secondary--text"
  }, [_vm._v(" 0 ")])]), _c('p', {
    staticClass: "mb-16"
  }, [_vm._v(" The free plan will allow end users to use the service with some restrictions. ")]), _c('v-btn', {
    staticClass: "font-600 text-capitalize",
    attrs: {
      "outlined": "",
      "color": "secondary",
      "href": "/sign-up"
    }
  }, [_vm._v(" Get Started ")])], 1), _c('div', {
    staticClass: "pricing-card text-center primaryLight white--text"
  }, [_c('h3', {
    staticClass: "mb-5"
  }, [_vm._v("Starter")]), _c('div', {
    staticClass: "d-flex justify-center mb-14"
  }, [_c('div', {
    staticClass: "font-600 text-16 mr-2"
  }, [_vm._v("$")]), _c('h3', {
    staticClass: "display-3 font-weight-black"
  }, [_vm._v("TBC")])]), _c('p', {
    staticClass: "mb-16"
  }, [_vm._v(" The paid plan will allow end users to use the service without restrictions. ")]), _c('v-btn', {
    staticClass: "font-600 text-capitalize",
    attrs: {
      "outlined": "",
      "color": "white",
      "href": "/sign-up"
    }
  }, [_vm._v(" Coming soon ")])], 1), _c('div', {
    staticClass: "pricing-card text-center primary white--text"
  }, [_c('h3', {
    staticClass: "mb-5"
  }, [_vm._v("Pro")]), _c('div', {
    staticClass: "d-flex justify-center mb-14"
  }, [_c('div', {
    staticClass: "font-600 text-16 mr-2"
  }, [_vm._v("$")]), _c('h3', {
    staticClass: "display-3 font-weight-black"
  }, [_vm._v("TBC")])]), _c('p', {
    staticClass: "mb-16"
  }, [_vm._v(" The paid plan will allow end users to use the service without restrictions. ")]), _c('v-btn', {
    staticClass: "font-600 text-capitalize",
    attrs: {
      "outlined": "",
      "color": "white",
      "href": "/sign-up"
    }
  }, [_vm._v(" Coming soon ")])], 1)])])], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-card', {
    staticClass: "card-hover",
    class: _vm.isShortListed ? "elevation-6" : ''
  }, [_c('v-chip', {
    staticClass: "ma-2 p-absolute left-0 z-1 ma-3",
    attrs: {
      "color": "primary",
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.counter) + " miles ")]), _c('div', {
    staticClass: "card-hover-icon"
  }, [_c('div', {
    staticClass: "d-flex flex-column p-absolute right-0 z-1 mr-2 mt-1 card-hover-icon"
  }, [_c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "secondary"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-eye")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('base-card', [_c('v-card-title', [_c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "absolute": "",
      "right": "",
      "icon": "",
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "height": "250",
      "src": _vm.getPropertyAvatar
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h2', {
    staticClass: "mt-4 mb-4 leading-tight"
  }, [_vm._v(_vm._s(_vm.contentText))]), _c('h5', {
    staticClass: "grey--text text--darken-5 mb-3"
  }, [_vm._v("Distance: "), _c('span', {
    staticClass: "grey--text text--darken-1 font-weight-medium ml-2"
  }, [_vm._v(_vm._s(_vm.counter.toFixed(2)) + " miles")])]), _vm.amount[1] >= 1000 ? _c('div', [_c('h2', {
    staticClass: "font-weight-bold primary--text mb-2"
  }, [_vm._v(" £" + _vm._s(_vm.amount[0] / 1000) + "M - £" + _vm._s(_vm.amount[1] / 1000) + "M")])]) : _c('div', [_c('h2', {
    staticClass: "font-weight-bold primary--text mb-2"
  }, [_vm._v("£" + _vm._s(_vm.amount[0]) + "K - £" + _vm._s(_vm.amount[1]) + "K")])]), _c('h5', {
    staticClass: "grey--text text--darken-5 mb-3"
  }, [_vm._v("As seen on: "), _c('span', {
    staticClass: "grey--text text--darken-1 font-weight-medium ml-2"
  }, [_vm._v(_vm._s(_vm.portal))])])])], 1)], 1)], 1)], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.AddToShortList(_vm.tripId);
      }
    }
  }, [_vm.isShortListed ? _c('div', [_c('v-icon', [_vm._v("mdi-map-marker-star")])], 1) : _c('div', [_c('v-icon', [_vm._v("mdi-map-marker-star-outline")])], 1)]), _c('v-btn', {
    attrs: {
      "icon": "",
      "color": "#E84560"
    },
    on: {
      "click": function ($event) {
        return _vm.RemoveFromWishList(_vm.tripId);
      }
    }
  }, [_c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("mdi-map-marker-remove")])], 1)], 1)]), _c('router-link', {
    attrs: {
      "to": {
        path: '/listing-detail',
        query: {
          Id: _vm.tripId
        }
      }
    }
  }, [_c('v-img', {
    staticClass: "br-t-8",
    attrs: {
      "height": "250",
      "src": _vm.getPropertyAvatar
    }
  })], 1), _c('v-card-text', {
    staticClass: "justify-content-between align-end",
    staticStyle: {
      "height": "250px"
    }
  }, [_vm.isShortListed ? _c('v-chip', {
    staticClass: "text-sm amber--text mb-1",
    attrs: {
      "small": "",
      "color": "amber",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v(" Shortlisted ")]) : _vm._e(), _c('div', {
    staticClass: "flex-grow-1 my-3"
  }, [_c('router-link', {
    staticClass: "text-decoration-none",
    attrs: {
      "to": {
        path: '/listing-detail',
        query: {
          Id: _vm.tripId
        }
      }
    }
  }, [_c('h6', {
    staticClass: "mb-0 grey--text text--darken-4"
  }, [_vm._v(_vm._s(_vm.contentText.substring(0, 100)) + " ")])]), _c('div', {
    staticClass: "grey--text mt-2 mb-2"
  }, [_vm._v(" Date Added: " + _vm._s(_vm.dateAdded) + " ")]), _vm.amount[1] >= 1000 ? _c('div', {
    staticClass: "d-flex"
  }, [_c('h6', {
    staticClass: "primary--text mr-2 mb-0"
  }, [_vm._v(" £" + _vm._s(_vm.amount[0] / 1000) + "M - £" + _vm._s(_vm.amount[1] / 1000) + "M")])]) : _c('div', {
    staticClass: "d-flex"
  }, [_c('h6', {
    staticClass: "primary--text mr-2 mb-0"
  }, [_vm._v("£" + _vm._s(_vm.amount[0]) + "K - £" + _vm._s(_vm.amount[1]) + "K")])])], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('v-btn', {
    staticClass: "mb-8",
    attrs: {
      "depressed": "",
      "href": _vm.getlogo.link,
      "bottom": "",
      "target": "_blank"
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "100"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.getlogo.value,
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "grey--text mt-n4"
  }, [_vm._v(" Nearest Place: " + _vm._s(_vm.nearestPlace) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-dialog', {
    attrs: {
      "width": "90%",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticStyle: {
      "border-radius": "0px"
    }
  }, [_c('div', {}, [_c('div', {
    staticClass: "container mx-auto"
  }, [_c('Editor', {
    attrs: {
      "lessonData": _vm.lessonData
    },
    on: {
      "reset-full-screen-mode": _vm.closeDialog
    }
  })], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="pa-4 mb-10 d-flex justify-space-around flex-wrap">
            <v-btn
                x-large
                color="success"
                dark
                @click="meet"
              >
                Meet
              </v-btn>
            <v-btn
                x-large
                color="error"
                dark
                @click="skip"
              >
                Skip
              </v-btn>
        </div>
        <v-container
          class="px-0"
          fluid
        >
          <v-switch
            v-model="currentUserResumeDate"
            inset
            :label="`Out of Office: ${ currentUserResumeDate ? 'On':'Off'}`"
          ></v-switch>
        </v-container>
        <template v-if="currentUserResumeDate">
          <div>
            <!-- <div class="mb-6">Active picker: <code>{{ activePicker || 'null' }}</code></div> -->
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Out of Office"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <!---set :max to three months ahead-->
              <v-date-picker
                v-model="date"
                :active-picker.sync="activePicker"
                :max="addThreeMonths"
                min="1950-01-01"
                @change="save"
              ></v-date-picker>
            </v-menu>
          </div>
        </template>      
        <div class="mt-5 mb-10">
          <v-btn
            block
            color="primary"
            class="text-capitalize font-600"
            @click="update"
          >
            Save
          </v-btn>
        </div>
      </div>
    </div>
    </div>
  </v-app>
</template>

<script>
  import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
  import { mapGetters } from 'vuex'
  import axios from 'axios';

// const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
const cheqqMateServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL,
  }
};

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit('app/START_LOADING');
    return config;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    store.commit('app/FINISH_LOADING');
    return response;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

  // const auth = getAuth()
  export default {
    data () {
      return {
        checkbox: false,
        user: {
          email: '',
        },
        activePicker: null,
        date: null,
        menu: false,
        matchId: null,
        memberId: null,
        switch1: false,
        _switcher: null,
        currentUser: null,
      }
    },
    computed: {
      ...mapGetters('app', ['isLoading']),
      addThreeMonths () {
        const d = new Date(Date.now())
        d.setMonth(d.getMonth() + 3)
        return d.toISOString().split('T')[0]
      },
      currentUserResumeDate: {
        get(){
          return this.currentUser 
          ? !this.dateInPast(this.currentUser.person.resumeDate) 
          : false
        },
        set(val) {
          // add 1 day
          const d = new Date(Date.now())
          d.setDate(d.getDate() + 1)
          this.currentUser.person.resumeDate = val
          ? d.toISOString()
          : null
        }
      },
      // switcher: {
      //   get () {
      //     return this._switcher == this.currentUserResumeDate
      //   },
      //   set (val) {
      //     this._switcher = !val
      //   }
      // },
    },
    created () {
      if(this.$route.query.memberId && this.$route.query.matchId) {
        this.matchId = this.$route.query.matchId
        this.memberId = this.$route.query.memberId
        this.readMemberById()
      }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      "$route.query.matchId"(){
        this.matchId = this.$route.query.matchId
      },
      "$route.query.memberId"(){
        this.memberId = this.$route.query.memberId
      },
      date (val) {
        this.currentUser.person.resumeDate = val
      },
    },
    methods: {
      save (date) {
        this.$refs.menu.save(date)
      },
      convertDate (date) {
        return new Date(date).toISOString().split('T')[0]
      },
      //check if currentUser.resumeDate is in the past
      dateInPast (date) {
        const today = new Date()
        const resumeDate = new Date(date.split(' ')[0].split('/').reverse().join('-'))
        return resumeDate < today
      },
      forgetPassword () {
        this.$store.commit('app/START_LOADING')
        sendPasswordResetEmail(auth, this.user.email)
          .then(() => {
            // Password reset email sent!
            // ..
            this.$store.commit('app/FINISH_LOADING')
            // redirect
            this.$router.push('/sign-in')
          })
          .catch((error) => {
            const errorCode = error.code
            const errorMessage = error.message
            // ..
          })
      },
      update() {
        axios
        .post(
          `${cheqqMateServiceBaseUrl}/updateMemberResumeDateById`,
          {
            OutOfOfficeDetails: {      
              MemberDirectoryId: this.currentUser.memberDirectoryId,
              MemberId: this.memberId,
              MatchId: this.matchId,
              ResumeDate: this.currentUser.person.resumeDate
            }
          },
          cheqqMateServiceConfig,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Content-Type': 'application/json'
            }
          }
        )
        .then(res => {
          if(res.data) {

          } else {
          }
        })
        .catch(error => {
          if (error.response) {
          // console.log(error) //localdev
          }
      });
      },
      meet() {
        axios
        .post(
          `${cheqqMateServiceBaseUrl}/upsertMatchReport`,
          {
            Member: this.memberId,
            MatchId: this.matchId,
            ConfirmedCheckIn: "yes",
            SkippedCheckIn: "no"
          },
          cheqqMateServiceConfig,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Content-Type': 'application/json'
            }
          }
        )
        .then(res => {
          if(res.data) {

          } else {
          }
        })
        .catch(error => {
          if (error.response) {
          // console.log(error) //localdev
          }
      });
      },
      skip() {
        axios
        .post(
          `${cheqqMateServiceBaseUrl}/upsertMatchReport`,
          {
            Member: this.memberId,
            MatchId: this.matchId,
            ConfirmedCheckIn: "no",
            SkippedCheckIn: "yes"
          },
          cheqqMateServiceConfig,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Content-Type': 'application/json'
            }
          }
        )
        .then(res => {
          if(res.data) {

          } else {
          }
        })
        .catch(error => {
          if (error.response) {
          // console.log(error) //localdev
          }
      });
      },
      readMemberById() {
        axios
        .get(
          `${cheqqMateServiceBaseUrl}/getMemberById`+
            '?Member=' + this.memberId + '&MatchId=' + this.matchId,
          cheqqMateServiceConfig,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Content-Type': 'application/json'
            }
          }
        )
        .then(res => {
          if(res.data) {
            //save to vuex, so that it persists, instead of consistently calling api
            // this.$store.commit('user/GROUP_DETAILS_UPDATED', res.data.group);
            this.currentUser = res.data.person
            this.date = this.currentUser.person.resumeDate
          } 
          else {
          }
        })
        .catch(error => {
          if (error.response) {
          }
        });
      },  
    },
  }
</script>

<style lang="scss" scoped>
  .sign-up-card {
    width: 500px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    margin: 2rem auto;
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
    @media(max-width: 500px){
      width: 100%;
    }
    .sign-up-card-container {
      padding: 3rem 3.75rem 0px;
      @media(max-width: 500px){
        padding: 3rem 1rem 0px;
      }
    }
  }

</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ecom-dropdownmenu pt-0 d-none d-lg-block"
  }, [_c('ul', {
    staticClass: "py-3 mb-0"
  }, _vm._l(_vm.navigations, function (nav, index) {
    return _c('li', {
      key: index
    }, [_c('a', {
      attrs: {
        "href": "#"
      }
    }, [_c('v-avatar', {
      staticClass: "mr-2",
      attrs: {
        "tile": "",
        "size": "20"
      }
    }, [_c('img', {
      directives: [{
        name: "svg-inline",
        rawName: "v-svg-inline"
      }],
      staticClass: "icon",
      attrs: {
        "src": nav.icon,
        "alt": ""
      }
    })]), _c('p', {
      staticClass: "m-0 mb-0 flex-grow-1"
    }, [_vm._v(" " + _vm._s(nav.title))]), nav.menuData ? _c('v-icon', [_vm._v("mdi-chevron-right")]) : _vm._e()], 1), nav.menuComponent == 'MegaMenu2' && nav.menuData ? _c('div', {
      staticClass: "sub-menu"
    }, [_c('ul', {
      staticClass: "py-3"
    }, [_vm._l(nav.menuData, function (navTwo, index) {
      return _c('li', {
        key: index
      }, [_c('router-link', {
        attrs: {
          "to": "/search-product"
        }
      }, [_c('v-avatar', {
        staticClass: "mr-2",
        attrs: {
          "tile": "",
          "size": "20"
        }
      }, [_c('img', {
        directives: [{
          name: "svg-inline",
          rawName: "v-svg-inline"
        }],
        staticClass: "icon",
        attrs: {
          "src": navTwo.icon,
          "alt": ""
        }
      })]), _c('p', {
        staticClass: "mb-0 flex-grow-1"
      }, [_vm._v(" " + _vm._s(navTwo.title))]), navTwo.menuData ? _c('v-icon', [_vm._v("mdi-chevron-right")]) : _vm._e()], 1), _c('div', {
        staticClass: "sub-menu"
      }, [navTwo.menuData && navTwo.menuData.categories ? _c('div', {
        staticClass: "mega-menu"
      }, [_c('div', {
        staticClass: "d-flex justify-space-between flex-wrap mb-4"
      }, _vm._l(navTwo.menuData.categories, function (navThree, index) {
        return _c('div', {
          key: index
        }, [_c('h6', {
          staticClass: "fw-semibold mb-3"
        }, [_vm._v(_vm._s(navThree.title))]), _vm._l(navThree.subCategories, function (navFour, index) {
          return _c('ul', {
            key: index
          }, [_c('li', {}, [_c('router-link', {
            attrs: {
              "to": "/search-product"
            }
          }, [_vm._v(_vm._s(navFour.title))])], 1)]);
        })], 2);
      }), 0), _c('div', {
        staticClass: "d-flex justify-content-between"
      })]) : _vm._e()])], 1);
    }), _c('li', [_c('a', {
      attrs: {
        "href": "#"
      }
    }, [_c('v-avatar', {
      staticClass: "mr-2",
      attrs: {
        "tile": "",
        "size": "20"
      }
    }, [_c('img', {
      directives: [{
        name: "svg-inline",
        rawName: "v-svg-inline"
      }],
      staticClass: "icon",
      attrs: {
        "src": require("@/assets/images/icons/man.svg"),
        "alt": ""
      }
    })]), _c('p', {
      staticClass: "mb-0 flex-grow-1"
    }, [_vm._v(" Man")])], 1)])], 2)]) : nav.menuComponent == 'MegaMenu1' && nav.menuData && nav.menuData.categories ? _c('div', {
      staticClass: "sub-menu"
    }, [_c('div', {
      staticClass: "mega-menu"
    }, [_c('div', {
      staticClass: "row flex-wrap mb-4"
    }, _vm._l(nav.menuData.categories, function (navTwo, index) {
      return _c('div', {
        key: index,
        staticClass: "col-md-3"
      }, [_c('h6', {
        staticClass: "fw-semibold mb-3"
      }, [_vm._v(_vm._s(navTwo.title))]), _c('ul', _vm._l(navTwo.subCategories, function (navThree, index) {
        return _c('li', {
          key: index
        }, [_c('router-link', {
          attrs: {
            "to": "/search-product"
          }
        }, [_vm._v(_vm._s(navThree.title))])], 1);
      }), 0)]);
    }), 0), _c('div', {
      staticClass: "d-flex justify-content-between"
    })])]) : _vm._e()]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-invoice-text ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1 mb-0"
  }, [_vm._v("Transactions")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), _c('div', {
    staticClass: "mb-5"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('base-card', [_c('div', {
    staticClass: "pa-5"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('v-toolbar-title', [_vm._v("Subscription Details")])], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "secondary",
      "disabled": "",
      "dense": "",
      "label": "Subscription Plan"
    },
    model: {
      value: _vm.userProfile.subscription.name,
      callback: function ($$v) {
        _vm.$set(_vm.userProfile.subscription, "name", $$v);
      },
      expression: "userProfile.subscription.name"
    }
  }), _c('p', {
    staticClass: "text-11 grey--text"
  }, [_vm._v("Subscription Status")]), _c('v-switch', {
    staticClass: "mt-n2",
    attrs: {
      "color": "success",
      "disabled": "",
      "inset": ""
    },
    model: {
      value: _vm.userProfile.subscription.isActive,
      callback: function ($$v) {
        _vm.$set(_vm.userProfile.subscription, "isActive", $$v);
      },
      expression: "userProfile.subscription.isActive"
    }
  }), _c('p', {
    staticClass: "text-11 grey--text"
  }, [_vm._v("Subscription Created Date")]), _c('v-chip', [_c('v-icon', {
    staticClass: "icon"
  }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
    staticClass: "ma-1 grey--text text--darken-4"
  }, [_vm._v(_vm._s(_vm.formatDateTime(_vm.userProfile.subscription.createdDate)))])], 1)], 1)], 1)])])], 1)], 1)], 1)])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
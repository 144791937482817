var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-hover-icon"
  }, [_c('div', {
    staticClass: "d-flex flex-column p-absolute right-0 z-1 mr-2 mt-1 card-hover-icon"
  }, [_c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('base-card', [_c('v-card-title', [_c('h3', {
    staticClass: "mt-4 mb-4 secondary--text text-wrap"
  }, [_vm._v(_vm._s(_vm.modalTitle))])]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('p', {
    staticClass: "grey--text text--darken-1 mb-3"
  }, [_vm._v(_vm._s(_vm.modalParagraph))])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "elevation": "0",
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.close(false);
      }
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "elevation": "0",
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.confirm(false);
      }
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
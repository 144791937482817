<template>
  <div
    :class="[
      { 'sale-page-one': $route.path == '/sale-page-one' },
      { 'sale-page-two': $route.path == '/sale-page-two' },
    ]"
  >
    <v-app-bar
      class="navbar white"
      :class="{ 'd-none': $route.path == '/sale-page-two' }"
    >
      <div class="container">
        <!-- <div class="d-flex justify-space-between align-center"> -->
        <!-- <spacer/> -->
        <div
          id="navbar-toggle-dropdown"
          class="navbar-toggle-dropdown p-absolute"
          :class="{ open: isToggleNavbar }"
        >
          <navbar />
        </div>
        <v-row>
          <v-col md="2">
            <router-link to="/">
              <v-avatar

                width="auto"
                height="35"
                tile
                class="ml-n6 mt-3"
              >
                <img
                  src="@/assets/CheqqMateforCreators.Logo.png"
                  alt=""
                >
              </v-avatar>
            </router-link>
          </v-col>
          <v-col md="3">
            <ul class="navbar-nav navigation-navbar d-flex flex-row">
              <li class="nav-item me-3 mt-5">
                <a
                  class="nav-link secondary--text"
                  href="/"
                >Home</a>
                <ul>
                  <li>
                    <router-link to="/dashboard">
                      <p class="mb-0">
                        Dashboard
                      </p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3 mt-5">
                <a class="nav-link secondary--text">User Account</a>
                <ul>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Profile</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/view-profile">
                            <p class="mb-0">
                              View Profile
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/edit-profile">
                            <p class="mb-0">
                              Edit Profile
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/manage-subscription">
                            <p class="mb-0">
                              Manage Subscription
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Places</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/member-directory">
                            <p class="mb-0">
                              Member Directory
                            </p>
                          </router-link>
                        </li> 
                        <li>
                          <router-link to="/group-directory">
                            <p class="mb-0">
                              Group Directory
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/schedules">
                            <p class="mb-0">
                              Schedules
                            </p>
                          </router-link>
                        </li>                          
                      </ul>
                    </div>
                  </li> -->
                </ul>
              </li>
            </ul>
          </v-col>
          <!--DARK/LIGHT MODE-->
          <!-- <v-checkbox class="mt-4"
              v-model="$vuetify.theme.dark"
              color="purple"
              off-icon="mdi-theme-light-dark"
              on-icon="mdi-theme-light-dark"
          ></v-checkbox> -->
          <v-col md="4" />
          <v-col
            md="2"
            class="d-flex justify-center"
            v-if="this.$store.state.auth.authUser || loggedIn"
          >
            <router-link to="/view-profile">
              <v-avatar
                size="40"
                class="me-3 mt-3 left-0"
              >
              <v-img :src="_userProfile.avatar"></v-img>
                <!-- <vue-letter-avatar
                  :name="authUser.email?_userProfile.username:authUser.email"
                  :rounded="true"
                /> -->
              </v-avatar>
            </router-link>
            <p class="secondary--text mt-5">
              {{ authUser.email?_userProfile.username:authUser.email }}
            </p>
          </v-col>
          <v-col md="1">
            <div v-if="!this.$store.state.auth.authUser || !loggedIn">
              <v-btn
                href="/sign-in"
                outlined
                color="primary"
                class="font-600 text-capitalize mt-3"
              >
              Login
              </v-btn>
            </div>
            <div v-else>
              <v-btn
              outlined
              color="primary"
              class="font-600 text-capitalize mt-3"
              @click="logOut"
            >
              Sign Out
            </v-btn>
            </div>
          </v-col>
        </v-row>
        <!-- <v-row
          v-if="!this.$store.state.auth.authUser || !loggedIn"
          justify="space-between"
        >
          <v-col md="3" />
          <v-col md="2">

          </v-col>
        </v-row> -->
      </div>
    </v-app-bar>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import HeaderNavbar from '@/views/home/header-navbar'
  import Navbar from '@/views/home/navbar'
  import { directive as onClickaway } from 'vue-clickaway'
  import VueLetterAvatar from 'vue-letter-avatar'
  import Vue from 'vue'
  // import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

  // const auth = getAuth();
  // const defaultUser = [{
  //   displayName: 'Hello',
  //   email: 'world'
  // }]

  const defaultUserProfile = {
    id: 'Jane.Done@example.com',
    username: 'Jane.Done',
    type: 'userProfile',
    userId: 'Jane.Done@example.com',
    createdDate: '2011-11-02T12:10:07Z',
    subscriptions: [{
    }],
    locationsList: [
      {
        locations: [{
        }],
      },
    ],
    wishListProperties: 0,
    zooplaProperties: 0,
    zooplaPropertiiesLastModifiedDate: '',
    rightMoveProperties: 0,
    rightMovePropertiesLastModifiedDate: '',
    onTheMarketProperties: 0,
    onTheMarketPropertiesLastModifiedDate: '',
    dashboard: {
      history: [{}],
      topRoundtripList: [{}],
    },
  }

  Vue.use(VueLetterAvatar)
  export default {
    name: 'BaseAppBar',
    directives: {
      onClickaway: onClickaway,
    },
    components: {
      HeaderNavbar,
      Navbar,
    },

    computed: {
      ...mapState('auth', ['authUser']),
      ...mapGetters('user', ['getShortList', 'getWishList', 'getUserProfile']),
      ...mapGetters('store', ['getCartProducts']),
      cartTotal () {
        let total = 0
        this.getCartProducts.forEach(product => {
          total += product.amount * product.qty
        })
        return total
      },
      authUser () {
        return this.$store.state.auth.authUser
      },
      _userProfile: {
        get () {
          return this.$store.state.user.userProfile.userId == this.authUser.email
            ? this.$store.state.user.userProfile
            : defaultUserProfile
        },
        set (value) {
          // this.$store.commit('user/USER_DETAILS_UPDATED', value)
        },
      },
    },
    data: () => ({
      resetAppBar: false,
      cartCount: 0,
      colorNav: false,
      dialog: false,
      isToggleNavbar: false,
      user: {},
      loggedIn: true,
      align: 'end',
      j: 'space-between',
      items: [
        { title: 'Car' },
        { title: 'Clothes' },
        { title: 'Electronics' },
        { title: 'Laptop' },
      ],
      itemTwo: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      drawer: false,
      group: null,
    }),
    // created() {
    //   // https://firebase.google.com/docs/auth/web/manage-users
    //   onAuthStateChanged(auth, (user) => {
    //     if (user) {
    //       // User is signed in, see docs for a list of available properties
    //       this.user = user;
    //       // store user state in vuex
    //       this.$store.commit('user/CURRENT_USER_UPDATED', user);
    //       // ...
    //     } else {
    //       // User is signed out
    //       this.user = null;
    //     }
    //   });
    // },
    methods: {
      ...mapActions('store', ['addCart', 'removeCart']),
      toggleNavbar () {
        if (this.$route.path == '/') {
          this.isToggleNavbar = false
        } else {
          this.isToggleNavbar = !this.isToggleNavbar
        }
      },
      away () {
        this.isToggleNavbar = false
      },
      removeCartx (item) {
        this.removeCart(item)
      },
      addCartx (item) {
        this.addCart(item)
      },
      logOut () {
        this.$store.commit('app/START_LOADING')
        this.$store.dispatch('auth/logOut')
        this.$store.commit('app/FINISH_LOADING')
        this.loggedIn = false
        // this.$router.push({ path: '/' })//.then(refresh-page)
        // push & refresh page
        this.$router.push({ path: '/sign-in' })
      // this.$router.go(this.$router.push({ path: '/' }));

      // reset app-bar
      // this.resetAppBar = true;
      },
    },
  }
</script>
<style lang="scss" scoped>
$z-index-sub-topbar: 2;
$md: 959px;
$z-99: 99;
.z-999 {
  z-index: 999;
}

.v-list-item {
  min-height: 34px;
}
.top-bar {
  height: 40px;
  display: flex;
  align-items: center;
}
.sale-page-one {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sale-page-two {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sub-topbar {
  position: relative;
  box-shadow: none !important;
  width: 100%;
  height: 80px !important;
  z-index: $z-99;
  .dropdown-ecommerce {
    display: none;
  }
  &.v-app-bar--fixed {
    position: unset;
  }
  // z-index: $z-index-sub-topbar;

  &.v-app-bar--is-scrolled {
    position: fixed;
    top: 0;
    box-shadow: none !important;
    .dropdown-ecommerce {
      display: block;
    }
  }
  @media (max-width: $md) {
  }
}

.navbar {
  height: 60px;
  width: 100%;
  z-index: 2;
  box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
  &.v-app-bar--is-scrolled {
    position: relative;
    // top: 64px;
    // margin-bottom: 64px;
    box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
    // box-shadow: none !important;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.search-bar {
  ::v-deep .v-input__slot {
      border: 1px solid rgb(218, 225, 231);
      background-color: #fff !important;

  }
  .search-bar-dropdown {

     height: 39px;
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 2px;
    border-radius: 22px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 1px solid rgb(218, 225, 231) !important;
    box-shadow: none !important;
    @media (max-width: $md) {
      display: none;
    }
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
}

// toggleNavbarButton
.navbar-toggle-dropdown {
  z-index: 98;
  display: none;
  &.open {
    display: block;
    top: 64px;
  }
}
.v-hidden {
  visibility: hidden;
}
.empty-cart-sidebar {
  height: 80vh
}
</style>

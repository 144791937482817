<template>
  <v-container class="py-6">
      <v-row>
        <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                    <DashbordSidebar />
                </div>
                <div class="box-content">
                    <div class="d-flex justify-end pa-2 d-block d-md-none">
                        <v-btn
                            icon   
                            @click="isSidebar = !isSidebar"
                        >
                            <v-icon dark>
                                mdi-format-list-bulleted-square
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="box-container">
                        <v-row class="mb-n6">
                            <v-col cols="9" class="mt-2">
                                <v-avatar
                                    tile
                                    size="25"
                                    class="me-3 mb-2"
                                >
                                    <v-icon
                                        color="grey darken-1"
                                    >
                                    mdi-chart-pie
                                    </v-icon>
                                </v-avatar>
                                <span class="text-h5 font-weight-bold grey--text text--darken-1">Summary</span>
                                
                            </v-col>
                            <v-col cols="3" >
                                <v-select
                                :items="items"
                                label="Date Range"
                                solo
                                dense
                                flat
                                ></v-select>
                            </v-col>

                        </v-row>
                       <v-divider class="mb-4"></v-divider>
                       <v-row>
                        <v-col cols="6" sm="6" md="4" lg="4">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> 0 </h3>
                                <p class="mb-0 text-caption grey--text text--darken-1"> Total Members </p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="4">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> 0 </h3>
                                <p class="mb-0 text-caption grey--text text--darken-1"> New Members </p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="4">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> 0 </h3>
                                <p class="mb-0 text-caption grey--text text--darken-1"> Active Members </p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <!---secondary-->
                          <v-col cols="6" sm="6" md="4" lg="4">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> 0 </h3>
                                <p class="mb-0 text-caption grey--text text--darken-1"> Messages Posted </p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="4">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> 0 </h3>
                                <p class="mb-0 text-caption grey--text text--darken-1"> Messages posted on #Topic</p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="4">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> 0 </h3>
                                <p class="mb-0 text-caption grey--text text--darken-1"> Unanswered questions </p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <!---Top panels-->
                          <v-col cols="6">
                            <base-card>
                                <v-card-text class="text-16 mb-n8 font-weight-bold grey--text text--darken-1">
                                Top Contributors
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          small
                                          color="grey darken-1"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                        mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>Tooltip</span>
                                </v-tooltip>
                                </v-card-text>
                                <v-list one-line class="mt-2 pa-3">
                                  <template v-for="(item, index) in items2">
                                    <v-divider
                                    v-if="item.divider"
                                    :key="index"
                                    :inset="item.inset"
                                    ></v-divider>
                                    <v-list-item
                                    v-else
                                    :key="item.title"
                                    @mouseover="hoveredIndex0 = index"
                                    @mouseleave="hoveredIndex0 = null"
                                    >
                                    <v-list-item-avatar>
                                        <v-img :src="item.avatar"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content >
                                        <v-list-item-title v-html="item.title"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action
                                    >
                                      <v-btn
                                        v-show="hoveredIndex0 === index"
                                        icon
                                        small
                                        plain
                                      >
                                        <v-icon
                                          color="grey"
                                        >
                                          mdi-message
                                        </v-icon>
                                      </v-btn>                                    
                                    </v-list-item-action>
                                    </v-list-item>
                                  </template>
                                </v-list>
                            </base-card>
                          </v-col>
                          <v-col cols="6">
                            <base-card>
                                <v-card-text class="text-16 mb-n8 font-weight-bold grey--text text--darken-1">
                                Most engaged members
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          small
                                          color="grey darken-1"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                        mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>Tooltip</span>
                                </v-tooltip>
                                </v-card-text>
                                <v-list one-line class="mt-2 pa-3">
                                  <template v-for="(item, index) in items2"
                                  >
                                      <v-divider
                                      v-if="item.divider"
                                      :key="index"
                                      :inset="item.inset"
                                      ></v-divider>

                                      <v-list-item
                                      v-else
                                      :key="item.title"
                                      @mouseover="hoveredIndex1 = index"
                                      @mouseleave="hoveredIndex1 = null"
                                      >
                                      <v-list-item-avatar>
                                          <v-img :src="item.avatar"></v-img>
                                      </v-list-item-avatar>

                                      <v-list-item-content >
                                          <v-list-item-title v-html="item.title"></v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-action
                                      >
                                        <v-btn
                                          v-show="hoveredIndex1 === index"
                                          icon
                                          small
                                          plain
                                        >
                                          <v-icon
                                            color="grey"
                                          >
                                            mdi-message
                                          </v-icon>
                                        </v-btn>                                    
                                      </v-list-item-action>
                                      </v-list-item>
                                  </template>
                                </v-list>
                            </base-card>
                          </v-col>
                          <!---Bottom panels-->
                          <v-col cols="6">
                            <base-card>
                                <v-card-text class="text-16 mb-n8 font-weight-bold grey--text text--darken-1">
                                    Inactive new members
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          small
                                          color="grey darken-1"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                        mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>Tooltip</span>
                                </v-tooltip>
                                </v-card-text>
                                    <v-list one-line class="mt-2 pa-3"
                                    >
                                        <template v-for="(item, index) in items2"
                                        >
                                            <v-divider
                                            v-if="item.divider"
                                            :key="index"
                                            :inset="item.inset"
                                            ></v-divider>

                                            <v-list-item
                                            v-else
                                            :key="item.title"
                                            @mouseover="hoveredIndex2 = index"
                                            @mouseleave="hoveredIndex2 = null"
                                            >
                                            <v-list-item-avatar>
                                                <v-img :src="item.avatar"></v-img>
                                            </v-list-item-avatar>

                                            <v-list-item-content >
                                                <v-list-item-title v-html="item.title"></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action
                                            >
                                              <v-btn
                                                v-show="hoveredIndex2 === index"
                                                icon
                                                small
                                                plain
                                              >
                                                <v-icon
                                                  color="grey"
                                                >
                                                  mdi-message
                                                </v-icon>
                                              </v-btn>                                    
                                            </v-list-item-action>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                            </base-card>
                          </v-col>
                          <v-col cols="6">
                            <base-card>
                                <v-card-text class="text-16 mb-n8 font-weight-bold grey--text text--darken-1">
                                    Top messages
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          small
                                          color="grey darken-1"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                        mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>Tooltip</span>
                                </v-tooltip>
                                </v-card-text>
                                <v-list two-line class="mt-2 pa-3">
                                  <template v-for="(item, index) in items2"
                                  >
                                      <v-divider
                                      v-if="item.divider"
                                      :key="index"
                                      :inset="item.inset"
                                      ></v-divider>

                                      <v-list-item
                                      v-else
                                      :key="item.title"
                                      @mouseover="hoveredIndex3 = index"
                                      @mouseleave="hoveredIndex3 = null"
                                      >
                                      <v-list-item-avatar>
                                        <v-img :src="item.avatar"></v-img>
                                      </v-list-item-avatar>

                                      <v-list-item-content >
                                          <v-list-item-title v-html="item.title"></v-list-item-title>
                                          <v-list-item-subtitle class="mt-1">
                                            <v-chip
                                              label
                                              small
                                              class="darkGrey--text text--darken-2 font-weight-bold"
                                            >
                                            <v-icon class="pr-1" small color="darkGrey lighten-3" left>
                                              mdi-message
                                            </v-icon>
                                              458
                                          </v-chip>
                                          &nbsp;
                                          <v-chip
                                              label
                                              small
                                              class="darkGrey--text text--darken-2 font-weight-bold"
                                            >
                                            <v-icon class="pr-1" small color="darkGrey lighten-3" left>
                                              mdi-thumb-up
                                            </v-icon>
                                              4
                                          </v-chip>
                                            <!-- <v-btn 
                                              class="grey--text text--darken-3 text-caption" 
                                              color="grey lighten-3"
                                              depressed 
                                              x-small>
                                              <v-icon
                                                color="grey darken-1"
                                                class="pr-2"
                                                x-small
                                              >
                                                mdi-message
                                              </v-icon>
                                                
                                              </v-btn>   -->
                                          </v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
                                  </template>
                                </v-list>
                            </base-card>
                          </v-col>
                       </v-row>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

const defaultUserProfile = {
    id: 'Jane.Done@example.com',
    username: 'Jane.Done',
    type: 'userProfile',
    userId: 'Jane.Done@example.com',
    personalDetails: {
        id: "tzishi@live.co.uk",
        name: "Tino Zishiri",
        phoneNumber: "07943062109",
        emailAddres: "tzishi@live.co.uk",
        resumeDate: "14/08/2023 10:46",
        isDeletedFromDirectory: false
    },
    subscription: {
        name: "Free",
        isActive: true,
        createdDate: "14/08/2023 10:46"
    },
    memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
    groups: [
        {
            groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
            groupName: "Tino Zishiri's Awesome Squad"
        }
    ],
    schedule: [
        {
            "id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
            "scheduleName": "Tino Zishiri's Awesome Squad Schedule"
        }
    ],
    matches: 1,
    matchesLastModifiedDate: "14/08/2023 10:46",
    meetups: 1,
    meetupsLastModifiedDate: "14/08/2023 10:46",
    pauses: 1,
    pausesLastModifiedDate: "14/08/2023 10:46",
    dashboard: {
        topUserGroups: [
            {
                userGroup: null,
                matches: 0,
                meetups: 0,
                pauses: 0
            }
        ]
    },
  };
  const defaultMemberDirectory = {
    id: "4fe4dd40-cb2f-4164-bea2-16b05305c603",
    type: "Members",
    userId: "tino.zishiri@gmail.com",
    person: [
        {
            id: "ca7337e5-4a8d-4241-ab5d-62a5bcc419ba",
            name: "Tino Zishiri",
            phoneNumber: "447943062109",
            emailAddress: "tino.zishiri@gmail.com",
            resumeDate: "02/10/2023 22:16",
            isDeletedFromDirectory: false
        },
        {
            id: "734b201f-16cf-43cd-9115-39e991473550",
            name: "Tino Zishiri 1",
            phoneNumber: "447943062109",
            emailAddress: "member1@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        },
        {
            id: "1d519c8f-768f-4392-8d24-aed21f71324c",
            name: "Tino Zishiri 2",
            phoneNumber: "07943062109",
            emailAddress: "member2@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        },
        {
            id: "ab55e046-3110-4a74-aa85-1cca7b0260f8",
            name: "Tino Zishiri 3",
            phoneNumber: "07943062109",
            emailAddress: "member3@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        }
      ],
    }

import DashbordSidebar from "@/components/DashboardSidebar";
import { mapState, mapActions, mapGetters } from 'vuex';
import VueLetterAvatar from 'vue-letter-avatar';
import Vue from 'vue';
import axios from 'axios';

const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
const cheqqMateServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
  }
};

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit('app/START_LOADING');
    return config;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    store.commit('app/FINISH_LOADING');
    return response;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

Vue.use(VueLetterAvatar);
export default {
    components: {
        DashbordSidebar
    },
    data() {
        return{
            isSidebar: false,
            loginlink: '',
            items: ['Last 7 Days', 'Last 30 Days'],
            items2: [
                {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                title: 'Alex, Scott'},
                { divider: true, inset: true },
                {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
                title: 'Sandra Adams'},
                { divider: true, inset: true },
                {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
                title: 'Trevor Hansen'},
                { divider: true, inset: true },
                {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
                title: 'Britta Holt'},
            ],
            hoveredIndex0: null,
            hoveredIndex1: null,
            hoveredIndex2: null,
            hoveredIndex3: null,
            matchArray: [],
            matchDate: '',
            options: {
                chart: {
                    height: 265,
                    type: 'area',
                    toolbar: {
                    show: false,
                    },
                },
            },
        }
    },
    watch: {
      userProfile(value) {
      },
    },
    computed: {
      ...mapState('auth', ['authUser','userProfile', 'memberDirectory']),
        ...mapState('user', ['matchList']),
      authUser () {
        return this.$store.state.auth.authUser
      },
      userProfile() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.userProfile
          : defaultUserProfile
      },
      memberDirectory() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.memberDirectory
          : defaultMemberDirectory
      },
      scheduleHistoryAggregate(){
        return this.matchArray.length > 0 && this.matchDate != ''
          ? this.$store.state.user.scheduleHistoryAggregate.filter(a => 
            this._matchDate == this.formatDateTime(a.createdDate) &&
            this.matchArray.includes(a.id))
          : this.$store.state.user.scheduleHistoryAggregate
      },
      groupList() {
        return this.$store.state.user.groupList
          ? this.$store.state.user.groupList
          : defaultUserProfile.groups
      },
      groupsV2() {
        // add a filter by matchDate  
        return this.scheduleHistoryAggregate.map(a => {
          return {
            matchId: a.id,
            createdDate: this.formatDateTime(a.createdDate),
            name: this.groupList.filter(b => b.id == a.groupId)[0].name
          }
        })
      },
      scheduleList() {
        return this.$store.state.user.scheduleList
          ? this.$store.state.user.scheduleList
          : defaultUserProfile.schedule
      },
      stripeAccountId() {
        return this.$store.getters['user/getUserProfile']?this.$store.getters['user/getUserProfile'].merchant.stripeAccountId:'';
      },
      chargeStatus() {
        return this.$store.getters['user/getUserProfile']?this.$store.getters['user/getUserProfile'].merchant.chargeStatus:'';
      },
      chartOptions () {
        return [{
        //   colors: ["#D53E59"],
        // https://github.com/apexcharts/vue-apexcharts/tree/master/example/src/charts
          chart: {
            height: 250,
            type: 'area',
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            show: true,
          },
          xaxis: {
            type: "string",
            labels: {
              show: true,
            },
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          },
          yaxis: {
            show: true,
          },
          stroke: {
            width: 3,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        },
        }]
      },
      series() {
        return [{
          name: 'series1',
          data: [31, 40, 28, 51, 42, 109, 100]
        }, {
            name: 'series2',
            data: [11, 32, 45, 32, 34, 52, 41]
        }]
      },
    },
    mounted () {
    },
    methods: {
        generateLoginLink() {
            if(!this.loginlink){
                axios
                .get(
                    `${cheqqMateServiceBaseUrl}/loginLink`+
                    '?AccountId=' + this.stripeAccountId,
                    cheqqMateServiceConfig,
                    {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json'
                    }
                    }
                )
                .then(res => {
                    if(res.data) {
                        //save to local property
                        this.loginlink = res.data.link
                    } 
                    else {
                    }
                })
                .catch(error => {
                    if (error.response) {
                    }
                });
            }
        },
        formatDateTime (date) {
            // return date only without time and format it to dd/mm/yyyy
            return date.split('T')[0].split('-').reverse().join('/')
        } 
    }
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('dashbord-sidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-certificate ")])], 1), _c('h2', {
    staticClass: "mb-0 grey--text text--darken-1 mb-1"
  }, [_vm._v(" Courses ")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addCourse();
      }
    }
  }, [_vm._v(" Add New Course ")])], 1), _c('v-data-iterator', {
    attrs: {
      "items": _vm.courseList,
      "items-per-page": _vm.itemsPerPage,
      "page": _vm.page,
      "hide-default-footer": ""
    },
    on: {
      "update:itemsPerPage": function ($event) {
        _vm.itemsPerPage = $event;
      },
      "update:items-per-page": function ($event) {
        _vm.itemsPerPage = $event;
      },
      "update:page": function ($event) {
        _vm.page = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_c('v-row', _vm._l(props.items, function (item, idx) {
          return _c('v-col', {
            key: idx,
            attrs: {
              "cols": "12"
            }
          }, [_c('base-card', {}, [_c('div', {
            staticClass: "pt-3 pb-3 pr-5 pl-5 d-flex align-center flex-wrap justify-space-between"
          }, [item.thumbnailImageUrl ? _c('div', [_c('v-card', {
            staticStyle: {
              "max-width": "125px"
            },
            attrs: {
              "outlined": ""
            }
          }, [_c('v-avatar', {
            attrs: {
              "size": "50"
            }
          }, [_c('v-img', {
            attrs: {
              "contain": "",
              "src": item.thumbnailImageUrl
            }
          })], 1)], 1)], 1) : _c('div', [_c('v-avatar', {
            staticClass: "mr-1",
            attrs: {
              "size": "50",
              "tile": "",
              "bordered": "",
              "border-radius": "5",
              "color": "primary"
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "white",
              "x-large": ""
            }
          }, [_vm._v(" mdi-certificate-outline ")])], 1)], 1), _c('div', {
            staticClass: "mr-6 mt-2"
          }, [_c('a', {
            staticClass: "text-decoration-none",
            attrs: {
              "href": ("/manage-course?courseId=" + (item.id))
            }
          }, [_c('v-row', [_c('span', {
            staticClass: "text-sm secondary--text"
          }, [_vm._v(" Name ")])]), _c('v-row', [_c('p', {
            staticClass: "mt-2 secondary--text"
          }, [_vm._v(_vm._s(item.courseName))])])], 1)]), _c('div'), _c('div', [_c('v-row', [_c('span', {
            staticClass: "text-sm secondary--text ml-2 mr-4"
          }, [_vm._v(" Delete ")])]), _c('v-row', [_c('v-btn', {
            attrs: {
              "icon": ""
            },
            on: {
              "click": function ($event) {
                _vm.deleteAction(_vm.courseList.indexOf(item));
              }
            }
          }, [_c('v-icon', [_vm._v(" mdi-delete ")])], 1)], 1)], 1), _c('div', {}, [_c('v-row', [_c('span', {
            staticClass: "text-sm secondary--text ml-2 mr-1"
          }, [_vm._v(" Edit ")])]), _c('v-row', [_c('div', {
            staticClass: "mr-2"
          }, [_c('router-link', {
            staticStyle: {
              "text-decoration": "none !important"
            },
            attrs: {
              "to": {
                path: '/manage-course',
                query: {
                  courseId: _vm.courseList[idx].id,
                  index: idx
                }
              }
            }
          }, [_c('v-btn', {
            attrs: {
              "icon": ""
            }
          }, [_c('v-icon', [_vm._v(" mdi-pencil ")])], 1)], 1)], 1)])], 1)])])], 1);
        }), 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-row', {
          staticClass: "my-5 me-1",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-spacer'), _c('span', {
          staticClass: "mr-4 grey--text"
        }, [_vm._v(" Page " + _vm._s(_vm.page) + " of " + _vm._s(_vm.numberOfPages) + " ")]), _c('v-btn', {
          staticClass: "mr-1",
          attrs: {
            "fab": "",
            "disabled": _vm.page == 1,
            "small": "",
            "color": "primary"
          },
          on: {
            "click": _vm.formerPage
          }
        }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-btn', {
          staticClass: "ml-1",
          attrs: {
            "fab": "",
            "disabled": _vm.page == _vm.numberOfPages,
            "small": "",
            "color": "primary"
          },
          on: {
            "click": _vm.nextPage
          }
        }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
import store from './store';
import "./plugins";
import "@/assets/scss/_global.scss";
import "./vee-validate";
import { generateComplementaryColors } from './utils/colorUtils';
Vue.config.productionTip = false;


// plugin svgrender 
// import Vue plugin
import VueSvgInlinePlugin from "vue-svg-inline-plugin";

// import polyfills for IE if you want to support it
import "vue-svg-inline-plugin/src/polyfills";
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin);

// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
	attributes: {
		data: [ "src" ],
		remove: [ "alt" ]
	}
});

// end:: svgrender 

import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  


  Vue.component(VueSlickCarousel)
  Vue.component("VueSlickCarousel", VueSlickCarousel);
  import VueApexCharts from 'vue-apexcharts'
  Vue.use(VueApexCharts)

  Vue.component('apexchart', VueApexCharts)


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCVXXZtrBQy4kES_P12qZAdkhPGgGM6qEA",
  authDomain: "cheqqmate.firebaseapp.com",
  projectId: "cheqqmate",
  storageBucket: "cheqqmate.appspot.com",
  messagingSenderId: "1089146615750",
  appId: "1:1089146615750:web:adad8a6764a202314024c7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
new Vue({
  router,
  vuetify,
  store,
  // computed: {
  //   user() {
  //     // Replace 'getterName' with the name of your getter
  //     return this.$store.getters['auth/getAuthUser'].email;
  //   }
  // },
  // watch: {
  //   user: function (newVal, oldVal) {
  //     alert('user stored locally', { newVal, oldVal });
  //   }
  // },
  created() {
    /* Auto-login using created() lifecycle hook */
    const userString = localStorage.getItem('user');
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit('auth/SET_USER_DATA', userData);

      // call action to get user data then call get schedule data using user email
      this.$store.dispatch('user/getUser', userData.email).then(() => {
        var userEmail = userData.email;
        var memberDirectoryId = this.$store.state.user.userProfile.memberDirectoryId;
        // var groupDirectoryId = this.$store.state.user.userProfile.groupDirectoryId;

        var payloadV2 = {
          userId: userEmail,
          memberDirectoryId: memberDirectoryId
        };
        // call action to get schedule data
        this.$store.dispatch('user/getScheduleHistoryAggregate', userEmail);      
        // call action to get member data
        this.$store.dispatch('user/getMemberDirectory', payloadV2);
        // call action to get group data
        this.$store.dispatch('user/getGroups', userEmail);
        // call action to get schedule data
        this.$store.dispatch('user/getSchedules', userEmail);
        // call action to get matches data
        this.$store.dispatch('user/getMatches')
        // call action to get events data
        this.$store.dispatch('user/getEvents')
        // call action to get courses data
        this.$store.dispatch('user/getCourses')                   
      });
    }
      const colorCombo = generateComplementaryColors()
      this.$store.dispatch('user/setColorCombinations', colorCombo)
  },
  render: h => h(App)
}).$mount("#app");

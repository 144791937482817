const tinycolor = require('tinycolor2');

function generateComplementaryColors() {
    const colors = [];
    
    for (let i = 0; i < 100; i++) {
        const foreground = getRandomColor();
        const background = getComplementaryColor(foreground);
        colors.push({ foreground, background });
    }
    
    return colors;
}

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    
    let lighterColor = tinycolor(color).lighten(15); // Lighten the color by 15%
    return lighterColor.toHexString(); // Convert the color to a hexadecimal string
}

function getComplementaryColor(color) {
    // Implement your logic to calculate the complementary color here
    // You can use libraries like tinycolor2 or write your own algorithm
    
    // Example implementation using tinycolor2 library:
    const complementary = tinycolor(color).complement().toHexString();
    
    return complementary;
}

module.exports = {
    generateComplementaryColors,
    getRandomColor,
    getComplementaryColor
};
<template>
    <v-container class="py-6">
        <v-row>
          <v-col cols="12">
              <div class="box-wrapper">
                  <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                  <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                     <DashbordSidebar />
                  </div>
                  <div class="box-content">
                      <div class="d-flex justify-end pa-2 d-block d-md-none">
                          <v-btn
                              icon   
                              @click="isSidebar = !isSidebar"
                          >
                              <v-icon dark>
                                  mdi-format-list-bulleted-square
                              </v-icon>
                          </v-btn>
                      </div>
                      <div class="box-container">
                          <div class="d-flex justify-space-between flex-wrap mb-5">
                            <div class="d-flex align-center">
                              <v-avatar
                                  tile
                                  size="25"
                                  class="me-3"
                              >
                                  <v-icon
                                  color="grey darken-1"
                                  >
                                  mdi-wallet
                                  </v-icon>
                              </v-avatar>
                              <h2 class="grey--text text--darken-1 mb-0">Manage Paywall</h2>
                            </div>
                              <v-btn 
                                  outlined 
                                  color="primary" 
                                  class="text-capitalize font-600"
                                  @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                              >
                                  BACK
                              </v-btn>                             
                         </div>
                         <div>
                          <!---alert-->
                          <v-alert
                            v-if="!stripeAccountId || chargeStatus !== 'active'"
                            color="#ff627e"
                            prominent
                            type="error"
                          >
                            <v-row align="center">
                              <v-col class="grow">
                                CheqqMate uses Stripe to get you paid quickly and keep your personal and payment information secure. 
                                Thousands of companies around the world trust Stripe to process payments for their users. 
                                Set up a Stripe account to get paid with CheqqMate.
                              </v-col>
                              <v-col class="shrink">
                                <v-btn href="/merchant-onboarding" color="white" class="text-11 grey--text text--darken-2">Get Started</v-btn>
                              </v-col>
                            </v-row>
                          </v-alert>
                        </div>
                      <!---toolbar-->
                      <v-card>
                        <v-toolbar
                          flat
                          color="primary"
                          dark
                        >
                          <v-toolbar-title>Paywall Details</v-toolbar-title>
                        </v-toolbar>
                        <v-tabs vertical>
                          <v-tab>
                            <v-icon left>
                              mdi-account
                            </v-icon>
                            Option 1
                          </v-tab>
                          <v-tab>
                            <v-icon left>
                              mdi-lock
                            </v-icon>
                            Option 2
                          </v-tab>
                          <v-tab>
                            <v-icon left>
                              mdi-access-point
                            </v-icon>
                            Option 3
                          </v-tab>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <p>
                                  Onboarding Status
                                </p>
                                  <v-btn
                                    x-large
                                    outlined
                                    color="primary"
                                    dark
                                    block
                                    class="text-capitalize font-600 mb-5"
                                    href="/merchant-onboarding" 
                                  >
                                  <!-- <v-icon dark class="mr-2">
                                    mdi-open-in-new
                                  </v-icon> -->
                                    View Onboarding Status
                                  </v-btn>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <p>
                                  Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Nunc sed turpis.
                                </p>

                                <p>
                                  Suspendisse feugiat. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In hac habitasse platea dictumst. Fusce ac felis sit amet ligula pharetra condimentum.
                                </p>

                                <p>
                                  Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Nam commodo suscipit quam. In consectetuer turpis ut velit. Sed cursus turpis vitae tortor. Aliquam eu nunc.
                                </p>

                                <p>
                                  Etiam ut purus mattis mauris sodales aliquam. Ut varius tincidunt libero. Aenean viverra rhoncus pede. Duis leo. Fusce fermentum odio nec arcu.
                                </p>

                                <p class="mb-0">
                                  Donec venenatis vulputate lorem. Aenean viverra rhoncus pede. In dui magna, posuere eget, vestibulum et, tempor auctor, justo. Fusce commodo aliquam arcu. Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
                                </p>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <p>
                                  Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                                </p>

                                <p class="mb-0">
                                  Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.
                                </p>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                        </v-tabs>
                      </v-card>
                      </div>
                  </div>
              </div>
          </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import DashbordSidebar from "@/components/DashboardSidebar.vue"
  import { mapState, mapActions, mapGetters } from 'vuex';

  const defaultUserProfile = {
    "id": "Jane.Done@example.com",
    "username": "Jane.Done",
    "type": "userProfile",
    "userId": "Jane.Done@example.com",
    "createdDate": "2011-11-02T12:10:07Z",
    "subscriptions": [{
    }],
    "locationsList": [
        {
            "locations": [{
            }]
        }
    ],
	"wishListProperties": 0,
	"zooplaProperties": 0,
	"zooplaPropertiiesLastModifiedDate": "",
	"rightMoveProperties": 0,
	"rightMovePropertiesLastModifiedDate": "",
	"onTheMarketProperties": 0,
	"onTheMarketPropertiesLastModifiedDate": "",
	"dashboard": {
		"history": [{}],
		"topRoundtripList": [{}]
	}
};
  
    export default {
      components: {
          DashbordSidebar
      },    
      data: () => ({
        selectedMembers: null,
        selectedDayOfWeek: '',
        selectedDayOfMonth: '',
        selectedRecurrencePattern: '',
        dialog: false,
        file: {},
        fileData: "",
        singleSelect: false,
        dialog2: false,
        isSidebar: false,
        dialogDelete: false,
        uploadedFile: '',
        scheduleName: 'My Schedule',
        headers: [
          {
            text: 'Full Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Phone Number', value: 'phoneNumber', sortable: false },
          { text: 'Email Address', value: 'emailAddress', sortable: false },
          { text: 'Resume Date', value: 'resumeDate', sortable: true },
          { text: 'Is Archived', value: 'isDeletedFromDirectory', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      }),
  
      computed: {
        stripeAccountId() {
          return this.$store.getters['user/getUserProfile']?this.$store.getters['user/getUserProfile'].merchant.stripeAccountId:'';
        },
        chargeStatus() {
          return this.$store.getters['user/getUserProfile']?this.$store.getters['user/getUserProfile'].merchant.chargeStatus:'';
        },
      },
  
      watch: {
      },
  
      created () {
        this.initialize()
      },
  
      methods: {
        initialize(){},
        formatDateTime (date) {
          return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
        // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
        },        
      },
    }
  </script>
  <style lang="scss" scoped>
      .dashboard-nav-link {
          color: #2B3445 !important;
          text-decoration: none;
          align-items: center;
          display: flex;
          justify-content: space-between;
          border-left: 4px solid #fff;
          margin-bottom: 20px;
          svg {
              width: 20px;
          }
          &:hover, &.active {
              color: #2196F3 !important;
              border-left: 4px solid #2196F3;
          }
      }
  </style>
<template>
    <v-container class="py-6">
        <v-row>
          <v-col cols="12">
              <div class="box-wrapper">
                  <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                  <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                     <DashbordSidebar />
                  </div>
                  <div class="box-content">
                      <div class="d-flex justify-end pa-2 d-block d-md-none">
                          <v-btn
                              icon   
                              @click="isSidebar = !isSidebar"
                          >
                              <v-icon dark>
                                  mdi-format-list-bulleted-square
                              </v-icon>
                          </v-btn>
                      </div>
                      <div class="box-container">                        
                          <div class="d-flex justify-space-between flex-wrap mb-5">
                             <div class="d-flex align-center mt-n2 ">
                                  <v-avatar tile size="25" class="me-3">
                                      <img src="@/assets/images/icons/account-group.svg" alt="">
                                  </v-avatar>
                                  <h2 class="mb-0 secondary--text">Manage Group</h2>
                              </div>
                              <v-btn 
                                  outlined 
                                  color="primary" 
                                  class="text-capitalize font-600"
                                  @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                              >
                                  BACK
                              </v-btn>
                         </div>
                        <!--group settings-->
                      <!---toolbar-->
                      <v-card class="mb-4">
                        <v-toolbar
                          flat
                          color="primary"
                          dark
                        >
                          <v-toolbar-title>Group Settings</v-toolbar-title>
                        </v-toolbar>
                        <v-tabs vertical>
                          <v-tab>
                            <v-icon left>
                              mdi-information
                            </v-icon>
                            Details
                          </v-tab>
                          <v-tab>
                            <v-icon left>
                              mdi-tag
                            </v-icon>
                            Pricing
                          </v-tab>
                          <v-tab>
                            <v-icon left>
                              mdi-cog
                            </v-icon>
                            Config
                          </v-tab>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <p>
                                  Group Details
                                </p>
                                <v-col cols="6" lg="5" md="6" sm="12">
                                <v-text-field
                                    v-model="groupDetails.name"
                                    color="secondary"
                                    outlined
                                    :counter="30"
                                    dense
                                    label="Name"
                                ></v-text-field>
                              </v-col>
                            <v-col >
                              <v-textarea
                                  v-model="groupDetails.description"
                                  counter="100"
                                  outlined
                                  name="input-7-4"
                                  label="Description"
                                  value=""
                                  rows="1"
                                  class="mt-n4"
                                  >
                              </v-textarea>
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="groupDetails.interests"
                                :items="interests"
                                :item-text="'label'"
                                :item-value="'label'"
                                chips
                                label="Interests"
                                multiple
                                outlined
                                class="mb-n6 mt-n4"
                              ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-btn 
                                    color="primary"
                                    outlined
                                    class="text-capitalize font-600" @click="groupNameUpdated()">
                                    Save Changes
                                </v-btn>
                            </v-col>
                              </v-card-text>
                              
                            </v-card>
                          </v-tab-item>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <p>
                                  Subscription Details 
                                </p>
                                <v-col cols="6" lg="6" md="6" sm="12">
                                  <v-text-field
                                      v-model="groupDetails.subscriptionModel.productName"
                                      color="secondary"
                                      outlined
                                      :counter="50"
                                      dense
                                      label="Name"
                                  ></v-text-field>
                                </v-col>
                                <v-col >
                                  <v-textarea
                                      v-model="groupDetails.subscriptionModel.productDescription"
                                      counter="100"
                                      outlined
                                      name="input-7-4"
                                      label="Description"
                                      value=""
                                      rows="1"
                                      class="mt-n4"
                                      >
                                  </v-textarea>
                                </v-col>
                                <v-col cols="6" lg="5" md="6" sm="12">
                                  <v-select
                                      v-model="groupDetails.subscriptionModel.chargeStatus"
                                      :items="['Free', 'Chargeable']"
                                      color="secondary"
                                      label="Charge Status"
                                      class="mt-n4"
                                      outlined
                                      dense
                                    ></v-select>
                                </v-col>
                                <v-col>
                                  <v-card
                                    v-if="groupDetails.subscriptionModel.chargeStatus == 'Chargeable'"
                                    class="mt-n6"
                                    outlined
                                  >
                                  <v-col>
                                    <v-text-field
                                      v-model="groupDetails.subscriptionModel.price"
                                      outlined
                                      dense
                                      label="Price"
                                      prepend-inner-icon="mdi-currency-usd"
                                    ></v-text-field>
                                    <!--https://stackoverflow.com/questions/51296834/vuejs-vuetify-how-to-access-properties-of-object-in-v-select-->
                                  </v-col>
                                  <v-col class="mt-n10 mb-n4">
                                    <sub class="">How often the subscription will recur. For example, Recurrence=month and "Recurrence Frequency"=3 bills every 3 months</sub>
                                  </v-col>
                                  <v-col>
                                    <v-select
                                      v-model="groupDetails.subscriptionModel.recurringInterval"
                                      :items="['day', 'week', 'month', 'year']"
                                      label="Recurrence"
                                      outlined
                                      dense
                                    ></v-select>
                                    <v-select
                                    v-model="groupDetails.subscriptionModel.recurringCount"
                                    :items="[1,3]"
                                    outlined
                                    dense
                                    label="Recurrence Frequency"
                                  ></v-select>
                                  </v-col>
                                  </v-card>
                                </v-col>
                                <v-col cols="12"  :class="groupDetails.subscriptionModel.chargeStatus == 'Chargeable'?'':'mt-n10'" class="pa-4 d-flex justify-space-between flex-wrap">
                                  <v-btn 
                                      color="primary"
                                      outlined
                                      class="text-capitalize font-600" @click="groupNameUpdated()">
                                      Save Changes
                                  </v-btn>
                                  <v-btn 
                                      color="primary"
                                      outlined
                                      :loading="isLoading"
                                      :disabled="groupDetails.subscriptionModel.price === 0 || isLoading"
                                      class="text-capitalize font-600" @click="createProduct()">
                                      Add to Stripe
                                  </v-btn>
                              </v-col>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <p>
                                  Group Configuration
                                </p>

                                <div class="d-flex justify-start ml-n3 mb-n2">
                                  <v-switch
                                    v-model="groupDetails.isActive"
                                    color="primary"
                                    label="Active"
                                    class="mt-1 ml-3"
                                  ></v-switch>
                                  <v-switch
                                    v-model="groupDetails.isPublic"
                                    color="primary"
                                    label="Public"
                                    class="mt-1 ml-6"
                                  ></v-switch>
                                </div>
                              </v-card-text>
                              <v-col cols="12" class="mt-n2 mb-4">
                                <v-btn 
                                    color="primary"
                                    outlined
                                    class="text-capitalize font-600" @click="groupNameUpdated()">
                                    Save Changes
                                </v-btn>
                            </v-col>
                            </v-card>
                          </v-tab-item>
                        </v-tabs>
                      </v-card>


                        <!--table-->                                           
                          <template>
                              <v-data-table
                                  :headers="headers"
                                  :items="filteredMembers"
                                  sort-by="name"
                                  class="elevation-1"
                              >
                                <template v-slot:item.avatar="{ item }">
                                  <v-img :src="item.avatar" max-height="50px" max-width="50px"></v-img>
                                </template>
                                <template v-slot:item.authProvider="{ item }">
                                  <v-avatar size="25">
                                    <img :src="require(`@/assets/images/logos/${item.authProvider}.svg`)">
                                  </v-avatar>
                                </template>
                                <template v-slot:top>
                                <v-toolbar
                                  flat
                                >
                                  <!--dialog block user-->
                                  <v-dialog v-model="dialogDelete" max-width="600px" :retain-focus="false">
                                    <base-card>
                                        <v-card-title>
                                            <h3 class="mt-4 mb-2 secondary--text text-wrap">Are you sure you want to block this member?</h3>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col>
                                                    <p class="grey--text text--darken-5 mb-3">Blocked members will no longer be included in regular check-ins.</p>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            dark
                                            @click="closeDelete">Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            dark
                                            @click="deleteItemConfirm"
                                        >
                                            Confirm
                                        </v-btn>
                                        </v-card-actions>                    
                                    </base-card>
                                  </v-dialog>
                                </v-toolbar>
                                </template>
                                <template v-slot:item.resumeDate="{ item }">
                                    <v-chip small
                                    >
                                      <v-icon small
                                        class="icon"
                                      >
                                        mdi-clock-time-four
                                      </v-icon>
                                      <!-- {{ formatDateTime(item.resumeDate) }} -->
                                      <p class="ma-1 secondary--text text-sm">{{ formatDateTime(item.resumeDate) }}</p>
                                    </v-chip>
                                </template>
                                <!-- <template v-slot:item.lastSeen="{ item }">
                                    <v-chip small
                                    >
                                      <v-icon small
                                        class="icon"
                                      >
                                        mdi-clock-time-four
                                      </v-icon>
                                      <p class="ma-1 secondary--text text-sm">{{ formatDateTime(item.lastSeen) }}</p>
                                    </v-chip>
                                </template> -->
                                <template v-slot:item.isDeletedFromDirectory="{ item }">
                                    <v-switch color="primary" v-model="item.isDeletedFromDirectory"></v-switch> 
                                </template>
                                <template v-slot:item.actions="{ item }">
                                <v-icon
                                    medium
                                    @click="deleteItem(filteredMembers.indexOf(item))"
                                >
                                    mdi-delete
                                </v-icon>
                                </template>
                                <template v-slot:no-data>
                                <v-btn
                                    color="primary"
                                    @click="initialize"
                                >
                                    Reset
                                </v-btn>
                                </template>
                              </v-data-table>
                          </template>                          
                      </div>
                  </div>
              </div>
          </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import DashbordSidebar from "@/components/DashboardSidebar.vue"
  import { mapState, mapActions, mapGetters } from 'vuex';
  import axios from 'axios';
  import interestList from "../../data/interestList";

  // const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
  const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
  const cheqqMateServiceConfig = {
    headers: {
      'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
      'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
    }
  };
  
  const http = axios.create();
  
  http.interceptors.request.use(
    config => {
      store.commit('app/START_LOADING');
      return config;
    },
    error => {
      store.commit('app/FINISH_LOADING');
      store.commit(
        'app/SET_ERROR',
        'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
      );
  
      return Promise.reject(error);
    }
  );
  
  http.interceptors.response.use(
    response => {
      store.commit('app/FINISH_LOADING');
      return response;
    },
    error => {
      store.commit('app/FINISH_LOADING');
      store.commit(
        'app/SET_ERROR',
        'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
      );
  
      return Promise.reject(error);
    }
  );
  const defaultUserProfile = {
    id: 'Jane.Done@example.com',
    username: 'Jane.Done',
    type: 'userProfile',
    userId: 'Jane.Done@example.com',
    personalDetails: {
        id: "tzishi@live.co.uk",
        name: "Tino Zishiri",
        phoneNumber: "07943062109",
        emailAddres: "tzishi@live.co.uk",
        resumeDate: "14/08/2023 10:46",
        isDeletedFromDirectory: false
    },
    subscription: {
        name: "Free",
        isActive: true,
        createdDate: "14/08/2023 10:46"
    },
    memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
    groups: [
        {
            groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
            groupName: "Tino Zishiri's Awesome Squad"
        }
    ],
    schedule: [
        {
            "id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
            "scheduleName": "Tino Zishiri's Awesome Squad Schedule"
        }
    ],
    matches: 1,
    matchesLastModifiedDate: "14/08/2023 10:46",
    meetups: 1,
    meetupsLastModifiedDate: "14/08/2023 10:46",
    pauses: 1,
    pausesLastModifiedDate: "14/08/2023 10:46",
    dashboard: {
        topUserGroups: [
            {
                userGroup: null,
                matches: 0,
                meetups: 0,
                pauses: 0
            }
        ]
    },
  };
  const defaultGroupDetails = {
    id: "c6f50550-d47a-42c8-94e1-59a00620bf11",
    type: "Groups",
    userId: "tino.zishiri@gmail.com",
    name: "Tino Zishiri's Awesome Squad",
    isActive: true,
    createdDate: "02/10/2023 22:16",
    memberDirectoryId: "4fe4dd40-cb2f-4164-bea2-16b05305c603",
    memberId: [
        "xa7337e5-4a8d-4241-ab5d-62a5bcc419ba",
        "x34b201f-16cf-43cd-9115-39e991473550",
        "xd519c8f-768f-4392-8d24-aed21f71324c",
        "xb55e046-3110-4a74-aa85-1cca7b0260f8",
        "x33f99e4-c50a-4c01-8e25-a932574e276c",
        "x596ef45-6579-455b-9dbb-85b568a68c59"
    ]
  };
  const defaultMemberDirectory = {
    id: "4fe4dd40-cb2f-4164-bea2-16b05305c603",
    type: "Members",
    userId: "tino.zishiri@gmail.com",
    person: [
        {
            id: "aa7337e5-4a8d-4241-ab5d-62a5bcc419ba",
            name: "User 0",
            phoneNumber: "447943062109",
            emailAddress: "tino.zishiri@gmail.com",
            resumeDate: "02/10/2023 22:16",
            isDeletedFromDirectory: false
        },
        {
            id: "004b201f-16cf-43cd-9115-39e991473550",
            name: "User 1",
            phoneNumber: "447943062109",
            emailAddress: "member1@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        },
        {
            id: "dd519c8f-768f-4392-8d24-aed21f71324c",
            name: "User 2",
            phoneNumber: "07943062109",
            emailAddress: "member2@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        },
        {
            id: "bb55e046-3110-4a74-aa85-1cca7b0260f8",
            name: "User 3",
            phoneNumber: "07943062109",
            emailAddress: "member3@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        }
      ],
  };
    export default {
      components: {
          DashbordSidebar
      },    
      data: () => ({
        selectedMembers: null,
        deletedItem: null,
        dialog: false,
        saveButton: false,
        file: {},
        fileData: "",
        singleSelect: false,
        dialog2: false,
        isSidebar: false,
        dialogDelete: false,
        uploadedFile: '',
        groupName: null,
        headers: [
        { text: '', value: 'avatar', sortable: false },
        { text: 'Name', align: 'start', sortable: true, value: 'name'},
        { text: 'Login', value: 'authProvider', sortable: false },
        { text: 'Resume Date', value: 'resumeDate', sortable: true },
        { text: 'Blocked', value: 'isDeletedFromDirectory', sortable: false },
        { text: 'Remove', value: 'actions', sortable: false },
        ],
        headers2: [
          {
            text: 'Full Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Phone Number', value: 'phoneNumber', sortable: false },
          { text: 'Email Address', value: 'emailAddress', sortable: false },
          // { text: 'Resume Date', value: 'resumeDate', sortable: true },
          // { text: 'Is Archived', value: 'isDeletedFromDirectory', sortable: false },
          // { text: 'Actions', value: 'actions', sortable: false },
        ],
        members: [],
        groupMembers: [],
        _groupMembers: [],
        members2: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          phoneNumber: '',
          emailAddress: '',
          resumeDate: new Date().toJSON().slice(0,10),
          isDeletedFromDirectory: false,
        },
        defaultItem: {
          name: '',
          phoneNumber: '',
          emailAddress: '',
          resumeDate: new Date().toJSON().slice(0,10),
          isDeletedFromDirectory: false,
        },
      }),
  
      computed: {
        ...mapState('auth', ['authUser', 'memberDirectory','group']),
        ...mapGetters("app", ["isLoading"]),
        authUser () {
          return this.$store.state.auth.authUser
        },
        interests(){
            return interestList
        },
        formTitle () {
          return this.editedIndex === -1 ? 'Add Members' : 'Add Member'
        },
        groupDetails() {
          return this.$store.state.user.userProfile.userId == this.authUser.email
              ? this.$store.state.user.group
              : defaultGroupDetails       
        },
        memberDirectory() {
          return this.$store.state.user.userProfile.userId == this.authUser.email
            ? this.$store.state.user.memberDirectory
            : defaultMemberDirectory
        },
        userProfile() {
          return this.$store.state.user.userProfile.userId == this.authUser.email
            ? this.$store.state.user.userProfile
            : defaultUserProfile
        },
        filteredMembers: {
          get(){
            const membersInGroup = this.groupDetails.memberId;
            const filteredMembers = this.memberDirectory.person.filter(member => {
              return membersInGroup.includes(member.id);
            });
            return filteredMembers; 
          },
          set(value) {
            //update MemberId array
            this.$store.commit('user/ADD_MEMBER_TO_GROUP', value)
          }  
        },
        memberDirectoryPerson() {
          // return members where isDeletedFromDirectory is false
          return this.memberDirectory.person.filter(member => {
            return member.isDeletedFromDirectory == false;
          });
        } 
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialog2 (val) {
          val || this.close()
        },      
        dialogDelete (val) {
          val || this.closeDelete()
        },
        file(f) {
          if (f) {
              f.text().then((data) => {
              this.fileData = data;
              });
          }
        },
        "$route.query.groupId"(){
            this.groupId = this.$route.query.groupId
          },
        "$route.query.groupName"(){
          this.groupName = this.$route.query.groupName
        },          
        "groupDetails.name"() {
            this.saveButton = true;
        },           
      },
      mounted () {
        // console.log(this.$route.query.groupName)
        if(this.$route.query.groupName) {
          this.groupName = this.$route.query.groupName
        } else {
          this.readGroupById()
        }
      },
      created () {
        this.initialize()
      },
  
      methods: {
        ...mapActions('user', ['getMemberDirectory','updateGroupName','updateGroupMemberById','deleteGroupMemberById','insertProduct']),
        ...mapGetters('user', ['getShortList', 'getWishList', 'getUserProfile', 'getCurrentUser','getMemberDirectoryGetter']),    
          async sleep(ms) {
              return new Promise(resolve => setTimeout(resolve, ms));
          },
          formatDateTime (date) {
            return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
            // return new Date(date).toLocaleString().replace(/(.*)\D\d+/, '$1')
          },  
          nextPage () {
              if (this.page + 1 <= this.numberOfPages) this.page += 1
          },
          formerPage () {
              if (this.page - 1 >= 1) this.page -= 1
          },
          season (val) {
              return this.range[val]
          },
          hasHistory () { 
              return window.history.length > 2 
          },        
        initialize () {
        },
          editItem (item) {
              this.editedIndex = this.members.indexOf(item)
              this.editedItem = Object.assign({}, item)
              this.dialog = true
          },
  
          deleteItem (item) {
            this.deletedItem = this.groupDetails.memberId[item];
            this.dialogDelete = true

          },
          deleteItemConfirm () {
            // console.log(this.deletedItem)
            this.$store.commit('user/REMOVE_MEMBER_FROM_GROUP', this.deletedItem);
            this.$store.commit('user/DELETED_MEMBER_LIST_ID_UPDATED', this.deletedItem)
            this.deleteGroupMemberById()
            this.closeDelete()
          },
  
          close () {
              this.dialog = false
              this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              })
          },
          close2 () {
            this.dialog2 = false
          },
          closeDelete () {
              this.dialogDelete = false
              this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              })
          },
          groupNameUpdated() {
            this.updateGroupName();
          },
          groupMembersUpdated() {
            this.updateGroupMemberById();
          },
          createProduct() {
            this.insertProduct();
          },
          save () {
              if (this.editedIndex > -1) {
              Object.assign(this.members[this.editedIndex], this.editedItem)
              } else {
              this.members.push(this.editedItem)
              }
              this.close()
          },
          save2 () {
            var memberIds = []
              for (var i in this.selectedMembers) {
                memberIds.push(this.selectedMembers[i].id)
              }
              // Iterate through memberIds
              for (let member of memberIds) {
                // Check if the object is not in this.filteredMembers
                if (!this.filteredMembers.some(item => item.id === member.id)) {
                  // Push the object into this.filteredMembers
                  this.filteredMembers = member;
                }
              }
            this.close()
        },
          update() {
            axios
            .post(
              `${cheqqMateServiceBaseUrl}/upsertMember`,
              {
                UserEmail: "tzishi@live.co.uk",
                MemberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
                MemberDetails: this.members
            },
              cheqqMateServiceConfig,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  'Content-Type': 'application/json'
                }
              }
            )
            .then(res => {
              if(res.data) {
              } else {
              }
              this.saveButton = false;
            })
            .catch(error => {
              if (error.response) {
              }
            });
          },

          readGroupById() {
            axios
            .get(
              `${cheqqMateServiceBaseUrl}/getGroupById`+
                '?UserEmail=' + this.authUser.email + '&Group=' + this.$route.query.groupId,
              cheqqMateServiceConfig,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  'Content-Type': 'application/json'
                }
              }
            )
            .then(res => {
              if(res.data) {
                //save to vuex, so that it persists, instead of consistently calling api
                this.$store.commit('user/GROUP_DETAILS_UPDATED', res.data.group);
                this.groupMembers.push(res.data.group)
              } 
              else {
              }
            })
            .catch(error => {
              if (error.response) {
              }
            });
          },
      },
    }
  </script>
  <style lang="scss" scoped>
      .dashboard-nav-link {
          color: #2B3445 !important;
          text-decoration: none;
          align-items: center;
          display: flex;
          justify-content: space-between;
          border-left: 4px solid #fff;
          margin-bottom: 20px;
          svg {
              width: 20px;
          }
          &:hover, &.active {
              color: #2196F3 !important;
              border-left: 4px solid #2196F3;
          }
      }
  </style>
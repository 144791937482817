var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('div', {
    staticClass: "bg-body d-flex flex-column justify-center align-center min-vh-100"
  }, [_c('div', {
    staticClass: "sign-up-card"
  }, [_c('div', {
    staticClass: "sign-up-card-container"
  }, [_c('div', {
    staticClass: "text-center mb-10"
  }, [_c('h3', {
    staticClass: "mb-3"
  }, [_vm._v(" Forgot Password ")]), _c('h5', {
    staticClass: "text-sm font-600 grey--text text--darken-4"
  }, [_vm._v(" Please provide email to retrieve password ")])]), _c('div', {
    staticClass: "mb-4"
  }, [_c('p', {
    staticClass: "text-14 mb-1"
  }, [_vm._v(" Email ")]), _c('v-text-field', {
    staticClass: "mb-4",
    attrs: {
      "placeholder": "example@gmail.com",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1), _c('div', {
    staticClass: "mb-4"
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.forgetPassword
    }
  }, [_vm._v(" Reset Password ")])], 1)]), _c('div', {
    staticClass: "py-4 grey lighten-2"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "grey--text text--darken-1"
  }, [_c('a', {
    staticClass: "ms-2 grey--text text--darken-4 font-600",
    attrs: {
      "href": "/"
    }
  }, [_vm._v("Return to homepage")])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
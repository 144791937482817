<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <dashbord-sidebar />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn
                icon
                @click="isSidebar = !isSidebar"
              >
                <v-icon dark>
                  mdi-format-list-bulleted-square
                </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex justify-space-between flex-wrap mb-5">
                <div class="d-flex align-center ">
                  <v-avatar
                    tile
                    size="25"
                    class="me-3"
                  >
                    <v-icon
                      color="grey darken-1"
                    >
                      mdi-sitemap
                    </v-icon>
                  </v-avatar>
                  <h2 class="mb-0 grey--text text--darken-1">
                    Workflows
                  </h2>
                </div>
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize font-600"
                  @click="addWorkflow()"
                >
                  Add New Workflow
                </v-btn>
              </div>
              <v-data-iterator
                :items="workflowList"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                hide-default-footer
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="(item, idx) in props.items"
                      :key="idx"
                      cols="12"
                    >
                      <base-card class="">
                        <div class="pt-3 pb-3 pr-5 pl-5 d-flex align-center flex-wrap justify-space-between">
                          <!--avatar-->
                          <div>
                            <v-avatar
                              size="50"
                              tile
                              bordered
                              border-radius="5"
                              color="primary"
                              class="mr-1"
                            >
                              <v-icon
                                color="white"
                                x-large
                              >
                                mdi-sitemap
                              </v-icon>
                            </v-avatar>
                          </div>
                          <!--name-->
                          <div class="mr-6 mt-2">
                            <a
                              :href="`/workflow-editor?workflowId=${item.id}`"
                              class="text-decoration-none"
                            >
                              <v-row>
                                <span class="text-sm secondary--text">
                                  Name
                                </span>
                              </v-row>
                              <v-row>
                                <p class="mt-2 secondary--text">{{ item.workflowName }}</p>
                              </v-row>
                            </a>
                          </div>
                          <!--spacer-->
                          <div>
                          </div>
                           <!--archive instead of delete??? 01.04.2024-->                   
                           <div>
                            <v-row>
                              <span class="text-sm secondary--text ml-2 mr-4">
                                Delete
                              </span>
                            </v-row>
                            <v-row>
                                <v-btn
                                  icon
                                  @click="deleteAction(workflowList.indexOf(item))"
                                >
                                  <v-icon
                                  >
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                            </v-row>
                          </div>
                          <!---edit-->
                          <div class="">
                            <v-row>
                              <span class="text-sm secondary--text ml-2 mr-1">
                                Edit
                              </span>
                            </v-row>
                            <v-row>
                              <div class="mr-2">
                                <router-link
                                  style="text-decoration: none !important;"
                                  :to="{ path: '/workflow-editor',
                                         query: {
                                           id: workflowList[idx].id,
                                         }
                                  }"
                                >
                                  <v-btn
                                    icon
                                  >
                                    <v-icon
                                    >
                                      mdi-pencil
                                    </v-icon>
                                  </v-btn>
                                </router-link>
                              </div>
                            </v-row>
                          </div>
                        </div>
                      </base-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:footer>
                  <v-row
                    class="my-5 me-1"
                    align="center"
                    justify="center"
                  >
                    <v-spacer />
                    <span
                      class="mr-4
                                grey--text"
                    >
                      Page {{ page }} of {{ numberOfPages }}
                    </span>
                    <v-btn
                      fab
                      :disabled="page == 1"
                      small
                      color="primary"
                      class="mr-1"
                      @click="formerPage"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      :disabled="page == numberOfPages"
                      small
                      color="primary"
                      class="ml-1"
                      @click="nextPage"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DashbordSidebar from '@/components/DashboardSidebar.vue'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import ManageWorkflow from './ManageWorkflow'
  import { nanoid } from 'nanoid';
  export default {
    components: {
      DashbordSidebar,
      ManageWorkflow
    },
    data () {
      return {
        workflowList: [],
        isSidebar: false,
        page: 1,
        itemsPerPage: 5,
        items: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20],
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        status: false,
        title: '',
        paragraph: '',
        modalType: '',
        _id: '',
        nestableItem: [
          {
            id: 0,
            type: "trigger",
            condition: null,
            description: "New Trigger",
            children: []
          }
        ],
      }
    },
    computed: {
      ...mapGetters('user', ['getCurrentUser','memberDirectory']),
      ...mapState('auth', ['authUser']),
      ...mapState('user', ['memberDirectory']),
      authUser () {
        return this.$store.state.auth.authUser
      },
      memberDirectory() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.memberDirectory
          : []
      },
      numberOfPages () {
        return Math.ceil(this.workflowList?this.workflowList.length:1 / this.itemsPerPage)
      },
      userProfile() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.userProfile
          : []
      },   
    },
    created () {
      this.initialize()
    },
    methods: {
      ...mapActions('user', ['getUser','updateWorkflow','delWorkflow']),
      ...mapGetters('app', ['isVisible']),
      formatDateTime (date) {
        return new Date(date).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(/(.*)\D\d+/g, '$1')
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
      generateId: () => nanoid(10),
      addWorkflow() {
        // just datetime no seconds
        var date = new Date().toJSON().slice(0, 10)
        var time = new Date().toJSON().slice(11, 16)
        var dateTime = date + '-' + time.replace(':', '-').replace(':', '-')
        var _workflowName = `new-workflow-${dateTime}`
        
        var payload = {
          UserEmail: this.memberDirectory.userId,
          WorkflowDetails: 
            {
              memberDirectoryId: this.memberDirectory.id,
              workflowName: _workflowName,
              workflowTree,
		        }
        }
        this.updateWorkflow(payload).then(() => {
          this.$store.dispatch('user/getUser', this.userProfile.userId)
          // this.$store.dispatch('user/getUser', this.memberDirectory.userId)
        })
      },
      initialize () {
        this.workflowList = this.memberDirectory?this.memberDirectory.workflows:[]
      },
      deleteAction (id) {
        this._id = id
        this.modalType = 'delete'
        this.$store.commit('app/SET_MODAL_STATUS', this.isVisible == this.isVisible)
      },
    },
  }
</script>

<style lang="scss" scoped>
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #2196F3 !important;
            border-left: 4px solid #2196F3;
        }
    }
    @media (max-width: 550px) {
    .status {
      display: none;
    }
}
</style>

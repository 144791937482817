var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nestable-handle",
    attrs: {
      "draggable": ""
    },
    on: {
      "dragstart": _vm.dragstart,
      "touchstart": _vm.dragstart,
      "touchend": _vm.touchend,
      "touchmove": _vm.touchmove,
      "mouseover": function ($event) {
        _vm.isHovered = true;
      },
      "mouseleave": function ($event) {
        _vm.isHovered = false;
      }
    }
  }, [_vm.item.level === 'Section' ? _c('div', {
    staticClass: "add-item pl-2 pb-n2 mb-n2 darkGrey--text d-flex justify-space-between flex-wrap text-overline",
    staticStyle: {
      "min-height": "36px"
    }
  }, [_vm._v(" Section " + _vm._s(_vm.item.id + 1) + " " + _vm._s(_vm.item.description ? (": " + (_vm.item.description)) : '') + " "), _c('span', [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isHovered,
      expression: "isHovered"
    }],
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.editItem(_vm.item);
      }
    }
  }, [_vm._v(" mdi-pencil ")])], 1), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isHovered,
      expression: "isHovered"
    }],
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteItem(_vm.item);
      }
    }
  }, [_vm._v(" mdi-delete ")])], 1)], 1)]) : _vm.item.level === 'Lesson' && !_vm.hasParent ? _c('div', {
    staticClass: "add-item pl-4 pt-2 error--text font-weight-bold d-flex justify-space-between flex-wrap text-body-2",
    staticStyle: {
      "min-height": "36px"
    }
  }, [_vm._v(" Lessons can only be added inside a Section. Move this lesson to a Section or delete it. "), _c('span', {
    staticClass: "mt-n2"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteItem(_vm.item);
      }
    }
  }, [_vm._v(" mdi-delete ")])], 1)], 1)]) : _c('div', {
    staticClass: "mt-n2 mb-n1 text-body-1 grey--text text--darken-1 font-weight-medium d-flex justify-space-between flex-wrap text-none",
    staticStyle: {
      "min-height": "36px"
    }
  }, [_c('span', [_vm._t("default"), _vm._v(" " + _vm._s(_vm.item.description ? (": " + (_vm.item.description)) : '') + " ")], 2), _c('span', {
    staticClass: "mt-n2"
  }, [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isHovered,
      expression: "isHovered"
    }],
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.editItem(_vm.item);
      }
    }
  }, [_vm._v(" mdi-pencil ")])], 1), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isHovered,
      expression: "isHovered"
    }],
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.editItemFullScreen(_vm.item);
      }
    }
  }, [_vm._v(" mdi-fullscreen ")])], 1), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isHovered,
      expression: "isHovered"
    }],
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteItem(_vm.item);
      }
    }
  }, [_vm._v(" mdi-delete ")])], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="400"
    >
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          Accept Cookies & Privacy Policy
        </v-card-title>

        <v-card-text class="mt-5">
          {{showDialog}} <br/>
          This website uses cookies. By using this website you consent to our use of these cookies. For more information visit our
            <a href="/">Privacy Policy</a>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false, acceptCookies()"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VueCookies from 'vue-cookies';

export default {
    props: {
      dialog: {
        type: Boolean,
        // default: true,
      },
    },
    data () {
      return {
      }
    },
    methods: {
      acceptCookies(){
        //https://github.com/cmp-cc/vue-cookies
        VueCookies.set('accept_cookies', true, "1y")
      },
      continueWithoutLogin() {
  
      },
    },
    computed: {
      showDialog(){
        return !this.dialog
      }
    },
    mounted() {
    }
  }
</script>
import axios from 'axios';
import store from '@/store';

/* API must only return data type string */
var backendServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
var backendServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
  }
};

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit('app/START_LOADING');
    return config;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    store.commit('app/FINISH_LOADING');
    return response;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

// post request - userProfile
export const createUserProfile = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/createUserProfile',
      data,
      backendServiceConfig
    );
  }
};

// get request - userProfile
export const readUserProfile = {
  get() {
    return http.get(
      backendServiceBaseUrl + '/getUserProfile',
      backendServiceConfig
    );
  },
  getById(userEmail) {
    return http.get(
      backendServiceBaseUrl +
        '/getUserProfile' +
        '?UserEmail=' +
        userEmail,
        backendServiceConfig
    );
  }
};

export const readScheduleHistory= {
  get() {
    return http.get(
      backendServiceBaseUrl + '/getScheduleHistory',
      backendServiceConfig
    );
  },
  getById(payload) {
    return http.get(
      backendServiceBaseUrl +
        '/getScheduleHistory' +
        '?UserEmail=' +
        payload.userId +
        '&ScheduleId=' +
        payload.scheduleId,
        backendServiceConfig
    );
  }
};

export const readScheduleHistoryAggregate = {
  get() {
    return http.get(
      backendServiceBaseUrl + '/getScheduleHistoryAggregate',
      backendServiceConfig
    );
  },
  getById(userEmail) {
    return http.get(
      backendServiceBaseUrl +
        '/getScheduleHistoryAggregate' +
        '?UserEmail=' +
        userEmail,
        backendServiceConfig
    );
  }
};

export const readMemberDirectory = {
  get() {
    return http.get(
      backendServiceBaseUrl + '/getMemberDirectory',
      backendServiceConfig
    );
  },
  getById(payload) {
    return http.get(
      backendServiceBaseUrl +
      '/getMemberDirectory' +
      '?UserEmail=' +
      payload.userId +
      '&MemberDirectoryId=' +
      payload.memberDirectoryId,
      backendServiceConfig
    );
  }
};

export const readWishList = {
  get(userEmail) {
    return http.get(
      backendServiceBaseUrl +
        '/getWishList' +
        '?UserEmail=' +
        userEmail,
        backendServiceConfig
    );
  },
  getById(payload) {
    return http.get(
      backendServiceBaseUrl +
        '/getWishListById' +
        '?UserEmail=' +
        payload.userEmail +
        '?ListId=' +
        payload.listId,        
        backendServiceConfig
    );
  }
};

export const readGroup = {
  getById(payload) {
    return http.get(
      backendServiceBaseUrl +
        '/getGroupById' +
        '?UserEmail=' +
        payload.userId +
        '&Group=' +
        payload.groupId,        
        backendServiceConfig
    );
  }
};
export const readGroups = {
  get(userEmail) {
    return http.get(
      backendServiceBaseUrl +
        '/getGroups' +
        '?UserEmail=' +
        userEmail,
        backendServiceConfig
    );
  },
};

export const readSchedule = {
  getById(payload) {
    return http.get(
      backendServiceBaseUrl +
        '/getScheduleById' +
        '?UserEmail=' +
        payload.userId +
        '&ScheduleId=' +
        payload.groupId,        
        backendServiceConfig
    );
  }
};
export const readSchedules = {
  get(userEmail) {
    return http.get(
      backendServiceBaseUrl +
        '/getSchedules' +
        '?UserEmail=' +
        userEmail,
        backendServiceConfig
    );
  },
};
export const readEvent = {
  getById(payload) {
    return http.get(
      backendServiceBaseUrl +
        '/getEventById' +
        '?UserEmail=' +
        payload.userId +
        '&EventId=' +
        payload.groupId,        
        backendServiceConfig
    );
  }
};
export const readEvents = {
  get(userEmail) {
    return http.get(
      backendServiceBaseUrl +
        '/getEvents' +
        '?UserEmail=' +
        userEmail,
        backendServiceConfig
    );
  },
};
export const upsertEvent = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertEvent',
      data,
      backendServiceConfig
    );
  }
};
export const deleteEvent = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/deleteEvent',
      data,
      backendServiceConfig
    );
  }
};
export const readCourse = {
  getById(payload) {
    return http.get(
      backendServiceBaseUrl +
        '/getCourseById' +
        '?UserEmail=' +
        payload.userId +
        '&CourseId=' +
        payload.groupId,        
        backendServiceConfig
    );
  }
};
export const readCourses = {
  get(userEmail) {
    return http.get(
      backendServiceBaseUrl +
        '/getCourses' +
        '?UserEmail=' +
        userEmail,
        backendServiceConfig
    );
  },
};
export const upsertWorkflow = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertWorkflow',
      data,
      backendServiceConfig
    );
  }
};
export const upsertCourse = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertCourse',
      data,
      backendServiceConfig
    );
  }
};
export const upsertLesson = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertLesson',
      data,
      backendServiceConfig
    );
  }
};
export const deleteCourse = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/deleteCourse',
      data,
      backendServiceConfig
    );
  }
};
export const readMatches = {
  get(userEmail) {
    return http.get(
      backendServiceBaseUrl +
        '/getMatches' +
        '?UserEmail=' +
        userEmail,
        backendServiceConfig
    );
  },
};

export const upsertUserProfile = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertUserProfile',
      data,
      backendServiceConfig
    );
  }
};

export const upsertGroupName = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertGroup',
      data,
      backendServiceConfig
    );
  }
};
export const insertMerchantDetails = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/createAccount',
      data,
      backendServiceConfig
    );
  }
};

export const readOnboardingLink = {
  get(payload) {
    return http.get(
      backendServiceBaseUrl +
        '/accountLink' +
        '?UserEmail=' +
        payload.UserEmail +
        '&AccountId=' +
        payload.AccountId,
        backendServiceConfig
    );
  },
};

export const upsertChargeStatus = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertChargeStatus',
      data,
      backendServiceConfig
    );
  }
};
export const upsertGroup = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertGroup',
      data,
      backendServiceConfig
    );
  }
};
export const upsertSchedule = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertSchedule',
      data,
      backendServiceConfig
    );
  }
};
export const upsertGroupMemberById = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertGroupMemberById',
      data,
      backendServiceConfig
    );
  }
};

export const upsertShortList = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/upsertShortList',
      data,
      backendServiceConfig
    );
  }
};

export const createProduct = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/createProduct',
      data,
      backendServiceConfig
    );
  }
};
// delete request - group
export const deleteGroupMemberById = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/deleteGroupMemberById',
      data,
      backendServiceConfig
    );
  }
};

export const deleteGroup = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/deleteGroup',
      data,
      backendServiceConfig
    );
  }
};

export const deleteMember = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/deleteMember',
      data,
      backendServiceConfig
    );
  }
};

export const deleteWishListById = {
  post(data) {
    return http.post(
      backendServiceBaseUrl + '/deleteWishListById',
      data,
      backendServiceConfig
    );
  }
};

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationProvider', {
    attrs: {
      "name": _vm.$attrs.label,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('v-select', _vm._g(_vm._b({
          attrs: {
            "error-messages": errors
          },
          model: {
            value: _vm.innerValue,
            callback: function ($$v) {
              _vm.innerValue = $$v;
            },
            expression: "innerValue"
          }
        }, 'v-select', _vm.$attrs, false), _vm.$listeners))];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
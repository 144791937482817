<template>
      <!-- card-hover-shadow  -->
     <base-card class="card-hover"  :class="isShortListed ? `elevation-6` : '' ">   
          <v-chip
            class="ma-2 p-absolute left-0 z-1 ma-3"
            color="primary"
            small
          >
            {{counter}} miles
          </v-chip>
          <div class="card-hover-icon">
            <div class="d-flex flex-column p-absolute right-0 z-1 mr-2 mt-1 card-hover-icon">
                <!-- dialog -- modal  -->
                <v-dialog  v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </template>
                    <base-card>
                        <v-card-title>
                            <v-btn
                                class="mt-4"
                                absolute
                                right
                                icon
                                color="secondary"
                                text
                                @click="dialog = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-img
                                        height="250"
                                        :src="getPropertyAvatar"
                                    ></v-img>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <h2 class="mt-4 mb-4 leading-tight">{{contentText}}</h2>
                                    <h5 class="grey--text text--darken-5 mb-3">Distance: <span class="grey--text text--darken-1 font-weight-medium ml-2">{{counter.toFixed(2)}} miles</span></h5>
                                    <div v-if="amount[1] >=1000">
                                      <h2 class="font-weight-bold primary--text mb-2"> £{{amount[0]/1000}}M - £{{amount[1]/1000}}M</h2>
                                    </div>
                                    <div v-else>
                                      <h2 class="font-weight-bold primary--text mb-2">£{{amount[0]}}K - £{{amount[1]}}K</h2>
                                    </div>                              
                                    <h5 class="grey--text text--darken-5 mb-3">As seen on: <span class="grey--text text--darken-1 font-weight-medium ml-2">{{portal}}</span></h5>
                                </v-col>
                            </v-row>
                        </v-card-text>           
                    </base-card>
                </v-dialog>
                <!-- end dialog end modal  -->
                <v-btn
                    icon
                    color="secondary"
                    @click="AddToShortList(tripId)"
                >
                <div v-if="isShortListed">
                  <v-icon>mdi-map-marker-star</v-icon>
                </div>
                <div v-else>
                  <v-icon>mdi-map-marker-star-outline</v-icon>
                </div>
                </v-btn>
                <v-btn
                    icon
                    color="#E84560"
                    @click="RemoveFromWishList(tripId)"
                >
                    <v-icon class="ml-2">mdi-map-marker-remove</v-icon>
                </v-btn>
            </div>
          </div>
          <router-link :to="{ path: '/listing-detail', 
                  query: {
                      Id: tripId
                      }
                  }">
            <v-img
              height="250"
              class="br-t-8"
              :src="getPropertyAvatar"
            ></v-img>
          </router-link>
          <v-card-text class="justify-content-between align-end"  style="height: 250px;">
            <v-chip v-if="isShortListed"
                class="text-sm amber--text mb-1"
                small
                color="amber"
                label
                outlined
                >
                Shortlisted
            </v-chip>
            <div class="flex-grow-1 my-3">
              <router-link :to="{ path: '/listing-detail', 
                  query: {
                      Id: tripId,
                      }
                  }"
                class="text-decoration-none">
                <h6 class="mb-0 grey--text text--darken-4">{{contentText.substring(0, 100)}} </h6>
              </router-link>
                <div class="grey--text mt-2 mb-2">
                 Date Added: {{dateAdded}} 
                </div>
              <div v-if="amount[1] >=1000" class="d-flex">
                <h6 class="primary--text mr-2 mb-0"> £{{amount[0]/1000}}M - £{{amount[1]/1000}}M</h6>
              </div>
              <div v-else class="d-flex">
                <h6 class="primary--text mr-2 mb-0">£{{amount[0]}}K - £{{amount[1]}}K</h6>
              </div>
            </div>
            <!-- btn cart  -->
            <!-- <slot name="cardCart"></slot> -->
            <div class="d-flex flex-column">
              <v-btn class="mb-8"
                depressed
                :href="getlogo.link"
                bottom
                target="_blank"
              >
                <v-avatar class="" size="100">
                    <img :src="getlogo.value" alt=""> 
                </v-avatar>
              </v-btn>
                <div class="grey--text mt-n4">
                 Nearest Place: {{nearestPlace}} 
                </div>
            </div>
          </v-card-text>
     </base-card>
</template>
<script>
  import { mapGetters, mapActions  } from 'vuex';
  export default {
    props: {
      btnText: {
        type: String,
        default: ''
      },
      listing: Object,
      cardHoverShadow: {
        type: Boolean,
        default: false
      },
      counter: {
        type: Number,
        default: 0
      },
      contentImg: String,
      contentText: String,
      portal: String,
      tripId: String,
      dateAdded: String,
      nearestPlace: String,
      amount: {
        type: Array,
        default: [50, 5000]
      },
      contentRating: {
        type: Number,
        default: 4.5
      }
    },
    computed: {
      ...mapGetters(['getProducts']),
      ...mapGetters('user', ['getShortList']),
      isShortListed(){
        var x = this.getShortList.filter(item => item.id == this.tripId);
        return x.length > 0
      },
      getPropertyAvatar(){
        var value
        if(this.portal == 'zoopla'){
          value = require(`@/assets/images/unmove-homes/zoopla_${this.contentImg}.png`)
          
        }
        if(this.portal == 'rightmove'){
          value = require(`@/assets/images/unmove-homes/rightmove_${this.contentImg}.png`)
        }

        if(this.portal == 'onthemarket'){
          value = require(`@/assets/images/unmove-homes/onthemarket_${this.contentImg}.png`)
        }
        return value
      },
      getlogo() {
        var payload = {
          link: "",
          value: ""
        }
        if(this.portal == 'zoopla'){
          payload.link = 'https://www.zoopla.co.uk/',
          payload.value = require("@/assets/images/unmove-homes/zoopla.svg")
          
        }
        if(this.portal == 'rightmove'){
          payload.link = 'https://www.rightmove.co.uk/',
          payload.value = require("@/assets/images/unmove-homes/rightmove.svg")
        }

        if(this.portal == 'onthemarket'){
          payload.link = 'https://www.onthemarket.com/',
          payload.value = require("@/assets/images/unmove-homes/onthemarketcolor.svg")
        }
        return payload
      },
      
    },
    data () {
      return {
        dialog: false,
            items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
            options: {
                chart: {
                    height: 265,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
            },
            series: [{
                name: 'series1',
                data: [70, 60, 80, 51, 42, 109, 100, 50, 80]
            }],
            chartOptions: {
                 colors: ["#2196F3"],
                chart: {
                    height: 265,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                    },
                    stroke: {
                    curve: 'smooth'
                    },
                    grid: {
                    show: false,
                    },
                    xaxis: {
                    type: 'datetime',
                    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z", "2018-09-19T07:30:00.000Z", "2018-09-19T08:30:00.000Z"],
                    labels: {
                        show: false
                    }
                    },
                    yaxis: {
                    show: false
                    },
                    markers: {
                    size: 4
                    },
                    stroke: {
                    width: 1
                    },
                    tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                    },
            },        
      }
    },
    methods: {
      ...mapActions(["addCart"]),
      AddToShortList(i) {
        if(this.isShortListed){
            this.$store.commit('user/REMOVE_SHORT_LIST', i);       
        }
        else{
          this.$emit('add-listing', i);
        }
      },
      RemoveFromWishList(i){
        this.$emit('remove-listing', i);
      }
    }
  }
</script>
<style lang="scss" scoped>
   
    .br-t-8 {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  .card-hover {
    &:hover {
      .card-hover-icon {
        display: block;
      }
    }
    .card-hover-icon {
      display: none;
    }
  }

  
        
        
        
</style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-content', [_c('loading-overlay'), _c('v-fade-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-wallet ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1 mb-0"
  }, [_vm._v("Manage Paywall")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), _c('div', [!_vm.stripeAccountId || _vm.chargeStatus !== 'active' ? _c('v-alert', {
    attrs: {
      "color": "#ff627e",
      "prominent": "",
      "type": "error"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "grow"
  }, [_vm._v(" CheqqMate uses Stripe to get you paid quickly and keep your personal and payment information secure. Thousands of companies around the world trust Stripe to process payments for their users. Set up a Stripe account to get paid with CheqqMate. ")]), _c('v-col', {
    staticClass: "shrink"
  }, [_c('v-btn', {
    staticClass: "text-11 grey--text text--darken-2",
    attrs: {
      "href": "/merchant-onboarding",
      "color": "white"
    }
  }, [_vm._v("Get Started")])], 1)], 1)], 1) : _vm._e()], 1), _c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Paywall Details")])], 1), _c('v-tabs', {
    attrs: {
      "vertical": ""
    }
  }, [_c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-account ")]), _vm._v(" Option 1 ")], 1), _c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-lock ")]), _vm._v(" Option 2 ")], 1), _c('v-tab', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-access-point ")]), _vm._v(" Option 3 ")], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Onboarding Status ")]), _c('v-btn', {
    staticClass: "text-capitalize font-600 mb-5",
    attrs: {
      "x-large": "",
      "outlined": "",
      "color": "primary",
      "dark": "",
      "block": "",
      "href": "/merchant-onboarding"
    }
  }, [_vm._v(" View Onboarding Status ")])], 1)], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Nunc sed turpis. ")]), _c('p', [_vm._v(" Suspendisse feugiat. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In hac habitasse platea dictumst. Fusce ac felis sit amet ligula pharetra condimentum. ")]), _c('p', [_vm._v(" Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Nam commodo suscipit quam. In consectetuer turpis ut velit. Sed cursus turpis vitae tortor. Aliquam eu nunc. ")]), _c('p', [_vm._v(" Etiam ut purus mattis mauris sodales aliquam. Ut varius tincidunt libero. Aenean viverra rhoncus pede. Duis leo. Fusce fermentum odio nec arcu. ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Donec venenatis vulputate lorem. Aenean viverra rhoncus pede. In dui magna, posuere eget, vestibulum et, tempor auctor, justo. Fusce commodo aliquam arcu. Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi. ")])])], 1)], 1), _c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', [_vm._v(" Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo. ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis. ")])])], 1)], 1)], 1)], 1)], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
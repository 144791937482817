<template>
  <v-container class="py-6">
      <v-row>
        <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                    <DashbordSidebar />
                </div>
                <div class="box-content">
                    <div class="d-flex justify-end pa-2 d-block d-md-none">
                        <v-btn
                            icon   
                            @click="isSidebar = !isSidebar"
                        >
                            <v-icon dark>
                                mdi-format-list-bulleted-square
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="box-container">
                       <div class="d-flex justify-space-between flex-wrap mb-5">
                           <div class="d-flex align-center">
                            <v-avatar
                                    tile
                                    size="25"
                                    class="me-3"
                                >
                                    <v-icon
                                    color="grey darken-1"
                                    >
                                    mdi-account
                                    </v-icon>
                                </v-avatar>
                                <h2 class="grey--text text--darken-1 mb-0">Profile</h2>
                            </div>
                            <v-btn 
                                outlined 
                                color="primary" 
                                class=""
                                href='/edit-profile'
                            >
                                Edit Profile
                            </v-btn>
                       </div>
                       <v-row>
                          <v-col cols="12" md="12" lg="4">
                              <base-card>
                                <div class="pa-5">
                                    <div class="d-flex justify-space-between align-center flex-wrap">
                                        <div class="d-flex align-center">
                                            <v-avatar size="64" class="me-4">
                                                <img :src="userProfile.avatar" alt="">
                                                <!-- <vue-letter-avatar :name="userProfile.personalDetails.name" :rounded=true /> -->
                                            </v-avatar>
                                            <div>
                                                <v-row>
                                                    <h4 class="ml-2 font-600 secondary--text">{{userProfile.personalDetails.name}}</h4>
                                                </v-row>
                                                <v-row>
                                                    <!-- <p class="mb-0 grey--text text--darken-1">{{subscriptionLevel}}</p> -->
                                                <!--- bronze = 1 subscription, silver = 2, gold = 3, platinum = 4 -->
                                                </v-row>                                                
                                            </div>      
                                        </div>
                                    </div>
                                </div>
                              </base-card>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="2">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> {{ groupList?groupList.length:0 }} </h3>
                                <p class="mb-0 text-12 grey--text text--darken-1"> Groups </p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="2">
                            <router-link to="/schedules" style="text-decoration:none">
                              <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> {{ scheduleList?scheduleList.length:0 }} </h3>
                                <p class="mb-0 text-12 grey--text text--darken-1"> Schedules </p>
                              </base-card>
                            </router-link>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="2">
                            <router-link to="/member-directory" style="text-decoration:none">
                              <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> {{ memberDirectory.person.length }} </h3>
                                <p class="mb-0 text-12 grey--text text--darken-1"> Members </p>
                              </base-card>
                            </router-link>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="2">
                            <router-link to="/matches" style="text-decoration:none">
                              <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> {{ matchList.length  }} </h3>
                                <p class="mb-0 text-12 grey--text text--darken-1">Matches</p>
                              </base-card>
                            </router-link>
                          </v-col>
                          <!---secondary-->
                          <v-col cols="6" sm="6" md="4" lg="4">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> {{ groupList?groupList.length:0 }} </h3>
                                <p class="mb-0 text-12 grey--text text--darken-1"> Events </p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="4">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> {{ groupList?groupList.length:0 }} </h3>
                                <p class="mb-0 text-12 grey--text text--darken-1"> Courses </p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <v-col cols="6" sm="6" md="4" lg="4">
                            <router-link to="/group-directory" style="text-decoration:none">
                                <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                                <h3 class="font-600 secondary--text mb-0"> {{ groupList?groupList.length:0 }} </h3>
                                <p class="mb-0 text-12 grey--text text--darken-1"> Workflows </p>
                                </base-card>
                            </router-link>
                          </v-col>
                          <!---User Details-->
                          <v-col cols="12">
                              <base-card>
                                <div class="pa-4 d-flex justify-space-between flex-wrap">
                                    <div class="mx-2 my-2">
                                        <p class="text-sm grey--text text--darken-1 mb-1">
                                            User Name
                                        </p>
                                        <span class="secondary--text">{{userProfile.personalDetails.name}}</span>
                                    </div>
                                    <div class="mx-2 my-2">
                                        <p class="text-sm grey--text text--darken-1 mb-1">
                                            Email
                                        </p>
                                        <span class="secondary--text">{{userProfile.personalDetails.emailAddress}}</span>
                                    </div>
                                    <div class="mx-2 my-2">
                                        <p class="text-sm grey--text text--darken-1 mb-1">
                                            Member Since
                                        </p>
                                        <span class="secondary--text">{{userProfile.subscription.createdDate}}</span>
                                    </div>
                                    <div class="mx-2 my-2">
                                        <p class="text-sm grey--text text--darken-1 mb-1">
                                            Community
                                        </p>
                                        <span class="secondary--text">{{userProfile.personalDetails.organisation}}</span>
                                    </div>
                                </div>
                              </base-card>
                          </v-col>
                          <!---Payment Details-->
                          <v-col cols="12">
                            <v-expansion-panels>
                                <v-expansion-panel @change="generateLoginLink()">
                                    <v-expansion-panel-header>
                                        <p class="mb-0 text-12 grey--text text--darken-1"> Stripe Account </p>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="d-flex justify-space-between flex-wrap">
                                        <div class="mx-2">
                                            <p class="text-sm grey--text text--darken-1 mb-1">
                                                Stripe
                                            </p>
                                            <span class="secondary--text">
                                                <a :href="loginlink?loginlink.url:''" target="_blank" style="text-decoration: none;">
                                                    <v-btn
                                                        dense
                                                        outlined
                                                        color="primary"
                                                        dark
                                                        class="text-capitalize font-600 mb-5"
                                                    >
                                                    <v-icon dark class="mr-2">
                                                        mdi-open-in-new
                                                    </v-icon>
                                                        View Stripe Dashboard
                                                    </v-btn>
                                                </a>
                                            </span>
                                        </div>
                                        <div class="mx-2">
                                            <p class="text-sm grey--text text--darken-1 mb-1">
                                                Your Balance
                                            </p>
                                            <span class="secondary--text">$0.00</span>
                                        </div>
                                        <div class="mx-2">
                                            <p class="text-sm grey--text text--darken-1 mb-1">
                                                Member Since
                                            </p>
                                            <span class="secondary--text">{{userProfile.subscription.createdDate}}</span>
                                        </div>
                                        <div class="mx-2">
                                            <p class="text-sm grey--text text--darken-1 mb-1">
                                                Onboarding
                                            </p>
                                            <div class="text-center">
                                                <router-link
                                                    style="text-decoration: none !important;"
                                                    :to="{ path: '/merchant-onboarding'
                                                    }"
                                                >
                                                    <v-btn
                                                    icon
                                                    >
                                                    <v-icon>
                                                        mdi-arrow-right-circle
                                                    </v-icon>
                                                    </v-btn>
                                                </router-link>
                                                </div>
                                        </div>
                                    </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                       </v-row>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

const defaultUserProfile = {
    id: 'Jane.Done@example.com',
    username: 'Jane.Done',
    type: 'userProfile',
    userId: 'Jane.Done@example.com',
    personalDetails: {
        id: "tzishi@live.co.uk",
        name: "Tino Zishiri",
        phoneNumber: "07943062109",
        emailAddres: "tzishi@live.co.uk",
        resumeDate: "14/08/2023 10:46",
        isDeletedFromDirectory: false
    },
    subscription: {
        name: "Free",
        isActive: true,
        createdDate: "14/08/2023 10:46"
    },
    memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
    groups: [
        {
            groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
            groupName: "Tino Zishiri's Awesome Squad"
        }
    ],
    schedule: [
        {
            "id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
            "scheduleName": "Tino Zishiri's Awesome Squad Schedule"
        }
    ],
    matches: 1,
    matchesLastModifiedDate: "14/08/2023 10:46",
    meetups: 1,
    meetupsLastModifiedDate: "14/08/2023 10:46",
    pauses: 1,
    pausesLastModifiedDate: "14/08/2023 10:46",
    dashboard: {
        topUserGroups: [
            {
                userGroup: null,
                matches: 0,
                meetups: 0,
                pauses: 0
            }
        ]
    },
  };
  const defaultMemberDirectory = {
    id: "4fe4dd40-cb2f-4164-bea2-16b05305c603",
    type: "Members",
    userId: "tino.zishiri@gmail.com",
    person: [
        {
            id: "ca7337e5-4a8d-4241-ab5d-62a5bcc419ba",
            name: "Tino Zishiri",
            phoneNumber: "447943062109",
            emailAddress: "tino.zishiri@gmail.com",
            resumeDate: "02/10/2023 22:16",
            isDeletedFromDirectory: false
        },
        {
            id: "734b201f-16cf-43cd-9115-39e991473550",
            name: "Tino Zishiri 1",
            phoneNumber: "447943062109",
            emailAddress: "member1@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        },
        {
            id: "1d519c8f-768f-4392-8d24-aed21f71324c",
            name: "Tino Zishiri 2",
            phoneNumber: "07943062109",
            emailAddress: "member2@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        },
        {
            id: "ab55e046-3110-4a74-aa85-1cca7b0260f8",
            name: "Tino Zishiri 3",
            phoneNumber: "07943062109",
            emailAddress: "member3@email.co.uk",
            resumeDate: "02/10/2023 22:17",
            isDeletedFromDirectory: false
        }
      ],
    }

import DashbordSidebar from "@/components/DashboardSidebar";
import { mapState, mapActions, mapGetters } from 'vuex';
import VueLetterAvatar from 'vue-letter-avatar';
import Vue from 'vue';
import axios from 'axios';

const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
const cheqqMateServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
  }
};

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit('app/START_LOADING');
    return config;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    store.commit('app/FINISH_LOADING');
    return response;
  },
  error => {
    store.commit('app/FINISH_LOADING');
    store.commit(
      'app/SET_ERROR',
      'An unexpected error occurred when retrieving data from the server. Please try again or contact support.'
    );

    return Promise.reject(error);
  }
);

Vue.use(VueLetterAvatar);
export default {
    components: {
        DashbordSidebar
    },
    data() {
        return{
            isSidebar: false,
            loginlink: ''
        }
    },
    watch: {
      userProfile(value) {
      },
    },
    computed: {
      ...mapState('auth', ['authUser','userProfile', 'memberDirectory']),
        ...mapState('user', ['matchList']),
      authUser () {
        return this.$store.state.auth.authUser
      },
      userProfile() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.userProfile
          : defaultUserProfile
      },
      memberDirectory() {
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.memberDirectory
          : defaultMemberDirectory
      }, 
      groupList() {
        return this.$store.state.user.groupList
          ? this.$store.state.user.groupList
          : defaultUserProfile.groups
      },
      scheduleList() {
        return this.$store.state.user.scheduleList
          ? this.$store.state.user.scheduleList
          : defaultUserProfile.schedule
      },
      stripeAccountId() {
        return this.$store.getters['user/getUserProfile']?this.$store.getters['user/getUserProfile'].merchant.stripeAccountId:'';
      },
      chargeStatus() {
        return this.$store.getters['user/getUserProfile']?this.$store.getters['user/getUserProfile'].merchant.chargeStatus:'';
      }
    },
    mounted () {
    },
    methods: {
        generateLoginLink() {
            if(!this.loginlink){
                axios
                .get(
                    `${cheqqMateServiceBaseUrl}/loginLink`+
                    '?AccountId=' + this.stripeAccountId,
                    cheqqMateServiceConfig,
                    {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json'
                    }
                    }
                )
                .then(res => {
                    if(res.data) {
                        //save to local property
                        this.loginlink = res.data.link
                    } 
                    else {
                    }
                })
                .catch(error => {
                    if (error.response) {
                    }
                });
            }

        }, 
    }
}
</script>

<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
    <!--snackbar to display alert/notification message-->
    <v-snackbar
        v-model = "snackbar"
        color = "primary"
        elevation = "16"
        class = "snackbar"
    >
      {{ snackbarMessage }}
    </v-snackbar>
      <div class="sign-up-card">
        <v-snackbar
          class=""
          :timeout="5000"
          :value="getAuthenticationErrors"
          absolute
          top
          color="red darken-2"
          left
          text
        >
          <strong>Login Failed: </strong>{{ getAuthenticationErrors }}
        </v-snackbar>
        <validation-observer
          ref="obs"
          v-slot="{ invalid, validated, handleSubmit }"
        >
        <div class="sign-up-card-container">
          <div class="text-center mb-10">
            <h3 class="mb-3">Create Your Account</h3>
            <h5 class="text-sm font-600 grey--text text--darken-4">Please provide your details</h5>
          </div>
          <div class="mb-1">
            <v-text-field-with-validation
                  v-model="user.username"
                  rules="required"
                  label="Username"
                  placeholder="John_S"
                />
          </div>
          <div class="mb-1">
            <v-text-field-without-spaces
              v-model="user.community"
              label="Community"
              placeholder=""
              hint="No spaces allowed"
            />
          </div>
          <div class="mb-1">
            <v-text-field-with-validation
              v-model="user.email"
              rules="required|email"
              label="Email"
              placeholder="example@gmail.com"
            />
          </div>
          <div class="mb-1">
            <v-text-field-password-with-validation
              v-model="user.password"
              rules="required"
              label="Password"
              placeholder="**********"
            />
          </div>
          <div class="mb-4">
             <v-checkbox v-model="checkbox">
              <template v-slot:label>
                <div>
                  By signing up, you agree to

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        href="#"
                        @click.stop
                        v-on="on"
                      >
                        Terms & Condtion
                      </a>
                    </template>
                      Opens in new window
                  </v-tooltip>
                 
                </div>
              </template>
             </v-checkbox>
          </div>

          <div class="mb-4 mt-n4">
            <v-btn
              @click.prevent="handleSubmit(userRegistration)"
              block
              elevation="0"
              color="primary"
              class="text-capitalize font-600"
            >
                Create Account
            </v-btn>
            
          </div>
        </div>
      </validation-observer>
         <div class="py-4 grey lighten-2">
              <div class="text-center">
                <div class="text-14 text-center my-3">Already Have Account? <router-link to="/sign-in" class=" grey--text text--darken-4 font-600">Sign In</router-link></div>
              </div>
          </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { mapGetters, mapActions  } from 'vuex';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VTextFieldWithValidation from '../components/inputs/VTextFieldWithValidation.vue';
import VTextFieldWithoutSpaces from '../components/inputs/VTextFieldWithoutSpaces.vue';
import VTextFieldPasswordWithValidation from '../components/inputs/VTextFieldPasswordWithValidation.vue'

const auth = getAuth();

//https://firebase.google.com/docs/auth/web/password-auth#web-version-9
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VTextFieldWithValidation,
      VTextFieldPasswordWithValidation,
      VTextFieldWithoutSpaces
    },
    data () {
      return {
        checkbox: false,
        snackbar: false,
        snackbarMessage: '',
        user: {
          username: '',
          community: '',
          email: '',
          password: ''
        }        
      }
    },
    computed: {
      ...mapGetters('user', ['getAuthenticationErrors'])
    },
    methods: {
    ...mapActions('user', ['createUser']), 
      async createUserProfile(user) {
          this.createUser(user);
      },          
      userRegistration() {
        this.$store.commit('app/START_LOADING'); 
        createUserWithEmailAndPassword(auth, this.user.email, this.user.password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;

            // Update displayName
            updateProfile(auth.currentUser, {
              displayName: this.user.username,
            })
            
            // Initialise user in cosmosDB - using user input
            this.createUserProfile(this.user)

            //end loading state
            this.$store.commit('app/FINISH_LOADING');  
            // redirect
            this.$router.push('/sign-in')
          })
        .catch((error) => {
          this.snackbar = true;
          this.snackbarMessage = error.message;
        });
      }
    }    
  }
</script>

<style lang="scss" scoped>
  .sign-up-card {
    width: 400px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    margin: 2rem auto;
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
    @media(max-width: 500px){
      width: 100%;
    }
    .sign-up-card-container {
      padding: 3rem 3.75rem 0px;
      @media(max-width: 500px){
        padding: 3rem 1rem 0px;
      }
    }
  }

</style>
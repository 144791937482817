<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        width="90%"
        transition="dialog-bottom-transition"
      >
        <v-card style="border-radius: 0px;">
          <div class="">
            <div class="container mx-auto">
              <Editor :lessonData="lessonData" @reset-full-screen-mode="closeDialog"/>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
</template>
<script>
import Editor from '@/components/editor/Editor.vue';
import { nanoid } from 'nanoid';
export default {
    name      : 'CourseEditor',
    components: {
      Editor
    },
    props: {
      showEditor: {
        type: Boolean,
        required: false,
        default: false
      },
      lessonData: {
        type: Object,
        required: false,
        default: () => ({})
      },
    },
    data () {
        return {
          attachment_content: '', //will probably be the v-model for a textarea
          dialog: false,
          notifications: false,
          sound: true,
          widgets: false,
          my_html: '',
          document: ''
        }
    },
    methods: {
      closeDialog () {
        this.$emit('reset-full-screen-mode');
        this.dialog = false
      },
      // an example method that you would call to insert the attachmnet once the attachment_content has been entered
      addAttachment(content) {
        this.trix.attachment(this.attachment_content);
      },
      generateId: () => nanoid()
    },
    watch: {
      showEditor: function (val) {
          this.dialog = val
        },
      }
}
</script>
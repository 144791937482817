<template>
  <v-container class="py-6">
    <welcome-message :dialog="showDialog" />
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <dashboard-sidebar-two />
          </div>

          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn
                icon
                @click="isSidebar = !isSidebar"
              >
                <v-icon dark>
                  mdi-format-list-bulleted-square
                </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
            
              <div class="d-flex align-center mb-5 secondary--text">
                <img
                  v-svg-inline
                  class="icon mr-3"
                  src="@/assets/images/icons/chart-bar.svg"
                  alt=""
                >
                <h2 class="mb-0 secondary--text">
                  Dashboard
                </h2>
              </div>
              <div class="row">
                <v-col
                  v-for="(dashBoardItem, index) in dashboardCard"
                  :key="index"
                  cols="12"
                  sm="6"
                  lg="4"
                >
                  <base-card>
                    <div class="px-5 py-9 text-center">
                      <h4 class="font-600 grey--text text--darken-2 mb-2">
                        {{ dashBoardItem.titleOne }}
                      </h4>
                      <h1 class="grey--text text--darken-4 mb-1">
                        {{ dashBoardItem.titleTwo }}
                      </h1>
                    </div>
                  </base-card>
                </v-col>
                <v-col
                  cols="12"
                  lg="8"
                  sm="12"
                >
                  <base-card>
                    <v-card-text class="text-16 font-600 grey--text text--darken-4">
                      Schedule Snapshot
                    </v-card-text>
                    <div class="pa-4 d-flex justify-space-between flex-wrap">
                        <div v-if="matchArray.length > 0" class="my-n2">
                            <p class="text-sm grey--text text--darken-1 mb-1">
                                Group
                            </p>
                            <span class="secondary--text">{{ groupsV2?groupsV2[0].name:'' }}</span>
                        </div>
                        <div v-if="matchDate" class="my-n2">
                            <p class="text-sm grey--text text--darken-1 mb-1">
                                Date
                            </p>
                            <span class="secondary--text">{{ matchDate }}</span>
                        </div>
                    </div>
                    <div class="pa-5">
                      <div id="chart">
                        <apexchart
                          type="bar"
                          height="250"
                          :options="chartOptions[0]"
                          :series="series"
                        />
                      </div>
                    </div>
                  </base-card>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                  sm="12"
                >
                  <base-card>
                    <div class="pa-5 secondary--text">
                      <h4>Advanced Stats</h4>
                      <div
                        v-for="(item,idx) in advancedStats"
                        :key="idx"
                        class="d-flex justify-space-between align-center my-2 text-truncate"
                      >
                        <div class="d-flex align-center">
                          <span
                            style="max-width: 115px;"
                            class="text-truncate"
                          >{{ item.titleOne }}
                          </span>
                        </div>
                        <h6 class="text-12 font-600">
                          {{ item.titleTwo?item.titleTwo.toFixed(2):0 }} %
                        </h6>
                      </div>
                    </div>
                  </base-card>
                  <base-card class="mt-6">
                    <div class="pa-5 secondary--text">
                      <h4>Filters</h4>
                      <v-col>
                        <v-select
                          v-model="matchDate"
                          :items="dates"
                          :item-text="'label'"
                          :item-value="'label'"
                          chips
                          dense
                          label="Date"
                          outlined
                          class="mb-n6 ml-n3 text-10"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          v-model="matchArray"
                          :items="filteredGroupsV2"
                          :item-text="'name'"
                          :item-value="'matchId'"
                          chips
                          dense
                          label="Group"
                          multiple
                          outlined
                          class="mb-n6 ml-n3"
                        ></v-select>
                      </v-col>
                    </div>
                  </base-card> 
                </v-col>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

  import DashboardSidebarTwo from '@/components/DashboardSidebarTwo.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import VueCookies from 'vue-cookies'
  import CookieConsent from '../components/shared/CookieConsent.vue'
  import WelcomeMessage from '../components/shared/WelcomeMessage.vue'
  const defaultUserProfile = {
    id: 'Jane.Done@example.com',
    username: 'Jane.Done',
    type: 'userProfile',
    userId: 'Jane.Done@example.com',
    personalDetails: {
        id: "tzishi@live.co.uk",
        name: "Tino Zishiri",
        phoneNumber: "07943062109",
        emailAddres: "tzishi@live.co.uk",
        resumeDate: "14/08/2023 10:46",
        isDeletedFromDirectory: false
    },
    subscription: {
        name: "Free",
        isActive: true,
        createdDate: "14/08/2023 10:46"
    },
    memberDirectoryId: "6ce53275-284f-44b0-b3a5-878820097175",
    groups: [
        {
            groupId: "3b9ca61d-3484-479c-8153-91a6ef2500c3",
            groupName: "Tino Zishiri's Awesome Squad"
        }
    ],
    schedule: [
        {
            "id": "34c42de5-7d95-4a13-b50c-6f6b512aa1a2",
            "scheduleName": "Tino Zishiri's Awesome Squad Schedule"
        }
    ],
    matches: 1,
    matchesLastModifiedDate: "14/08/2023 10:46",
    meetups: 1,
    meetupsLastModifiedDate: "14/08/2023 10:46",
    pauses: 1,
    pausesLastModifiedDate: "14/08/2023 10:46",
    dashboard: {
        topUserGroups: [
            {
                userGroup: null,
                matches: 0,
                meetups: 0,
                pauses: 0
            }
        ]
    },
  }
  const defaultScheduleHistory = {
    id: "84a6ec97-fa1e-41de-8a35-b06ff00c1ef7",
    type: "Schedules",
    userId: "tino.zishiri@gmail.com",
    groupId: "c6f50550-d47a-42c8-94e1-59a00620bf11",
    scheduleName: "Tino Zishiri's Awesome Squad Schedule",
    recurrencePattern: "Weekly",
    dayOfWeek: 5,
    dayOfMonth: 0,
    timeOfDay: 15,
    scheduleDateTime: "2023-10-13T15:13:00+01:00",
    scheduleHistory: [
        {
            matchId: "20a7ed7b-1275-4454-b46e-53bf46f5ff3e",
            messages: {
                id: "f2cdb863-97bc-41c9-8541-4189b9f5546b",
                sentDate: "2023-10-03T21:36:13.3889657Z",
                Message: "Hello World"
            }
        },
        {
            matchId: "9ec45295-07bd-41e2-adfd-4fbf782aa285",
            messages: {
                id: "55c4ae5e-a182-41da-bbc3-feb3e0d3a8bf",
                sentDate: "2023-10-09T16:33:27.4106275Z",
                Message: "Hello, this is a test message"
            }
        },
        {
            matchId: "bba1a9f2-941d-458c-aaea-ea6ac194c1b6",
            messages: {
                id: "968c7151-5bff-42ff-bbdb-3340b7e91768",
                sentDate: "2023-10-09T17:07:34.1456717Z",
                Message: "Hello, this is a test message"
            }
        }
    ],
  }
  const defaultScheduleHistoryAggregate = [
    {
        id: "b326a1b1-58f1-4ba5-8505-279936e71157",
        Responses: 2,
        Meetups: 1,
        Skipped: 1
    },
    {
        id: "20a7ed7b-1275-4454-b46e-53bf46f5ff3e",
        Responses: 1,
        Meetups: 1,
        Skipped: 0
    }]
  const defaultCategories = []
  const defaultListNames = []
  export default {
    components: {
      DashboardSidebarTwo,
      CookieConsent,
      WelcomeMessage
    },
    data () {
      return {
        showDialog: false,
        isSidebar: false,
        matchArray: [],
        matchDate: '',
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        options: {
          chart: {
            height: 265,
            type: 'area',
            toolbar: {
              show: false,
            },
          },
        },
      }
    },
    computed: {
      ...mapGetters('user', ['getCurrentUser']),
      ...mapState('auth', ['authUser']),
      authUser () {
        return this.$store.state.auth.authUser
      },
      createdDate () {
        return this.userProfile.dashboard.history.map(a => a.createdDate)
      },
      userProfile: {
        get () {
          return this.$store.state.user.userProfile.userId == this.authUser.email
            ? this.$store.state.user.userProfile
            : defaultUserProfile
        },
        set (value) {
          // this.$store.commit('user/USER_DETAILS_UPDATED', value)
        },
      },
      _matchDate() {
        return this.matchDate
      },
      scheduleHistory(){
        return this.$store.state.user.userProfile.userId == this.authUser.email
          ? this.$store.state.user.scheduleHistory
          : defaultScheduleHistory
      },
      scheduleHistoryAggregate(){
        return this.matchArray.length > 0 && this.matchDate != ''
          ? this.$store.state.user.scheduleHistoryAggregate.filter(a => 
            this._matchDate == this.formatDateTime(a.createdDate) &&
            this.matchArray.includes(a.id))
          : this.$store.state.user.scheduleHistoryAggregate
      },
      //create meetupsHistoryAggregate which sums up meetups property in scheduleHistoryAggregate
      meetupsHistoryAggregate(){
        return this.scheduleHistoryAggregate.map(a => 
        //sum up meetups property
        a.meetups).reduce((a, b) => a + b, 0)
      },
      skippedHistoryAggregate(){
        return this.scheduleHistoryAggregate.map(a => 
        //sum up skipped property
        a.skipped).reduce((a, b) => a + b, 0)
      },
      responsesHistoryAggregate(){
        return this.scheduleHistoryAggregate.map(a => 
        //sum up responses property
        a.responses).reduce((a, b) => a + b, 0)
      },
      groupList() {
        return this.$store.state.user.groupList
          ? this.$store.state.user.groupList
          : defaultUserProfile.groups
      },
      dates() {
        return this.scheduleHistoryAggregate.map(a => this.formatDateTime(a.createdDate))
      },
      datesV2() {
        return {
          createdDate: this.scheduleHistoryAggregate.map(a => a.createdDate),
          userFriendlyCreatedDate: this.scheduleHistoryAggregate.map(a => this.formatDateTime(a.createdDate))
        }
      },
      groups() {
        return this.scheduleHistoryAggregate.map(a => this.groupList.filter(b => b.id == a.groupId)[0].name)
      },
      groupsV2() {
        // add a filter by matchDate  
        return this.scheduleHistoryAggregate.map(a => {
          return {
            matchId: a.id,
            createdDate: this.formatDateTime(a.createdDate),
            name: this.groupList.filter(b => b.id == a.groupId)[0].name
          }
        })
      },
      filteredGroupsV2(){
        //filter groupsV2 on matchDate
        return this.groupsV2.filter(a => a.createdDate == this.matchDate)
      },
      dashboardCard () {
        var payload = [
          {
            titleOne: 'Matches',
            titleTwo: this.scheduleHistoryAggregate.length
          },
          {
            titleOne: 'Meetups',
            titleTwo: this.meetupsHistoryAggregate
          },
          {
            titleOne: 'Skipped',
            titleTwo: this.skippedHistoryAggregate
          },
        ]
        return payload
      },
      advancedStats () {
        var payload = [
          {
            titleOne: 'Meetups',
            titleTwo: ( this.meetupsHistoryAggregate/this.responsesHistoryAggregate) * 100,
          },
          {
            titleOne: 'Skipped',
            titleTwo: ( this.skippedHistoryAggregate/this.responsesHistoryAggregate ) * 100,
          },
        ]
        return payload
      },      
      chartOptions () {
        return [{
          colors: ["#D53E59"],
          chart: {
            height: 265,
            type: 'area',
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            show: false,
          },
          xaxis: {
            type: 'string',
            categories: ['Responses', 'Meetups', 'Skipped'],
            labels: {
              show: true,
            },
          },
          yaxis: {
            show: true,
          },
          stroke: {
            width: 3,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        }]
      },
      series() {
        return [
            {
              name: 'responses',
              data: [this.responsesHistoryAggregate, this.meetupsHistoryAggregate, this.skippedHistoryAggregate],
            }
        ]
      },
    },
    mounted () {
    var accept_cookies = VueCookies.get('accept_cookies')
          if (accept_cookies == 'false') {
            this.showDialog = false
          } else {
            this.showDialog = true
          }
        },
    created() {
      /* Auto-login using created() lifecycle hook */
      const userString = localStorage.getItem('user');
      if (userString) {
        const userData = JSON.parse(userString);
        this.$store.commit('auth/SET_USER_DATA', userData);

        // call action to get user data then call get schedule data using user email
        this.$store.dispatch('user/getUser', userData.email).then(() => {
          var userEmail = userData.email;
          var memberDirectoryId = this.$store.state.user.userProfile.memberDirectoryId;
          // var groupDirectoryId = this.$store.state.user.userProfile.groupDirectoryId;

          var payloadV2 = {
            userId: userEmail,
            memberDirectoryId: memberDirectoryId
          };
          // call action to get schedule data
          this.$store.dispatch('user/getScheduleHistoryAggregate', userEmail);      
          // call action to get member data
          this.$store.dispatch('user/getMemberDirectory', payloadV2);
          // call action to get group data
          this.$store.dispatch('user/getGroups', userEmail);
          // call action to get schedule data
          this.$store.dispatch('user/getSchedules', userEmail);
          // call action to get matches data
          this.$store.dispatch('user/getMatches')     
        });
      }
    },
    methods: {
      formatDateTime (date) {
        // return date only without time and format it to dd/mm/yyyy
        return date.split('T')[0].split('-').reverse().join('/')
      }      
    },
  }
</script>

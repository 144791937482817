var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-snackbar', {
    staticClass: "snackbar",
    attrs: {
      "color": "primary",
      "elevation": "16",
      "timeout": 500
    },
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('v-row', {
    staticClass: "mb-n6"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "50"
    }
  }, [_c('v-icon', {
    staticClass: "icon",
    attrs: {
      "large": "",
      "color": "grey darken-1"
    }
  }, [_vm._v(" mdi-account-clock ")])], 1), _c('h2', {
    staticClass: "grey--text text--darken-1 ml-n2"
  }, [_vm._v("Retention")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.items,
      "label": "Date Range",
      "solo": "",
      "dense": "",
      "flat": ""
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('base-card', [_c('div', {
    staticClass: "pa-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column text-start align-start justify-start"
  }, [_c('p', {
    staticClass: "mb-0 text-body-2 font-weight-bold secondary--text"
  }, [_c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "blue lighten-5"
    }
  }, [_c('span', {
    staticClass: "blue--text"
  }, [_vm._v("#")])]), _vm._v(" Avg. Member Tenure "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "color": "grey darken-1"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip")])])], 1), _c('h3', {
    staticClass: "font-600 secondary--text ml-12"
  }, [_vm._v(" 0 ")])])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column text-start align-start justify-start"
  }, [_c('p', {
    staticClass: "mb-0 text-body-2 font-weight-bold secondary--text"
  }, [_c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "green lighten-5"
    }
  }, [_c('span', {
    staticClass: "green--text"
  }, [_vm._v("#")])]), _vm._v(" Avg. Member Activity "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "color": "grey darken-1"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip")])])], 1), _c('h3', {
    staticClass: "font-600 secondary--text ml-12"
  }, [_vm._v(" 0 ")])])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column text-start align-start justify-start mb-n6"
  }, [_c('p', {
    staticClass: "mb-0 text-body-2 font-weight-bold secondary--text"
  }, [_c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "label": "",
      "small": "",
      "color": "grey lighten-2"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("#")])]), _vm._v(" Top Tenured Members "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "color": "grey darken-1"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip")])])], 1), _c('div', {
    staticClass: "ml-n4 mt-2"
  }, [_c('v-select', {
    attrs: {
      "dense": "",
      "items": _vm.items,
      "multiple": "",
      "readonly": "",
      "solo": "",
      "flat": "",
      "color": "white"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return [index < 2 ? _c('v-chip', {
          staticClass: "ma-1",
          attrs: {
            "small": "",
            "color": "grey lighten-2"
          }
        }, [_c('span', {
          staticClass: "grey--text"
        }, [_vm._v(_vm._s(item))])]) : _vm._e(), index === 1 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var on = _ref5.on,
                attrs = _ref5.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v("(+" + _vm._s(_vm.value.length - 1) + " others)")])];
            }
          }], null, true)
        }, [_c('span', [_c('ul', {
          staticStyle: {
            "list-style-type": "none"
          }
        }, _vm._l(_vm.items, function (item, index) {
          return _c('li', {
            key: index
          }, [_vm._v(" " + _vm._s(item) + " ")]);
        }), 0)])])], 1) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)])])], 1)], 1)]), _c('base-card', [_c('v-row', {
    staticClass: "ma-2"
  }, [_c('v-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "text-h5 font-weight-bold grey--text text--darken-1"
  }, [_vm._v("Retention")]), _c('br'), _c('span', {
    staticClass: "text-caption grey--text text--darken-1"
  }, [_vm._v("Retention measures the activity of community members over time")])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-checkbox', {
    staticClass: "bordered-checkbox",
    attrs: {
      "dense": "",
      "color": "primary",
      "off-icon": "mdi-chart-bar",
      "on-icon": "mdi-chart-bell-curve-cumulative",
      "label": "Chart"
    },
    model: {
      value: _vm.areaChart,
      callback: function ($$v) {
        _vm.areaChart = $$v;
      },
      expression: "areaChart"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.items,
      "label": "Date Range",
      "outlined": "",
      "dense": ""
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-n10 pa-5"
  }, [_c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": _vm.chartType,
      "height": "250",
      "options": _vm.chartOptions[0],
      "series": _vm.series
    }
  })], 1)])], 1)], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "shadow-sm br-8",
    class: _vm.cardHoverShadow ? 'card-hover-shadow' : '',
    attrs: {
      "max-width": "100%"
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-landing"
  }, [_c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "temporary": ""
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-avatar', [_c('img', {
    directives: [{
      name: "svg-inline",
      rawName: "v-svg-inline"
    }],
    attrs: {
      "src": require("@/assets/images/landing/CheqqMateLogo.svg"),
      "alt": ""
    }
  })])], 1)], 1), _c('v-divider'), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.items, function (_ref, i) {
    var text = _ref[0],
      link = _ref[1];
    return _c('v-list-item', {
      key: i,
      attrs: {
        "link": ""
      },
      on: {
        "click": function ($event) {
          return _vm.$vuetify.goTo(link);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "subtitile-1"
    }, [_vm._v(_vm._s(text))])], 1)], 1);
  }), 1)], 1), _c('v-container', [_c('v-app-bar', {
    staticClass: "px-15 header-landing-appbar",
    class: {
      expand: _vm.flat
    },
    attrs: {
      "app": "",
      "flat": _vm.flat,
      "elevation": "0"
    }
  }, [_c('v-toolbar-title', [_c('v-img', {
    staticClass: "cursor-pointer",
    attrs: {
      "src": require("@/assets/CheqqMateforCreators.Logo.png"),
      "max-width": "300",
      "max-height": "50"
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo('#home');
      }
    }
  })], 1), _c('v-spacer'), _vm.isXs ? _c('v-app-bar-nav-icon', {
    staticClass: "mr-4",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.drawer = !_vm.drawer;
      }
    }
  }) : _c('div', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo('#features');
      }
    }
  }, [_c('span', {
    staticClass: "mr-2 text-capitalize grey--text text--darken-3"
  }, [_vm._v("Features")])]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo('#faqs');
      }
    }
  }, [_c('span', {
    staticClass: "mr-2 text-capitalize grey--text text--darken-3"
  }, [_vm._v("FAQs")])]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo('#pricing');
      }
    }
  }, [_c('span', {
    staticClass: "mr-2 text-capitalize grey--text text--darken-3"
  }, [_vm._v("Pricing")])]), !this.$store.state.auth.authUser ? _c('span', [_c('v-btn', {
    staticClass: "mx-2 font-600 text-capitalize",
    attrs: {
      "href": "/sign-in",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v(" Sign In ")])], 1) : _c('span', [_c('router-link', {
    attrs: {
      "to": "/view-profile"
    }
  }, [_c('v-avatar', {
    staticClass: "mx-2",
    attrs: {
      "size": "40"
    }
  }, [_c('v-img', {
    attrs: {
      "src": this.$store.state.user.userProfile.avatar
    }
  })], 1)], 1), _c('v-btn', {
    staticClass: "mx-2 font-600 text-capitalize",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.logOut
    }
  }, [_vm._v(" Sign Out ")])], 1)], 1)], 1), _c('section', {
    staticClass: "p-relative pt-100",
    attrs: {
      "id": "home"
    }
  }, [_c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "500",
      "contain": "",
      "src": require("@/assets/images/landing/JoinCheqqMateforCreators.svg"),
      "alt": ""
    }
  }), _c('h4', {
    staticClass: "font-600 primary--text mb-4 mt-4"
  }, [_vm._v("Spark meaningful connections with automated pairings throughout your community.")]), _c('h2', {
    staticClass: "font-weight-bold secondary--text mb-3"
  }, [_vm._v(" Ready to create a community around your "), _c('span', {
    staticClass: "primary--text font-weight-bold text-animation",
    staticStyle: {
      "font-size": "1em"
    }
  }, [_vm._v(_vm._s(_vm.animatedText))])]), _c('div', {
    staticClass: "d-flex justify-center landing-button-wrapper mt-5"
  }, [!this.$store.state.auth.authUser ? _c('div', [_c('v-btn', {
    staticClass: "font-600 text-capitalize mr-4",
    attrs: {
      "href": "/sign-in",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v(" Login ")])], 1) : _c('div', [_c('v-btn', {
    staticClass: "font-600 text-capitalize mr-4",
    attrs: {
      "href": "/dashboard",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v(" Go to Dashboard ")])], 1)])], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "1200",
      "contain": "",
      "src": require("@/assets/images/landing/cheqq-mate-hero-image.svg")
    }
  })], 1), _c('div', {
    staticClass: "p-absolute landing-figma-button"
  }, [_c('v-btn', {
    staticClass: "font-600 text-capitalize mr-4",
    attrs: {
      "href": "/",
      "elevation": "4",
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v(" Get the App ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
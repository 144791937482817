var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "box-wrapper"
  }, [_c('div', {
    staticClass: "box-overlay",
    class: {
      'open': _vm.isSidebar
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }), _c('div', {
    staticClass: "box-sidebar pb-3 shadow-sm",
    class: {
      'open': _vm.isSidebar
    }
  }, [_c('DashbordSidebar')], 1), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "d-flex justify-end pa-2 d-block d-md-none"
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isSidebar = !_vm.isSidebar;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted-square ")])], 1)], 1), _c('div', {
    staticClass: "box-container"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between flex-wrap mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-center mt-n2"
  }, [_c('v-avatar', {
    staticClass: "me-3",
    attrs: {
      "tile": "",
      "size": "25"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/icons/history.svg"),
      "alt": ""
    }
  })]), _c('h2', {
    staticClass: "mb-0 secondary--text"
  }, [_vm._v("Match Details")])], 1), _c('v-btn', {
    staticClass: "text-capitalize font-600",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/');
      }
    }
  }, [_vm._v(" BACK ")])], 1), _c('div', {
    staticClass: "mb-5"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('base-card', [_c('div', {
    staticClass: "pa-5"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('v-toolbar-title', [_vm._v("Match Overview")])], 1), _vm.matchDetails.createdDate ? _c('div', {
    staticClass: "my-n2"
  }, [_c('p', {
    staticClass: "text-sm grey--text text--darken-1 mb-3"
  }, [_vm._v(" Date: " + _vm._s(this.formatDateTime(_vm.matchDetails.createdDate)) + " ")])]) : _vm._e(), _c('v-row', _vm._l(_vm.dashboardCard, function (dashBoardItem, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "sm": "6",
        "lg": "4"
      }
    }, [_c('base-card', [_c('div', {
      staticClass: "px-5 py-9 text-center"
    }, [_c('h4', {
      staticClass: "font-600 grey--text text--darken-2 mb-2"
    }, [_vm._v(" " + _vm._s(dashBoardItem.titleOne) + " ")]), _c('h1', {
      staticClass: "grey--text text--darken-4 mb-1"
    }, [_vm._v(" " + _vm._s(dashBoardItem.titleTwo) + " ")])])])], 1);
  }), 1)], 1)])], 1)], 1)], 1), [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.pairsV2,
      "sort-by": "pair"
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("Match Records")])], 1)];
      },
      proxy: true
    }, {
      key: "item.pair",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('p', {
          staticClass: "mt-3 grey--text"
        }, [_c('v-badge', {
          attrs: {
            "color": "darkGrey mt-3",
            "inline": "",
            "content": item.pair
          }
        })], 1)];
      }
    }, {
      key: "item.responseDate",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_c('v-icon', {
          staticClass: "icon",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
          staticClass: "ma-1 secondary--text text-sm"
        }, [_vm._v(_vm._s(item.responseDate ? _vm.formatDateTime(item.responseDate) : '31/12/9999, 00:00'))])], 1)];
      }
    }, {
      key: "item.reportStatus",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": _vm.getColor(item.reportStatus),
            "dark": ""
          }
        }, [_c('p', {
          staticClass: "ma-1 text-sm"
        }, [_vm._v(_vm._s(item.reportStatus))])])];
      }
    }, {
      key: "item.actions",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          attrs: {
            "medium": ""
          },
          on: {
            "click": function ($event) {
              return _vm.viewRecord(item);
            }
          }
        }, [_vm._v(" mdi-eye ")])];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.initialize
          }
        }, [_vm._v(" Reset ")])];
      },
      proxy: true
    }])
  })], _c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "retain-focus": false
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('base-card', [_c('v-card-title', [_c('h3', {
    staticClass: "pa-4 ml-3 secondary--text text-wrap"
  }, [_vm._v("Match Card")])]), _c('div', {
    staticClass: "pa-4 d-flex justify-space-around flex-wrap"
  }, [_c('div', {
    staticClass: "my-n6"
  }, [_c('v-row', [_c('span', {
    staticClass: "text-11 grey--text text--darken-4 mb-8"
  })]), _c('v-row', [_c('span', {
    staticClass: "text-11 grey--text text--darken-4 mt-9 mb-2"
  }, [_vm._v("Name: ")])]), _c('v-row', [_c('span', {
    staticClass: "text-11 grey--text text--darken-4 mt-2 mb-2"
  }, [_vm._v("Report Status: ")])]), _c('v-row', [_c('span', {
    staticClass: "text-11 grey--text text--darken-4 mt-3 mb-2"
  }, [_vm._v("Response Date: ")])]), _c('v-row', [_c('span', {
    staticClass: "text-11 grey--text text--darken-4 mt-3 mb-1"
  }, [_vm._v("Days to Respond: ")])])], 1), _c('div', {
    staticClass: "my-n6"
  }, [_c('p', {
    staticClass: "ml-n3 text-11 grey--text text--darken-4 mt-n8 mb-4"
  }, [_c('v-avatar', [_c('v-img', {
    attrs: {
      "src": ("https://api.dicebear.com/7.x/initials/svg?seed=" + (_vm.pairsMoreDetails[0].name))
    }
  })], 1)], 1), _c('p', {
    staticClass: "ml-n3 text-11 grey--text text--darken-4 mb-4"
  }, [_vm._v(" Champion ")]), _c('v-row', [_c('span', {
    staticClass: "mb-2 ml-1 grey--text text--darken-4"
  }, [_vm._v(" " + _vm._s(_vm.pairsMoreDetails[0].name) + " ")])]), _c('v-row', [_c('span', {
    staticClass: "secondary--text mt-1 mb-2"
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": _vm.getColor(_vm.pairsMoreDetails[0].reportStatus),
      "dark": ""
    }
  }, [_c('p', {
    staticClass: "ma-1 text-sm"
  }, [_vm._v(_vm._s(_vm.pairsMoreDetails[0].reportStatus))])])], 1)]), _c('v-row', [_c('span', {
    staticClass: "mt-1 mb-2"
  }, [_c('v-chip', {
    attrs: {
      "small": ""
    }
  }, [_c('v-icon', {
    staticClass: "icon",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
    staticClass: "ma-1 secondary--text text-sm"
  }, [_vm._v(_vm._s(_vm.pairsMoreDetails[0].responseDateV2 ? _vm.formatDateTime(_vm.pairsMoreDetails[0].responseDateV2) : 'tbc'))])], 1)], 1)]), _c('v-row', [_c('span', {
    staticClass: "secondary--text mt-1 mb-1"
  }, [_c('v-badge', {
    attrs: {
      "color": "darkGrey",
      "inline": "",
      "content": _vm.subtractDates(_vm.pairsMoreDetails[0].responseDate ? _vm.pairsMoreDetails[0].responseDate : new Date(), _vm.matchDetails.createdDate)
    }
  })], 1)])], 1), _c('div', {
    staticClass: "my-n6"
  }, [_c('p', {
    staticClass: "ml-n3 text-11 grey--text text--darken-4 mt-n8 mb-4"
  }, [_c('v-avatar', [_c('v-img', {
    attrs: {
      "src": ("https://api.dicebear.com/7.x/initials/svg?seed=" + (_vm.pairsMoreDetails[1].name))
    }
  })], 1)], 1), _c('p', {
    staticClass: "ml-n3 text-11 grey--text text--darken-4 mb-4"
  }, [_vm._v(" Challenger ")]), _c('v-row', [_c('span', {
    staticClass: "mb-2 ml-1 grey--text text--darken-4"
  }, [_vm._v(_vm._s(_vm.pairsMoreDetails[1].name) + " ")])]), _c('v-row', [_c('span', {
    staticClass: "mb-2 mt-1"
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": _vm.getColor(_vm.pairsMoreDetails[1].reportStatus),
      "dark": ""
    }
  }, [_c('p', {
    staticClass: "ma-1 text-sm"
  }, [_vm._v(_vm._s(_vm.pairsMoreDetails[1].reportStatus))])])], 1)]), _c('v-row', [_c('span', {
    staticClass: "mt-1 mb-2"
  }, [_c('v-chip', {
    attrs: {
      "small": ""
    }
  }, [_c('v-icon', {
    staticClass: "icon",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-clock-time-four ")]), _c('p', {
    staticClass: "ma-1 secondary--text text-sm"
  }, [_vm._v(_vm._s(_vm.pairsMoreDetails[1].responseDateV2 ? _vm.formatDateTime(_vm.pairsMoreDetails[1].responseDateV2) : 'tbc'))])], 1)], 1)]), _c('v-row', [_c('span', {
    staticClass: "secondary--text mt-1 mb-1"
  }, [_c('v-badge', {
    attrs: {
      "color": "darkGrey",
      "inline": "",
      "content": _vm.subtractDates(_vm.pairsMoreDetails[1].responseDate ? _vm.pairsMoreDetails[1].responseDate : new Date(), _vm.matchDetails.createdDate)
    }
  })], 1)])], 1)]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Close ")])], 1)], 1)], 1)], 2)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }